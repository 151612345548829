export default {
  home: {
    RebateAccountNumber: 'បង្វិលលេខគណនី',
    totalCommission: 'គណៈកម្មការសរុប',
    APPLY_FOR_A_REBATE: 'ដាក់ពាក្យសុំសងវិញ',
    availableBalance: 'សមតុល្យដែលអាចប្រើបាន',
    WITHDRAW_REBATE: 'ដាក់ប្រាក់ឡើងវិញ',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'ផ្ទេរប្រាក់វិញទៅក្នុងគណនីរបស់អ្នក',
    TOTAL_VOLUME: 'វ៉ុលសរុប',
    NET_FUNDING: 'មូលនិធិសុទ្ធ',
    DEPOSITS: 'ការដាក់ប្រាក់',
    WITHDRAW: 'ដកប្រាក់',
    OPEND_ACCOUNT: 'គណនីដែលបានបើក',
    PERFORMANCE: 'ការសម្តែង',
    MOST_TRADED_INSTRUMENTS: 'ឧបករណ៍ជួញដូរច្រើនបំផុត',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'គណនីឈានមុខគេសម្រាប់ខែនេះ',
    RECENTLY_OPENED_ACCOUNTS: 'គណនីដែលបានបើកថ្មីៗ',
    ACCOUNT_MANAGER: 'អ្នកគ្រប់គ្រង​គណនី',
    Account: 'គណនី',
    Name: 'ឈ្មោះ',
    Volume: 'កម្រិតសំឡេង',
    Rebate: 'សងវិញ',
    Balance: 'សមតុល្យ',
    ContactClient: 'ទាក់ទងអតិថិជន',
    applyRebateSuccessTip:
      'សូមអបអរសាទរយើងបានទទួលសំណើបង្វិលប្រាក់របស់អ្នកហើយ។  |  {amount} នឹងត្រូវបានផ្ទេរទៅគណនីបង្វិលប្រាក់ {account} ក្នុងពេលឆាប់ៗនេះ  |  នៅពេលដំណើរការរួចអ្នកនឹងអាចដកប្រាក់ឬផ្ទេរប្រាក់របស់អ្នកបាន។ ',
    HOVER: 'ការបង្វិលសងនៅថ្ងៃមុនត្រូវបានគណនានិងដំណើរការរវាង 09:00 - 11:00 AEST ជារៀងរាល់ថ្ងៃ។',
    blacklist:
      'គណនីនេះមិនអាចទទួលការបង្វិលសងប្រាក់វិញបានទេនៅពេលនេះ សូមទាក់ទងអ្នកគ្រប់គ្រងគណនីរបស់អ្នក។',
  },
  liveChat: { header: 'ជជែកផ្ទាល់' },
  contactUs: {
    tip: 'ប្រសិនបើអ្នកមានសំណួរឬចង់បានព័ត៌មានបន្ថែមសូមទាក់ទងមកយើងខ្ញុំតាមរយៈលេខទំនាក់ទំនងខាងក្រោម។  <br/>យើងនឹងព្យាយាមឆ្លើយតបទៅនឹងសំណួរទាំងអស់ក្នុងរយៈពេល ២៤ ម៉ោង។',
  },
  notification: {
    pendingIdentityProof: {
      header: 'ដំណើរការដាក់ពាក្យសុំគណនី',
      id: 'លេខសម្គាល់គឺ',
      poa: 'អាស័យដ្ឋានគឺ',
      both: 'អត្តសញ្ញាណប័ណ្ណនិងភស្តុតាងនៃអាស័យដ្ឋាន',
      content: 'ភស្តុតាងរបស់អ្នក {doc} កំពុងត្រូវបានដំណើរការហើយនឹងត្រូវបញ្ចប់ក្នុងពេលឆាប់ៗនេះ',
    },
  },
  identityProof: {
    home: {
      header: 'ការបំពេញពាក្យសុំគណនីមិនពេញលេញ',
      content:
        'សួស្តី {name} ពាក្យសុំគណនីរបស់អ្នកជិតរួចរាល់ហើយទោះយ៉ាងណាអ្នកនៅតែត្រូវបញ្ជូនឯកសារបន្ថែមដើម្បីបញ្ចប់គណនីរបស់អ្នក។',
    },
    popup: {
      header:
        'សួស្តី {name} មុនពេលគណនីផ្ទាល់របស់អ្នកត្រូវបានបញ្ចប់អ្នកត្រូវបញ្ចូលឯកសារខាងក្រោមដើម្បីផ្ទៀងផ្ទាត់។',
      addressProofSpan:
        '<strong>\n      <b>បញ្ជាក់​អាស័យ​ដ្ឋាន</b>\n      </strong>\n      <br />\n      <I>\n        បញ្ជូន <b>មួយ</b> នៃឯកសារខាងក្រោម\n        <br /><br />\n      </I>\n      - ច្បាប់ថតចម្លងវិក័យប័ត្រដែរប្រើប្រាស់ថ្មីៗ </br>(មិនលើសពី 3 ខែ)\n      <br />\n      <b class="or">ឬ</b><br />\n      - ច្បាប់ថតចម្លងវិក័យប័ត្រដែរប្រើប្រាស់ថ្មីៗ </br> (មិនលើសពី 3 ខែ)\n      <br />\n      <br />\n      <b>ការសំខាន់!</b> ឈ្មោះនៅលើឯកសារត្រូវតែបង្ហាញពីឈ្មោះពេញនិងអាស័យដ្ឋានបច្ចុប្បន្នរបស់អ្នក។\n      <br />',
    },
    reasonType: {
      4: 'ឯកសារមិនពេញលេញ',
      5: 'ឯកសារមិនច្បាស់លាស់',
      6: 'ត្រូវការច្បាប់ចម្លងមានពណ៌',
      8: 'ឈ្មោះបើកគណនីមិនត្រូវគ្នា',
      9: 'កាលបរិច្ឆេទចេញភស្តុតាងបញ្ជាក់មិនមានលក្ខណៈគ្រប់គ្រាន់',
    },
    reason: 'ឯកសាររបស់អ្នកមិនអាចផ្ទៀងផ្ទាត់បានទេ <br> មូលហេតុ៖ {reason}  សូម​ព្យាយាម​ម្តង​ទៀត។',
    success:
      'សូមអរគុណ។  យើងបានទទួលឯកសាររបស់អ្នកដោយជោគជ័យហើយបច្ចុប្បន្នកំពុងដំណើរការវាដើម្បីផ្ទៀងផ្ទាត់។',
  },
  fileUploader: {
    supportedFormat: 'ប្រភេទឯកសារដែលគាំទ្រមាន៖ png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'ទំហំឯកសារផ្ទុកឡើងអតិបរមា៖ {maxSize} មេកាបៃ',
    maxFileNum: 'ចំនួនឯកសារអតិបរមា៖ {maxFileNum}',
    failedFileExtension: 'សូមបង្ហោះប្រភេទឯកសារត្រឹមត្រូវ។',
  },
  ibAccounts: {
    ibAccount: 'គណនី IB',
    subIbs: 'គណនីរង IB',
    tradingList: 'បញ្ជីជួញដូរ',
    OpeningList: 'បើកបញ្ជី',
    closeList: 'បិទបញ្ជី',
    tradingListColumn: {
      ticket: 'TicketID',
      openTime: 'ម៉ោងបើក',
      type: 'ប្រភេទ',
      volume: 'បរិមាណ',
      item: 'ធាតុ',
      openPrice: 'តម្លៃបើក',
      closeTime: 'ម៉ោងបិទ',
      closePrice: 'តម្លៃបិទ',
      commission: 'កម្រៃជើងសារ',
      profit: 'ប្រាក់ចំណេញ',
    },
  },
  withdraw: {
    default: {
      selectCC: 'ជ្រើសរើសកាតឥណទាន',
      selectBA: ' ជ្រើសរើសគណនីធនាគារ',
      anotherCC: 'បញ្ចូលកាតឥណទានដោយដៃ',
      anotherBA: 'បន្ថែមគណនីធនាគារ',
      remember: 'ចងចាំគណនីរបស់ខ្ញុំ',
    },
    transfer: {
      info: '{platform} នឹងសងថ្លៃសេវាធនាគារដែលគិតថ្លៃសម្រាប់ការដក <u> មួយ </u> ក្នុងមួយខែ។  ការដកប្រាក់បន្ថែមក្នុងរយៈពេលមួយខែនឹងត្រូវបង់ថ្លៃសេវាធនាគារចំនួន ២០ ឯកតានៃរូបិយប័ណ្ណដកប្រាក់របស់អ្នក។',
      note: 'In order to process your withdrawal request, please provide your passport, for company accounts, please provide invoice to {email}',
      label: {
        upload: 'សូមបញ្ចូលច្បាប់ចម្លងនៃរបាយការណ៍ធនាគាររបស់អ្នក (បច្ចុប្បន្នទៅ ៣ ខែចុងក្រោយ)',
      },
      formValidation: { upload: 'សូមបញ្ចូលរបាយការណ៍ធនាគារ' },
    },
    REBATE_WITHDRAW_REQUEST: 'សងសំណង',
    PleaseSelectDesiredWithdrawMethod: 'សូមជ្រើសរើសវិធីដកប្រាក់ដែលចង់បាន',
    BankName: 'ឈ្មោះធនាគា',
    BankAccountsName: 'ឈ្មោះគណនីធនាគារ',
    FasaPayAccountName: 'ឈ្មោះគណនី FasaPay',
    AustralianBankName: 'ឈ្មោះធនាគារអូស្ត្រាលី',
    BankAccountNumber: 'លេខគណនីធនាគារ',
    FasaPayAccountNumber: 'លេខគណនី FasaPay',
    BankAccountBeneficiary: 'អ្នកទទួលគណនីធនាគារ',
    BSB: 'អេសប៊ីអេស',
    SortCode: 'តម្រៀបលេខកូដ',
    skrillEmail: 'អ៊ីមែល Skrill',
    NetellerEmail: 'អ៊ីមែល Neteller',
    BitwalletEmail: 'អ៊ីមែល Bitwallet',
    SticpayEmail: 'អ៊ីម៉ែល SticPay',
    BitcoinEmail: '  អាស័យដ្ឋានកាបូប Bitcoin',
    USDTEmail: 'អាស័យដ្ឋានកាបូបលុយ',
    BankBranch: 'សាខាធនាគា',
    Province: 'ខេត្ត',
    City: 'ទីក្រុង',
    BankAddress: 'អាស័យដ្ឋានធនាគា',
    AccountNumberIBAN: 'លេខគណនី/IBAN',
    BankBeneficiaryName: 'ឈ្មោះអ្នកទទួលប្រាក់នៅធនាគារ',
    BankAccountBeneficiaryName: 'ឈ្មោះគណនីធនាគារអ្នកទទួល',
    AccountHoldersAddress: 'អាស័យដ្ឋានរបស់ម្ចាស់គណនី',
    SelectWithdrawCard: 'ជ្រើសរើសកាតដកប្រាក់របស់អ្នក',
    Swift: 'រហ័ស',
    SwiftAud: 'រហ័ស(មិនមែនAUDគណនី)',
    ABA_SortCodeABA: 'ABA/កូដទន់',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: 'កំណត់ត្រាសំខាន់',
    ifscCode: 'កូដIFSC',
    uploadDocument: 'សូមបញ្ចូលច្បាប់ចម្លងនៃរបាយការណ៍ធនាគាររបស់អ្នក (បច្ចុប្បន្នទៅ ៣ ខែចុងក្រោយ)',
    name: 'ឈ្មោះ',
    phone: 'ទូរសព្ទ័',
    address: 'អាស័យដ្ឋាន',
    email: 'អ៊ីមែល',
    vpa: 'VPA',
    YourPaymentGreaterThanAvailableBalance: 'ការទូទាត់របស់អ្នកគឺធំជាងសមតុល្យដែលអ្នកមាន',
    integer: 'Only integer input is supported',
    CannotUseMoreThanTwoDecimalValues:
      'អ្នកមិនអាចប្រើប្រាស់លេខនិងលេខអវិជ្ជមានដែលលើសពី2នៅខ្ទង់ទសភាគ',
    YourPaymentIsBeingProcessed: 'ពាក្យស្នើសុំដកប្រាក់របស់អ្នកត្រូវបានដាក់ស្នើ',
    Itcannotbe0: 'វាមិនអាចទៅជា0បានទេ',
    noCardWarn:
      'អ្នកមិនមានកាតដែលអាចដកប្រាក់បានទេសូមភ្ជាប់កាតរបស់អ្នកនៅក្នុងព័ត៌មានលម្អិតអំពីការដកប្រាក់ជាមុន',
    amtLarger:
      'ចំនួនទឹកប្រាក់ដកអប្បបរមាគឺ ៣០ ដុល្លារ។  សមតុល្យក្រោម ៣០ ដុល្លារត្រូវតែដកចេញទាំងស្រុង',
    blackList:
      'ជាអកុសលបច្ចុប្បន្នអ្នកមិនអាចដាក់ពាក្យស្នើសុំដកប្រាក់បានទេ។  សូមទាក់ទង {supportEmail} សម្រាប់ព័ត៌មានបន្ថែម',
    NetellerChargeAlert:
      'ការដកប្រាក់របស់ Neteller ត្រូវបានកំណត់ត្រឹម ៥០០,០០០ ដុល្លារក្នុងមួយប្រតិបត្តិការហើយថ្លៃសេវា២% (កំណត់ត្រឹម ៣០ ដុល្លារ) ត្រូវបានអនុវត្ត។',
    FasaPayChargeAlert: 'FasaPay គិតថ្លៃសេវា ០.៥% រាល់ការដកប្រាក់។',
    SkrillChargeAlert: 'Skrill គិតថ្លៃប្រតិបត្តិការ ១% រាល់ការដកប្រាក់។',
    cryptoAlert:
      'សូមកត់សម្គាល់ថាយើងមិនអាចដាក់ប្រាក់ឬដកប្រាក់តាមរយៈBUSDTបានទេសូមប្រាកដថាអាស័យដ្ឋាននិងរូបិយប័ណ្ឌគ្រីបតូត្រូវគ្នានឹងសង្វាក់និងរូបិយប័ណ្ណដែលយើងទទួលយកបើមិនដូច្នោះទេអ្នកអាចបាត់បង់មូលនិធិ។',
    usdtNote:
      'តម្លៃហ្គាសបច្ចុប្បន្ននៃ ERC20 គឺខ្ពស់ជាងធម្មតាយ៉ាងខ្លាំង ហើយជាលទ្ធផលវាអាចមានការពន្យាពេលយូរក្នុងដំណើរការប្រតិបត្តិការរបស់អ្នក។  ម៉្យាងទៀត អ្នកអាចដកប្រាក់តាមរយៈ TRC20 ដោយផ្តល់អាសយដ្ឋានកាបូបដែលត្រូវគ្នា ឬអ្នកអាចជ្រើសរើសបង់ថ្លៃហ្គាស ដើម្បីបង្កើនល្បឿនការដកប្រាក់ ERC20 របស់អ្នក។',
    status: {
      withdrawalSubmitted: 'បានដាក់ស្នើរ',
      withdrawalCanceled: 'បានលុបចោល',
      withdrawalAuditing: 'កំពុងដំណើរការ',
      withdrawalRejected: 'បានបដិសេធ',
      withdrawalAccepted: 'កំពុងដំណើរការ',
      withdrawalPayFailed: 'បានបរាជ័យ',
      withdrawalSuccess: 'បានដំណើរការ',
      withdrawalFailed: 'បានបរាជ័យ',
      withdrawalPending: 'កំពុងរង់ចាំ',
      withdrawalPartialFailed: 'បានបរាជ័យ',
      transferSubmitted: 'កំពុងដំណើរការ',
      transferSuccess: 'បានដំណើរការ',
      transferRejected: 'បានបដិសេធ',
      transferFailed: 'បានបរាជ័យ',
      transferProcessing: 'កំពុងដំណើរការ',
    },
    formErrorMsg: {
      bankName: 'ឈ្មោះធនាគារត្រូវបានទាមទារ',
      bsb: 'BSB ត្រូវបានទាមទារ',
      sortCode: 'Sort Code ត្រូវបានទាមទារ',
      beneName: 'ឈ្មោះរបស់អ្នកទទួលប្រាក់ធនាគារត្រូវបានទាមទារ',
      accNum: 'លេខគណនីធនាគារត្រូវបានទាមទារ',
      swift: 'បានទាមទារយ៉ាងរហ័ស',
      bankAddress: 'អាស័យដ្ឋានធនាគារត្រូវបានទាមទារ',
      holderAddress: 'អ្នកកាន់អាស័យដ្ឋានត្រូវបានទាមទារ',
      amt: 'ចំនួនទឹកប្រាក់ត្រូវបានទាមទារ',
      firstSixDigits: 'សូមបញ្ចូលលេខ6ខ្ទង់ដំបូង ',
      lastFourDigits: 'សូមបញ្ចូលលេខ4ខ្ទង់ដំបូង',
      branchName: 'ឈ្មោះរបស់ Branch ត្រូវបានទាមទារ',
      email: 'អ៊ីមែលត្រូវបានទាមទារ',
      accName: 'ឈ្មោះគណនីត្រូវបានទាមទារ',
      province: 'ធនាគារខេត្តត្រូវបានទាមទារ',
      city: 'ធនាគារទីក្រុងត្រូវបានទាមទារ',
      bankCard: 'សូមជ្រើសរើសកាត',
      emailFormat: 'សូមបញ្ចូលអាស័យដ្នានអ៊ីមែលដែលត្រឹមត្រូវ',
      amt0: 'ចំនួនទឹកប្រាក់មិនអាចនៅសល់សូន្យបានទេ',
      ifscCode: 'កូដ IFSC ត្រូវបានទាមទារ',
      bitcoin: 'អាស័យដ្ឋានកាបូបBitcoinត្រូវបានទាមទារ',
      usdt: 'អាស័យដ្ឋានកាបូបUSDTត្រូវបានទាមទារ',
      dynamicReq: '{fieldname} ត្រូវបានទាមទារ',
      uploadDocument: 'សូមបញ្ចូលរបាយការណ៍ធនាគារ',
    },
    note: "ដោយសារការផ្លាស់ប្តូរថ្មីៗ ធនាគាររបស់យើងប្រហែលជាមិនអាចដំណើរការការផ្ទេរប្រាក់តាមធនាគារអន្តរជាតិសម្រាប់អតិថិជនដែលបានចុះឈ្មោះនៅក្នុងប្រទេសមួយចំនួនបានទេ។<br/>ប្រសិនបើអ្នកត្រូវតែដកប្រាក់តាមរយៈការផ្ទេរប្រាក់តាមធនាគារអន្តរជាតិ សូមទាក់ទងផ្នែកជំនួយអតិថិជនរបស់យើងតាមរយៈ +44 2080 363 883 ឬតាមរយៈ <a  href='mailto:{mail}'>{mail}</a>.<br/> យើងសូមអភ័យទោសចំពោះភាពរអាក់រអួល។",
    minAmountWarn:
      'ចំនួនទឹកប្រាក់ដកដែលបានអនុវត្តគឺតិចជាងអប្បបរមាសម្រាប់ច្រកផ្លូវទូទាត់ដែលបានអនុវត្ត។  សូមបញ្ចូលសមតុល្យគណនីរបស់អ្នកទៅ/លើសអប្បបរមា មុនពេលដាក់សំណើដកប្រាក់។  ព័ត៌មានបន្ថែមអាចរកបាននៅ<a href="https://{regulatorUrl}/clients/accounts/withdraw/">ទីនេះ</a>។',
  },
  rebate: {
    status: { processed: 'បានដំណើរការ', processing: 'កំពុងដំណើរការ', rejected: 'បានបដិសេធ' },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'សំណើរសុំផ្ទេរប្រាក់វិញ',
    transferAmountIsGreaterThanAvailableBalance:
      'ចំនួនទឹកប្រាក់ផ្ទេររបស់អ្នកគឺធំជាងសមតុល្យដែលអ្នកមាន',
    yourRebateTransferHasBeenProcessed: 'សំណើរសុំផ្ទេរប្រាក់របស់អ្នកត្រូវបានដាក់ស្នើរ',
    PleaseEnterTransferInformation: 'សូមបញ្ចូលព័ត៌មានសំណើផ្ទេរប្រាក់ដែលត្រឹមត្រូវ',
    tradingAccount: ' គណនីជួញដូរ',
    rebateAccount: 'គណនីបង្វិលប្រាក់',
    account: 'Account',
  },
  transferHistory: {
    payStatus: {
      processing: 'កំពុងដំណើរការ',
      paid: 'បានបង់ប្រាក់',
      unpaid: 'មិនបានបង់ប្រាក់',
      rejected: 'បានបដិសេធ',
    },
  },
  common: {
    liveChat: { desc: '<li><p>ត្រូវការជំនួយ?</p><span>ការជជែកផ្ទាល់</span></li>' },
    lang: {
      english: 'ភាសាអង់គ្លេស',
      chinese: 'ប្រទេសចិន',
      french: 'ប្រទេសបារាំង',
      thai: 'ប្រទេសថៃ',
      german: 'ប្រទេសអាឡឺម៉ង់',
      spanish: 'ប្រទេសអេស្ប៉ាញ',
      malay: 'ប្រទេសម៉ាឡេស៊ី',
      viet: 'ប្រទេសវៀតណាម',
      indo: 'ប្រទេសឥណ្ឌូនេស៊ី',
      arabic: 'ប្រទេសអារ៉ាប់',
      japanese: 'ជប៉ុន',
    },
    button: {
      confirm: 'ទទួល',
      cancel: 'បោះបង់',
      identityProof: 'បំពេញពាក្យស្នើរសុំ',
      upload: 'UPLOAD',
      selectFile: 'ជ្រើសរើសឯកសារ',
      fundnow: 'ដាក់ប្រាក់ឥលូវនេះ',
      iunderstand: 'ខ្ញុំយល់ព្រម។',
    },
    withdrawChannel: {
      banktransfer: 'ការផ្ទេរប្រាក់តាមធនាគារ',
      aus: 'ការផ្ទេរប្រាក់តាមធនាគារ ប្រទេសអូស្ដ្រាលី',
      int: 'ការផ្ទេរប្រាក់តាមធនាគារ អន្ដរជាតិ',
      chn: 'ការផ្ទេរតាមធនាគា រប្រទេសចិន',
      uk: 'ការផ្ទេរប្រាក់តាមធនាគារ - ចក្រភពអង់គ្លេស',
      skrill: 'ទាក់ទងនឹងប្រាក់ (ផ្ញើរប្រាក់អោយនរណាម្នាក់)',
      skrill_neteller: 'ទាក់ទងនឹងប្រាក់ / ការផ្ទេរប្រាក់',
      neteller: 'ការផ្ទេរប្រាក់',
      unionpay: 'ឈ្មោះក្រុមហ៊ុន Union Pay',
      fasapay: 'ឈ្មោះក្រុមហ៊ុន FasaPay',
      thailandinstantbankwiretransfer: 'ការផ្ទេរប្រាក់តាមធនាគារភ្លាមៗរបស់ប្រទេសថៃ',
      malaysiainstantbankwiretransfer: 'ការផ្ទេរប្រាក់តាមធនាគារភ្លាមៗរបស់ប្រទេសម៉ាឡេស៊ី',
      banktransferbpaypolipay: 'ការផ្ទេរប្រាក់តាមធនាគារ /BPay/PoliPay',
      bitwallet: 'ឈ្មោះក្រុមហ៊ុន Bitwallet',
      cryptocurrencybitcoin: 'រូបបិយប័ណ្ឌគ្រីបតូ Bitcoin',
      cryptocurrencyusdt: 'រូបបិយបណ័្ឌគ្រីបតូ USDT',
      nigeriainstantbankwiretransfer: 'ការផ្ទេរប្រាក់តាមធនាគារភ្លាមៗរបស់ប្រទេសនីហ្សេរីយ៉ា',
      vietnaminstantbankwiretransfer: 'ការផ្ទេរប្រាក់តាមធនាគារភ្លាមៗរបស់ប្រទេសវៀតណាម',
      indonesiainstantbankwiretransfer: 'ការផ្ទេរប្រាក់តាមធនាគារភ្លាមៗរបស់ប្រទេឥណ្ឌូនេស៊ី',
      philippinesinstantbankwiretransfer: 'ការផ្ទេរប្រាក់តាមធនាគារភ្លាមៗរបស់ប្រទេសហ្វីលីពីន',
      southafricainstantbankwiretransfer: 'ការផ្ទេរប្រាក់តាមធនាគារភ្លាមៗរបស់ប្រទេសអាព្រិចត្បូង',
      indiainstantbanktransfer: 'ការផ្ទេរប្រាក់តាមធនាគារភ្លាមៗរបស់ប្រទេសឥណ្ឌា',
      laosinstantbankwiretransfer: 'ការផ្ទេរប្រាក់តាមធនាគារភ្លាមៗរបស់ប្រទេសឡាវ',
      sticpay: 'ឈ្មោះក្រុមហ៊ុន SticPay',
      astropay: 'អាស្រ្តូផេ ( ឈ្មោះ )',
      transfer: 'គណនីផ្ទេរប្រាក់',
      dasshpeupi: 'UPI',
      ugandabanktransfer: 'សេវាផ្ទេរប្រាក់តាមធនាគារក្នុងស្រុកប្រទេសអ៊ូហ្គង់ដា',
      rwandabanktransfer: 'សេវាផ្ទេរប្រាក់តាមធនាគារក្នុងស្រុកប្រទេសរវ៉ាន់ដា',
      zambiabanktransfer: 'សេវាផ្ទេរប្រាក់តាមធនាគារក្នុងស្រុកប្រទេសហ្សំបៀ',
      congobanktransfer: 'សេវាផ្ទេរប្រាក់តាមធនាគារក្នុងស្រុកប្រទេសកុងហ្គោ',
      cameroonbanktransfer: 'សេវាផ្ទេរប្រាក់តាមធនាគារក្នុងស្រុកប្រទេសកាមេរូន',
      burundibanktransfer: 'សេវាផ្ទេរប្រាក់តាមធនាគារក្នុងស្រុកប្រទេសប៊ូរុនឌី',
      kenyabanktransfer: 'សេវាផ្ទេរប្រាក់តាមធនាគារក្នុងស្រុកប្រទេសកេនយ៉ា',
      ghanabanktransfer: 'សេវាផ្ទេរប្រាក់តាមធនាគារក្នុងស្រុកប្រទេសហ្គាណា',
      tanzaniabanktransfer: 'សេវាផ្ទេរប្រាក់តាមធនាគារក្នុងស្រុកប្រទេសតង់ហ្សានី',
      brazilbanktransfer: 'ការផ្ទេរប្រាក់តាមធនាគាររហ័សរបស់ប្រេស៊ីល',
    },
    country: { international: 'អន្ដរជាតិ', australia: 'ប្រទេសអូស្ដ្រាលី', uk: 'ចក្រភពអង់គ្លេស' },
    field: { country_region: 'ប្រទេស/តំបន់', yes: 'យល់ព្រម', no: 'បដិសេធ' },
    keys: {
      SUBMIT: 'ដាក់ស្នើ',
      FROM: 'មកពី',
      UPDATE: 'ធ្វើឱ្យទាន់សម័យ',
      STATUS: 'ស្ថានភាព',
      TO: 'ទៅ',
      DATE: 'កាលបរិច្ឆេទ',
      pleaseSelect: 'សូមជ្រើសរើស',
      amount: 'ចំនួន',
      actualAmount: '  Actual withdrawals = ',
      rebateAccount: 'គណនីបង្វិលប្រាក់',
      comingSoon: 'ជិតមកដល់ឆាប់ៗនេះ',
      tradingHistory: 'ប្រវត្តិពណិជ្ជកម្ម',
      noData: 'គ្មានទិន្នន័យ',
      NAME: 'ឈ្មោ',
      EMAIL: 'អ៊ីម៉ែល',
      ACCTYPE: 'ប្រភេទគណនី',
      PLATFORM: 'វេទិកា',
      BASECURRENCY: 'រូបិយប័ណ្ណមូលដ្ឋាន',
      DATERANGE: 'កាលបរិច្ឆេទ',
      DEST: 'គោលដៅ',
      ACCNUM: 'លេខគណនី',
      BALANCE: 'តុល្យភាព',
      NONE: 'គ្មាន',
      ALL: 'ទាំងអស់',
      PROFITLOST: 'ខាតប្រាក់ចំណេញ',
      MARGINLEVEL: 'កម្រិតចំណេញ (%)',
      ACCOUNTEQUITY: 'គណនីហេរញ្ញវត្ថុ',
      CREDIT: 'ឥណទាន',
    },
    dateRange: {
      today: 'ថ្ងៃនេះ',
      yesterday: 'ម្សិលមិញ',
      lastSevenDays: 'កាលពី7ថ្ងៃមុន',
      lastThirtyDays: 'កាលពី30ថ្ងៃមុន',
      weekToDate: 'សប្តាហ៍ដល់កាលបរិច្ឆេទ',
      monthToDate: 'ខែដល់កាលបរិច្ឆេទ',
      quarterToDate: 'ត្រីមាសដល់កាលបរិច្ឆេទ',
      previousWeek: 'សប្ដាហ៍មុន',
      previousMonth: 'ខែមុន',
      previousQuarter: 'ត្រីមាសមុន',
    },
    products: {
      forex: 'ឈ្មោះក្រុមហ៊ុន FOREX',
      commodities: 'ពាណិជ្ជកម្ម',
      indices: 'និមិត្តសញ្ញា',
      crypto: 'គ្រីបតូ',
    },
  },
  withdrawalFreeze: {
    header: 'ការដកប្រាក់បញ្ចូលសម្រាប់ពេលបច្ចុប្បន្នមិនត្រូវគាំទ្របានឡើយ។',
    desc: 'ដោយសារការកែសម្រាប់ពាស់លេខសំងាត់ចូលរបស់អ្នក, ការដកប្រាក់បញ្ចូលមិនត្រូវគាំទ្រសម្រាប់ T+{day} ថ្ងៃឡើងវិញដោយសារមានហេតុថ្លៃ។ ការការដកប្រាក់បញ្ចូលនេះរងចាំថាត្រូវបានដោះស្រាយក្នុងរយៈពេល {hour} ម៉ោង។.',
    desc2:
      'ដោយសារការកែសម្រាប់អ៊ីមែល/លេខទូរស័ព្ទចូលរបស់អ្នក, ការដកប្រាក់បញ្ចូលមិនត្រូវគាំទ្រសម្រាប់ T+{day} ថ្ងៃឡើងវិញ។ ការការដកប្រាក់បញ្ចូលនេះរងចាំថាត្រូវបានដោះស្រាយក្នុងរយៈពេល {hour} ម៉ោង។',
  },
  report: {
    rebate: {
      column: {
        name: 'ឈ្មោ',
        accountNumber: 'លេខគណនី',
        accountType: 'ប្រភេទគណនី',
        volume: 'កម្រិតសំឡេង',
        rebate: 'សងវិញ',
        totalRebate: 'ការបញ្ចុះតម្លៃសរុប',
        date: 'កាលបរិច្ឆេទ',
        instrument: 'ឧបករណ៍',
        notionalvalue: 'តម្លៃពិសេស',
      },
      dropdown: { allInstruments: 'ឧបករណ៍ទាំងអស់', all: 'ទាំងអស់', instruments: 'ឧបករណ៍' },
      others: {
        rebate: 'សងវិញ',
        instrumentsTraded: 'ឧបករណ៍ដែលបានជួញដូរ',
        lots: 'ច្រើន',
        totalRebate: 'ការបញ្ចុះតម្លៃសរុប',
        rebates: 'ការសងប្រាក់វិញ',
      },
    },
  },
  ibReport: { openedAccs: 'គណនីដែលបានបើក', funding: 'មូលនិធិ', account: 'គណនី' },
  lead: { demoAccounts: 'គណនីសាកល្បង' },
  pendingAccounts: {
    errorDoc:
      'បេក្ខជនខាងក្រោមនេះបានបំពេញបែបបទដាក់ពាក្យតាមអ៊ិនធរណេតប៉ុន្តែមិនទាន់ផ្តល់ភស្តុតាងនៃអត្តសញ្ញាណ (អត្តសញ្ញាណប័ណ្ណ) ដើម្បីបញ្ចប់គណនីរបស់ពួកគេ។',
    acceptedDocType: 'ទម្រង់បែបបទនៃការទទួលយកអត្តសញ្ញាណប័ណ្ណរួមមាន៖',
    primaryDoc: 'លេខសម្គាល់បឋម',
    passport: 'ថតចម្លងលិខិតឆ្លងដែនអន្តរជាតិដែលមានសុពលភាពរបស់អ្នក (ទំព័រហត្ថលេខា)ច្បាប់',
    id: 'ច្បាប់ចម្លងនៃរដ្ឋាភិបាលរបស់អ្នកដែលបានចេញអត្តសញ្ញាណប័ណ្ណសញ្ជាតិ',
    dl: 'ច្បាប់ថតចម្លងប័ណ្ណបើកបរដែលមានសុពលភាពរបស់អ្នក',
    address: 'លេខសម្គាល់បន្ទាប់បន្សំ',
    addressList: [
      'ច្បាប់ថតចម្លងវិក័យប័ត្រថ្លៃសេវាសាធារណៈថ្មីៗនេះ។  ឧ។  ហ្គាសឬអគ្គិសនីដែលមានឈ្មោះនិងអាស័យដ្ឋានរបស់អ្នក (មិនលើសពី ៣ ខែ)',
      'ច្បាប់ចម្លងនៃរបាយការណ៍ប័ណ្ណឥណទានឬរបាយការណ៍ធនាគាររបស់អ្នក (គ្មានការបោះពុម្ពតាមអ៊ីនធឺណិតទេ)',
    ],
    tip: 'សូមលើកទឹកចិត្តបេក្ខជនឱ្យផ្តល់នូវច្បាប់ថតចម្លងមួយនៃឯកសារអត្តសញ្ញាណប័ណ្ណបឋមនិងមធ្យមដែលបានទទួលយកមក ',
  },
  rebatePaymentHistory: {
    rebateHistory: 'ការសងប្រាក់វិញ',
    withdrawHistory: 'ដកប្រាក់',
    transferHistory: 'ការផ្លាស់ប្តូរ',
  },
  referralLinks: {
    downloadPage: 'ទាញយក APP',
    campLang: 'ភាសាយុទ្ធនាការ',
    cpy: 'ចម្លង',
    alreadyCPY: 'បានចម្លង',
    liveAcc: 'គណនីផ្ទាល់',
    demoAcc: 'គណនីសាកល្បង',
    homePage: 'ទំព័រដើម',
    promotion: 'ลิงค์การส่งเสริมกิจกรรม (Event promotion link)',
    hintTitle: 'ลิงค์การส่งเสริมกิจกรรม (event promotion link) คืออะไร?',
    hintMessage:
      'ลิงค์การส่งเสริมกิจกรรม (event promotion link) เป็นช่องทางการส่งเสริมใหม่ที่มีไว้ให้บริการแก่ IB การแชร์ลิงค์นี้จะนำผู้ใช้ไปยังหน้ากิจกรรมโบนัส และทำการลงทะเบียนให้เสร็จสิ้น การแชร์ลิงค์นี้อาจทำให้มีจำนวนของผู้ลงทะเบียนเพิ่มขึ้นได้',
    hintShortLink: 'แชร์โดยใช้การย่อลิงค์ (short link)',
    hintShortLinkTitle: 'short link คืออะไร?',
    hintShortLinkMessage:
      'การย่อลิงค์ (short link) เป็นกฎการสร้างลิงก์ซึ่งสามารถช่วยป้องกันไม่ให้ผู้อื่นทำการเปลี่ยนแปลงลิงค์ของคุณได้ และเพื่อเป็นการป้องกันสิทธิ์ในการพัฒนา IB ของคุณ',
    customButton: 'ប្ដូរតាមបំណង',
    copyButton: 'хуулбарлах',
    customInviteCodeHeader: 'លេខកូដអញ្ជើញផ្ទាល់ខ្លួន',
    currentCode: 'លេខកូដអញ្ជើញបច្ចុប្បន្ន',
    newCode: 'លេខកូដអញ្ជើញថ្មី។',
    notice:
      'ចំណាំ៖ លេខកូដអញ្ជើញមានយ៉ាងហោចណាស់ 6 ប៉ារ៉ាម៉ែត្រ។ អក្សរ លេខ និមិត្តសញ្ញាមិនពិសេសអាចត្រូវបានបញ្ចូល',
    inviteCodeFormatError: 'ទម្រង់​នៃ​លេខ​កូដ​អញ្ជើញ​ខុស សូម​កែ​សម្រួល​ម្ដង​ទៀត។',
    inviteCodeAlreadyExist: 'លេខកូដអញ្ជើញត្រូវបានចុះឈ្មោះជាមុនដោយអ្នកផ្សេងទៀត សូមបញ្ចូលម្តងទៀត',
    pleaseEnter: 'សូមបញ្ចូល',
    inviteCodeHeader: 'លេខកូដអញ្ជើញផ្សព្វផ្សាយ',
    inviteCode: 'លេខ​កូដ​ការ​អញ្ជើញ',
  },
  settings: { info: 'ព័ត៌មាន', reg: 'បានចុះឈ្មោះ' },
  unfundedAcc: {
    neverFundedAccs: 'គណនីដែលមិនធ្លាប់មាន',
    prevFundedZero: 'មូលនិធិពីមុន (សូន្យគ្មានតុល្យភាព)',
    accBalance: 'សមតុល្យ​គណនី',
  },
  multiib: { level: 'កម្រិត {កម្រិត}', noib: 'មិនមានអនុ IB នៅក្រោមគណនីនេះទេ។' },
  header: { bckToCP: 'ត្រលប់ទៅក្លោងទ្វារអតិថិជន', logout: 'ចាក់ចេញ' },
  menu: {
    dashboard: 'DASHBOARD',
    ibReport: 'របាយការណ៍របស់ IB',
    rebateReport: 'រាយការណ៍ឡើងវិញ',
    iblevel: 'ពហុកម្រិត IB',
    ibAccs: 'គណនី IB',
    leads: 'ដឹកនាំ',
    pendingAccs: 'គណនីដែលកំពុងរង់ចាំ',
    unfundedAccs: 'គណនីដែលមិនបានផ្តល់មូលនិធិ',
    payHistory: 'ប្រវត្តិដឹកជញ្ជូន',
    ibProfile: 'ប្រវត្តិរូប IB',
    refLinks: 'តំណយោង',
    contactUs: 'ទាក់ទង​មកកាន់​ពួក​យើង',
    switchCP: 'ប្ដូរទៅក្លោងទ្វារអតិថិជន',
  },
  footer: {
    riskWarn:
      'ការព្រមានអំពីហានិភ័យ៖ ការជួញដូរ Forex និង CFDs ពាក់ព័ន្ធនឹងហានិភ័យសំខាន់ហើយអាចបណ្តាលឱ្យបាត់បង់ការវិនិយោគរបស់អ្នក\n    រដ្ឋធានី។  ការជួញដូរផលិតផលដែលមានឥទ្ធិពលអាចមិនសមស្របសម្រាប់អ្នកវិនិយោគទាំងអស់។សូមពិចារណាមុនពេលជួញដូរ\n    ពិចារណាកម្រិតបទពិសោធន៍គោលបំណងវិនិយោគរបស់អ្នកនិងស្វែងរកដំបូន្មានហិរិញ្ញវត្ថុឯករាជ្យប្រសិនបើចាំបាច់\n    សូមអានឯកសារច្បាប់របស់យើងនៅលើគេហទំព័ររបស់យើងហើយធានាថាអ្នកយល់ច្បាស់អំពីហានិភ័យមុនពេលអ្នកធ្វើ\n    ការសម្រេចចិត្តជួញដូរ។',
  },
  responseMsg: {
    410: 'ការផ្ទៀងផ្ទាត់ប៉ារ៉ាម៉ែត្របានបរាជ័យ',
    411: 'ពាក្យសម្ងាត់មិនត្រឹមត្រូវ',
    420: 'មិនមានអ្នកប្រើប្រាស់ទេ',
    421: 'ការកំណត់ពាក្យសម្ងាត់បានបដិសេធ',
    490: 'សូមកុំបញ្ជូលព័ត៌មានកាតស្ទួនពេក',
    500: 'កំហុសមួយបានកើតឡើង',
    501: 'ការដាក់ពាក្យបានបរាជ័យ  សូម​ព្យាយាម​ម្តង​ទៀត​នៅ​ពេល​ក្រោយ។',
    505: 'ការផ្ទុកឯកសារបានបរាជ័យ',
    510: 'លេខកូដសារ SMS មិនត្រឹមត្រូវ',
    511: 'មិនអាចផ្ញើលេខកូដសារ SMS ទៅទូរស័ព្ទរបស់អ្នកបានទេ',
    520: 'ការចូលបានបរាជ័យ។  សូម​ព្យាយាម​ម្តង​ទៀត,',
    521: 'អ្នកប្រើប្រាស់ម្នាក់បានចូលទៅក្នុងឧបករណ៍នេះរួចហើយ!',
    522: 'សូមចូលម្តងទៀត',
    523: 'សូមចូលម្តងទៀត',
    524: 'ជាអកុសលអាស័យដ្ឋានអ៊ីមែលរបស់អ្នកមិនត្រូវបានគេស្គាល់ទេ',
    525: 'ឈ្មោះអ្នកប្រើឬពាក្យសម្ងាត់មិនត្រឹមត្រូវ',
    526: 'Recaptcha មិនត្រូវគ្នាទេ',
    527: 'អ្នកប្រើប្រាស់មិនមែនជា IB ទេ',
    528: 'មិនមានអ្នកប្រើប្រាស់ទេ',
    529: 'ឈ្មោះអ្នកប្រើឬពាក្យសម្ងាត់មិនត្រឹមត្រូវ',
    530: 'កូដមិនត្រឹមត្រូវ',
    531: 'កូដមិនត្រឹមត្រូវ',
    540: 'មិនអាចរកឃើញគណនីជួញដូររបស់អ្នកទេ',
    541: 'មិនអាចរកឃើញគណនីបង្វិលប្រាក់បានទេ',
    542: 'អ្នកមិនត្រូវបានអនុញ្ញាតឱ្យបើកគណនីបន្ថែមទេ',
    544: 'អ្នកមិនត្រូវបានអនុញ្ញាតឱ្យដាក់ពាក្យសុំគណនីបន្ថែមទេរហូតដល់ភស្តុតាងនៃអត្តសញ្ញាណប័ណ្ណរបស់អ្នកត្រូវបានផ្ទៀងផ្ទាត់',
    550: 'លេខគណនីមិនត្រឹមត្រូវ',
    551: 'សមតុល្យរបស់អ្នកមិនគ្រប់គ្រាន់',
    554: 'ចំនួនទឹកប្រាក់ដែលបានស្នើសុំគឺសូន្យឬទទេ',
    562: 'យើងមិនអាចដំណើរការការទូទាត់របស់អ្នកបានទេសូមព្យាយាមម្តងទៀត។  ប្រសិនបើបញ្ហានៅតែកើតមានសូមទាក់ទងមកយើងខ្ញុំតាមរយៈការជជែកផ្ទាល់ឬអ៊ីមែល {email}',
    564: 'ប្រទេសមិនត្រូវនឹងបណ្តាញទូទាត់ទេ',
    565: 'លេខកូដធនាគារមិនត្រឹមត្រូវ',
    566: 'រកមិនឃើញវិធីបង់ប្រាក់ទេ',
    567: 'ជាអកុសលវិធីបង់ប្រាក់នេះបច្ចុប្បន្នមិនអាចប្រើបានទេ។  យើងសូមអភ័យទោសចំពោះការរអាក់រអួលដែលបណ្តាលមកពី',
    581: 'អ្នកបានចូលរួមក្នុងការផ្សព្វផ្សាយដោយជោគជ័យ។  សូមកត់សម្គាល់ថាអ្នកត្រូវតែដាក់ប្រាក់ដើម្បីអាចដកប្រាក់។',
    584: 'អ្នកមិនមានការបញ្ចុះតម្លៃឡើងវិញដែលអាចអនុវត្តបានទេ',
    590: 'មិនអាចរកឃើញគណនីផ្ទេរប្រាក់បានទេ',
    591: 'មិនអាចរកឃើញគណនីផ្ទេរ',
    593: 'យើងមិនអាចដំណើរការការផ្ទេរប្រាក់របស់អ្នកពីគណនីដែលបានជ្រើសរើសដោយសារគណនីរបស់អ្នកមានឥណទានទេ។',
    594: 'ការផ្ទេរប្រាក់ពីគណនីនេះមិនត្រូវបានអនុញ្ញាតទេ',
    session_timeout: 'អស់ពេល, បញ្ជូនបន្តទៅទំព័រចូល។',
  },
};
