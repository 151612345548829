import { apiTo_login_by_token, apiGet_cp_url } from '@/resource'
import { handleError } from './handleError'
import store from '@/store'
import router from '@/router'
import { getRegulator, setWebWidgetByRegulator } from '@/util/regulator'
import { getCookies, setCookies } from '@/util/cookies'
import { Base64 } from 'js-base64'

function setGTM(userId) {
  window.dataLayer = window.dataLayer || [];
  dataLayer.push({ event: 'User', formName: userId })
}

export default {
  signOut(method = 'backToClientPortal', path = '') {
    const base64AccessToken = store.state.common.base64AccessToken
    store.commit('common/reset')
    store.commit('behavior/reset')
    store.commit('regulator/reset')
    store.commit('withdraw/setInitialState')
    if (method === 'logout') {
      store.commit('common/setAutoNotifyStatus')
      store.commit('common/setFirstLogin')
    }
    setCookies('token', '')
    this.getCpUrl(method, base64AccessToken, path)
  },
  loginByToken(accessToken) {
    console.log(accessToken, 'accessToken');
    apiTo_login_by_token({
      headers: {
        accessToken: Base64.decode(accessToken),
      },
    })
      .then(resp => {
        if (resp.data.code === 0) {
          const userInfo = resp.data.data
          store.dispatch('regulator/actionGetInformation')
          store.commit('common/setBase64AccessToken', accessToken)
          setCookies('token', accessToken, 30)
          store.commit('common/setLoginStatus', true)
          store.commit('common/setUserName', userInfo.userName)
          store.commit('common/setUserID', userInfo.userId)
          store.commit('common/setEmail', userInfo.email)
          store.commit('common/setPhone', userInfo.phone)
          store.commit('common/setCreateTime', userInfo.date)
          store.commit('common/setAddress', userInfo.address)
          store.commit('common/setLang', userInfo.language)
          store.commit('common/setCountryCode', userInfo.countryCode)
          store.commit('common/setRegulator', getRegulator(userInfo.regulator))
          store.commit('common/setRequireAddressProof', userInfo.identityProofStatus.requireAddressProof)
          store.commit(
            'common/setPendingAddressProofApproval',
            userInfo.identityProofStatus.pendingAddressProofApproval
          )

          setWebWidgetByRegulator(getRegulator(userInfo.regulator).toUpperCase(),userInfo.countryCode,userInfo.language)

          setCookies('cpUrl', userInfo.url, 60 * 24 * 30)
          setGTM(userInfo.userId)
          router.push('/home')
        }
      }, handleError)
      .catch(err => {
        router.go(-1)
      })
  },
  getCpUrl(method, base64AccessToken, path = '') {
    const cpUrl = getCookies('cpUrl')

    if (cpUrl) this.goToCp(method, cpUrl, base64AccessToken, path)
    else
      apiGet_cp_url().then(resp => {
        setCookies('cpUrl', resp.data.data, 60 * 24 * 30)
        this.goToCp(method, resp.data.data, base64AccessToken, path)
      }, handleError)
  },
  goToCp(method = 'backToClientPortal', cpUrl = Platform.cpUrl, base64AccessToken = '', path) {
    const baseUrl = process.env.NODE_ENV == 'development' ? 'http://localhost:8084/ ' : cpUrl
    let cpPath = ''
    if (path != null) cpPath = `&path=${path}`

    window.location =
      baseUrl +
      (method == 'logout' ? `logout?lang=${store.state.common.lang}` : `?accessToken=${base64AccessToken}${cpPath}`)
  },
}
