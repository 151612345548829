import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import commonState from './common/state'
import commonMutations from './common/mutations'

import behaviorState from './behavior/state'
import behaviorMutations from './behavior/mutations'

import regulatorState from './regulator/state'
import regulatorMutations from './regulator/mutations'
import regulatorActions from './regulator/actions'
import withdrawState from './withdraw/state'
import withdrawMutations from './withdraw/mutations'
Vue.use(Vuex)

const common = {
  namespaced: true,
  state: commonState,
  mutations: commonMutations,
}

const behavior = {
  namespaced: true,
  state: behaviorState,
  mutations: behaviorMutations,
}

const regulator = {
  namespaced: true,
  state: regulatorState,
  mutations: regulatorMutations,
  actions: regulatorActions,
}
const withdraw = {
  namespaced: true,
  state: withdrawState,
  mutations: withdrawMutations,
}
export default new Vuex.Store({
  modules: {
    common,
    behavior,
    regulator,
    withdraw
  },
  plugins: [createPersistedState()],
})
