import { countryCodeEnum } from '@/constants/country'

function getHostname() {
  return location.hostname
}

export function loadJS(param) {
  let { url = '', callback, id = '' } = param
  let srcArr = document.getElementsByTagName('script')
  for (let i = 0; i < srcArr.length; i++) {
    if (srcArr[i].id == id) {
      id = ''
    }
  }
  if (!id) return
  let head = document.getElementsByTagName('head')[0]
  let fn = () => {
    if (typeof callback === 'function') {
      callback()
    }
  }
  let js = document.createElement('script')
  js.setAttribute('type', 'text/javascript')
  js.onload = function () {
    fn()
  }
  js.onerror = () => {
    console.log('动态加载js出错')
  }
  js.setAttribute('src', url)
  js.setAttribute('s', +new Date())
  js.setAttribute('id', id)
  js.setAttribute('defer', '')
  head.appendChild(js)
}

export function removeJs(id) {
  let head = document.getElementsByTagName('head')[0]
  let srcArr = document.getElementsByTagName('script')
  for (let i = 0; i < srcArr.length; i++) {
    if (srcArr[i].id == id) {
      head.removeChild(srcArr[i])
    }
  }
}

// create kakao talk
export function createKakaoTalk() {
  setTimeout(function () {
    window.kakaoAsyncInit = function () {
      Kakao.Channel.createChatButton({
        container: '#kakao-talk-channel-chat-button',
      })
    }
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = 'https://t1.kakaocdn.net/kakao_js_sdk/2.7.1/kakao.channel.min.js'
      js.integrity = 'sha384-lOC4IQUE4pvQiN/ApcSu406vhpK70lbINlZL0+vtbOFHdJtXOScqo7gjVOITEmU6'
      js.crossOrigin = 'anonymous'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'kakao-js-sdk')
  }, 3000)
}

export function createZendesk2(tags, code, zlang) {
  setTimeout(function () {
    window.$zopim ||
      (function (d, s) {
        var z = (window.$zopim = function (c) {
            z._.push(c)
          }),
          $ = (z.s = d.createElement(s)),
          e = d.getElementsByTagName(s)[0]
        z.set = function (o) {
          z.set._.push(o)
        }
        z._ = []
        z.set._ = []
        $.async = true
        $.setAttribute('charset', 'utf-8')
        $.src = 'https://v2.zopim.com/?2dUPVq922wuufXQPqck9kSM7gpc1Nuhz'
        z.t = +new Date()
        $.type = 'text/javascript'
        e.parentNode.insertBefore($, e)
      })(document, 'script')
    $zopim(function () {
      $zopim.livechat.setLanguage(zlang || 'en')
      if (tags == 'FSC' && code == countryCodeEnum.KOREA) {
        // South Korea
        $zopim.livechat.prechatForm.setGreetings(
          'INFINOX 고객센터에 오신 것을 환영합니다.\n\n문의사항을 말씀주시면 최대한 빠르게 답변드리도록 하겠습니다.\n\n카카오톡 한국어 상담의 경우엔, 아래의 링크를 눌러주세요:\nhttp://pf.kakao.com/_wxdLxnb/chat \n\n실 계좌 개설은 이곳에서 진행해주세요 https://www.infinox.com/fsc/ko/registration/live-account'
        )
      } else if (code == countryCodeEnum.CHINA) {
        if (getHostname().includes('ixglobalweb')) {
          $zopim.livechat.prechatForm.setGreetings(
            `欢迎来到 INFINOX 客户支持。\n\n如果您有任何问题或建议，我们的客户支持团队将将尽快为您提供帮助。\n\n如需开立真实账户，请单击下方注册链接：\nhttps://cn.ixglobalweb.com/fsc/zh_cn/registration/live-account/`
          )
        } else if (getHostname().includes('ixglobalmarket')) {
          $zopim.livechat.prechatForm.setGreetings(
            `欢迎来到 INFINOX 客户支持。\n\n如果您有任何问题或建议，我们的客户支持团队将将尽快为您提供帮助。\n\n如需开立真实账户，请单击下方注册链接：\nhttps://www.ixglobalmarket.com/fsc/zh_cn/registration/live-account/`
          )
        }
      }
    })
  }, 3000)
}

export function createAdroll(regulator) {
  const adrollScript = `
  adroll_adv_id = "R7TFUM22DJBLVLLG42H6NQ";
  adroll_pix_id = "66Q32BWDENBJPCI6FATSH5";
  adroll_version = "2.0";

  (function(w, d, e, o, a) {
      w.__adroll_loaded = true;
      w.adroll = w.adroll || [];
      w.adroll.f = [ 'setProperties', 'identify', 'track' ];
      var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id
              + "/roundtrip.js";
      for (a = 0; a < w.adroll.f.length; a++) {
          w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
              return function() {
                  w.adroll.push([ n, arguments ])
              }
          })(w.adroll.f[a])
      }

      e = d.createElement('script');
      o = d.getElementsByTagName('script')[0];
      e.async = 1;
      e.src = roundtripUrl;
      o.parentNode.insertBefore(e, o);
  })(window, document);
  adroll.track("pageView");`
  const infinoxUrl = 'ibportal.infinox.com'
  if (
    (location.hostname.includes(infinoxUrl) || location.hostname.includes('ibportal-chelsea.crm-alpha.com')) &&
    regulator &&
    regulator !== 'fsc'
  ) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = adrollScript
    document.body.appendChild(script)
  }
}

export function createGtmFsc(regulator) {
  const gtmScript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-TN3DPKD');`
  const gtmBodyScript = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TN3DPKD"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`
  if (regulator == 'fsc') {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = gtmScript
    script.defer = true
    document.head.appendChild(script)
    const bodyScript = document.createElement('div')
    bodyScript.innerHTML = gtmBodyScript
    document.body.appendChild(bodyScript.firstChild)
  }
}

export function createGtmFsc2(regulator) {
  if (regulator == 'fsc') {
    const gtmPreheadSnippet = document.createElement('script')
    gtmPreheadSnippet.setAttribute('async', true)
    gtmPreheadSnippet.src = `ttps://www.googletagmanager.com/gtag/js?id=AW-325280443`
    document.head.appendChild(gtmPreheadSnippet)

    const gtmHeadSnippet = document.createElement('script')
    gtmHeadSnippet.setAttribute('type', 'text/javascript')
    gtmHeadSnippet.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'AW-325280443');
    `
    document.head.appendChild(gtmHeadSnippet)
  }
}

export function createMeta(regulator) {
  if (regulator == 'fsc') {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = `
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    );
    fbq('init', '1115178243056969');
    fbq('track', 'PageView');
    `
    document.head.appendChild(script)

    const noScript = document.createElement('noscript')
    noScript.innerHTML = `<img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=1115178243056969&ev=PageView&noscript=1"
    />`
    document.head.appendChild(noScript)
  }
}
