export default {
  home: {
    RebateAccountNumber: 'رقم حساب العمولة المرتجعة',
    totalCommission: 'اجمالي العمولة',
    APPLY_FOR_A_REBATE: 'التقديم لعمولة مرتجعة',
    availableBalance: 'الرصيد المتوفر',
    WITHDRAW_REBATE: 'سحب العمولة المرتجعة',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'تحويل العمولة المرتجعة الى حسابك',
    TOTAL_VOLUME: 'اجمالي حجم العقد',
    NET_FUNDING: 'صافي التمويل',
    DEPOSITS: 'الايداع',
    WITHDRAW: 'السحب',
    OPEND_ACCOUNT: 'حساب مفتوح',
    PERFORMANCE: 'الاداء',
    MOST_TRADED_INSTRUMENTS: 'الادوات الاكثر تداول',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: ' الحسابات الأكثر أداء لهذا الشهر',
    RECENTLY_OPENED_ACCOUNTS: 'الحسابات المفتوحة حديثا',
    ACCOUNT_MANAGER: 'ادارة الحساب',
    Account: 'الحساب  ',
    Name: 'الاسم',
    Volume: 'الحجم',
    Rebate: 'العمولة المرتجعة',
    Balance: 'الرصيد المتوفر',
    ContactClient: 'الاتصال بعميل',
    applyRebateSuccessTip:
      'تهانينا ، لقد تلقينا طلب الخصم الخاص بك. | سيتم تحويل {amount} إلى حساب الخصم {account} قريبًا. | بمجرد المعالجة ، ستتمكن من سحب أموالك أو تحويلها.',
    HOVER: 'يتم احتساب العمولات المرتجعة لليوم السابق ومعالجتها يوميًا من الساعة 09:00 إلى 11:00 بتوقيت شرق أستراليا.',
    blacklist: 'لا يمكن لهذا الحساب الحصول على حسم في الوقت الحالي، يرجى التواصل مع مدير حسابك.',
  },
  liveChat: { header: 'دردشة مباشرة' },
  contactUs: {
    tip: 'إذا كانت لديك أسئلة أو ترغب في مزيد من المعلومات ، فيرجى الاتصال بنا من خلال أحد تفاصيل الاتصال أدناه. <br> سنسعى للرد على جميع الاستفسارات في غضون 24 ساعة.',
  },
  fileUploader: {
    supportedFormat: 'أنواع الملفات المدعومة: png ، jpg ، jpeg ، bmp ، pdf ، doc ، docx',
    supportedFormatCustom: 'أنواع الملفات المدعومة: {files}',
    maxSize: 'الحد الأقصى لحجم ملف التحميل: {maxSize} ميغا بايت',
    maxFileNum: 'أقصى رقم للملف: {maxFileNum}',
    failedFileExtension: 'يرجى تحميل نوع الملف الصحيح',
  },
  notification: {
    pendingIdentityProof: {
      header: 'معالجة طلب الحساب',
      id: 'اثبات الشخصية هو :   ',
      poa: 'العنوان هو :',
      both: 'اثبات الشخصية والعنوان هو',
      content: ' يتم حاليًا معالجة إثبات {doc} الخاص بك وسيتم الانتهاء منه قريبًا.',
    },
  },
  identityProof: {
    home: {
      header: 'طلب حساب غير مكتمل',
      content:
        'مرحبًا {name} ، طلب حسابك قد اكتمل تقريبًا ، ومع ذلك لا تزال بحاجة إلى إرسال وثائق إضافية لإتمام حسابك.',
    },
    popup: {
      header: 'مرحبًا {name} ، قبل أن يتم الانتهاء من حسابك الحقيقي ، يتعين عليك تحميل المستند التالي للتحقق.',
      addressProofSpan:
        '<strong>\n      <b>تاكيد العنوان</b>\n      </strong>\n      <br />\n      <I>\n        أرسل <b> أحد </ b> من المستندات أدناه\n        <br /><br />\n      </I>\n      - نسخة من فاتورة مرافق حديثة </br> (لا يزيد عمرها عن 3 أشهر)\n      <br />\n      <b class="or">or</b><br />\n      - نسخة من كشف حساب بنكي حديث </br> (لا يزيد عمره عن 3 أشهر)\n      <br />\n      <br />\n      <b>هام! </b> يجب أن يُظهر الاسم الموجود في المستند بوضوح اسمك الكامل وعنوانك الحالي.\n      <br />',
    },
    reasonType: {
      4: 'مستندات غير مكتملة',
      5: 'المستندات غير المصرح بها',
      6: 'مطلوب نسخة ملونة',
      8: 'اسم فتح الحساب غير مطابق',
      9: 'تاريخ إصدار إثبات العنوان غير مؤهل',
    },
    reason: 'تعذر التحقق من مستنداتك ، <br> السبب: {reason}. حاول مرة اخرى.',
    success: 'شكرا لك. لقد تلقينا مستنداتك بنجاح ونقوم حاليًا بمعالجتها للتحقق منها.',
  },
  ibAccounts: {
    ibAccount: 'حساب IB',
    subIbs: 'حساب IB فرعي',
    tradingList: 'قائمة التداول',
    OpeningList: 'فتح القائمة',
    closeList: 'إغلاق القائمة',
    tradingListColumn: {
      ticket: 'معرف التذكرة',
      openTime: 'وقت الفتح',
      type: 'النوع',
      volume: 'حجم',
      item: 'العنصر',
      openPrice: 'سعر الفتح',
      closeTime: 'وقت الإغلاق',
      closePrice: 'سعر الإغلاق',
      commission: 'العمولة',
      profit: 'الربح',
    },
  },
  withdraw: {
    warning: {
      desc: 'يُرجى الانتباه إلى ما يلي:',
      desc1: 'سيتحمل المستخدم النهائي رسوم تحويل مصرفي، والتي تتراوح عادةً من 135 إلى 250 ين ياباني.',
      desc2: 'قد تتأخر طلبات السحب التي يتم إجراؤها بعد الساعة 2 مساءً أو قد تتم معالجتها في اليوم التالي.',
    },
    default: {
      selectCC: 'اختر بطاقة الإئتمان',
      selectBA: 'اختر حساب البنك',
      anotherCC: 'ادخل بطاقة الإئتمان يدويا',
      anotherBA: 'اضف حساب البنك',
      remember: 'تذكر حسابي',
    },
    transfer: {
      info: 'ستسدد {platform} الرسوم المصرفية المفروضة على عملية سحب <u> واحدة </ u> شهريًا. عمليات السحب الإضافية خلال الشهر ستتحمل رسومًا بنكية قدرها 20 وحدة من عملة السحب الخاصة بك.',
      label: { upload: 'يرجى تحميل نسخة من كشف حسابك المصرفي (الحالي إلى آخر 3 أشهر).' },
      formValidation: { upload: 'يرجى تحميل كشف حساب بنكي' },
    },
    REBATE_WITHDRAW_REQUEST: 'طلب سحب العمولة  المرتجعة',
    PleaseSelectDesiredWithdrawMethod: 'الرجاء تحديد طريقة السحب المطلوبة',
    BankName: 'اسم البنك',
    bankCode: 'الرّمز البنكي',
    bankBranchCode: 'رمز الفرع',
    bankAccountCurrency: 'عملة حساب البنك',
    BankAccountsName: 'اسم حسابات البنك',
    FasaPayAccountName: 'اسم حساب FasaPay',
    AustralianBankName: 'اسم البنك الاسترالي',
    BankAccountNumber: 'رقم حساب البنك',
    FasaPayAccountNumber: 'رقم حساب FasaPay',
    BankAccountBeneficiary: 'حساب بنك المستفيد',
    BSB: 'BSB',
    SortCode: 'الكود البنكى',
    skrillEmail: 'ايميل Skrill',
    NetellerEmail: 'ايميل Neteller',
    PerfectMoneyEmail: 'ايميل Perfect Money',
    BitwalletEmail: 'ايميل Bitwallet',
    SticpayEmail: 'ايميل SticPay',
    BitcoinEmail: 'عنوان محفظة البيتكوين',
    USDTEmail: 'عنوان محفظة Tether',
    BankAccountName: 'اسم البنك المستفيد',
    BankBranch: 'فرع بنك (رقم الفرع المكون من 3 أرقام)',
    BankAccountType: 'نوع الحساب المصرفي',
    Province: 'المقاطعة',
    City: 'المدينة',
    BankAddress: 'عنوان البنك',
    AccountNumberIBAN: 'رقم الحساب / IBAN',
    BankBeneficiaryName: 'اسم بنك المستفيد',
    BankAccountBeneficiaryName: 'اسم صاحب حساب بنك المستفيد',
    AccountHoldersAddress: 'عنوان صاحب الحساب  ',
    SelectWithdrawCard: 'حدد بطاقة السحب الخاصة بك',
    Swift: 'كود التحويل',
    SwiftAud: 'كود التحويل (حسابات ليس بالدولار الاسترالي)',
    ABA_SortCodeABA: 'ABA / رمز الفرز',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: 'ملاحظات هامة',
    ifscCode: 'كود الـ IFSC',
    uploadDocument: 'يرجى تحميل نسخة من كشف حسابك المصرفي (الحالي إلى آخر 3 أشهر).',
    YourPaymentGreaterThanAvailableBalance: 'دفعتك أكبر من الرصيد المتاح',
    integer: 'لا يدعم سوى الأعداد الصحيحة',
    CannotUseMoreThanTwoDecimalValues:
      'لا يمكنك استخدام الأعداد والأرقام السالبة التي تحتوي على أكثر من منزلتين عشريتين',
    YourPaymentIsBeingProcessed: 'تم تقديم طلب السحب الخاص بك',
    Itcannotbe0: 'لا يمكن أن يكون 0',
    noCardWarn: 'ليس لديك أي بطاقة متاحة للسحب ، يرجى ربط بطاقتك في تفاصيل السحب أولاً',
    amtLarger: 'الحد الأدنى لمبلغ السحب هو {minLimit} دولارًا. يجب سحب الأرصدة التي تقل عن 30 دولارًا بالكامل',
    blackList: 'للأسف لا يمكنك حاليًا تقديم طلب سحب. يرجى الاتصال بـ {supportEmail} للحصول على مزيد من المعلومات',
    cryptoAlert:
      'يرجى ملاحظة أننا غير قادرين على الإيداع والسحب من خلال BUSDT، يرجى التأكد من أن العنوان والعملة المشفرة يطابقان السلسلة والعملة التي نقبلها، وإلا فقد تخسر المال.',
    NetellerChargeAlert:
      'سحوبات Neteller محدودة بحد يبلغ 500،000 دولار في المعاملة، ويطبق رسم بنسبة 2% (بحد أقصى 30 دولار).',
    FasaPayChargeAlert: 'تفرض FasaPay رسم معاملة 0.5% على كل سحب.',
    SkrillChargeAlert: 'تفرض Skrill رسم معاملة 1% على كل سحب.',
    name: 'الاسم',
    phone: 'رقم الهاتف',
    address: 'العنوان',
    email: 'الايميل',
    status: {
      withdrawalSubmitted: 'تم التقديم',
      withdrawalCanceled: 'تم الالغاء',
      withdrawalAuditing: 'تتم المعالجة',
      withdrawalRejected: 'تم الرفض',
      withdrawalAccepted: 'تتم المعالجة',
      withdrawalPayFailed: 'فشلت',
      withdrawalSuccess: 'تمت المعالجة',
      withdrawalFailed: 'فشلت',
      withdrawalPending: 'قيد الانتظار',
      withdrawalPartialFailed: 'فشلت',
      transferSubmitted: 'تتم المعالجة',
      transferSuccess: 'تمت المعالجة',
      transferRejected: 'تم الرفض',
      transferFailed: 'فشلت',
      transferProcessing: 'تتم المعالجة',
    },
    formErrorMsg: {
      bankName: 'اسم البنك مطلوب',
      bsb: 'BSB مطلوب الـ',
      sortCode: 'مطلوب Sort Code',
      beneName: 'مطلوب اسم بنك المستفيد ',
      bankCodeReq: 'الرّمز البنكي مطلوب',
      bankBranchCodeReq: 'رمز الفرع مطلوب',
      accNum: ' مطلوب رقم الحساب البنكي ',
      swift: ' مطلوب كود التحويل ',
      bankAddress: 'مطلوب عنوان البنك ',
      holderAddress: 'مطلوب عنوان صاحب الحساب ',
      amt: 'الكمية مطلوبة',
      firstSixDigits: 'ادخل اول ستة ارقام ',
      lastFourDigits: 'ادخل اخر اربعة ارقام',
      branchName: 'مطلوب اسم فرع البنك',
      email: 'ادخل الايميل',
      accName: 'مطلوب اسم الحساب ',
      province: 'منطقة البنك مطلوبة',
      city: 'مدينة البنك مطلوبة',
      bankCard: 'الرجاء اختيار الكرت',
      emailFormat: 'الرجاء ادخال الايميل الصحيح',
      amt0: 'الكمية لايمكن ان تكون 0',
      ifscCode: 'مطلوب رمز الـ IFSC',
      bitcoin: 'عنوان محفظة بيتكوين مطلوب',
      bitcoinAddressLength: 'يجب أن يكون عنوان محفظة البيتكوين (Bitcoin) بين 26 و 42 رمزاً.',
      usdt: 'مطلوب عنوان محفظة USDT',
      dynamicReq: '{fieldname} مطلوب',
      uploadDocument: 'يرجى تحميل كشف حساب بنكي',
    },
    note: "بسبب التغيرات الأخيرة، قد يتعذر على بنكنا معالجة التحويلات البنكية الدولية إلى العملاء المسجلين في بعض البلدان.<br/>\n      إذا كنت مضطرًا إلى السحب من خلال التحويلات البنكية الدولية، يرجى التواصل مع خدمة عملائنا على 883 363 2080 44+ أو على <a href='mailto:{mail}'>{mail}</a>.<br/>\n      نعتذر عن أي إزعاج.",
    minAmountWarn:
      'مبلغ السحب المطبق أقل من الحد الأدنى لبوابة الدفع المطبقة. يرجى زيادة رصيد حسابك إلى الحد الأدنى أو أكثر منه قبل إرسال طلب السحب. يمكن العثور على مزيد من المعلومات<a href="https://{regulatorUrl}/clients/accounts/withdraw/">هنا.</a>',
  },
  rebate: {
    status: { processed: 'تمت المعالجة', processing: 'تتم المعالجة', rejected: 'تم الرفض' },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'طلب تحويل العمولة المرتجعة',
    transferAmountIsGreaterThanAvailableBalance: 'مبلغ التحويل الخاص بك أكبر من الرصيد المتاح',
    yourRebateTransferHasBeenProcessed: 'تم طلب تحويل العمولة المرتجعة',
    PleaseEnterTransferInformation: 'الرجاء إدخال معلومات نقل دقيقة',
    tradingAccount: 'حساب التداول',
    rebateAccount: 'حساب العمولة المرتجعة',
    account: 'الحساب',
    blackList: 'لقد حُظِر على هذا الحساب إجراء التحويلات بين الحسابات، يرجى التواصل مع {supportEmail}',
  },
  transferHistory: {
    payStatus: {
      processing: 'تتم المعالجة',
      paid: 'تم الدفع ',
      unpaid: 'لم يتم الدفع',
      rejected: 'تم الرفض',
    },
  },
  common: {
    liveChat: { desc: '<li> <p> هل تحتاج إلى مساعدة؟ </p> <span> دردشة مباشرة  </span> </li>' },
    lang: {
      english: 'الإنجليزية',
      chinese: 'الصينية',
      french: 'الفرنسية',
      thai: 'التايلندية',
      german: 'الالمانية',
      spanish: 'الاسبانية',
      malay: 'الملايو',
      viet: 'الفيتنامية',
      indo: 'الاندونيسية',
      arabic: 'العربية',
      japanese: 'اليابانية',
    },
    button: {
      confirm: 'تاكيد',
      cancel: 'الغاء',
      identityProof: 'اكمل الطلب',
      upload: 'تحميل',
      selectFile: 'اختيار ملف',
      fundnow: 'مول حسابك الان',
      iunderstand: 'أنا أفهم',
    },
    withdrawChannel: {
      hongkongbanktransfer: 'تحويل بنكي محلي في هونج كونج',
      banktransfer: 'تحويل بنكي',
      aus: 'تحويل بنكي - استراليا',
      int: 'تحويل بنكي - دولي',
      chn: 'تحويل بنكي - الصين',
      uk: 'تحويل بنكي - المملكة المتحدة)',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      fasapay: 'FasaPay',
      thailandinstantbankwiretransfer: 'تحويل بنكي مباشر لتايلاند',
      malaysiainstantbankwiretransfer: 'تحويل بنكي مباشر لماليزيا',
      banktransferbpaypolipay: 'تحويل بنكي /BPay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'عملة رقمية - بتكوين',
      cryptocurrencyusdt: 'عملة رقمية - USDT',
      nigeriainstantbankwiretransfer: 'تحويل بنكي مباشر لنيجيريا',
      vietnaminstantbankwiretransfer: 'تحويل بنكي مباشر لفيتنام',
      indonesiainstantbankwiretransfer: 'تحويل بنكي مباشر لاندونيسيا',
      philippinesinstantbankwiretransfer: 'التحويل البنكي الفوري الفلبيني',
      southafricainstantbankwiretransfer: ' التحويل البنكي الفوري لجنوب أفريقيا',
      indiainstantbanktransfer: ' تحويل بنكي مباشر للهند',
      laosinstantbankwiretransfer: 'التحويل البنكي لبنك LAOS الفوري',
      ugandabanktransfer: 'تحويل بنك محلي  اوغاندا',
      rwandabanktransfer: 'تحويل بنك محلي رواندا',
      zambiabanktransfer: 'تحويل بنك محلي زامبيا',
      congobanktransfer: 'تحويل بنك محلي الكونغو',
      cameroonbanktransfer: 'تحويل بنك محلي الكاميرون',
      burundibanktransfer: 'تحويل بنك محلي بوروندي',
      kenyabanktransfer: 'تحويل بنك محلي كينيا',
      ghanabanktransfer: 'تحويل بنك محلي غانا',
      tanzaniabanktransfer: 'تحويل بنك محلي تانزانيا',
      brazilbanktransfer: 'التحويل المصرفي الفوري في البرازيل',
      mexicobanktransfer: 'التحويل المصرفي الفوري في المكسيك',
      internetbankingmexico: '(لمكسيك) الخدمات المصرفية عبر الإنترنت',
      internetbankingbrazil: '(البرازيل) الخدمات المصرفية عبر الإنترنت',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      transfer: 'تحويل الى حسابات',
    },
    country: { international: 'دولي', australia: 'استراليا', uk: 'المملكة المتحدة' },
    field: { country_region: 'البلد / المنطقة', yes: 'نعم', no: 'لا' },
    keys: {
      SUBMIT: 'ارسال',
      FROM: 'من',
      UPDATE: 'التحديث',
      STATUS: 'الحالة',
      TO: 'الى',
      DATE: 'التاريخ',
      pleaseSelect: 'الرجاء الاختيار',
      amount: 'الكمية',
      actualAmount: '  السحوبات الفعلية = ',
      rebateAccount: 'حساب العمولة المرتجعة',
      comingSoon: 'قريبا',
      tradingHistory: 'سجل التداول',
      noData: 'لايوجد بيانات',
      NAME: 'الاسم',
      EMAIL: 'الايميل',
      ACCTYPE: 'نوع الحساب',
      PLATFORM: 'المنصة',
      BASECURRENCY: 'العملة الاساسية',
      DATERANGE: 'معدل التاريخ',
      DEST: 'المسافة',
      ACCNUM: 'رقم الحساب',
      BALANCE: 'الرصيد المتوفر',
      NONE: 'بدون',
      ALL: 'الكل',
      PROFITLOST: 'خسارة الربح',
      MARGINLEVEL: 'مستوى الهامش(٪)',
      ACCOUNTEQUITY: 'رصيد الحساب',
      CREDIT: 'ائتمان',
      DOWNLOAD: 'التحميلات',
    },
    dateRange: {
      today: 'اليوم',
      yesterday: 'البارحة',
      lastSevenDays: 'اخر 7 ايام',
      lastThirtyDays: 'اخر 30 يوم',
      weekToDate: 'اسبوع ليوم',
      monthToDate: 'الشهر ليوم',
      quarterToDate: 'ربع ليوم',
      previousWeek: 'الاسبوع السابق',
      previousMonth: 'الشهر السابق',
      previousQuarter: 'الربع السابق',
    },
    products: {
      forex: 'سوق العملات',
      commodities: 'السلع',
      indices: 'المؤشرات',
      crypto: 'العملات الرقمية',
      bond: 'السندات',
      bonds: 'السندات',
      gold: 'ذهب',
      minigold: 'ميني جولد',
      goldfutures: 'العقود الآجلة للذهب',
      bitcoin: 'بيتكوين',
      silver: 'فضة',
      silverfutures: 'العقود الآجلة للفضة',
      oil: 'نفط',
      oilfutures: 'العقود الآجلة للنفط',
      index: 'مؤشر',
      indexfutures: 'العقود الآجلة للمؤشر',
      etf: 'صناديق المؤشرات المتداولة',
      forexfutures: 'الفوركس',
      stock: 'العقود الآجلة للفوركس',
    },
  },
  withdrawalFreeze: {
    header: 'عمليات السحب غير مدعومة مؤقتًا',
    desc: 'نظرًا لتعديل كلمة مرور تسجيل الدخول الخاصة بك، السحوبات غير مدعومة مؤقتًا لمدة T+{day} أيام لضمان أمان حسابك. يُتوقع رفع هذا القيد في {hour} ساعات.',
    desc2:
      'نظرًا لتعديل عنوان بريدك الإلكتروني/رقم هاتف تسجيل الدخول الخاص بك، السحوبات غير مدعومة مؤقتًا لمدة T+{day} أيام. يُتوقع رفع هذا القيد في {hour} ساعات.',
  },
  report: {
    rebate: {
      column: {
        name: 'الاسم',
        accountNumber: 'رقم الحساب',
        accountType: 'نوع الحساب',
        volume: 'الحجم',
        rebate: 'العمولة المرتجعة',
        totalRebate: 'مجموع العمولة المرتجعة',
        date: 'التاريخ',
        instrument: 'أداة',
        notionalvalue: 'القيمة الاسمية',
      },
      dropdown: { allInstruments: 'جميع الادوات', all: 'الكل', instruments: 'الادوات ' },
      others: {
        rebate: 'العمولة المرتجعة',
        instrumentsTraded: 'ادوات تم تداولها',
        lots: 'اللوتات',
        totalRebate: 'مجموع العمولة المرتجعة',
        rebates: 'العمولة المرتجعة',
      },
    },
    btn: { showAll: 'إظهار الكل', hide: 'إخفاء' },
  },
  ibReport: { openedAccs: 'فتح حساب', funding: 'تمويل الحساب', account: 'الحساب' },
  lead: { demoAccounts: 'حسابات تجريبية' },
  pendingAccounts: {
    errorDoc:
      'قام المتقدمون التاليون بملئ نموذج الطلب عبر الإنترنت ولكنهم لم يقدموا بعد إثبات الهوية (ID) من أجل تفعيل حساباتهم.',
    acceptedDocType: 'تشمل نماذج إثبات الهوية المقبولة ما يلي:',
    primaryDoc: 'الهوية الاساسية',
    passport: 'نسخة من جواز سفرك الدولي ساري المفعول (صفحة التوقيع)',
    id: 'نسخة من بطاقة الهوية الوطنية الصادرة عن حكومتك',
    dl: 'نسخة من رخصة قيادتك سارية المفعول',
    address: 'الهوية الثانية',
    addressList: [
      'نسخة من فاتورة مرافق عامة حديثة. على سبيل المثال الغاز أو الكهرباء التي تحتوي على اسمك وعنوان إقامتك (بتاريخ لا يزيد عن 3 أشهر)',
      'نسخة من كشف حساب بطاقة الائتمان الخاصة بك أو كشف الحساب المصرفي (نسخة غير مطبوعة عبر الإنترنت)',
    ],
    tip: 'يرجى تشجيع المتقدمين على تقديم نسخة واحدة من وثائق الهوية الأساسية والثانوية المقبولة إلى',
  },
  rebatePaymentHistory: {
    rebateHistory: 'العمولة المرتجعة',
    withdrawHistory: 'السحوبات',
    transferHistory: 'التحويلات',
  },
  referralLinks: {
    copyLink: 'نسخ الوصلة',
    downloadPage: 'تحميل التطبيق',
    campLang: 'اللغة ',
    cpy: 'نسخ',
    alreadyCPY: 'تم النسخ !',
    liveAcc: 'حساب حقيقي',
    demoAcc: 'حساب تجريبي',
    homePage: 'الصفحة الرئيسية',
    promotion: 'رابط العرض الترويجي للحدث',
    hintTitle: 'ما هو رابط العرض الترويجي للحدث؟',
    hintMessage:
      'إن رابط العرض الترويجي للحدث طريقة ترويجية جديدة تقدم لوسيط التقديم. حيث يرشد رابط المشاركة المستخدمين لصفحة حدث المكافأة وإكمال التسجيل. يمكن لمعدل نجاح تسجيل المستخدمين أن يتحسن بفاعلية من خلال مشاركة الرابط.',
    hintShortLink: 'شارك باستخدام رابط قصير',
    hintShortLinkTitle: 'ما هو الرابط القصير؟',
    hintShortLinkMessage:
      'يعد الرابط القصير قاعدة لخلق الروابط، ويمكنه حماية رابطك المشارك من أن يغيره الآخرونبشكل أفضل، وبالتالي حماية حقوق تطويرك كوسيط تقديم',
    customButton: 'يعدل أو يكيف',
    copyButton: 'ينسخ',
    customInviteCodeHeader: 'رمز دعوة مخصص',
    currentCode: 'رمز الدعوة الحالي',
    newCode: 'رمز دعوة جديد',
    notice: 'ملاحظة: يحتوي رمز الدعوة على 6 معلمات على الأقل. يمكن إدخال الحروف والأرقام والرموز غير الخاصة',
    inviteCodeFormatError: 'تنسيق رمز الدعوة خاطئ ، يرجى تعديله مرة أخرى',
    inviteCodeAlreadyExist: 'تم تسجيل رمز الدعوة بشكل استباقي من قبل الآخرين ، يرجى إعادة إدخاله',
    pleaseEnter: 'تفضل',
    inviteCodeHeader: 'رمز دعوة الترويج',
    inviteCode: 'شفرة الدعوة',
  },
  settings: {
    info: 'معلومات',
    reg: 'تم التسجيل في',
    CustomerInfo: 'معلومات العميل',
    name: 'اسم',
    email: 'بريد إلكتروني',
    phone: 'رقم الهاتف',
    address: 'عنوان',
  },
  unfundedAcc: {
    neverFundedAccs: 'حسابات لم تمول ابدا',
    prevFundedZero: 'تم تمويله سابقًا (لكن الرصيد صفر الآن)',
    accBalance: 'رصيد الحساب',
  },
  multiib: { level: 'المستوى {level}', noib: 'لا يوجد وسطاء (IB) فرعيون ضمن هذا الحساب.' },
  AWCDialog: { optIn: 'اشترك الآن', noThanks: 'لا شكرا' },
  header: { bckToCP: 'عودة إلى بوابة العميل', logout: 'تسجيل الخروج' },
  menu: {
    dashboard: 'اللوحة الرئيسية',
    ibReport: 'تقرير الـ IB',
    rebateReport: 'تقرير العمولة المرتجعة',
    iblevel: 'عدة - مستويات للـ IB',
    ibAccs: 'حسابات الـ IB',
    leads: 'عملاء',
    pendingAccs: 'حسابات في الانتظار',
    unfundedAccs: 'حسابات غير ممولة',
    payHistory: 'سجل التحويلات',
    ibProfile: 'الملف الشخصي للوسيط الـ IB',
    refLinks: 'روابط الدعوة',
    contactUs: 'اتصل بنا',
    switchCP: 'التبديل إلى بوابة العميل',
  },
  footer: {
    riskWarn:
      'تحذير من المخاطر: ينطوي تداول الفوركس والعقود مقابل الفروقات على مخاطر كبيرة ويمكن أن يؤدي إلى خسارة استثماراتك\n  رأس مال. قد لا يكون تداول المنتجات مع الرافعة المالية مناسبًا لجميع المستثمرين. يرجى الأخذ في هذا الاعتبار قبل التداول\n  ضع في اعتبارك مستوى خبرتك وأهدافك الاستثمارية واطلب مشورة مالية مستقلة إذا لزم الأمر.\n  يرجى قراءة مستنداتنا القانونية على موقعنا الإلكتروني والتأكد من فهمك الكامل للمخاطر قبل القيام بأي منها\n  قرارات التداول.',
    riskWarn_fsc:
      'تحذير من المخاطر: المشتقات هي أدوات معقدة\n  وتنطوي على مخاطر عالية لخسارة الأموال \n بسرعة بسبب الرافعة المالية. يجب أن تفكر فيما\n  إذا كنت تفهم كيفية عمل المشتقات وما إذا كنت \n تستطيع أن تحمل مخاطر عالية بفقدان أموالك.',
  },
  responseMsg: {
    410: 'فشل التحقق من صحة المعامل',
    411: 'كلمة سر خاطئة',
    420: 'المستخدم غير موجود',
    421: 'رفضت عملية إعادة تعيين كلمة المرور',
    490: 'الرجاء عدم تقديم معلومات بطاقة مكررة',
    500: 'حدث خطأ.',
    501: 'فشل التطبيق. الرجاء معاودة المحاولة في وقت لاحق.',
    505: 'فشلت عملية تحميل الملف',
    510: 'رمز رسالة نصية غير صحيح',
    511: 'تعذر إرسال رمز SMS إلى هاتفك',
    520: 'فشل تسجيل الدخول. حاول مرة اخرى.',
    521: 'تم تسجيل دخول المستخدم بالفعل على هذا الجهاز!',
    522: 'الرجاد الدخول على الحساب من جديد',
    523: 'الرجاد الدخول على الحساب من جديد',
    524: 'للأسف لم يتم التعرف على عنوان بريدك الإلكتروني',
    525: 'اسم المستخدم أو كلمة المرور غير صحيحة',
    526: 'اختيارك من الرسم غير مطابق',
    527: 'المستخدم ليس وسيط تقديم',
    528: 'المستخدم غير موجود',
    529: 'اسم المستخدم أو كلمة المرور غير صحيحة',
    530: 'الرمز غير صالح',
    531: 'الرمز غير صالح',
    540: 'تعذر العثور على حساب التداول الخاص بك',
    541: 'لا يمكن العثور على حساب الخصم',
    542: 'لا يسمح لك بفتح حساب إضافي',
    544: 'لا يُسمح لك بتقديم طلب للحصول على حساب إضافي حتى يتم التحقق من إثبات هويتك',
    550: 'رقم الحساب غير صحيح',
    551: 'رصيدك غير كاف',
    554: 'المبلغ المطلوب صفر أو فارغ',
    562: 'لم نتمكن من معالجة دفعتك ، يرجى المحاولة مرة أخرى. إذا استمرت المشكلة ، يرجى الاتصال بنا عبر الدردشة المباشرة أو البريد الإلكتروني {email}',
    564: 'البلد لا تتطابق مع قناة الدفع',
    565: 'رمز البنك غير صحيح',
    566: 'طريقة الدفع غير موجودة',
    567: 'للأسف طريقة الدفع هذه غير متوفرة حاليًا. ونحن نعتذر عن أي إزعاج',
    581: 'لقد نجحت في الاشتراك في الترقية. يرجى ملاحظة أنه يجب عليك عمل إيداع لسحب الأموال.',
    584: 'ليس لديك أي خصومات قابلة للتطبيق',
    590: 'لا يمكن العثور على حساب التحويل',
    591: 'لا يمكن العثور على حساب التحويل',
    593: 'لا يمكننا معالجة تحويل أموالك من الحساب المحدد لأن  حسابك يحتوي على رصيد.',
    594: 'لا يسمح بتحويل الأموال من هذا الحساب',
    session_timeout: 'انتهت مدة الجلسة ، إعادة التوجيه إلى صفحة تسجيل الدخول.',
  },
}
