// 進行同步執行
import Vue from 'vue'

export default {
  setLang(state, value) {
    state.lang = value
  },
  setLoginStatus(state, value) {
    state.loginStatus = value
  },
  setUserName(state, value) {
    Vue.ls.set('setUserName', value)
    state.userName = value
  },
  setUserID(state, value) {
    state.userID = value
  },
  setEmail(state, value) {
    state.email = value
  },
  setPhone(state, value) {
    state.phone = value
  },
  setCreateTime(state, value) {
    state.createTime = value
  },
  setAddress(state, value) {
    state.address = value
  },
  setCpUrl(state, value) {
    state.cpUrl = value
  },
  setBase64AccessToken(state, value) {
    state.base64AccessToken = value
  },
  setCountryCode(state, value) {
    state.countryCode = value
  },
  setRegulator(state, value) {
    state.regulator = value
  },
  setRequireAddressProof(state, value) {
    state.requireAddressProof = value
  },
  setKeepUploadPOA(state, value) {
    state.keepUploadPOA = value
  },
  setPendingAddressProofApproval(state, value) {
    state.pendingAddressProofApproval = value
  },
  setAutoNotifyStatus(state, value) {
    state.autoNotifyStatus = value
  },
  setFirstLogin(state, value) {
    state.firstLogin = value
  },
  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      if (key == 'userName') Vue.ls.set('setUserName', '')
      if (key != 'CUID' && key != 'lang') state[key] = ''
    })
  },
}
