export default {
  home: {
    RebateAccountNumber: 'Número de cuenta de rebates',
    totalCommission: 'comisión total',
    APPLY_FOR_A_REBATE: 'SOLICITAR COMISIÓN',
    availableBalance: 'Saldo disponible',
    WITHDRAW_REBATE: 'RETIRAR COMISIÓN',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'TRANSFERIR',
    TOTAL_VOLUME: 'VOLUMEN TOTAL',
    NET_FUNDING: 'DEPÓSITO NETO',
    DEPOSITS: 'DEPOSITOS',
    WITHDRAW: 'RETIROS',
    OPEND_ACCOUNT: 'CUENTAS ABIERTAS',
    PERFORMANCE: 'ACTUACIÓN',
    MOST_TRADED_INSTRUMENTS: 'INSTRUMENTOS MÁS COMERCIADOS',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'CUENTAS DE MAYOR RENDIMIENTO PARA ESTE MES',
    RECENTLY_OPENED_ACCOUNTS: 'CUENTAS RECIENTEMENTE ABIERTAS',
    ACCOUNT_MANAGER: 'GERENTE DE CUENTAS',
    Account: 'Cuenta',
    Name: 'Nombre',
    Volume: 'Volumen',
    Rebate: 'COMISIÓN',
    Balance: 'balance ',
    ContactClient: 'Comuníquese con el cliente',
    applyRebateSuccessTip:
      'Felicitaciones, hemos recibido su solicitud de reembolso. | {amount} se transferirá a la cuenta de reembolso {account} dentro de 1 día hábil. | Puede retirar o transferir fondos una vez que se procese su reembolso.',
    HOVER: 'Las comisíones del día anterior se calculan y procesan entre las 09:00 y las 11:00 AEST todos los días.',
    blacklist:
      'Esta cuenta no puede efectuar reembolsos en este momento, por favor, contacte con con su gestor de cuenta.',
  },
  liveChat: { header: 'Chat en vivo' },
  contactUs: {
    tip: 'Si tiene preguntas o desea obtener más información, comuníquese con nosotros a través de uno de los datos de contacto a continuación. Nos esforzaremos por responder a todas las consultas dentro de las 24 horas.',
  },
  fileUploader: {
    supportedFormat: 'Tipos de archivo compatibles: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Tipos de archivo compatibles: {files}',
    maxSize: 'Tamaño máximo de archivo subido: {maxSize} MB',
    maxFileNum: 'Número máximo de archivos: {maxFileNum}',
    failedFileExtension: 'Cargue el tipo de archivo correcto',
  },
  notification: {
    pendingIdentityProof: {
      header: 'PROCESANDO SOLICITUD DE CUENTA',
      id: 'La identificación es',
      poa: 'La dirección es',
      both: 'La identificación y la prueba de dirección son',
      content: 'Su prueba de {doc} está siendo procesada y se completará en breve.',
    },
  },
  identityProof: {
    home: {
      header: 'SOLICITUD DE CUENTA NO COMPLETADA',
      content:
        'Hola {name}, la solicitud de su cuenta está casi completada, sin embargo, debe enviar documentación adicional para terminar.',
    },
    popup: {
      addressProofSpan:
        '<strong>\n     <b>Confirmar dirección</b>\n      </strong>\n      <br />\n      <I>\n        Envíe <b>uno</b> de los siguientes documentos\n        <br /><br />\n      </I>\n      -  Copia de una factura de gastos reciente </br>(Con una validez de hasta 3 meses)\n      <br />\n      <b class="or">or</b><br />\n      - Copia de un extracto bancario reciente </br>(Con una validez de hasta 3 meses)\n      <br />\n      <br />\n      <b>¡IMPORTANTE!</b> El documento DEBE mostrar claramente su nombre completo y dirección actual.\n      <br />',
    },
    reasonType: {
      4: 'Documentos incompletos',
      5: 'Documentos no aprobados',
      6: 'Requerida copia en color',
      8: 'El nombre de la apertura de cuenta no coincide',
      9: 'La fecha de emisión de la prueba de dirección no reúne los requisitos requeridos',
    },
    reason: 'No se ha podido verificar su documentación,<br> Razón: {reason}. Por favor, inténtelo de nuevo.',
    success: 'Gracias. Hemos recibido sus documentos y los estamos procesando para su verificación.',
  },
  ibAccounts: {
    ibAccount: 'Cuenta IB',
    subIbs: 'Cuenta Sub IB',
    tradingList: 'Lista de trading',
    OpeningList: 'Lista de apertura',
    closeList: 'Lista de cierre',
    tradingListColumn: {
      ticket: 'TickedID',
      openTime: 'Hora de apertura',
      type: 'Tipo',
      volume: 'Volumen',
      item: 'Producto',
      openPrice: 'Precio de apertura',
      closeTime: 'Hora de cierre',
      closePrice: 'Precio de cierre',
      commission: 'Comisión',
      profit: 'Beneficio',
    },
  },
  withdraw: {
    warning: {
      desc: 'Por favor, tenga en cuenta lo siguiente:',
      desc1:
        'El usuario final incurrirá en una comisión por transferencia bancaria, que suele oscilar entre los 135 y los 250 JPY.',
      desc2:
        'Las solicitudes de retiro de fondos realizadas después de las 14.00 horas pueden sufrir demoras o tramitarse al día siguiente.',
    },
    default: {
      selectCC: 'Seleccione la tarjeta de crédito',
      selectBA: 'Seleccione la cuenta bancaria',
      anotherCC: 'Introduzca la tarjeta de crédito manualmente',
      anotherBA: 'Añadir cuenta bancaria',
      remember: 'Recuerda mi cuenta',
    },
    transfer: {
      info: '{platform} will reimburse the bank fee charged for <u>ONE</u> withdrawal per month. Additional withdrawals within the month will incur a bank fee of 20 units of your withdrawal currency.',
      note: 'In order to process your withdrawal request, please provide your passport, for company accounts, please provide invoice to {email}',
      label: {
        upload: 'Please upload a copy of your bank statement (current to the last 3 months)',
      },
      formValidation: { upload: 'Please upload a bank statement' },
    },
    REBATE_WITHDRAW_REQUEST: 'Solicitar la retirada de la comisión',
    PleaseSelectDesiredWithdrawMethod: 'Seleccione el método de retiro deseado',
    BankName: 'Nombre del banco',
    bankCode: 'Código bancario',
    bankBranchCode: 'Código de sucursal',
    bankAccountCurrency: 'Divisa de la cuenta bancaria',
    BankAccountsName: 'Nombre de cuentas bancarias',
    FasaPayAccountName: 'Nombre de la cuenta de FasaPay',
    AustralianBankName: 'Nombre del banco australiano',
    BankAccountNumber: 'Número de cuenta bancaria',
    FasaPayAccountNumber: 'Número de cuenta de FasaPay',
    BankAccountBeneficiary: 'Beneficiario de la cuenta bancaria',
    BSB: 'BSB',
    SortCode: 'Código de sucursa',
    skrillEmail: 'Correo electrónico de Skrill',
    NetellerEmail: 'Correo electrónico de Neteller',
    PerfectMoneyEmail: 'Correo electrónico de Perfect Money',
    BitwalletEmail: 'Correo electrónico de Bitwallet',
    SticpayEmail: 'Correo electrónico de SticPay',
    BitcoinEmail: 'Dirección de la cartera de Bitcoin',
    USDTEmail: 'Dirección de la cartera de Tether',
    BankAccountName: 'Nombre del beneficiario del banco',
    BankBranch: 'Sucursal bancaria (número de la sucursal compuesto por 3 dígitos)',
    BankAccountType: 'Tipo de cuenta bancaria',
    Province: 'Provincia',
    City: 'Ciudad',
    BankAddress: 'Dirección del banco',
    AccountNumberIBAN: 'Número de cuenta / IBAN',
    BankBeneficiaryName: 'Nombre del beneficiario del banco',
    BankAccountBeneficiaryName: 'Nombre del beneficiario de la cuenta bancaria',
    AccountHoldersAddress: 'Dirección del titular de la cuenta',
    SelectWithdrawCard: 'Seleccione su tarjeta de retiro',
    Swift: 'Swift',
    SwiftAud: 'Swift (cuentas sin AUD)',
    ABA_SortCodeABA: 'ABA / Código de clasificación',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: 'Notas importantes',
    ifscCode: 'Código IFSC',
    uploadDocument:
      'Por favor, envíe una copia de su extracto bancario (actualizado con una antigüedad no superior a 3 meses)',
    YourPaymentGreaterThanAvailableBalance: 'Su pago es mayor que el saldo disponible',
    integer: 'Solo se admite la entrada de números enteros',
    CannotUseMoreThanTwoDecimalValues: 'No puede usar números y números negativos con más de dos lugares decimales',
    YourPaymentIsBeingProcessed: 'Su solicitud de retiro ha sido enviada',
    Itcannotbe0: 'No puede ser 0',
    noCardWarn:
      'No tiene ninguna tarjeta disponible para realizar retiros, primero vincule su tarjeta en Detalles del retiro',
    amtLarger:
      'La cantidad mínima de retiro es de {minLimit} dólares. Los saldos inferiores a 30 dólares deben ser retirados en su totalidad.',
    blackList:
      'Desafortunadamente actualmente no puede solicitar una retirada. Por favor, póngase en contacto con {supportEmail} para más información',
    NetellerChargeAlert:
      'Los retiros de Neteller están limitados a $ 500,000 por transacción y se aplica una tarifa del 2% (con un límite de $ 30).',
    FasaPayChargeAlert: 'FasaPay cobra una tarifa de transacción del 0,5% por cada retiro.',
    SkrillChargeAlert: 'Skrill cobra una tarifa de transacción del 1% por cada retiro.',
    cryptoAlert:
      'Por favor, tenga en cuenta que no podemos depositar o retirar BUSDT. Por favor, asegúrese que la dirección y la criptodivisa coinciden con la cadena y divisa disponibles o podría resultar en una pérdida de fondos.',
    name: 'Nombre',
    phone: 'Número de teléfono',
    address: 'Dirección',
    email: 'Correo electrónico',
    status: {
      withdrawalSubmitted: 'Presentada',
      withdrawalCanceled: 'Cancelado',
      withdrawalAuditing: 'Procesando',
      withdrawalRejected: 'Rechazado',
      withdrawalAccepted: 'Procesando',
      withdrawalPayFailed: 'Ha fallado',
      withdrawalSuccess: 'Procesada',
      withdrawalFailed: 'Ha fallado',
      withdrawalPending: 'Pendiente',
      withdrawalPartialFailed: 'Ha fallado',
      transferSubmitted: 'Procesando',
      transferSuccess: 'Procesada',
      transferRejected: 'Rechazado',
      transferFailed: 'Ha fallado',
      transferProcessing: 'Procesando',
    },
    formErrorMsg: {
      bankName: 'Se requiere el nombre del banco',
      bsb: 'Se requiere BSB',
      sortCode: 'El Sort Code es obligatorio',
      bankCodeReq: 'Se necesita el código bancario',
      bankBranchCodeReq: 'Se necesita el código de sucursal',
      beneName: 'Se requiere el nombre del beneficiario del banco',
      accNum: 'Se requiere el número de cuenta bancaria',
      swift: 'Se requiere Swift',
      bankAddress: 'Se requiere la dirección del banco',
      holderAddress: 'Se requiere la dirección del titular',
      amt: 'Se requiere la cantidad',
      firstSixDigits: 'Ingrese los primeros 6 dígitos',
      lastFourDigits: 'Ingrese los últimos 4 dígitos',
      branchName: 'El nombre de la sucursal es obligatorio',
      email: 'correo electronico es requerido',
      accName: 'Se requiere el nombre de la cuenta',
      province: 'La provincia del banco es obligatoria',
      city: 'La ciudad del banco es obligatoria',
      bankCard: 'Por favor seleccione una tarjeta',
      emailFormat: 'Por favor, introduzca una dirección de email correcta',
      amt0: 'La cantidad no puede ser 0',
      ifscCode: 'Se requiere IFSC',
      bitcoin: 'La dirección de la cartera de Bitcoin es obligatoria',
      bitcoinAddressLength: 'La dirección de la cartera de Bitcoin debe tener entre 26 y 42 caracteres',
      usdt: 'La dirección de la cartera de USDT es obligatoria',
      dynamicReq: 'El campo {fieldname} es obligatorio',
      uploadDocument: 'Por favor, envíe un extracto bancario',
    },
    note: "Debido a cambios recientes, nuestro banco podría no ser capaz de procesar transferencias internacionales para clientes registrados en ciertos países. <br/>\n      Si desea retirar a través de una transferencia bancaria internacional, por favor, póngase en contacto con atención al cliente en +44 2080 363 883 o en <a href='mailto:{mail}'>{mail}</a>.<br/>\n      Lamentamos los inconvenientes.",
    minAmountWarn:
      'La cantidad de retirada solicitada es menor al mínimo necesario para la plataforma de pago seleccionada. Por favor, asegúrese de que dispone de fondos suficientes antes de realizar una solicitud de retirada. Puede encontrar más información <a href="https://{regulatorUrl}/clients/accounts/withdraw/">aquí</a>.',
  },
  rebate: { status: { processed: 'Procesada', processing: 'Procesando', rejected: 'Rechazado' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'SOLICITUD DE TRANSFERENCIA DE COMISIÓN',
    transferAmountIsGreaterThanAvailableBalance: 'El monto de su transferencia es mayor que el saldo disponible',
    yourRebateTransferHasBeenProcessed: 'su transferencia de reembolso ha sido enviada',
    PleaseEnterTransferInformation: 'Ingrese información de transferencia precisa',
    tradingAccount: 'Cuenta de operaciones',
    rebateAccount: 'Cuenta de comisión',
    account: 'Cuenta',
    blackList:
      'Esta cuenta ha sido restringida por hacer una transferencia entre cuentas, por favor, póngase en contacto en {supportEmail}',
  },
  transferHistory: {
    payStatus: {
      processing: 'Procesando',
      rejected: 'Rechazado',
      paid: 'Pagado',
      unpaid: 'No pagado',
    },
  },
  common: {
    selectIb: 'Seleccionar IB',
    liveChat: { desc: '<li><p>¿Necesita ayuda?</p><span>Chat en vivo</span></li>' },
    lang: {
      english: 'Inglés',
      chinese: 'chino',
      french: 'francés',
      thai: 'tailandés',
      german: 'alemán',
      spanish: 'Español',
      malay: 'malayo',
      viet: 'vietnamita',
      indo: 'indonesio',
      arabic: 'Arábica',
      japanese: 'Japonesa',
    },
    button: {
      confirm: 'CONFIRMAR',
      cancel: 'CANCELAR',
      identityProof: 'COMPLETAR SOLICITUD',
      upload: 'ENVIAR',
      selectFile: 'Seleccionar archivo',
      fundnow: 'AÑADIR FONDOS AHORA',
      iunderstand: 'Entiendo',
    },
    withdrawChannel: {
      hongkongbanktransfer: 'Transferencia bancaria local de Hong Kong',
      banktransfer: 'Transferencia bancaria',
      aus: 'Transferencia bancaria - Australia',
      int: 'Transferencia bancaria - Internacional',
      chn: 'Transferencia bancaria - China',
      uk: 'Transferencia bancaria - Reino Unido',
      unionpay: 'Pago sindical',
      thailandinstantbankwiretransfer: 'Transferencia bancaria instantánea de Tailandia',
      malaysiainstantbankwiretransfer: 'Transferencia bancaria instantánea de Malasia',
      banktransferbpaypolipay: 'Transferencia bancaria / BPay / PoliPay',
      cryptocurrencybitcoin: 'Criptodivisa-Bitcoin',
      cryptocurrencyusdt: 'Criptodivisa-USDT',
      nigeriainstantbankwiretransfer: 'Transferencia bancaria instantánea de Nigeria',
      vietnaminstantbankwiretransfer: 'Transferencia bancaria instantánea de Vietnam',
      indonesiainstantbankwiretransfer: 'Transferencia bancaria instantánea de Indonesia',
      philippinesinstantbankwiretransfer: 'Transferencia bancaria instantánea de Philippine',
      southafricainstantbankwiretransfer: 'Transferencia bancaria instantánea de Sudáfrica',
      indiainstantbanktransfer: 'Transferencia bancaria instantánea de India',
      laosinstantbankwiretransfer: 'Transferencia bancaria instantánea de Laos',
      ugandabanktransfer: 'Transferencia bancaria local de Uganda',
      rwandabanktransfer: 'Transferencia bancaria local de Ruanda',
      zambiabanktransfer: 'Transferencia bancaria local de Zambia',
      congobanktransfer: 'Transferencia bancaria local del Congo',
      cameroonbanktransfer: 'Transferencia bancaria local de Camerún',
      burundibanktransfer: 'Transferencia bancaria local de Burundi',
      kenyabanktransfer: 'Transferencia bancaria local de Kenia',
      ghanabanktransfer: 'Transferencia bancaria local de Ghana',
      tanzaniabanktransfer: 'Transferencia bancaria local de Tanzania',
      brazilbanktransfer: 'Transferencia bancaria instantánea de Brasil',
      mexicobanktransfer: 'Transferencia bancaria instantánea de México',
      internetbankingmexico: 'Banca online (México)',
      internetbankingbrazil: 'Banca online (Brasil)',
      astropay: 'Astropay',
      transfer: 'Transferencia a las cuentas',
    },
    country: { international: 'Internacional', australia: 'Australia', uk: 'Reino Unido' },
    field: { country_region: 'País / Región', yes: 'Sí' },
    keys: {
      SUBMIT: 'ENVIAR',
      FROM: 'DESDE',
      UPDATE: 'ACTUALIZAR',
      TO: 'HASTA',
      DATE: 'FECHA',
      pleaseSelect: 'Por favor seleccione',
      amount: 'Cantidad',
      actualAmount: '  Retiradas reales = ',
      rebateAccount: 'Cuenta de comisión',
      comingSoon: 'En breve',
      tradingHistory: 'HISTORIAL COMERCIAL',
      noData: 'Sin datos',
      NAME: 'NOMBRE',
      EMAIL: 'CORREO ELECTRÓNICO',
      ACCTYPE: 'TIPO DE CUENTA',
      PLATFORM: 'PLATAFORMA',
      BASECURRENCY: 'MONEDA BASE',
      DATERANGE: 'RANGO DE FECHAS',
      STATUS: 'Estado',
      DEST: 'DESTINO',
      ACCNUM: 'NÚMERO DE CUENTA',
      BALANCE: 'SALDO',
      NONE: 'Ninguno',
      ALL: 'Todas',
      PROFITLOST: 'Ganancia /\nPerdida',
      MARGINLEVEL: 'Nivel de margen(%)',
      ACCOUNTEQUITY: 'Equidad de cuentas',
      CREDIT: 'Crédito',
      DOWNLOAD: 'DESCARGAS',
      MOBILE: 'Móvil',
      ACCEQUITY: 'Equidad de la cuenta',
    },
    dateRange: {
      today: 'Hoy',
      yesterday: 'Ayer',
      lastSevenDays: 'Los últimos 7 días',
      lastThirtyDays: 'Últimos 30 días',
      weekToDate: 'Semana hasta la fecha',
      monthToDate: 'Mes hasta la fecha',
      quarterToDate: 'Trimestre hasta la fecha',
      previousWeek: 'Semana pasada',
      previousMonth: 'Mes anterior',
      previousQuarter: 'Trimestre anterior',
    },
    products: {
      forex: 'FOREX',
      commodities: 'MATERIAS PRIMAS',
      indices: 'ÍNDICES',
      crypto: 'CRYPTO',
      sharecfd: 'ACCIONES CFD',
      bond: 'BONOS',
      bonds: 'BONOS',
      gold: 'ORO',
      minigold: 'MINI ORO',
      goldfutures: 'FUTUROS DE ORO',
      bitcoin: 'BITCOIN',
      silver: 'PLATA',
      silverfutures: 'FUTUROS DE PLATA',
      oil: 'PETRÓLEO',
      oilfutures: 'FUTUROS DE PETRÓLEO',
      index: 'ÍNDICE',
      indexfutures: 'FUTUROS DE ÍNDICE',
      etf: 'ETF',
      forexfutures: 'FOREX',
      stock: 'FUTUROS DE FOREX',
    },
  },
  withdrawalFreeze: {
    header: 'Los retiros no se admiten temporalmente',
    desc: 'Debido a la modificación de su contraseña de inicio de sesión, los retiros no son compatibles temporalmente durante T+{day} días para garantizar la seguridad de su cuenta. Se espera que esta restricción se levante en {hour} horas.',
    desc2:
      'Debido a la modificación de su dirección de correo electrónico/número de teléfono de inicio de sesión, los retiros no son compatibles temporalmente durante T+{day} días. Se espera que esta restricción se levante en {hour} horas.',
  },
  report: {
    rebate: {
      column: {
        name: 'NOMBRE',
        accountNumber: 'NÚMERO DE CUENTA',
        accountType: 'TIPO DE CUENTA',
        volume: 'Volumen',
        rebate: 'COMISIÓN',
        totalRebate: 'COMISIÓN TOTAL',
        date: 'FECHA',
        instrument: 'INSTRUMENTO',
        notionalvalue: 'VALOR NOCIONAL',
        sharecfd: 'ACCIONES CFD',
      },
      dropdown: {
        allInstruments: 'Todos los instrumentos',
        all: 'Todas',
        instruments: 'Instrumentos',
      },
      others: {
        rebate: 'COMISIÓN',
        instrumentsTraded: 'INSTRUMENTOS COMERCIADOS',
        lots: 'Lotes',
        totalRebate: 'Rebate total',
        rebates: 'COMISIÓN',
        searchBy: 'Buscar por nombre/cuenta',
      },
    },
    btn: { showAll: 'Mostrar Todo', hide: 'Ocultar' },
    trade: {
      column: {
        account: 'Cuenta',
        platform: 'Plataforma',
        symbol: 'Símbolo',
        type: 'Tipo',
        closeTime: 'Tiempo de cierre',
        profit: 'Beneficio',
        commission: 'Comisión',
      },
      clear: 'Borrar',
    },
  },
  ibReport: { openedAccs: 'CUENTAS ABIERTAS', funding: 'FONDOS', account: 'CUENTA' },
  lead: { demoAccounts: 'CUENTAS DEMO' },
  pendingAccounts: {
    errorDoc:
      'Los siguientes solicitantes han completado el formulario de solicitud en línea, pero aún deben proporcionar una prueba de identificación (ID) para finalizar su cuenta.',
    acceptedDocType: 'Las formas de identificación aceptadas incluyen:',
    primaryDoc: 'ID principal',
    passport: 'Una copia de su pasaporte internacional válido (página de firma)',
    id: 'Una copia de la tarjeta de identidad nacional emitida por su gobierno',
    dl: 'Una copia de su licencia de conducir válida.',
    address: 'Identificación secundaria',
    addressList: [
      'Una copia de una factura reciente de servicios públicos. Por ejemplo, gas o electricidad que contenga su nombre y dirección residencial (no mayor de 3 meses)',
      'Una copia del extracto de su tarjeta de crédito o extracto bancario (sin impresiones en línea)',
    ],
    tip: 'Solicite a los solicitantes a proporcionar una copia de UNO de los documentos de identificación primarios y secundarios aceptados a',
    divc: 'Una copia de su tarjeta de identificación de votante',
    dric: 'Una copia de su tarjeta de identificación de residente',
  },
  rebatePaymentHistory: {
    rebateHistory: 'HISTORIAL DE COMISIÓN',
    withdrawHistory: 'HISTORIAL DE RETIROS',
    transferHistory: 'HISTORIAL DE TRANSFERENCIAS',
  },
  referralLinks: {
    copyLink: 'COPY LINK',
    downloadPage: 'Descargar aplicación',
    campLang: 'Idioma de la campaña',
    cpy: 'COPIAR',
    alreadyCPY: 'COPIADO!',
    liveAcc: 'Cuenta real',
    demoAcc: 'Cuenta demo',
    homePage: 'Página de inicio',
    promotion: 'Enlace de promoción de eventos',
    hintTitle: '¿Qué es un enlace de promoción de eventos?',
    hintMessage:
      'El enlace de promoción de eventos es un nuevo método de promoción proporcionado a los IBs. El enlace a compartir guiará a los usuarios a la página del evento de bono y cumplimentación del registro. El porcentaje de éxito en cuanto a registro de usuarios puede mejorarse de forma efectiva compartiendo este enlace.',
    hintShortLink: 'Compartir usando un enlace corto',
    hintShortLinkTitle: '¿Qué es un enlace corto?',
    hintShortLinkMessage:
      'El enlace corto se basa en una regla de generación de enlaces que permite proteger mejor su enlace compartido para evitar que otras personas lo cambien, protegiendo así sus derechos de desarrollo como IB',
    customButton: 'personalizar',
    copyButton: 'Copiar',
    customInviteCodeHeader: 'código de invitación personalizado',
    currentCode: 'código de invitación actual',
    newCode: 'nuevo código de invitación',
    notice:
      'Nota: El código de invitación tiene al menos 6 parámetros. Se pueden ingresar letras, números, símbolos no especiales',
    inviteCodeFormatError: 'El formato del código de invitación es incorrecto, edítelo nuevamente.',
    inviteCodeAlreadyExist:
      'El código de invitación ha sido registrado de forma preventiva por otros, vuelva a ingresar',
    pleaseEnter: 'por favor escribe',
    inviteCodeHeader: 'Código de invitación de promoción',
    inviteCode: 'Código de invitación',
    selectCountryOrLang: 'Seleccione el país o el idioma preferido de su cliente',
    byCountry: 'Por país',
    byLanguage: 'Por idioma',
  },
  settings: {
    info: 'INFORMACÍON',
    reg: 'REGISTRADO EN',
    CustomerInfo: 'Información del cliente',
    name: 'Nombre',
    email: 'Correo electrónico',
    phone: 'Teléfono de contacto',
    address: 'Dirección',
  },
  unfundedAcc: {
    neverFundedAccs: 'CUENTAS NUNCA FINANCIADAS',
    prevFundedZero: 'FINANCIADO ANTERIORMENTE (SALDO CERO)',
    accBalance: 'SALDO DE LA CUENTA',
  },
  multiib: { level: 'NIVEL {level}', noib: 'No hay Sub-IBs en esta cuenta.' },
  AWCDialog: { optIn: 'PARTICIPE AHORA', noThanks: 'NO, GRACIAS' },
  header: { bckToCP: 'VOLVER AL PORTAL DE CLIENTE', logout: 'CERRAR SESIÓN', welcome: 'Hola,' },
  menu: {
    dashboard: 'PANEL DE CONTROL',
    ibReport: 'INFORME IB',
    rebateReport: 'REPORTE DE COMISIÓN',
    iblevel: 'SUB-IB',
    ibAccs: 'CUENTAS IB',
    leads: 'LEADS',
    pendingAccs: 'CUENTAS PENDIENTES',
    unfundedAccs: 'CUENTAS NO FINANCIADAS',
    payHistory: 'HISTORIAL DE TRANSACCIONES',
    ibProfile: 'PERFIL IB',
    refLinks: 'ENLACES DE REFERENCIA',
    contactUs: 'CONTÁCTENOS',
    switchCP: 'CAMBIAR A PORTAL DE CLIENTE',
    tradeReport: 'REPORTE DE TRADING',
    allRetailAccs: 'CUENTAS DE CLIENTES',
  },
  footer: {
    riskWarn:
      'Advertencia de riesgo: operar en Forex y CFD implica un riesgo significativo y puede resultar en la pérdida de su\n    capital. La negociación de productos apalancados puede no ser adecuada para todos los inversores. Antes de operar, tenga en cuenta\n    su nivel de experiencia, objetivos de inversión y busque asesoría financiera independiente si es necesario.\n    Lea nuestros documentos legales en nuestro sitio web y asegúrese de comprender completamente los riesgos antes de realizar cualquier\n    decisiones comerciales.',
    riskWarn_fsc:
      'Advertencia de riesgo: Los Derivados son instrumentos complejos y conllevan un alto riesgo de perder dinero rápidamente debido al apalancamiento. Debe considerar si entiende cómo funcionan los Derivados y si puede permitirse asumir el alto riesgo de perder su dinero.',
  },
  responseMsg: {
    410: 'Error de validación del parámetro',
    411: 'La contraseña no es correcta',
    420: 'El usuario no existe',
    421: 'Restablecimiento de contraseña rechazado',
    490: 'No envíe información de tarjetas duplicadas',
    500: 'Ha ocurrido un error.',
    501: 'Error en su solicitud Por favor, inténtelo de nuevo más tarde.',
    505: 'Error al enviar el archivo',
    510: 'El código SMS no es correcto',
    511: 'No se puede enviar el código SMS a su teléfono',
    520: 'Error al iniciar sesión. Por favor, vuelva a intentarlo.',
    521: '¡Un usuario ya ha iniciado sesión en este dispositivo!',
    522: 'Por favor, inicie sesión de nuevo',
    523: 'Por favor, inicie sesión de nuevo',
    524: 'Desafortunadamente, su dirección de correo electrónico no fue reconocida',
    525: 'El nombre de usuario y la contraseña no son correctos',
    526: 'El recaptcha no coincide',
    527: 'El usuario no es un IB',
    528: 'El usuario no existe',
    529: 'El nombre de usuario y la contraseña no son correctos',
    530: 'Código no válido',
    531: 'Código no válido',
    540: 'No se pudo encontrar su cuenta de trading',
    541: 'No se pudo encontrar la cuenta de reembolso',
    542: 'No se le permite abrir cuentas adicionales',
    544: 'No puede solicitar una cuenta adicional hasta que se verifique su prueba de identidad',
    550: 'El número de cuenta no es correcto',
    551: 'No tiene suficiente saldo',
    554: 'La cantidad solicitada es cero o nula',
    562: 'No fue posible procesar su pago, por favor, inténtelo de nuevo. Si el problema persiste, por favor contáctenos por chat, o por correo electrónico.',
    564: 'El país no coincide con el medio de pago',
    565: 'El código bancario no es correcto',
    566: 'Método de pago no encontrado',
    581: 'Elegiste con éxito el ascenso. Por favor, tenga en cuenta que debe hacer un depósito para poder retirar los fondos.',
    584: 'No tiene ninguna comisión aplicable',
    590: 'No se pudo encontrar la cuenta de su transferencia saliente',
    591: 'No se pudo encontrar la cuenta de su transferencia entrante',
    593: 'No podemos procesar la transferencia de sus fondos desde la cuenta seleccionada porque su cuenta contiene crédito.',
    594: 'No se permiten transferencias de fondos desde esta cuenta',
    session_timeout: 'La sesión ha llegado al límite de tiempo, serás redirigido a la página de acceso.',
  },
  allRetailAccs: {
    placeholder: 'Buscar email/nombre/cuenta/móvil',
    type: { all: 'Todo', funded: 'Con fondos', unfunded: 'Sin fondos' },
    filter: { name: 'Nombre', email: 'Email', account: 'Cuenta' },
    search: 'Búsqueda',
  },
}
