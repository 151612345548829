import { endAjaxLoading } from '@/util/loading'
import { Message } from 'element-ui'
import i18n from '@/lang/index'
import helper from '@/util/signinHelper.js'
import { setCookies, getCookies } from '@/util/cookies'
import store from '@/store'

function respHandler(resp) {
  endAjaxLoading()

  //if response is session_timeout, logout.
  if (resp.data.code === 'session_timeout') helper.signOut('logout')
  else {
    //if response isn't session_timeout, then update token.
    const token = getCookies('token')
    setCookies('token', token, 30)
  }

  const toIgnore = [0, 638, 639]

  if (resp.data.hasOwnProperty('code') && !toIgnore.includes(resp.data.code)) {
    let respMsg = getResponseMsg(resp.data.code)

    //if response code for Trade Report
    if (resp.data.code == 4100) {
      Message({
        message: i18n.t('report.trade.dateRangeLimit'),
        type: 'error',
      })
    }

    //if response code: 5xx, show error msg
    else if (resp.data.code >= 500) {
      Message({
        message: respMsg,
        type: 'error',
      })
    }

    //if response code: 4xx, show warning msg
    else if (resp.data.code >= 400) {
      Message({
        message: respMsg,
        type: 'warning',
      })
    }
  }

  return resp
}
function getResponseMsg(code) {
  const email =
    store.state.regulator.contactEmail ||
    Platform.info.getEmail(store.state.common.regulator, store.state.common.countryCode)
  return i18n.t('responseMsg.' + code, { email: email })
}
export default respHandler
