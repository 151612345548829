import Vue from 'vue'
import Router from 'vue-router'
import identity from './identity'
Vue.use(Router)

const pages = {
  '/home': () => import('@/views/home/index'),
  '/ibreport': () => import('@/views/IbReport'),
  '/rebatereport': () => import('@/views/RebateReport'),
  '/tradereport': () => import('@/views/TradeReport'),
  '/iblevel': () => import('@/views/IbLevel'),
  '/ibaccounts': () => import('@/views/IbAccounts'),
  '/pendingaccounts': () => import('@/views/PendingAccounts'),
  '/leads': () => import('@/views/Leads'),
  // '/unfundedaccounts': () => import('@/views/UnfundedAccounts'),
  '/allretailaccounts': () => import('@/views/AllRetailAccounts/index.vue'),
  '/rebateTransfer': () => import('@/views/RebateTransfer'),
  '/rebateWithdraw': () => import('@/views/RebateWithdraw'),
  '/settings': () => import('@/views/Settings'),
  '/contactUs': () => import(`@/views/platform/au/ContactUs`), // import(`@/views/platform/${process.env.PLATFORM}/ContactUs`),
  '/rebatePaymentHistory': () => import('@/views/RebatePaymentHistory'),
  '/referralLinks': () => import('@/views/ReferralLinks'),
}
console.log(process.env.PLATFORM)
let routes = [
  {
    path: '',
    component: () => import('@/views/Layout'),
    children: [
      {
        name: '/',
        path: '/',
        component: () => import('@/views/home/index'),
      },
      ...Object.keys(pages).map(b => {
        return {
          name: b,
          path: b,
          component: pages[b],
        }
      }),
    ],
  },
]

const router = new Router({
  mode: 'history',
  routes: [
    ...routes,
    {
      path: '',
      component: () => import('@/views/Layout'),
      children: [
        {
          name: '404',
          path: '*',
          component: () => import('@/views/404'),
        },
      ],
    },
  ],
})

export default router
identity.authorize()
