export default {
  home: {
    RebateAccountNumber: 'บัญชี IB',
    totalCommission: 'ค่าคอมมิชชั่นทั้งหมด',
    APPLY_FOR_A_REBATE: 'ส่งคำร้องเพื่อรับค่าคอมมิชชั่น',
    availableBalance: 'ยอดเงินคงเหลือ',
    WITHDRAW_REBATE: 'สั่งถอนค่าคอมมิชชั่น',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'โอนรีเบตไปยังบัญชีของคุณ',
    TOTAL_VOLUME: 'ปริมาณการซื้อขายทั้งหมด',
    NET_FUNDING: 'ยอดฝากสุทธิ',
    DEPOSITS: 'ยอดฝากทั้งหมด',
    WITHDRAW: 'ยอดถอนทั้งหมด',
    OPEND_ACCOUNT: 'จำนวนบัญชีที่เปิด',
    PERFORMANCE: 'ผลงาน',
    MOST_TRADED_INSTRUMENTS: 'ผลิตภัณฑ์ยอดนิยม',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'บัญชีที่ทำผลงานสูงสุดสำหรับเดือนนี้',
    RECENTLY_OPENED_ACCOUNTS: 'บัญชีที่ถูกเปิดเมื่อไม่นานมานี้',
    ACCOUNT_MANAGER: 'ฝ่ายบริการลูกค้า ',
    Account: 'บัญชี',
    Name: 'ชื่อ',
    Volume: 'ปริมาณการซื้อขาย',
    Rebate: 'ค่าคอมมิชชั่น',
    Balance: 'ยอดคงเหลือ',
    ContactClient: 'ติดต่อลูกค้า',
    applyRebateSuccessTip:
      'ขอแสดงความยินดี เราได้รับคำสั่งถอนค่าคอมมิชชั่นของท่านแล้ว |เงินจำนวน {amount} จะถูกโอนไปยังบัญชีรับค่าคอมมิชชั่น {account} ภายใน 1 วันทำการ | โดยท่านสามารถถอนหรือโอนเงินได้เมื่อค่าคอมมิชชั่นได้ถูกดำเนินการเรียบร้อยแล้ว',
    HOVER:
      'ค่าคอมมิชชั่นของวันก่อนหน้าจะถูกคำนวณและดำเนินการระหว่างเวลา 09:00 ถึง 11:00 ตามเวลาของประเทศออสเตรเลียในแต่ละวัน',
    blacklist: 'ไม่สามารถรับเงินคืนเข้าไปยังบัญชีนี้ได้ในขณะนี้ โปรดติดต่อผู้จัดการบัญชีของคุณ',
  },
  liveChat: { header: 'ไลฟ์แชท' },
  contactUs: {
    tip: 'หากคุณมีคำถามหรือต้องการข้อมูลเพิ่มเติมโปรดติดต่อเราผ่านทางหนึ่งในรายละเอียดการติดต่อด้านล่าง เราจะพยายามตอบคำถามทั้งหมดภายใน 24 ชั่วโมง',
  },
  fileUploader: {
    supportedFormat: 'ประเภทไฟล์ที่รองรับ: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'ประเภทไฟล์ที่รองรับ: {files}',
    maxSize: 'ขนาดไฟล์สูงสุดที่สามารถอัปโหลดได้ : {maxSize} MB',
    maxFileNum: 'จำนวนไฟล์สูงสุด: {maxFileNum}',
    failedFileExtension: 'โปรดอัปโหลดประเภทไฟล์ที่ถูกต้อง',
  },
  notification: {
    pendingIdentityProof: {
      header: 'กำลังดำเนินการเปิดบัญชี',
      id: 'เอกสารยืนยันตัวตนคือ',
      poa: 'ที่อยู่',
      both: 'เอกสารยืนยันตัวตนและที่อยู่คือ',
      content: 'กำลังประมวลผล {doc} ของท่านจะได้รับการสรุปในไม่ช้า',
    },
  },
  identityProof: {
    home: {
      header: 'การสมัครไม่สมบูรณ์',
      content:
        'สวัสดี คุณ {name} การสมัครเปิดบัญชีของท่านใกล้จะเสร็จสมบูรณ์แล้ว แต่ท่านยังต้องส่งเอกสารเพิ่มเติมเพื่อทำให้บัญชีของท่านเสร็จสมบูรณ์ ',
    },
    popup: {
      header: 'สวัสดี {name} ก่อนที่บัญชีซื้อขายของท่านจะได้รับการอนุมัติ ท่านจจะต้องอัปโหลดเอกสารต่อไปนี้เพื่อยืนยัน ',
      addressProofSpan:
        '<strong>\n      <b>ยืนยันที่อยู่</b>\n      </strong>\n      <br />\n      <I>\n      ส่ง <b>หนึ่ง</b> ในเอกสารด้านล่าง\n        <br /><br />\n      </I>\n      - สำเนาบิลค่าสาธารณูปโภคล่าสุด </br> (ไม่เกิน 3 เดือน)\n      <br />\n      <b class="or">or</b><br />\n      - สำเนารายการเดินบัญชีล่าสุด </br> (ไม่เกิน 3 เดือน)\n      <br />\n      <br />\n      <b>ข้อควรระวัง !</b>เอกสารต้องแสดงชื่อ-สกุล และที่อยู่อย่างชัดเจน\n      <br />',
    },
    reasonType: {
      4: 'เอกสารไม่ครบ',
      5: 'เอกสารไม่ชัดเจน',
      6: 'ต้องใช้สำเนาที่มีสี',
      8: 'ชื่อเปิดบัญชีไม่ตรงกัน',
      9: 'ที่อยู่หลักฐานวันที่ออกไม่ผ่านการรับรอง',
    },
    reason: 'ไม่สามารถตรวจสอบเอกสารของคุณได้<br> เหตุผล: {reason} กรุณาลองอีกครั้ง',
    success: 'ขอบคุณ เราได้รับเอกสารของท่านเรียบร้อยแล้วและกำลังดำเนินการตรวจสอบ',
  },
  ibAccounts: {
    ibAccount: 'บัญชี IB',
    subIbs: 'บัญชี IB ย่อย',
    tradingList: 'รายการซื้อขาย',
    OpeningList: 'เปิดรายการ',
    closeList: 'ปิดรายการ',
    tradingListColumn: {
      ticket: 'รหัสไอดีทิกเก็ต',
      openTime: 'เวลาเปิด',
      type: 'ประเภท',
      volume: 'ปริมาณ',
      item: 'รายการ',
      openPrice: 'ราคาเปิด',
      closeTime: 'เวลาปิด',
      closePrice: 'ราคาปิด',
      commission: 'คอมมิชชั่น',
      profit: 'กำไร',
    },
  },
  withdraw: {
    warning: {
      desc: 'หมายเหตุ:',
      desc1: 'ท่านจะต้องชำระค่าธรรมเนียมการโอนเงินผ่านธนาคารซึ่งโดยปกติจะอยู่ระหว่าง 135 ถึง 250 JPY',
      desc2: 'หากคำขอถอนเงินหลังเวลา 14:00 น.อาจมีความล่าช้าหรือดำเนินการในวันถัดไป',
    },
    default: {
      selectCC: 'เลือกบัตรเครดิต',
      selectBA: 'เลือกบัญชีธนาคาร',
      anotherCC: 'กรอกบัตรเครดิตด้วยตนเอง',
      anotherBA: 'เพิ่มบัญชีธนาคาร',
      remember: 'จดจำบัญชีของฉัน',
    },
    transfer: {
      info: '{platform} จะชำระค่าธรรมเนียมธนาคารคืนสำหรับการถอนเงิน <u>หนึ่ง</u> ครั้งต่อเดือน การถอนเงินเพิ่มเติมในเดือนนั้นจะถูกเก็บค่าธรรมเนียม 20 หน่วยของสกุลเงินที่ทำการถอน',
      label: { upload: 'โปรดอัปโหลดสำเนารายการเงินฝากถอนในบัญชีเงินฝาก (ไม่เกิน 3 เดือน)' },
      formValidation: { upload: 'โปรดอัปโหลดรายการเงินฝากถอนในบัญชีเงินฝาก' },
    },
    REBATE_WITHDRAW_REQUEST: 'คำร้องขอถอนเงินค่าคอมมิชชั่น',
    PleaseSelectDesiredWithdrawMethod: 'กรุณาเลือกช่องทางการถอนเงินที่ต้องการ',
    BankName: 'ชื่อธนาคาร',
    bankCode: 'รหัสธนาคาร',
    bankBranchCode: 'รหัสสาขา',
    bankAccountCurrency: 'สกุลเงินของบัญชีธนาคาร',
    BankAccountsName: 'ชื่อบัญชี',
    FasaPayAccountName: 'ชื่อบัญชี FasaPay',
    AustralianBankName: 'ชื่อธนาคารออสเตรเลีย',
    BankAccountNumber: 'เลขบัญชี',
    FasaPayAccountNumber: 'เลขบัญชี FasaPay',
    BankAccountBeneficiary: 'ธนาคารผู้รับผลประโยชน์',
    BSB: 'รหัส BSB',
    SortCode: 'จัดเรียงรหัส',
    skrillEmail: 'อีเมล Skrill',
    NetellerEmail: 'อีเมล Neteller',
    PerfectMoneyEmail: 'อีเมล Perfect Money',
    BitwalletEmail: 'อีเมล Bitwallet',
    SticpayEmail: 'อีเมล SticPay',
    BitcoinEmail: 'ที่อยู่ Bitcoin Wallet',
    USDTEmail: 'ที่อยู่ Tether Wallet',
    BankBranch: 'สาขาธนาคาร (กรุณาใส่หมายเลขสาขา 3 หลัก)',
    BankAccountName: 'ชื่อบัญชีผู้รับเงิน',
    BankAccountType: 'ประเภทบัญชีธนาคาร',
    Province: 'จังหวัด',
    City: 'เมือง',
    BankAddress: 'ที่อยู่ธนาคาร ',
    AccountNumberIBAN: 'เลขบัญชี/IBAN',
    BankBeneficiaryName: 'ชื่อผู้รับผลประโยชน์',
    BankAccountBeneficiaryName: 'ชื่อธนาคารผู้รับผลประโยชน์',
    AccountHoldersAddress: 'ที่อยู่ของเจ้าของบัญชี',
    SelectWithdrawCard: 'เลือกบัตรสั่งถอน',
    Swift: 'รหัส Swift',
    SwiftAud: 'Swift (ไม่ใช่บัญชีสกุลเงินดอลลาร์ออสเตรเลีย)',
    ABA_SortCodeABA: 'ABA/Sort Code',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: 'หมายเหตุสำคัญ',
    ifscCode: 'รหัส IFSC',
    uploadDocument: 'โปรดอัปโหลดสำเนารายการเงินฝากถอนในบัญชีเงินฝาก (ไม่เกิน 3 เดือน)',
    YourPaymentGreaterThanAvailableBalance: 'การชำระเงินของท่านมีจำนวนมากกว่ายอดคงเหลือในบัญชี',
    integer: 'รองรับเฉพาะข้อมูลนำเข้าเป็นจำนวนเต็ม',
    CannotUseMoreThanTwoDecimalValues: 'ท่านไม่สามารถใช้ตัวเลขหรือตัวเลขติดลบมากกว่าทศนิยมสองตำแหน่งได้',
    YourPaymentIsBeingProcessed: 'คำสั่งถอนได้ถูกดำเนินการเป็นที่เรียบร้อย',
    Itcannotbe0: 'ต้องไม่เป็น 0 ',
    noCardWarn: 'ท่านไม่มีบัตรที่พร้อมใช้งานสำหรับการสั่งถอน กรุณาเชื่อมโยงบัตรของท่านในส่วนของรายละเอียดการถอนก่อน',
    amtLarger: 'จำนวนเงินถอนขั้นต่ำคือ {currencyType} {minLimit} ยอดคงเหลือต่ำกว่า 30 จะต้องถอนออกเต็มจำนวน',
    blackList: 'ขออภัย เราไม่สามารถส่งคำขอการถอนเงินได้ในขณะนี้ โปรดติดต่อ {supportEmail} สำหรับข้อมูลเพิ่มเติม',
    NetellerChargeAlert:
      'การถอนเงินของ Neteller ถูกจำกัดไว้ที่ $500,000 ต่อธุรกรรม และมีค่าธรรมเนียม 2% (ต่อยอดที่ $30)',
    FasaPayChargeAlert: 'FasaPay เรียกเก็บค่าธรรมเนียมการทำธุรกรรม 0.5% สำหรับการถอนทุกครั้ง',
    SkrillChargeAlert: 'Skrill เรียกเก็บค่าธรรมเนียมการทำธุรกรรม 1% สำหรับทุกการถอน',
    cryptoAlert:
      'โปรดทราบว่าเราไม่สามารถฝากหรือถอนผ่าน BUSDT ได้ โปรดตรวจสอบให้แน่ใจว่าที่อยู่และสกุลเงินดิจิทัลตรงกับห่วงโซ่และสกุลเงินที่เรายอมรับ มิฉะนั้นคุณอาจสูญเสียเงิน',
    name: 'ชื่อ-นามสกุล',
    phone: 'หมายเลขโทรศัพท์',
    address: 'ที่อยู่',
    email: 'อีเมล',
    status: {
      withdrawalSubmitted: 'คำร้องขอถูกส่งเป็นที่เรียบร้อย\r\n',
      withdrawalCanceled: 'สถานะถูกยกเลิก',
      withdrawalAuditing: 'กำลังอยู่ในขั้นดำเนินการ',
      withdrawalRejected: 'ถูกปฏิเสธ',
      withdrawalAccepted: 'กำลังอยู่ในขั้นดำเนินการ',
      withdrawalPayFailed: 'สถานะไม่สำเร็จ ',
      withdrawalSuccess: 'ถูกดำเนินการเป็นที่เรียบร้อย',
      withdrawalFailed: 'สถานะไม่สำเร็จ ',
      withdrawalPending: 'รอดำเนินการ\r\n',
      withdrawalPartialFailed: 'สถานะไม่สำเร็จ ',
      transferSubmitted: 'กำลังอยู่ในขั้นดำเนินการ',
      transferSuccess: 'ถูกดำเนินการเป็นที่เรียบร้อย',
      transferRejected: 'ถูกปฏิเสธ',
      transferFailed: 'สถานะไม่สำเร็จ ',
      transferProcessing: 'กำลังอยู่ในขั้นดำเนินการ',
    },
    formErrorMsg: {
      bankName: 'จำเป็นต้องระบุชื่อบัญชี',
      bsb: 'จำเป็นต้องระบุรหัส BSB',
      sortCode: 'กรุณากรอกหมายเลข Sort Code',
      beneName: 'จำเป็นต้องระบุชื่อผู้รับผลประโยชน์',
      bankCodeReq: 'กรุณาระบุรหัสธนาคาร',
      bankBranchCodeReq: 'กรุณาระบุรหัสสาขา',
      accNum: 'จำเป็นต้องระบุเลขบัญชี',
      swift: 'จำเป็นต้องกรอกรหัส Swift',
      bankAddress: 'จำเป็นต้องต้องระบุที่อยู่ธนาคาร',
      holderAddress: 'จำเป็นต้องระบุที่อยู่ของผู้ถือบัตร',
      amt: 'จำเป็นต้องกรอกจำนวนเงิน',
      firstSixDigits: 'กรุณากรอกตัวเลข 6 หลักแรก',
      lastFourDigits: 'กรุณากรอกตัวเลข 4 หลักสุดท้าย',
      branchName: 'จำเป็นต้องระบุสาขา',
      email: 'จำเป็นต้องระบุอีเมล',
      accName: 'จำเป็นต้องกรอกชื่อบัญชี',
      province: 'จำเป็นต้องระบุที่อยู่จังหวัดของธนาคาร',
      city: 'จำเป็นต้องระบุประเทศของธนาคาร',
      bankCard: 'กรุณาเลือกบัตร',
      emailFormat: 'กรุณากรอกที่อยู่อีเมลที่ถูกต้อง',
      amt0: 'จำนวนไม่สามารถเป็น 0 ',
      ifscCode: 'ต้องใช้รหัส IFSC',
      bitcoin: 'กรุณากรอกที่อยู่ Bitcoin Wallet',
      bitcoinAddressLength: 'ที่อยู่กระเป๋าเงิน Bitcoin จะต้องประกอบด้วยตัวเลขและตัวอักษร 26-42 ตัว',
      usdt: 'กรุณากรอกที่อยู่ USDT Wallet',
      dynamicReq: '{fieldname} เป็นสิ่งจำเป็น',
      uploadDocument: 'โปรดอัปโหลดรายการเงินฝากถอนในบัญชีเงินฝาก',
    },
    note: "เนื่องจากการเปลี่ยนแปลงเมื่อไม่นานมานี้ ธนาคารของเราจึงอาจไม่สามารถดำเนินการโอนเงินผ่านธนาคารระหว่างประเทศสำหรับลูกค้าที่ลงทะเบียนในบางประเทศได้<br/>\n      หากคุณต้องถอนเงินผ่านการโอนเงินผ่านธนาคารระหว่างประเทศ โปรดติดต่อฝ่ายสนับสนุนลูกค้าของเราที่ +44 2080 363 883 หรือที่ <a href='mailto:{mail}'>{mail}</a><br/>\n      เราขออภัยในความไม่สะดวก",
    minAmountWarn:
      'จำนวนเงินที่ถอนออกนั้นน้อยกว่าจำนวนเงินขั้นต่ำสำหรับช่องทางการชำระเงินที่ใช้ กรุณาฝากเงินเข้าไปยังยอดเงินคงเหลือในบัญชีของคุณให้มีจำนวน/มากกว่าจำนวนเงินขั้นต่ำก่อนที่จะส่งคำร้องขอถอนเงิน สามารถดูข้อมูลเพิ่มเติม <a href="https://{regulatorUrl}/clients/accounts/withdraw/">ได้ที่นี่</a>',
  },
  rebate: {
    status: {
      processed: 'ถูกดำเนินการเป็นที่เรียบร้อย',
      processing: 'อยู่ระหว่างการดำเนินการ',
      rejected: 'ถูกปฏิเสธ',
    },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'คำร้องขอโอนเงินค่าคอมมิชชั่น',
    transferAmountIsGreaterThanAvailableBalance: 'จำนวนการโอนเงินของท่านมากกว่ายอดคงเหลือในบัญชี',
    yourRebateTransferHasBeenProcessed: 'การโอนเงินค่าคอมมิชชั่นได้ถูกดำเนินการเป็นที่เรียบร้อย',
    PleaseEnterTransferInformation: 'กรุณากรอกข้อมูลการโอนเงินที่ถูกต้อง\r\n',
    tradingAccount: 'บัญชีซื้อขาย',
    rebateAccount: 'บัญชีค่าคอมมิชชั่น',
    account: 'บัญชี',
    blackList: 'บัญชีนี้ถูกจำกัดการโอนเงินระหว่างบัญชีอื่น โปรดติดต่อ {supportEmail}',
  },
  transferHistory: {
    payStatus: {
      processing: 'กำลังดำเนินการ',
      paid: 'ชำระเงินแล้ว',
      unpaid: 'ยังไม่ได้ชำระเงิน',
      rejected: 'ถูกปฏิเสธ',
    },
  },
  common: {
    liveChat: { desc: '<li><p>ต้องการความช่วยเหลือหรือไม่</p><span>แชทสด</span></li>' },
    lang: {
      english: 'ภาษาอังกฤษ',
      chinese: 'ภาษาจีน',
      french: 'ภาษาฝรั่งเศษ',
      thai: 'ภาษาไทย',
      german: 'ภาษาเยอรมัน',
      spanish: 'ภาษาสเปน',
      malay: 'ภาษามลายู',
      viet: 'ภาษาเวียดนาม',
      indo: 'ภาษาอินโดนีเซีย',
      arabic: 'ภาษาอาหรับ',
      japanese: 'ญี่ปุ่น',
    },
    button: {
      confirm: 'ยืนยัน',
      cancel: 'ยกเลิก',
      identityProof: 'กรุณากรอกคำร้องให้เสร็จสมบูรณ์',
      upload: 'อัปโหลด',
      selectFile: 'เลือกไฟล์',
      fundnow: 'ฝากเงินตอนนี้',
      iunderstand: 'ฉันเข้าใจ',
    },
    withdrawChannel: {
      hongkongbanktransfer: 'การโอนเงินผ่านธนาคารภายในฮ่องกง',
      banktransfer: 'โอนเงินผ่านธนาคารระหว่างประเทศ',
      aus: 'โอนเงินผ่านธนาคารระหว่างประเทศ - ประเทศออสเตรเลีย',
      int: 'โอนเงินผ่านธนาคาร - ระหว่างประเทศ',
      chn: 'โอนเงินผ่านธนาคาร - ประเทศจีน',
      uk: 'โอนเงินผ่านธนาคาร - สหราชอาณาจักร',
      thailandinstantbankwiretransfer: 'โอนเงินผ่านธนาคารไทย',
      malaysiainstantbankwiretransfer: 'โอนเงินผ่านธนาคารมาเลเซีย',
      banktransferbpaypolipay: 'โอนเงินผ่านธนาคารระหว่างประเทศ / BPay / PoliPay',
      cryptocurrencybitcoin: 'สกุลเงินดิจิตัล Crypto-Bitcoin',
      cryptocurrencyusdt: 'สกุลเงินดิจิตัล Crypto-USDT',
      nigeriainstantbankwiretransfer: 'โอนเงินผ่านธนาคารไนจีเรีย',
      vietnaminstantbankwiretransfer: 'โอนเงินผ่านธนาคารเวียดนาม',
      indonesiainstantbankwiretransfer: 'โอนเงินผ่านธนาคารอินโดนีเซีย',
      philippinesinstantbankwiretransfer: 'โอนเงินผ่านธนาคารฟิลิปปินส์',
      southafricainstantbankwiretransfer: 'การโอนเงินผ่านธนาคารทันทีของแอฟริกาใต้',
      indiainstantbanktransfer: 'อินเดียโอนเงินผ่านธนาคารทันที',
      laosinstantbankwiretransfer: 'โอนเงินผ่านธนาคารได้ทันทีในประเทศลาว',
      ugandabanktransfer: 'การโอนเงินผ่านธนาคารในประเทศยูกันดา',
      rwandabanktransfer: 'การโอนเงินผ่านธนาคารในประเทศรวันดา',
      zambiabanktransfer: 'โอนเงินผ่านธนาคารในประเทศแซมเบีย',
      congobanktransfer: 'โอนเงินผ่านธนาคารในประเทศคองโก',
      cameroonbanktransfer: 'โอนเงินผ่านธนาคารในประเทศแคเมอรูน',
      burundibanktransfer: 'โอนเงินผ่านธนาคารในประเทศบุรุนดี',
      kenyabanktransfer: 'โอนเงินผ่านธนาคารในประเทศเคนยา',
      ghanabanktransfer: 'โอนเงินผ่านธนาคารในประเทศกานา',
      tanzaniabanktransfer: 'โอนเงินผ่านธนาคารในประเทศแทนซาเนีย',
      brazilbanktransfer: 'โอนเงินผ่านธนาคารบราซิล',
      mexicobanktransfer: 'เม็กซิโกโอนเงินผ่านธนาคารทันที',
      internetbankingmexico: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (เม็กซิโก)',
      internetbankingbrazil: 'ธุรกรรมออนไลน์ผ่านอินเทอร์เน็ต (บราซิล)',
      astropay: 'Astropay',
      transfer: 'โอนเข้าบัญชี',
    },
    country: { international: 'ระหว่างประเทศ', australia: 'ออสเตรเลีย', uk: 'สหราชอาณาจักร' },
    field: { country_region: 'ประเทศ/ภูมิภาค', yes: 'ใช่', no: 'ไม่ใช่' },
    keys: {
      SUBMIT: 'ส่ง',
      FROM: 'จาก',
      UPDATE: 'อัปเดตข้อมูล',
      TO: 'ถึง',
      DATE: 'วันที่',
      pleaseSelect: 'เลือกช่วงเวลา',
      amount: 'จำนวน',
      actualAmount: '  การถอนเงินจริง = ',
      rebateAccount: 'บัญชีค่าคอมมิชชั่น',
      comingSoon: 'เร็ว ๆ นี้',
      tradingHistory: 'ประวัติการซื้อขาย',
      noData: 'ไม่มีข้อมูลใด ๆ',
      NAME: 'ชื่อ',
      EMAIL: 'อีเมล',
      ACCTYPE: 'ประเภทบัญชี',
      PLATFORM: 'แพลตฟอร์ม',
      BASECURRENCY: 'สกุลเงิน',
      DATERANGE: 'ช่วงวันที่',
      STATUS: 'สถานะ',
      DEST: 'ปลายทาง',
      ACCNUM: 'เลขบัญชี  ',
      BALANCE: 'จำนวนเงิน ',
      NONE: 'ไม่มี',
      ALL: 'ทั้งหมด',
      PROFITLOST: 'กำไรขาดทุน',
      MARGINLEVEL: 'ระดับมาร์จิ้น(%)',
      ACCOUNTEQUITY: 'เงินทุนในบัญชี',
      CREDIT: '赠เครดิต金',
      DOWNLOAD: 'ดาวน์โหลด',
    },
    dateRange: {
      today: 'วันนี้',
      yesterday: 'เมื่อวาน',
      lastSevenDays: '7 วันย้อนหลัง',
      lastThirtyDays: '30 วันย้อนหลัง',
      weekToDate: 'สัปดาห์นี้',
      monthToDate: 'เดือนนี้',
      quarterToDate: 'ไตรมาสนี้',
      previousWeek: 'สัปดาห์ก่อนหน้า',
      previousMonth: 'เดือนก่อนหน้า',
      previousQuarter: 'ไตรมาสก่อนหน้า',
    },
    products: {
      forex: 'คู่สกุลเงิน',
      commodities: 'สินค้าโภคภัณฑ์',
      indices: 'ดัชนี',
      crypto: 'คริปโทเคอร์เรนซี ',
      bond: ' พันธบัตร',
      bonds: ' พันธบัตร',
      gold: 'ทองคำ',
      minigold: 'ทองคำมินิ',
      goldfutures: 'ทองคำฟิวเจอร์ส',
      bitcoin: 'สกุลเงินดิจิทัล',
      silver: 'โลหะเงิน',
      silverfutures: 'โลหะเงินฟิวเจอร์ส',
      oil: 'น้ำมัน',
      oilfutures: 'น้ำมันดิบฟิวเจอร์ส',
      index: 'ดัชนี',
      indexfutures: 'ดัชนีฟิวเจอร์ส',
      etf: 'ETF',
      forexfutures: 'คู่สกุลเงิน',
      stock: 'คู่สกุลเงินฟิวเจอร์ส',
    },
  },
  withdrawalFreeze: {
    header: 'การถอนเงินไม่สามารถทำได้ชั่วคราว',
    desc: 'เนื่องจากการเปลี่ยนแปลงรหัสผ่านการเข้าสู่ระบบของคุณ การถอนเงินจึงไม่สามารถทำได้ชั่วคราวเป็นเวลา T+{day} วัน เนื่องจากเหตุผลด้านความปลอดภัย คาดว่าจะสามารถกลับมาใช้งานใน {hour} ชั่วโมง.',
    desc2:
      'เนื่องจากมีการเปลี่ยนแปลงอีเมล/เบอร์โทรศัพท์สำหรับเข้าสู่ระบบ การถอนเงินจึงไม่สามารถทำได้ชั่วคราวสำหรับ T+{day} วัน คาดว่าจะสามารถกลับมาใช้งานใน {hour} ชั่วโมง',
  },
  report: {
    rebate: {
      column: {
        name: 'ชื่อ',
        accountNumber: 'หมายเลขบัญชี',
        accountType: 'ประเภทบัญชี',
        volume: 'ปริมาณการซื้อขาย',
        rebate: 'ค่าคอมมิชชั่น',
        totalRebate: 'ค่าคอมมิชชั่นทั้งหมด',
        date: 'วันที่',
        instrument: 'ผลิตภัณฑ์การซื้อขาย',
        notionalvalue: 'มูลค่าตามสัญญา',
      },
      dropdown: {
        allInstruments: 'ผลิตภัณฑ์ทั้งหมด',
        all: 'ทั้งหมด',
        instruments: 'ผลิตภัณฑ์การซื้อขาย',
      },
      others: {
        rebate: 'ค่าคอมมิชชั่น',
        instrumentsTraded: 'ผลิตภัณฑ์การซื้อขาย',
        lots: 'ล็อต',
        totalRebate: 'ค่าคอมมิชชั่นทั้งหมด',
        rebates: 'ค่าคอมมิชชั่น',
      },
    },
    btn: { showAll: 'แสดงทั้งหมด', hide: 'ซ่อน' },
  },
  ibReport: { openedAccs: 'จำนวนบัญชีที่เปิด', funding: 'รายการธุรกรรม', account: 'บัญชี' },
  lead: { demoAccounts: 'บัญชีทดลอง' },
  pendingAccounts: {
    errorDoc:
      'รายชื่อลูกค้าด้านล่างนี้ได้ทำการกรอกแบบฟอร์มการเปิดบัญชีสำเร็จแล้ว แต่ยังไม่ได้ทำการอัปโหลดเอกสารยืนยันตัวตนโปรดติดตามสถานะลูกค้าของคุณเพื่อการเปิดบัญชีที่สมบูรณ์',
    acceptedDocType: 'รูปแบบเอกสารที่สามารถใช้ยืนยันตัวตนได้มี ดังนี้:',
    primaryDoc: 'เอกสารยืนยันตัวตน (ID)',
    passport:
      'หนังสือเดินทาง (พาสปอร์ต) โดยแสดงหน้าที่มีลายเซ็นและหน้าถัดไปอย่างชัดเจน ห้ามมีการขีดเขียนทับข้อมูลบนบัตร และไม่เป็นสำเนา',
    id: 'บัตรประจำตัวประชาชน โดยแสดงข้อมูลบนบัตรอย่างชัดเจนครบถ้วน ห้ามมีการขีดเขียนทับข้อมูลบนบัตร และไม่เป็นสำเนา ',
    dl: 'ใบขับขี่ โดยแสดงข้อมูลบนบัตรอย่างชัดเจนครบถ้วน ห้ามมีการขีดเขียนทับข้อมูลบนบัตร และไม่เป็นสำเนา',
    address: 'เอกสารยืนยันที่อยู่ (POA)',
    addressList: [
      'เอกสารหรือสำเนาเอกสารที่ออกให้โดยรัฐบาล หรือบิลค่าใช้จ่าย เช่น ค่าแก๊ส ค่าไฟ ค่าน้ำ ฯลฯ ที่แสดงชื่อและที่อยู่ปัจจุบันของท่าน (ระบุวัน/เดือน/ปี ที่มีอายุไม่เกิน 6 เดือน)',
      'รายการเดินบัญชี ใบแจ้งยอด หรือสำเนาใบแจ้งยอดบัตรเครดิตรของท่าน (ไม่ใช่รูปแบบการพิมพ์จากระบบออนไลน์)',
    ],
    tip: 'กรุณาแนบเอกสารและส่งมายัง ',
  },
  rebatePaymentHistory: {
    rebateHistory: 'ประวัติค่าคอมมิชชั่น',
    withdrawHistory: 'ประวัติการถอน',
    transferHistory: 'ประวัติการโอนเงิน',
  },
  referralLinks: {
    copyLink: 'คัดลอกลิงก์',
    downloadPage: 'ดาวน์โหลดAPP',
    campLang: 'ภาษาเพื่อการโฆษณา',
    cpy: 'คัดลอกลิงก์',
    alreadyCPY: 'คัดลอกแล้ว',
    liveAcc: 'บัญชีซื้อขายจริง',
    demoAcc: 'บัญชีทดลองซื้อขาย',
    homePage: 'หน้าหลัก',
    promotion: 'ลิงค์การส่งเสริมกิจกรรม (Event promotion link)',
    hintTitle: 'ลิงค์การส่งเสริมกิจกรรม (event promotion link) คืออะไร?',
    hintMessage:
      'ลิงค์การส่งเสริมกิจกรรม (event promotion link) เป็นช่องทางการส่งเสริมใหม่ที่มีไว้ให้บริการแก่ IB การแชร์ลิงค์นี้จะนำผู้ใช้ไปยังหน้ากิจกรรมโบนัส และทำการลงทะเบียนให้เสร็จสิ้น การแชร์ลิงค์นี้อาจทำให้มีจำนวนของผู้ลงทะเบียนเพิ่มขึ้นได้',
    hintShortLink: 'แชร์โดยใช้การย่อลิงค์ (short link)',
    hintShortLinkTitle: 'short link คืออะไร?',
    hintShortLinkMessage:
      'การย่อลิงค์ (short link) เป็นกฎการสร้างลิงก์ซึ่งสามารถช่วยป้องกันไม่ให้ผู้อื่นทำการเปลี่ยนแปลงลิงค์ของคุณได้ และเพื่อเป็นการป้องกันสิทธิ์ในการพัฒนา IB ของคุณ',
    inviteCodeHeader: 'รหัสเชิญโปรโมชั่น',
    inviteCode: 'รหัสการเชิญ',
    customButton: 'ปรับแต่ง',
    copyButton: 'สำเนา',
    customInviteCodeHeader: 'รหัสเชิญที่กำหนดเอง',
    currentCode: 'รหัสเชิญปัจจุบัน',
    newCode: 'รหัสเชิญใหม่',
    notice: 'หมายเหตุ: รหัสเชิญมี อย่างน้อย 6 พารามิเตอร์ สามารถ ป้อนตัวอักษร ตัวเลข สัญลักษณ์ที่ไม่ใช่แบบ พิเศษได้',
    inviteCodeFormatError: 'รูปแบบรหัสเชิญไม่ถูก ต้อง โปรดแก้ไขอีก ครั้ง',
    inviteCodeAlreadyExist: 'ผู้อื่นได้ลงทะเบียนรหัส คำเชิญไว้ชั่วคราว โปรดป้อนอีกครั้ง',
    pleaseEnter: 'กรุณาป้อน',
  },
  settings: {
    info: 'ข้อมูล',
    reg: 'ลงทะเบียนเมื่อ',
    CustomerInfo: 'ข้อมูลลูกค้า',
    name: 'ชื่อ',
    email: 'อีเมล',
    phone: 'หมายเลขโทรศัพท์',
    address: 'ที่อยู่',
  },
  unfundedAcc: {
    neverFundedAccs: 'ลูกค้าที่ไม่เคยฝากเงิน',
    prevFundedZero: 'ลูกค้าที่เคยฝากเงิน (ยอดคงเหลือสุทธิ 0)',
    accBalance: 'ยอดคงเหลือ',
  },
  multiib: { level: 'IB ขั้นที่ {level}', noib: 'ไม่มี IB ย่อยภายใต้บัญชีนี้' },
  AWCDialog: { optIn: 'เข้าร่วมการแข่งขัน', noThanks: 'ไม่เป็นไรขอบคุณ' },
  header: { bckToCP: 'กลับสู่หน้าพอร์ทัลลูกค้า', logout: 'ออกจากระบบ\r\n' },
  menu: {
    dashboard: 'กระดานข้อมูล',
    ibReport: 'รายงาน IB',
    rebateReport: 'รายงานค่าคอมมิชชัน',
    iblevel: 'ระดับขั้น IB',
    ibAccs: 'การจัดการลูกค้า',
    leads: 'รายชื่อลูกค้าที่สนใจ',
    pendingAccs: 'บัญชีที่รอดำเนินการ',
    unfundedAccs: 'บัญชีที่ไม่เคยฝากเงิน',
    payHistory: 'ประวัติการทำธุรกรรม',
    ibProfile: 'ข้อมูลส่วนตัว',
    refLinks: 'ลิงก์อ้างอิง',
    contactUs: 'ติดต่อเรา',
    switchCP: 'สลับเป็นพอร์ทัลลูกค้า',
  },
  footer: {
    riskWarn:
      'คำเตือนความเสี่ยง: การซื้อขายส่วนต่าง (CFD) มีความเสี่ยงสูงต่อเงินทุนของท่านและอาจส่งผลให้เกิดการขาดทุน ท่านควรซื้อขายด้วยเงินที่ท่านสามารถยอมรับได้หากมีการขาดทุนเกิดขึ้นเท่านั้น ทั้งนี้การซื้อขาย CFD อาจไม่เหมาะสมกับนักลงทุนทุกท่าน โปรดตรวจสอบให้แน่ใจว่าท่านเข้าใจความเสี่ยงที่เกี่ยวข้องและใช้มาตรการที่เหมาะสมในการจัดกา โปรดอ่านเอกสารการเปิดเผยข้อมูลความเสี่ยงที่เกี่ยวข้องอย่างรอบคอบ',
    riskWarn_fsc:
      'คำเตือนความเสี่ยง: ตราสารอนุพันธ์เป็นตราสารที่มีความซับซ้อน และมีความเสี่ยงสูงที่จะสูญเสียเงินลงทุนของคุณได้อย่างรวดเร็ว โปรดพิจารณาว่าคุณมีความเข้าใจในการทำงานของตราสารอนุพันธ์อย่างถ่องแท้ และยอมรับได้ถึงความเสี่ยงในระดับสูงต่อการสูญเสียเงินของคุณ',
  },
  responseMsg: {
    410: 'การตรวจสอบค่าตัวเลขล้มเหลว',
    411: 'รหัสผ่านไม่ถูกต้อง',
    420: 'ไม่มีผู้ใช้งานอยู่ในระบบ',
    421: 'ปฏิเสธการรีเซ็ตรหัสผ่าน',
    490: 'โปรดอย่าส่งข้อมูลบัตรที่ซ้ำกัน',
    500: 'เกิดข้อผิดพลาด',
    501: 'แอปพลิเคชันล้มเหลว โปรดลองอีกครั้งภายหลัง',
    505: 'การอัปโหลดไฟล์ล้มเหลว',
    510: 'รหัส SMS ไม่ถูกต้อง',
    511: 'ไม่สามารถส่งรหัส SMS ไปยังหมายเลขของท่านได้',
    520: 'เข้าสู่ระบบล้มเหลว โปรดลองอีกครั้ง',
    521: 'ผู้ใช้งานลงชื่อเข้าใช้บนอุปกรณ์นี้แล้ว!',
    522: 'โปรดเข้าสู่ระบบอีกครั้ง',
    523: 'โปรดเข้าสู่ระบบอีกครั้ง',
    524: 'ไม่พบที่อยู่อีเมลของท่าน',
    525: 'ชื่อผู้ใช้และรหัสผ่านไม่ถูกต้อง',
    526: 'Recaptcha does not matchs',
    527: 'ผู้ใช้งานไม่ใช่ IB',
    528: 'ไม่มีผู้ใช้งานอยู่ในระบบ',
    529: 'ชื่อผู้ใช้และรหัสผ่านไม่ถูกต้อง',
    530: 'รหัสไม่ถูกต้อง',
    531: 'รหัสไม่ถูกต้อง',
    540: 'ไม่พบบัญชีการซื้อขายของท่าน',
    541: 'ไม่พบบัญชีค่าคอมมิชชั่น',
    542: 'คุณไม่สามารถเปิดบัญชีเพิ่มเติมได้',
    544: 'คุณไม่ได้รับอนุญาตให้สมัครบัญชีเพิ่มเติมจนกว่าจะได้รับการยืนยันหลักฐานยืนยันตัวตนของคุณ',
    550: 'หมายเลขบัญชีไม่ถูกต้อง',
    551: 'เงินไม่เพียงพอ',
    554: 'จำนวนเงินที่ร้องขอคือศูนย์หรือไม่มี',
    564: 'ประเทศไม่ตรงกับช่องทางการชำระเงิน',
    565: 'รหัสธนาคารไม่ถูกต้อง',
    566: 'ไม่พบวิธีการชำระเงิน',
    581: 'คุณเลือกเข้าร่วมการส่งเสริมการขายสำเร็จแล้ว โปรดทราบว่าคุณต้องทำการฝากเงินเพื่อถอนเงิน',
    584: 'ท่านไม่มีการคืนเงินที่เกี่ยวข้อง',
    590: 'ไม่พบบัญชีที่โอนออก',
    591: 'ไม่พบบัญชีที่โอนเข้า',
    593: 'เราไม่สามารถดำเนินการโอนเงินของคุณจากบัญชีที่เลือกได้เนื่องจากบัญชีของคุณมีเครดิต',
    594: 'ไม่อนุญาตให้โอนเงินออกจากบัญชีนี้',
    session_timeout: 'หมดเวลา กำลังกลับไปหน้าเข้าสู่ระบบ',
  },
}
