export default {
  home: {
    RebateAccountNumber: 'Số Tài Khoản Hoa Hồng',
    totalCommission: 'Tổng Hoa Hồng',
    APPLY_FOR_A_REBATE: 'Nhận Hoa Hồng',
    availableBalance: 'Số Dư Khả Dụng',
    WITHDRAW_REBATE: 'Rút Hoa Hồng',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'CHUYỂN CHIẾT KHẤU VÀO TÀI KHOẢN CỦA BẠN',
    TOTAL_VOLUME: 'TỔNG KHỐI LƯỢNG',
    NET_FUNDING: 'TIỀN NẠP RÒNG',
    DEPOSITS: 'Nạp Tiền',
    WITHDRAW: 'Rút Tiền',
    OPEND_ACCOUNT: 'Tài Khoản Đã Mở',
    PERFORMANCE: 'HIỆU SUẤT',
    MOST_TRADED_INSTRUMENTS: 'NHỮNG MÃ GIAO DỊCH NHIỀU NHẤT',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'NHỮNG TÀI KHOẢN HIỆU SUẤT TỐT NHẤT THÁNG NÀY',
    RECENTLY_OPENED_ACCOUNTS: 'NHỮNG TÀI KHOẢN MỚI MỞ',
    ACCOUNT_MANAGER: 'QUẢN LÝ TÀI KHOẢN',
    Account: 'Tài Khoản',
    Name: 'Tên',
    Volume: 'Khối Lượng',
    Rebate: 'Hoa Hồng',
    Balance: 'Số Dư',
    ContactClient: 'Liên Hệ Khách Hàng',
    applyRebateSuccessTip:
      'Xin chúc mừng, chúng tôi đã nhận được yêu cầu rút hoa hồng của bạn. {amount} sẽ được chuyển vào Tài khoản hoa hồng {account} trong vòng 1 ngày làm việc. Bạn có thể rút hoặc chuyển tiền sau khi khoản hoa hồng của bạn được xử lý.',
    HOVER: 'Các khoản hoa hồng được tính toán và xử lý trong khoảng 09:00 - 11:00 AEST hàng ngày.',
    blacklist: 'Tài khoản này hiện không thể nhận chiết khấu, vui lòng liên hệ với người quản lý tài khoản của bạn.',
  },
  liveChat: { header: 'Trò chuyện trực tuyến' },
  contactUs: {
    tip: 'Nếu bạn có thắc mắc hoặc muốn biết thêm thông tin, xin vui lòng liên hệ với chúng tôi thông qua một trong những chi tiết liên lạc dưới đây. Chúng tôi sẽ cố gắng trả lời tất cả các truy vấn trong vòng 24 giờ.',
  },
  fileUploader: {
    supportedFormat: 'Các loại tệp được hỗ trợ: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Các loại tệp được hỗ trợ: {files}',
    maxSize: 'Kích thước tệp tải lên tối đa: {maxSize} MB',
    maxFileNum: 'Số tệp tối đa: {maxFileNum}',
    failedFileExtension: 'Vui lòng tải lên đúng loại tệp',
  },
  notification: {
    pendingIdentityProof: {
      header: 'ĐƠN ĐĂNG KÝ TÀI KHOẢN CỦA BẠN ĐANG ĐƯỢC XỬ LÝ',
      id: 'ID là',
      poa: 'Địa chỉ là',
      both: 'ID và Bằng chứng địa chỉ là',
      content: 'Tài liệu xác minh {doc} của bạn hiện đang được xử lý và sẽ được hoàn tất trong thời gian ngắn.',
    },
  },
  identityProof: {
    home: {
      header: 'ĐƠN ĐĂNG KÝ TÀI KHOẢN CHƯA HOÀN THÀNH',
      content:
        'Xin chào {name}, ứng dụng tài khoản của bạn đã gần hoàn tất, tuy nhiên bạn vẫn cần gửi thêm tài liệu để hoàn thiện tài khoản của mình.',
    },
    shufti: {
      content: 'Vui lòng đảm bảo các chi tiết được cung cấp khớp với tài liệu ID của bạn và bằng cùng một ngôn ngữ.',
      countryOfDocument: 'Quốc gia như được hiển thị trên tài liệu',
    },
    popup: {
      header:
        'Xin chào {name}, trước khi tài khoản thực của bạn có thể được hoàn tất, bạn được yêu cầu tải lên tài liệu sau để xác minh.',
      addressProofSpan:
        '<strong>\n        <b> Xác nhận địa chỉ </ b>\n        </strong>\n        <br />\n        <I>\n        Gửi <b> một </ b> trong các tài liệu bên dưới\n          <br /><br />\n        </I>\n        - Bản sao hóa đơn tiện ích gần đây </br> (không quá 3 tháng)\n        <br />\n        <b class="or">or</b><br />\n        - Bản sao sao kê ngân hàng gần đây </br> (không quá 3 tháng)\n        <br />\n        <br />\n        <b> QUAN TRỌNG! </ b> Tên trên tài liệu PHẢI hiển thị rõ tên đầy đủ và địa chỉ hiện tại của bạn.\n        <br />',
    },
    reasonType: {
      4: 'Tài liệu không đầy đủ',
      5: 'Tài liệu không rõ ràng',
      6: 'Bản sao màu là bắt buộc',
      8: 'Tên mở tài khoản không khớp',
      9: 'Ngày cấp bằng chứng địa chỉ không đủ điều kiện',
    },
    reason: 'Không thể xác minh tài liệu của bạn,<br> Lý do: {reason}. Xin vui lòng thử lại.',
    success: 'Cảm ơn bạn. Chúng tôi đã nhận thành công tài liệu của bạn và hiện đang xử lý chúng để xác minh.',
  },
  ibAccounts: {
    ibAccount: 'Tài Khoản IB',
    subIbs: 'Tài Khoản IB Phụ',
    tradingList: 'Danh sách giao dịch',
    OpeningList: 'Đang mở danh sách',
    closeList: 'Đóng danh sách',
    tradingListColumn: {
      ticket: 'TicketID',
      openTime: 'Thời gian mở',
      type: 'Loại',
      volume: 'Khối lượng',
      item: 'Mục',
      openPrice: 'Giá mở',
      closeTime: 'Thời gian đóng',
      closePrice: 'Giá đóng cửa',
      commission: 'Phí hoa hồng',
      profit: 'Lợi nhuận',
    },
  },
  withdraw: {
    warning: {
      desc: 'Xin lưu ý những điều sau:',
      desc1: 'Người dùng cuối sẽ phải chịu phí chuyển khoản ngân hàng, thường dao động từ 135 đến 250 JPY.',
      desc2: 'Yêu cầu rút tiền được thực hiện sau 2 giờ chiều có thể bị trì hoãn hoặc xử lý vào ngày hôm sau.',
    },
    default: {
      selectCC: 'Chọn thẻ tín dụng',
      selectBA: 'Chọn tài khoản ngân hàng',
      anotherCC: 'Nhập thẻ tín dụng bằng cách thủ công',
      anotherBA: 'Thêm tài khoản ngân hàng',
      remember: 'Ghi nhớ tài khoản của tôi',
    },
    transfer: {
      info: '{platform} sẽ hoàn trả phí ngân hàng được tính cho rút tiền <u> ONE </ u> mỗi tháng. Rút tiền bổ sung trong tháng sẽ phải chịu một khoản phí ngân hàng là 20 đơn vị tiền tệ rút tiền của bạn.',
      label: {
        upload: 'Vui lòng tải lên bản sao bảng sao kê ngân hàng của bạn (trong vòng 3 tháng)',
      },
      formValidation: { upload: 'Vui lòng tải lên một sao kê ngân hàng' },
    },
    REBATE_WITHDRAW_REQUEST: 'YÊU CẦU RÚT HOA HỒNG',
    PleaseSelectDesiredWithdrawMethod: 'Vui lòng chọn phương thức rút tiền',
    BankName: 'Tên Ngân Hàng',
    bankCode: 'Mã Ngân Hàng',
    bankBranchCode: 'Mã Chi Nhánh',
    bankAccountCurrency: 'Loại Tiền Tệ Của Tại Khoản Ngân Hàng',
    BankAccountsName: 'Tên Tài Khoản Ngân Hàng',
    FasaPayAccountName: 'Tên Tài Khoản FasaPay',
    AustralianBankName: 'Tên Ngân Hàng Úc',
    BankAccountNumber: 'Số Tài Khoản Ngân Hàng',
    FasaPayAccountNumber: 'Số Tài Khoản FasaPay',
    BankAccountBeneficiary: 'Tài Khoản Thụ Hưởng',
    BSB: 'BSB',
    SortCode: 'Mã Sort',
    skrillEmail: 'Skrill Email',
    NetellerEmail: 'Neteller Email',
    PerfectMoneyEmail: 'Perfect Money Email',
    BitcoinEmail: 'Địa chỉ ví Bitcoin',
    USDTEmail: 'Địa chỉ ví Tether',
    SticpayEmail: 'SticPay Email',
    BankAccountName: 'Tên Người Thụ Hưởng',
    BankBranch: 'Chi Nhánh Ngân Hàng (Số chi nhánh gồm 3 chữ số)',
    BankAccountType: 'Loại tài khoản ngân hàng',
    Province: 'Tỉnh',
    City: 'Thành Phố',
    BankAddress: 'Địa Chỉ Ngân Hàng',
    AccountNumberIBAN: 'Số Tài Khoản/IBAN',
    BankBeneficiaryName: 'Tên Người Thụ Hưởng Ngân Hàng',
    BankAccountBeneficiaryName: 'Tên Người Thụ Hưởng Ngân Hàng',
    AccountHoldersAddress: 'Tên Chủ Tài Khoản',
    SelectWithdrawCard: 'Chọn Loại Thẻ Bạn Muốn Rút',
    Swift: 'Mã SWIFT',
    SwiftAud: 'Mã SWIFT (Không phải tài khoản AUD)',
    ABA_SortCodeABA: 'ABA/Sort Code',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: 'Ghi Chú Quan Trọng',
    ifscCode: 'Mã IFSC',
    uploadDocument: 'Vui lòng tải lên bản sao bảng sao kê ngân hàng của bạn (trong vòng 3 tháng)',
    YourPaymentGreaterThanAvailableBalance: 'Khoản thanh toán của bạn lớn hơn số dư khả dụng',
    integer: 'Chỉ hỗ trợ số nguyên tố',
    CannotUseMoreThanTwoDecimalValues: 'Bạn không thể sử dụng Số và Số âm có nhiều hơn hai chữ số thập phân',
    YourPaymentIsBeingProcessed: 'Đơn xin rút tiền của bạn đã được gửi đi.',
    Itcannotbe0: 'Không thể điền 0.',
    noCardWarn: 'Bạn không có thẻ nào để rút tiền, vui lòng liên kết thẻ của bạn trong Chi Tiết Rút Tiền trước',
    amtLarger: 'Số tiền rút tối thiểu là {currencyType} {minLimit}. Số dư dưới 30 phải được rút toàn bộ',
    blackList:
      'Rất tiếc, bạn hiện không thể gửi yêu cầu rút tiền. Vui lòng liên hệ {supportEmail} để biết thêm chi tiết.',
    NetellerChargeAlert:
      'Rút tiền qua Neteller được giới hạn ở mức 500.000 đô la cho mỗi giao dịch và áp dụng phí 2% (giới hạn ở mức 30 đô la).',
    FasaPayChargeAlert: 'FasaPay tính phí giao dịch 0,5% cho mỗi lần rút tiền.',
    SkrillChargeAlert: 'Skrill tính phí giao dịch 1% cho mỗi lần rút tiền.',
    cryptoAlert:
      'Xin lưu ý rằng chúng tôi không thể nạp hoặc rút tiền qua BUSDT, vui lòng đảm bảo địa chỉ và Tiền điện tử khớp với chuỗi và đơn vị tiền tệ mà chúng tôi chấp nhận, nếu không bạn có thể bị mất tiền.',
    name: 'Tên',
    phone: 'Số điện thoại',
    address: 'Địa Chỉ',
    email: 'Email',
    status: {
      withdrawalSubmitted: 'Đã gửi',
      withdrawalCanceled: 'Đã hủy',
      withdrawalAuditing: 'Đang Xử Lý',
      withdrawalRejected: 'Từ Chối',
      withdrawalAccepted: 'Đang Xử Lý',
      withdrawalPayFailed: 'Lỗi',
      withdrawalSuccess: 'Đã Xử Lý',
      withdrawalFailed: 'Lỗi',
      withdrawalPending: 'Tạm Ngưng',
      withdrawalPartialFailed: 'Lỗi',
      transferSubmitted: 'Đang Xử Lý',
      transferSuccess: 'Đã Xử Lý',
      transferRejected: 'Từ Chối',
      transferFailed: 'Lỗi',
      transferProcessing: 'Đang Xử Lý',
    },
    formErrorMsg: {
      bankName: 'Tên Ngân Hàng là bắt buộc',
      bsb: 'BSB là bắt buộc',
      sortCode: 'Sort Code là bắt buộc',
      beneName: 'Tên người thụ hưởng ngân hàng là bắt buộc',
      bankCodeReq: 'Mã Ngân Hàng là Bắt Buộc',
      bankBranchCodeReq: 'Mã Chi Nhánh là Bắt Buộc',
      accNum: 'Số tài khoản ngân hàng là bắt buộc',
      swift: 'Mã Swift là bắt buộc',
      bankAddress: 'Địa chỉ ngân hàng là bắt buộc',
      holderAddress: 'Địa chỉ chủ tài khoản là bắt buộc',
      amt: 'Số tiền là bắt buộc',
      firstSixDigits: 'Vui lòng nhập 6 chữ số đầu tiên',
      lastFourDigits: 'Vui lòng nhập 4 chữ số cuối cùng',
      branchName: 'Tên chi nhánh là bắt buộc',
      email: 'Email là bắt buộc',
      accName: 'Tên tài khoản là bắt buộc',
      province: 'Địa chỉ ngân hàng (Tỉnh) là bắt buộc',
      city: 'Địa chỉ ngân hàng (Thành Phố) là bắt buộc',
      bankCard: 'Vui lòng chọn một thẻ',
      emailFormat: 'Vui lòng nhập địa chỉ email chính xác',
      amt0: 'Số tiền không thể là 0',
      ifscCode: 'Mã IFSC là bắt buộc',
      bitcoin: 'Địa chỉ ví Bitcoin là bắt buộc',
      bitcoinAddressLength: 'Địa chỉ ví Bitcoin phải chứa từ 26-42 ký tự',
      usdt: 'Địa chỉ ví USDT là bắt buộc',
      dynamicReq: '{fieldname} là bắt buộc',
      uploadDocument: 'Vui lòng tải lên một sao kê ngân hàng',
    },
    note: "Do những thay đổi gần đây, ngân hàng của chúng tôi có thể không xử lý chuyển khoản ngân hàng quốc tế cho những khách hàng đã đăng ký ở một số quốc gia.<br/>\n      Nếu bạn phải rút tiền qua chuyển khoản ngân hàng quốc tế, vui lòng liên hệ với bộ phận Hỗ trợ khách hàng của chúng tôi theo số +44 2080 363 883 hoặc tại <a href='mailto:{mail}'>{mail}</a>.<br/>\n      Chúng tôi xin lỗi vì những bất tiện này.",
    minAmountWarn:
      'Số tiền rút yêu cầu nhỏ hơn mức tối thiểu được áp dụng của cổng thanh toán. Vui lòng nạp tài khoản để đạt hoặc lớn hơn số tiền tối thiểu trước khi đưa ra yêu cầu rút tiền. Thông tin chi tiết có thể tìm thấy <a href="https://{regulatorUrl}/clients/accounts/withdraw/">tại đây</a>.',
  },
  rebate: { status: { processed: 'Đã Xử Lý', processing: 'Đang Xử Lý', rejected: 'Từ Chối' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'YÊU CẦU CHUYỂN HOA HỒNG',
    transferAmountIsGreaterThanAvailableBalance: 'Số tiền chuyển của bạn lớn hơn số dư khả dụng',
    yourRebateTransferHasBeenProcessed: 'Chuyển khoản hoa hồng của bạn đã được gửi',
    PleaseEnterTransferInformation: 'Vui lòng nhập thông tin chuyển khoản chính xác',
    tradingAccount: 'Tài khoản giao dịch',
    rebateAccount: 'Tài Khoản Hoa Hồngt',
    account: 'Tài Khoản',
    blackList:
      'Tài khoản này đã bị hạn chế thực hiện chuyển khoản giữa các tài khoản, vui lòng liên hệ với {supportEmail}',
  },
  transferHistory: {
    payStatus: {
      processing: 'Đang Xử Lý',
      paid: 'Đã thanh toán',
      unpaid: 'Chưa thanh toán',
      rejected: 'Từ Chối',
    },
  },
  common: {
    liveChat: { desc: '<li><p>Bạn cần hỗ trợ?</p><span>Trò chuyện trực tuyến</span></li>' },
    lang: {
      english: 'Tiếng Anh',
      chinese: 'Tiếng Trung',
      french: 'Tiếng Pháp',
      thai: 'Tiếng Thái Lan',
      german: 'Tiếng Đức',
      spanish: 'Tiếng Tây Ban Nha',
      malay: 'Tiếng Malaysia',
      viet: 'Tiếng Việt',
      indo: 'Tiếng Indonesia',
      arabic: 'Tiếng Ả Rập',
      japanese: 'tiếng Nhật',
    },
    button: {
      confirm: 'XÁC NHẬN',
      cancel: 'HỦY BỎ',
      identityProof: 'HOÀN THÀNH ĐĂNG KÝ',
      upload: 'TẢI LÊN',
      selectFile: 'Chọn tập tin',
      fundnow: 'NẠP NGAY',
      iunderstand: 'Tôi xác nhận',
    },
    withdrawChannel: {
      hongkongbanktransfer: 'Chuyển khoản ngân hàng Hồng Kông',
      banktransfer: 'Chuyển khoản ngân hàng',
      aus: 'Chuyển khoản ngân hàng - Úc',
      int: 'Chuyển khoản ngân hàng - Quốc Tế',
      chn: 'Chuyển khoản ngân hàng - Trung Quốc',
      uk: 'Chuyển khoản ngân hàng - Vương quốc Anh',
      thailandinstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Thái Lan',
      malaysiainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Malaysia',
      banktransferbpaypolipay: 'Chuyển khoản ngân hàng / BPay / PoliPay',
      cryptocurrencybitcoin: 'Tiền điện tử-Bitcoin',
      cryptocurrencyusdt: 'Tiền điện tử-USDT',
      nigeriainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Nigeria',
      vietnaminstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Việt Nam',
      indonesiainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Indonesia',
      philippinesinstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Philippines',
      southafricainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Nam Phi',
      indiainstantbanktransfer: 'Chuyển khoản nhanh ngân hàng Ấn độ',
      laosinstantbankwiretransfer: 'Chuyển khoản ngân hàng lào tức thì',
      ugandabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Uganda',
      rwandabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Rwanda',
      zambiabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Zambia',
      congobanktransfer: 'Chuyển khoản Ngân hàng Địa phương Congo',
      cameroonbanktransfer: 'Chuyển khoản Ngân hàng Địa phương Cameroon',
      burundibanktransfer: 'Chuyển khoản Ngân hàng Địa phương Burundi',
      kenyabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Kenya',
      ghanabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Ghana',
      tanzaniabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Tanzania',
      brazilbanktransfer: 'Chuyển khoản nhanh ngân hàng Brazil',
      mexicobanktransfer: 'Chuyển khoản nhanh ngân hàng Mexico',
      internetbankingmexico: 'Ngân Hàng Điện Tử (Mexico)',
      internetbankingbrazil: 'Ngân Hàng Điện Tử (Brazil)',
      astropay: 'Astropay',
      transfer: 'Chuyển vào tài khoản',
    },
    country: { international: 'Quốc Tế', australia: 'Úc', uk: 'Vương quốc Anh' },
    field: { country_region: 'Quốc Gia / Khu Vực', yes: 'Có', no: 'Không' },
    keys: {
      SUBMIT: 'GỬI',
      FROM: 'TỪ',
      UPDATE: 'CẬP NHẬT',
      TO: 'ĐẾN',
      DATE: 'NGÀY',
      pleaseSelect: 'Vui lòng chọn',
      amount: 'Số Tiền',
      actualAmount: '  Số tiền rút thực tế = ',
      rebateAccount: 'Tài Khoản Hoa Hồng',
      comingSoon: 'Sẽ sớm ra mắt.',
      tradingHistory: 'Lịch Sử Giao Dịch',
      noData: 'Không có dữ liệu',
      NAME: 'TÊN',
      EMAIL: 'EMAIL',
      ACCTYPE: 'LOẠI TÀI KHOẢN',
      PLATFORM: 'NỀN TẢNG',
      BASECURRENCY: 'TIỀN TỆ CƠ SỞ',
      DATERANGE: 'KHOẢNG THỜI GIAN',
      STATUS: 'TRẠNG THÁI',
      DEST: 'ĐIỂM ĐẾN',
      ACCNUM: 'SỐ TÀI KHOẢN',
      BALANCE: 'SỐ DƯ',
      NONE: 'Không có',
      ALL: 'Tất Cả',
      PROFITLOST: 'Lợi nhuận bị mất',
      MARGINLEVEL: 'Mức ký quỹ (%)',
      ACCOUNTEQUITY: 'Vốn chủ sở hữu tài khoản',
      CREDIT: 'Tín dụng',
      DOWNLOAD: 'TẢI XUỐNG',
    },
    dateRange: {
      today: 'Hôm Nay',
      yesterday: 'Hôm Qua',
      lastSevenDays: '7 ngày trước',
      lastThirtyDays: '30 ngày trước',
      weekToDate: 'Tuần này',
      monthToDate: 'Tháng này',
      quarterToDate: 'Quý này',
      previousWeek: 'Tuần Trước',
      previousMonth: 'Tháng Trước',
      previousQuarter: 'Qúy Trước',
    },
    products: {
      forex: 'NGOẠI HỐI',
      commodities: 'HÀNG HÓA',
      indices: 'CHỈ SỐ',
      crypto: 'TIỀN ĐIỆN TỬ',
      bond: 'trái phiếu',
      bonds: 'trái phiếu',
      gold: 'VÀNG',
      minigold: 'VÀNG MINI',
      goldfutures: 'HỢP ĐỒNG TƯƠNG LAI VÀNG',
      bitcoin: 'BITCOIN',
      silver: 'BẠC',
      silverfutures: 'HỢP ĐỒNG TƯƠNG LAI BẠC',
      oil: 'DẦU',
      oilfutures: 'HỢP ĐỒNG TƯƠNG LAI DẦU',
      index: 'CHỈ SỐ',
      indexfutures: 'HỢP ĐỒNG TƯƠNG LAI CHỈ SỐ',
      etf: 'ETF',
      forexfutures: 'FOREX',
      stock: 'HỢP ĐỒNG TƯƠNG LAI FOREX',
    },
  },
  withdrawalFreeze: {
    header: 'Việc rút tiền tạm thời không được hỗ trợ',
    desc: 'Do việc thay đổi mật khẩu đăng nhập của bạn, việc rút tiền tạm thời không được hỗ trợ trong T+{day} ngày vì lý do an toàn. Hạn chế này dự kiến sẽ được khắc phục trong {hour} giờ',
    desc2:
      'Do việc sửa đổi email/số điện thoại đăng nhập của bạn, việc rút tiền tạm thời không được hỗ trợ trong T+{day} ngày. Hạn chế dự kiến sẽ được khắc phục sau {hour} giờ.',
  },
  report: {
    rebate: {
      column: {
        name: 'TÊN',
        accountNumber: 'SỐ TÀI KHOẢN',
        accountType: 'LOẠI TÀI KHOẢN',
        volume: 'Khối Lượng',
        rebate: 'Hoa Hồng',
        totalRebate: 'TỔNG HOA HỒNG',
        date: 'NGÀY',
        instrument: 'SẢN PHẨM GIAO DỊCH',
        notionalvalue: 'NGHĨA GIÁ TRỊ',
      },
      dropdown: {
        allInstruments: 'Tất cả sản phẩm',
        all: 'Tất Cả',
        instruments: 'SẢN PHẨM GIAO DỊCH',
      },
      others: {
        rebate: 'HOA HỒNG',
        instrumentsTraded: 'SẢN PHẨM ĐÃ GIAO DỊCH',
        lots: 'Lô',
        totalRebate: 'TỔNG HOA HỒNG',
        rebates: 'Hoa Hồng',
      },
    },
    btn: { showAll: 'Hiển thị tất cả', hide: 'Ẩn' },
  },
  ibReport: { openedAccs: 'TÀI KHOẢN ĐÃ MỞ', funding: 'VỐN', account: 'TÀI KHOẢN' },
  lead: { demoAccounts: 'TÀI KHOẢN DEMO' },
  pendingAccounts: {
    errorDoc:
      'Những người nộp đơn sau đây đã điền vào mẫu đơn trực tuyến nhưng chưa cung cấp bằng chứng nhận dạng (xác nhận ID) để hoàn tất tài khoản của họ.',
    acceptedDocType: 'Các hình thức ID được chấp nhận bao gồm:',
    primaryDoc: 'Chứng Minh Nhân Dân',
    passport: '1 bản copy hộ chiếu (Trang có chữ ký)',
    id: '1 bản copy thẻ căn cước',
    dl: '1 bản copy giấy phép lái xe vẫn còn hạn sử dụng',
    address: 'ID thứ hai',
    addressList: [
      'Một bản sao của một hóa đơn tiện ích công cộng gần đây. Ví dụ. Gas hoặc điện có chứa tên và địa chỉ nơi cư trú của bạn (không quá 3 tháng)',
      ' Bảng sao kê thẻ tín dụng hoặc bảng sao kê tài khoản ngân hàng của bạn (không có bản in trực tuyến)',
    ],
    tip: 'Vui lòng khuyến khích người nộp đơn cung cấp bản sao của MỘT trong số các tài liệu ID chính và phụ được chấp nhận cho',
  },
  rebatePaymentHistory: {
    rebateHistory: 'LỊCH SỬ HOA HỒNG',
    withdrawHistory: 'LỊCH SỬ RÚT TIỀN',
    transferHistory: 'LỊCH SỬ CHUYỂN TIỀN',
  },
  referralLinks: {
    copyLink: 'SAO CHÉP ĐƯỜNG DẪN',
    downloadPage: 'Tải ứng dụng',
    campLang: 'Ngôn ngữ chiến dịch',
    cpy: 'SAO CHÉP',
    alreadyCPY: 'ĐÃ SAO CHÉP',
    liveAcc: 'Tài Khoản Live',
    demoAcc: 'Tài Khoản Demo',
    homePage: 'Trang Chủ',
    promotion: 'Liên kết quảng cáo sự kiện',
    hintTitle: 'Liên kết quảng cáo sự kiện là gì?',
    hintMessage:
      'Liên kết quảng cáo sự kiện là một phương thức khuyến mãi mới được cung cấp cho IB. Liên kết chia sẻ sẽ hướng dẫn người dùng đến trang sự kiện tiền thưởng và hoàn tất việc đăng ký. Tỷ lệ đăng ký thành công của người dùng có thể được cải thiện một cách hiệu quả bằng cách chia sẻ liên kết. ',
    hintShortLink: 'Chia sẻ bằng cách sử dụng liên kết rút gọn',
    hintShortLinkTitle: 'Liên kết rút gọn là gì?',
    hintShortLinkMessage:
      'Liên kết rút gọn là quy tắc tạo liên kết, có thể bảo vệ liên kết được chia sẻ của bạn không bị người khác thay đổi tốt hơn, do đó bảo vệ quyền phát triển IB của bạn',
    inviteCodeHeader: 'Mã lời mời khuyến mãi',
    inviteCode: 'Mã mời',
    customButton: 'tùy chỉnh',
    copyButton: 'sao chép',
    customInviteCodeHeader: 'mã lời mời tùy chỉnh',
    currentCode: 'mã lời mời hiện tại',
    newCode: 'mã lời mời mới',
    notice: 'Lưu ý: Mã mời có ít nhất 6 tham số. Có thể nhập các chữ cái, số, ký hiệu không đặc biệt',
    inviteCodeFormatError: 'Định dạng của mã mời bị sai, vui lòng chỉnh sửa lại',
    inviteCodeAlreadyExist: 'Mã mời đã được người khác đăng ký trước, vui lòng nhập lại',
    pleaseEnter: 'vui lòng nhập',
  },
  settings: {
    info: 'THÔNG TIN',
    reg: 'ĐÃ ĐĂNG KÝ TRÊN',
    CustomerInfo: 'thông tin khách hàng',
    name: 'Tên',
    email: 'E-mail',
    phone: 'Số điện thoại',
    address: 'Địa chỉ',
  },
  unfundedAcc: {
    neverFundedAccs: 'TÀI KHOẢN CHƯA TỪNG NẠP TIỀN',
    prevFundedZero: 'TÀI KHOẢN TỪNG NẠP TIỀN (SỐ DƯ LÀ 0)',
    accBalance: 'SỐ DƯ TÀI KHOẢN',
  },
  multiib: { level: 'CẤP ĐỘ {level}', noib: 'Không có IB cấp dưới của tài khoản này' },
  AWCDialog: { optIn: 'THAM GIA NGAY', noThanks: 'KHÔNG, CÁM ƠN' },
  header: { bckToCP: 'TRỞ LẠI CỔNG KHÁCH HÀNG', logout: 'ĐĂNG XUẤT' },
  menu: {
    dashboard: 'Bảng Điều Khiển',
    ibReport: 'Báo Cáo IB',
    rebateReport: 'Báo Cáo Hoa Hồng',
    iblevel: 'IB ĐA CẤP ĐỘ',
    ibAccs: 'Tài Khoản IB',
    leads: 'KHÁCH HÀNG TIỀM NĂNG',
    pendingAccs: 'TÀI KHOẢN TẠM NGƯNG',
    unfundedAccs: 'TÀI KHOẢN CHƯA NẠP TIỀN',
    payHistory: 'LỊCH SỬ GIAO DỊCH',
    ibProfile: 'HỒ SƠ IB',
    refLinks: 'LINKS GIỚI THIỆU',
    contactUs: 'Liên Hệ Với Chúng Tôi',
    switchCP: 'ĐỔI SANG CỔNG KHÁCH HÀNG',
  },
  footer: {
    riskWarn:
      'CẢNH CÁO RỦI RO: giao dịch Forex và CFDs là giao dịch có rủi ro cao hệ lụi mất mác về vốn đầu tư. Giao dịch những sản phẩm dùng đòn bẩy có thể không phù với tất cả các nhà đầu tư. Trước khi giao dịch, nhà đàu tư vui lòng xác định rõ kinh nghiệm và hiểu biết về sản phẩm định đầu tư và nếu có thể nhà đầu tư nên  tìm đến các chuyên gia tư vấn tài chính để nhận lời khuyên. Vui lòng đọc tài liệu pháp lý trên trang web của chúng tôi và đảm bảo rằng bạn hiểu rõ được độ rủi ro trước khi đưa ra bất kỳ quyết định giao dịch',
    riskWarn_fsc:
      'Cảnh báo rủi ro: Công cụ phái sinh là công cụ phức tạp và có nguy cơ mất tiền nhanh chóng do đòn bẩy. Bạn nên xem xét liệu bạn có hiểu Công cụ phái sinh hoạt động như thế nào và liệu bạn có đủ khả năng chấp nhận rủi ro mất tiền cao hay không.',
  },
  responseMsg: {
    410: 'Xác thực thông số không thành công',
    411: 'Mật khẩu không đúng',
    420: 'Người dùng không tồn tại',
    421: 'Đặt lại mật khẩu bị từ chối',
    490: 'Vui lòng không gửi thông tin thẻ trùng lặp',
    500: 'Một lỗi đã xảy ra.',
    501: 'Đăng ký thất bại. Vui lòng thử lại sau.',
    505: 'Tải lên tệp không thành công',
    510: 'Mã SMS không hợp lệ',
    511: 'Không thể gửi mã SMS đến điện thoại',
    520: 'Đăng nhập thất bại. Vui lòng thử lại.',
    521: 'Một người dùng đã đăng nhập vào thiết bị này!',
    522: 'Xin vui lòng đăng nhập lại',
    523: 'Xin vui lòng đăng nhập lại',
    524: 'Thật không may, địa chỉ email của bạn không tìm thấy.',
    525: 'Tên đăng nhập hoặc mật khẩu không chính xác',
    526: 'Recaptcha does not matchs',
    527: 'Người dùng không phải là một IB',
    528: 'Người dùng không tồn tại',
    529: 'Tên đăng nhập hoặc mật khẩu không chính xác',
    530: 'Mã không hợp lệ',
    531: 'Mã không hợp lệ',
    540: 'Không thể tìm thấy tài khoản giao dịch của bạn ',
    541: 'Không thể tìm thấy tài khoản nhận hoa hồng',
    542: 'Bạn không được phép mở thêm tài khoản',
    544: 'Bạn không được phép đăng ký thêm tài khoản cho đến khi Bằng chứng ID của bạn được xác minh',
    550: 'Số tài khoản không chính xác',
    551: 'Số dư của bạn không đủ',
    554: 'Số lượng yêu cầu là 0 hoặc trống',
    564: 'Quốc gia không khớp với kênh thanh toán',
    565: 'Mã ngân hàng không chính xác',
    566: 'Không tìm thấy phương thức thanh toán',
    581: 'Bạn đã chọn tham gia chương trình khuyến mãi thành công. Xin lưu ý rằng bạn phải đặt cọc để rút tiền.',
    584: 'Bạn không có bất kỳ khoản hoa hồng nào',
    590: 'Không thể tìm thấy tài khoản chuyển khoản đi',
    591: 'Không thể tìm thấy tài khoản chuyển khoản vào',
    593: 'Chúng tôi không thể xử lý yêu cầu chuyển tiền của bạn từ tài khoản đã chọn do tài khoản của bạn đang chứa tín dụng.',
    594: 'Chuyển tiền từ tài khoản này không được cho phép',
    session_timeout: 'Qúa thời gian chờ, chuyển hướng đến trang đăng nhập.',
  },
}
