/*
 * @Author: kevin_Gxr kevin.guo@suntontech.net
 * @Date: 2023-03-18 10:14:41
 * @LastEditors: kevin_Gxr kevin.guo@suntontech.net
 * @LastEditTime: 2023-04-21 14:48:05
 * @FilePath: /inx-ib-portal/src/util/regulator.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { removeJs, loadJS, createZendesk2, createKakaoTalk } from './dynamicScript'
import { customerService } from '@/constants/customerService'
import { countryCodeEnum } from '@/constants/country'

const regulator = {
  a01b7a65383afe89c374cb5cb5e7d1bd39cb705797fd9f9e591c2fd85d80d009: 'fsc',
  '3928e21abbe4c1eb8db67dba3eb0a51ef2aeb12e04ce712f9d5dcf198990784b': 'fca',
  '422ed5cdad2973add887e99e71c631b57894f78014b42bda5d03a08de05aa78b': 'scb',
}

function getRegulator(SHA256) {
  return regulator[SHA256] || ''
}

function setWebWidgetByRegulator(tags, code, lang) {
  console.log(
    `%cRegulator:${tags} countryCode:${code} `,
    'color: #43bb88;font-size: 24px;font-weight: bold;text-decoration: underline;'
  )

  let list = customerService[tags]
  if (list.zendesk2 && list.zendesk2.includes(Number(code))) {
    createZendesk2(tags, code, lang) // zendesk2

    // create kakao for south korea
    if (code == countryCodeEnum.KOREA) {
      createKakaoTalk()
    }

    // } else if (list.zendesk1 && list.zendesk1.includes(Number(code))) {
    //   loadJS({ // zendesk1
    //     id: 'ze-snippet',
    //     url: 'https://static.zdassets.com/ekr/snippet.js?key=556f7121-35a9-42d5-b719-5a3bbfca154e',
    //   })
  } else if (list.convrs && list.convrs.includes(Number(code))) {
    let cvs = document.getElementById('convrs-chat-channels')
    cvs && (cvs.style.display = 'block')
    // loadJS({ // convrs
    //   id: 'convrs-webchat',
    //   url: 'https://webchat.conv.rs/99da45f913a344e5a937a4521248cfb2ee5d6985.js',
    // })
  } else if (list.kakao && list.kakao.includes(Number(code))) {
    createKakaoTalk()
  }
}

export { getRegulator, setWebWidgetByRegulator }
