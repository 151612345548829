export default {
  home: {
    RebateAccountNumber: 'Numero del conto dello sconto',
    totalCommission: 'commissione totale',
    APPLY_FOR_A_REBATE: 'RICHIEDI UNO SCONTO',
    availableBalance: 'saldo disponibile',
    WITHDRAW_REBATE: 'PRELEVA SCONTO',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'TRASFERISCI LO SCONTO SUL TUO CONTO',
    TOTAL_VOLUME: 'VOLUME TOTALE',
    NET_FUNDING: 'FINANZIAMENTO NETTO',
    DEPOSITS: 'DEPOSITI',
    WITHDRAW: 'PRELIEVO',
    OPEND_ACCOUNT: 'CONTO APERTO',
    PERFORMANCE: 'PERFORMANCE',
    MOST_TRADED_INSTRUMENTS: 'STRUMENTI PIÙ SCAMBIATI',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'CONTI CON LE MIGLIORI PERFORMANCE DEL MESE',
    RECENTLY_OPENED_ACCOUNTS: 'CONTI APERTI DI RECENTE',
    ACCOUNT_MANAGER: 'ACCOUNT MANAGER',
    Account: 'Conto',
    Name: 'Nome',
    Volume: 'Volume',
    Rebate: 'Sconto',
    Balance: 'Saldo',
    ContactClient: 'Contatta cliente',
    applyRebateSuccessTip:
      "Congratulazioni, abbiamo ricevuto la tua richiesta di sconto. | {amount} L'importo sarà trasferito a breve sul conto {account} del rimborso. | Una volta elaborata, sarai in grado di prelevare o trasferire i tuoi fondi.",
    HOVER:
      'Gli sconti del giorno precedente sono calcolati ed elaborati tra le 09:00 e le 11:00 AEST ogni giorno.',
    blacklist:
      'Al momento, questo account non può effettuare un rimborso. Contatta il tuo account manager.',
  },
  liveChat: { header: 'chat dal vivo' },
  contactUs: {
    tip: 'Se hai domande o desideri maggiori informazioni, contattaci attraverso uno dei recapiti indicati di seguito.<br> Faremo del nostro meglio per rispondere a tutte le domande entro 24 ore.',
  },
  notification: {
    pendingIdentityProof: {
      header: 'ELABORAZIONE DELLA RICHIESTA DI APERTURA CONTO',
      id: 'Il documento d’identità è',
      poa: 'L’indirizzo è',
      both: 'Il documento d’identità e il documento di conferma dell’indirizzo sono',
      content:
        'La conferma del tuo {doc} attualmente è in fase di elaborazione e sarà completata a breve. Per velocizzare l’elaborazione del tuo conto live, puoi trasferire subito dei fondi e usufruire dell’apertura prioritaria.',
    },
  },
  identityProof: {
    home: {
      header: 'RICHIESTA DI APERTURA CONTO INCOMPLETA',
      content:
        'Ciao {name}, la tua richiesta di apertura del conto è quasi terminata; affinché il tuo conto sia completo devi però inviare ulteriori documenti.',
    },
    popup: {
      header:
        'Ciao {name}, per poter concludere la creazione del tuo conto live devi caricare il seguente documento a fini di verifica.',
      addressProofSpan:
        '<strong><b>Conferma indirizzo</b></strong><br /><I>Invia <b>uno</b> dei documenti seguenti<br /><br /></I>- La copia di una bolletta recente </br>(non più vecchia di 3 mesi)<br /><b class=or">o</b><br />- La copia di un estratto conto bancario recente </br> (non più vecchio di 3 mesi)<br /><br /><b>IMPORTANTE!</b> Il nome sul documento DEVE mostrare chiaramente il tuo nome e cognome e il tuo indirizzo attuale.<br />"',
    },
    reasonType: {
      4: 'Documenti incompleti',
      5: 'Documenti non approvati',
      6: 'È necessaria una copia a colori',
      8: 'Il nome per l’apertura del conto non coincide',
      9: 'La data di emissione del documento di convalida dell’indirizzo non è idonea',
    },
    reason: 'Non è stato possibile verificare i tuoi documenti.<br> Motivo: {reason}. Riprova.',
    success:
      'Grazie. Abbiamo ricevuto i tuoi documenti e in questo momento li stiamo elaborando per verificarli.',
  },
  fileUploader: {
    supportedFormat: 'Tipi di file supportati: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Dimensione massima dei file caricati: {maxSize} MB',
    maxFileNum: 'Numero massimo di file: {maxFileNum}',
    failedFileExtension: 'Carica il tipo di file corretto',
  },
  ibAccounts: {
    ibAccount: 'Conto IB',
    subIbs: 'Conto IB secondario',
    tradingList: 'Lista di Trading',
    OpeningList: 'Lista di Apertura',
    closeList: 'Lista di Chiusura',
    tradingListColumn: {
      ticket: 'ID Ticket',
      openTime: 'Orario di Apertura',
      type: 'Tipo',
      volume: 'Volume',
      item: 'Articolo',
      openPrice: 'Prezzo di Apertura',
      closeTime: 'Orario di Chiusura',
      closePrice: 'Prezzo di Chiusura',
      commission: 'Commissione',
      profit: 'Profitto',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Seleziona carta di credito',
      selectBA: 'Seleziona conto bancario',
      anotherCC: 'Inserisci manualmente la tua carta di credito',
      anotherBA: 'Aggiungi conto bancario',
      remember: 'Ricorda il mio conto',
    },
    transfer: {
      info: '{piattaforma} rimborserà la commissione bancaria addebitata per <u>UN</u> prelievo al mese. Gli ulteriori prelievi effettuati nel corso del mese saranno soggetti a una commissione bancaria pari a 20 unità della valuta del prelievo.',
      note: 'Al fine di elaborare la sua richiesta di prelievo, si prega di fornire il tuo passaporto, per i conti aziendali, si prega di fornire la fattura a {email}',
      label: {
        upload: 'Carica una copia del tuo estratto conto bancario (aggiornato agli ultimi 3 mesi)',
      },
      formValidation: { upload: 'Carica un estratto conto bancario' },
    },
    REBATE_WITHDRAW_REQUEST: 'RICHIESTA DI PRELIEVO SCONTO',
    PleaseSelectDesiredWithdrawMethod: 'Seleziona il metodo di prelievo desiderato',
    bankCode: 'Codice bancario',
    bankBranchCode: 'Codice filiale',
    bankAccountCurrency: 'Valuta del conto bancario',
    BankName: 'Nome della banca',
    BankAccountsName: 'Nome del conto bancario',
    FasaPayAccountName: 'Nome dell’account FasaPay',
    AustralianBankName: 'Nome della banca australiana',
    BankAccountNumber: 'Numero del conto bancario',
    FasaPayAccountNumber: 'Numero dell’account FasaPay',
    BankAccountBeneficiary: 'Beneficiario del conto bancario',
    BSB: 'BSB',
    skrillEmail: 'Indirizzo e-mail di Skrill',
    NetellerEmail: 'Indirizzo e-mail di Neteller',
    BitwalletEmail: 'Indirizzo e-mail di Bitwallet',
    SticpayEmail: 'Indirizzo e-mail di SticPay',
    BitcoinEmail: 'Indirizzo del portafoglio Bitcoin',
    USDTEmail: 'Indirizzo del portafoglio Tether',
    USDTChain: 'Catena',
    BankBranch: 'Filiale della banca',
    Province: 'Provincia',
    City: 'Città',
    BankAddress: 'Indirizzo della banca',
    AccountNumberIBAN: 'Numero di conto/IBAN',
    BankBeneficiaryName: 'Nome del beneficiario della banca',
    BankAccountBeneficiaryName: 'Nome del beneficiario del conto bancario',
    AccountHoldersAddress: 'Indirizzo del titolare del conto',
    SelectWithdrawCard: 'Seleziona la tua carta per il prelievo',
    Swift: 'Swift',
    SwiftAud: 'Swift (conti non in AUD)',
    ABA_SortCodeABA: 'ABA/Codice di avviamento',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: 'Note importanti',
    ifscCode: 'Codice IFSC',
    name: 'Nome',
    phone: 'Telefono',
    address: 'Indirizzo',
    email: 'Indirizzo e-mail',
    vpa: 'VPA',
    YourPaymentGreaterThanAvailableBalance: 'Il pagamento è superiore al saldo disponibile',
    integer: "È supportato solo l'inserimento di numeri interi",
    CannotUseMoreThanTwoDecimalValues:
      'Non è possibile usare numeri positivi e negativi con più di due decimali',
    YourPaymentIsBeingProcessed: 'La tua richiesta di prelievo è stata inviata',
    Itcannotbe0: 'Non può essere pari a 0',
    noCardWarn:
      'Non hai nessuna carta disponibile per fare un prelievo, per favore collega la tua carta in Dettagli Prelievo prima.',
    amtLarger:
      'L’importo minimo del prelievo è di {currencyType}{minLimit}. I saldi inferiori a 30 devono essere prelevati per intero',
    blackList:
      'Purtroppo in questo momento non puoi inviare una richiesta di prelievo. Per maggiori informazioni, contatta {supportEmail}',
    NetellerChargeAlert:
      'I prelievi Neteller sono limitati a 500.000 USD per transazione e viene applicata una commissione del 2% (con un limite massimo del 30 USD).',
    FasaPayChargeAlert:
      'FasaPay addebita una commissione di transazione dello 0,5% per ogni prelievo.',
    SkrillChargeAlert: 'Skrill applica una commissione di transazione dell’1% per ogni prelievo.',
    cryptoAlert:
      'Ti preghiamo di notare che non possiamo effettuare depositi o prelievi tramite BUSDT; assicurati che l’indirizzo e la Criptovaluta corrispondano alla catena e alla valuta da noi accettati, altrimenti i fondi potrebbero andare persi.',
    usdtNote:
      "L'attuale tassa sul gas di ERC20 è significativamente più alta del solito e di conseguenza potrebbe esserci un lungo ritardo nell'elaborazione della tua transazione. In alternativa, sei in grado di prelevare fondi tramite TRC20 fornendo l'indirizzo del portafoglio corrispondente, oppure puoi scegliere di pagare la tassa del gas per accelerare il tuo prelievo di ERC20.",
    status: {
      withdrawalSubmitted: 'Inviato',
      withdrawalCanceled: 'Cancellato',
      withdrawalAuditing: 'In corso di elaborazione',
      withdrawalRejected: 'Rifiutato',
      withdrawalAccepted: 'In corso di elaborazione',
      withdrawalPayFailed: 'Non riuscito',
      withdrawalSuccess: 'Elaborato',
      withdrawalFailed: 'Non riuscito',
      withdrawalPending: 'In corso',
      withdrawalPartialFailed: 'Non riuscito',
      transferSubmitted: 'In corso di elaborazione',
      transferSuccess: 'Elaborato',
      transferRejected: 'Rifiutato',
      transferFailed: 'Non riuscito',
      transferProcessing: 'In corso di elaborazione',
    },
    formErrorMsg: {
      bankName: 'Il nome della banca è obbligatorio',
      bsb: 'Il BSB è obbligatorio',
      beneName: 'Il nome del beneficiario della banca è obbligatorio',
      accNum: 'Il numero del conto bancario è obbligatorio',
      swift: 'Il codice Swift è obbligatorio',
      bankAddress: 'L’indirizzo della banca è obbligatorio',
      holderAddress: 'L’indirizzo del titolare è obbligatorio',
      amt: 'L’importo è obbligatorio',
      firstSixDigits: 'Inserisci le prime 6 cifre',
      lastFourDigits: 'Inserisci le ultime 4 cifre',
      branchName: 'Il nome della filiale è obbligatorio',
      email: 'L’indirizzo e-mail è obbligatorio',
      accName: 'Il nome dell’account è obbligatorio',
      province: 'La provincia della banca è obbligatoria',
      city: 'La città della banca è obbligatoria',
      bankCard: 'Seleziona una carta',
      emailFormat: 'Inserisci l’indirizzo e-mail corretto',
      amt0: 'L’importo non può essere 0',
      ifscCode: 'Il codice IFSC è obbligatorio',
      bitcoin: 'L’indirizzo del portafoglio Bitcoin è obbligatorio',
      bitcoinFormat: "Formato errato dell'indirizzo del portafoglio Bitcoin",
      usdt: 'L’indirizzo del portafoglio USDT è obbligatorio',
      chain: 'È necessaria una catena',
      walletFormatIncorrect: "Formato errato dell'indirizzo del portafoglio {type}",
      dynamicReq: '{fieldname} è obbligatorio',
      bankCodeReq: 'Codice bancario obbligatorio',
      bankBranchCodeReq: 'Codice filiale obbligatorio',
    },
    note: "A causa di recenti cambiamenti, la nostra banca potrebbe non essere in grado di elaborare trasferimenti bancari internazionali per i clienti registrati in alcuni paesi.<br/>Se dovete prelevare tramite trasferimenti bancari internazionali, si prega di contattare il nostro Supporto Clienti su  +44 2080 363 883 or at <a href='mailto:{mail}'>{mail}</a>.<br/>\n    Ci scusiamo per gli inconvenienti.",
    minAmountWarn:
      'L\'importo del prelievo applicato è inferiore al minimo per il gateway di pagamento applicato. \n    Si prega di ricaricare il saldo del conto fino a/oltre il minimo prima di inviare una richiesta di prelievo. Ulteriori informazioni possono essere trovate <a href="https://{regulatorUrl}/clients/accounts/withdraw/">qui</a>.',
  },
  rebate: {
    status: {
      processed: 'Elaborato',
      processing: 'In corso di elaborazione',
      rejected: 'Rifiutato',
    },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'RICHIESTA DI TRASFERIMENTO DELLO SCONTO',
    transferAmountIsGreaterThanAvailableBalance:
      'L’importo del trasferimento è superiore al saldo disponibile',
    yourRebateTransferHasBeenProcessed: 'Il trasferimento dello sconto è stato inviato',
    PleaseEnterTransferInformation: 'Inserisci i dati esatti del trasferimento',
    tradingAccount: 'Conto di trading',
    rebateAccount: 'Conto dello sconto',
    account: 'Conto',
    blackList:
      'A questo account è stato impedito di effettuare trasferimenti tra account. Contatta {supportEmail}',
  },
  transferHistory: {
    payStatus: {
      processing: 'In corso di elaborazione',
      paid: 'Pagato',
      unpaid: 'Non pagato',
      rejected: 'Rifiutato',
    },
  },
  common: {
    liveChat: { desc: '<li><p>Hai bisogno di aiuto?</p><span>chat dal vivo</span></li>' },
    lang: {
      english: 'Inglese',
      chinese: 'Cinese',
      french: 'Francese',
      thai: 'Tailandese',
      german: 'Tedesco',
      spanish: 'Spagnolo',
      malay: 'Malese',
      viet: 'Vietnamita',
      indo: 'Indonesiano',
      arabic: 'Arabo',
      japanese: 'giapponese',
    },
    button: {
      confirm: 'CONFERMA',
      cancel: 'ANNULLA',
      identityProof: 'COMPILA LA DOMANDA',
      upload: 'CARICA',
      selectFile: 'Seleziona file',
      fundnow: 'FINANZIA ORA',
    },
    withdrawChannel: {
      hongkongbanktransfer: 'Bonifico bancario locale in Hong Kong',
      banktransfer: 'Bonifico bancario',
      aus: 'Bonifico bancario - Australia',
      int: 'Bonifico bancario - Internazionale',
      chn: 'Bonifico bancario - Cina',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      fasapay: 'FasaPay',
      thailandinstantbankwiretransfer: 'Bonifico bancario istantaneo in Thailandia',
      malaysiainstantbankwiretransfer: 'Bonifico bancario istantaneo in Malesia',
      banktransferbpaypolipay: 'Bonifico bancario/BPay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Criptovaluta-Bitcoin',
      cryptocurrencyusdt: 'Criptovaluta-USDT',
      nigeriainstantbankwiretransfer: 'Bonifico bancario istantaneo in Nigeria',
      vietnaminstantbankwiretransfer: 'Bonifico bancario istantaneo in Vietnam',
      indonesiainstantbankwiretransfer: 'Bonifico bancario istantaneo in Indonesia',
      philippinesinstantbankwiretransfer: 'Bonifico bancario istantaneo nelle Filippine',
      southafricainstantbankwiretransfer: 'Bonifico bancario istantaneo in Sudafrica',
      indiainstantbanktransfer: 'Bonifico bancario istantaneo in India',
      laosinstantbankwiretransfer: 'Bonifico bancario istantaneo nel Laos',
      sticpay: 'SticPay',
      transfer: 'Trasferisci sui conti',
      dasshpeupi: 'UPI',
      ugandabanktransfer: 'Bonifico bancario locale in Uganda',
      rwandabanktransfer: 'Bonifico bancario locale in Rwanda',
      zambiabanktransfer: 'Bonifico bancario locale in Zambia',
      congobanktransfer: 'Bonifico bancario locale in Congo',
      cameroonbanktransfer: 'Bonifico bancario locale in Cameroon',
      burundibanktransfer: 'Bonifico bancario locale in Burundi',
      kenyabanktransfer: 'Bonifico bancario locale in Kenia',
      ghanabanktransfer: 'Bonifico bancario locale in Ghana',
      tanzaniabanktransfer: 'Bonifico bancario locale in Tanzania',
    },
    country: { international: 'Internazionale', australia: 'Australia' },
    field: { country_region: 'Paese/Regione' },
    keys: {
      SUBMIT: 'INVIA',
      FROM: 'DA',
      UPDATE: 'AGGIORNA',
      STATUS: 'STATO',
      TO: 'A',
      DATE: 'DATA',
      pleaseSelect: 'Seleziona',
      amount: 'Importo',
      actualAmount: '  Prelievi effettivi = ',
      rebateAccount: 'Conto dello sconto',
      comingSoon: 'prossimamente',
      tradingHistory: 'CRONOLOGIA DELLE OPERAZIONI DI TRADING',
      noData: 'Nessun dato',
      NAME: 'NOME',
      EMAIL: 'INDIRIZZO E-MAIL',
      ACCTYPE: 'TIPO DI CONTO',
      PLATFORM: 'PIATTAFORMA',
      BASECURRENCY: 'VALUTA DI BASE',
      DATERANGE: 'INTERVALLO DI DATE',
      DEST: 'DESTINAZIONE',
      ACCNUM: 'NUMERO DI CONTO',
      BALANCE: 'SALDO',
      NONE: 'Nessuno',
      ALL: 'Tutto',
      PROFITLOST: 'Perdita di Profitto',
      MARGINLEVEL: 'Livello di Margine(%)',
      ACCOUNTEQUITY: 'Saldo Conto',
      CREDIT: 'Credito',
    },
    dateRange: {
      today: 'Oggi',
      yesterday: 'Ieri',
      lastSevenDays: 'Ultimi 7 giorni',
      lastThirtyDays: 'Ultimi 30 giorni',
      weekToDate: 'Settimana in corso',
      monthToDate: 'Mese in corso',
      quarterToDate: 'Trimestre in corso',
      previousWeek: 'Settimana precedente',
      previousMonth: 'Mese precedente',
      previousQuarter: 'Trimestre precedente',
    },
    products: {
      forex: 'FOREX',
      commodities: 'MATERIE PRIME',
      indices: 'INDICI',
      crypto: 'CRIPTOVALUTE',
    },
  },
  report: {
    rebate: {
      column: {
        name: 'NOME',
        accountNumber: 'NUMERO DI CONTO',
        accountType: 'TIPO DI CONTO',
        volume: 'Volume',
        rebate: 'Sconto',
        totalRebate: 'SCONTO TOTALE',
        date: 'DATA',
        instrument: 'STRUMENTO',
        notionalvalue: 'VALORE NOZIONALE',
      },
      dropdown: { allInstruments: 'Tutti gli strumenti', all: 'Tutto', instruments: 'Strumenti' },
      others: {
        rebate: 'SCONTO',
        instrumentsTraded: 'STRUMENTI NEGOZIATI',
        lots: 'Lotti',
        totalRebate: 'Sconto totale',
        rebates: 'Sconti',
      },
    },
  },
  ibReport: { openedAccs: 'CONTI APERTI', funding: 'FINANZIAMENTO', account: 'CONTO' },
  lead: { demoAccounts: 'CONTI DEMO' },
  pendingAccounts: {
    errorDoc:
      'I seguenti richiedenti hanno compilato il modulo di domanda online, ma devono ancora fornire un documento d’identità (ID) per concludere l’apertura del conto.',
    acceptedDocType: 'I documenti di identità accettati includono:',
    primaryDoc: 'Documento d’identità principale',
    passport: 'Copia di un passaporto internazionale valido (pagina della firma)',
    id: 'Copia di una carta d’identità nazionale rilasciata dal governo',
    dl: '',
    address: 'Documento d’identità secondario',
    addressList: [
      'Copia della bolletta di un’utenza pubblica recente. Ad es. Bolletta del gas o dell’elettricità che includa il tuo nome e il tuo indirizzo di residenza (non più vecchia di 3 mesi)',
      'Una copia dell’estratto conto della tua carta di credito o di un tuo estratto conto bancario (non stampe online)',
    ],
    tip: 'Si prega di incoraggiare i richiedenti a fornire una copia di UN documento d’identità primario e secondario accettato a ',
  },
  rebatePaymentHistory: {
    rebateHistory: 'SCONTI',
    withdrawHistory: 'PRELIEVI',
    transferHistory: 'TRASFERIMENTI',
  },
  referralLinks: {
    downloadPage: 'Scarica APP',
    campLang: 'Lingua della campagna',
    cpy: 'COPIA',
    alreadyCPY: 'COPIATO!',
    liveAcc: 'Conto live',
    demoAcc: 'Conto demo',
    homePage: 'Home page',
    promotion: 'Link promozione evento',
    hintTitle: "Cos'è un link di promozione evento?",
    hintMessage:
      "Il link di promozione evento è un nuovo metodo di promozione fornito agli IB. Il link di condivisione guiderà gli utenti alla pagina dell'evento bonus e permetterà il completamento della registrazione. La percentuale di successo della registrazione degli utenti può essere efficacemente migliorata condividendo il link.",
    hintShortLink: 'Condividi utilizzando un link breve',
    hintShortLinkTitle: "Cos'è un link breve?",
    hintShortLinkMessage:
      'Il link breve è una regola di generazione dei link, che può proteggere meglio il tuo link condiviso dalla modifica, proteggendo così i tuoi diritti di sviluppo IB',
    customButton: 'personalizzare',
    copyButton: 'copia',
    customInviteCodeHeader: 'codice di invito personalizzato',
    currentCode: 'codice di invito corrente',
    newCode: 'nuovo codice di invito',
    notice:
      'Nota: il codice di invito ha almeno 6 parametri. È possibile inserire lettere, numeri, simboli non speciali',
    inviteCodeFormatError: 'Il formato del codice di invito non è corretto, modificalo di nuovo',
    inviteCodeAlreadyExist:
      'Il codice di invito è stato preventivamente registrato da altri, reinserirlo',
    pleaseEnter: 'prego entra',
    inviteCodeHeader: 'Codice di invito alla promozione',
    inviteCode: 'Codice invito',
  },
  settings: { info: 'INFORMAZIONI', reg: 'REGISTRATO IL' },
  unfundedAcc: {
    neverFundedAccs: 'CONTI MAI FINANZIATI',
    prevFundedZero: 'FINANZIATO IN PRECEDENZA (SALDO ZERO)',
    accBalance: 'SALDO DEL CONTO',
  },
  multiib: { level: 'LIVELLO {level}', noib: 'In questo conto non ci sono IB secondari.' },
  AWCDialog: { optIn: 'PARTECIPA ORA', noThanks: 'NO GRAZIE' },
  header: { bckToCP: 'TORNA AL PORTALE CLIENTI', logout: 'FINE SESSIONE' },
  menu: {
    dashboard: 'PANNELLO DI CONTROLLO',
    ibReport: 'RAPPORTO IB',
    rebateReport: 'RELAZIONE SCONTI',
    iblevel: 'IB MULTILIVELLO',
    ibAccs: 'CONTI IB',
    leads: 'LEADS',
    pendingAccs: 'CONTI IN SOSPESO',
    unfundedAccs: 'CONTI NON FINANZIATI',
    payHistory: 'CRONOLOGIA TRANSAZIONI',
    ibProfile: 'PROFILO IB',
    refLinks: 'LINK DI REFERRAL',
    contactUs: 'CONTATTACI',
    switchCP: 'VAI AL PORTALE CLIENTI',
  },
  footer: {
    riskWarn:
      'Avvertenza sul rischio: Il trading sul forex e i contratti per differenza (CFD) comportano un rischio significativo e possono comportare la perdita del capitale investito. La negoziazione di prodotti con leva potrebbe non essere adatta a tutti gli investitori. Prima di effettuare operazioni di trading, ti preghiamo di valutare il tuo livello di esperienza e i tuoi obiettivi di investimento e, se necessario, di rivolgerti a un consulente finanziario indipendente. Ti preghiamo di leggere i documenti legali disponibili sul nostro sito web e di assicurarti di comprendere appieno i rischi prima di prendere qualsiasi decisione di trading.',
  },
  responseMsg: {
    410: 'Convalida parametro non riuscita',
    411: 'Password errata',
    420: 'L’utente non esiste',
    421: 'Reimpostazione password negata',
    490: 'Non inviare informazioni duplicate sulla carta',
    500: 'Si è verificato un errore.',
    501: 'Domanda non riuscita. Riprova più tardi.',
    505: 'Caricamento file non riuscito',
    510: 'Codice SMS non corretto',
    511: 'Non è stato possibile inviare il codice SMS al tuo telefono',
    520: 'Accesso non riuscito. Riprova.',
    521: 'Un utente è già connesso su questo dispositivo!',
    522: 'Effettua di nuovo l’accesso',
    523: 'Effettua di nuovo l’accesso',
    524: 'Purtroppo il tuo indirizzo e-mail non è stato riconosciuto',
    525: 'Nome utente o password errati',
    526: 'Il recaptcha non corrisponde',
    527: 'L’utente non è un IB',
    528: 'L’utente non esiste',
    529: 'Nome utente o password errati',
    530: 'Codice non valido',
    531: 'Codice non valido',
    540: 'Conto di trading non trovato',
    541: 'Conto dello sconto non trovato',
    542: 'Non puoi aprire un conto aggiuntivo',
    544: 'Non puoi richiedere un account aggiuntivo fino a quando non il tuo documento d’identità non verrà verificato',
    550: 'Numero di conto errato',
    551: 'Il tuo saldo è insufficiente',
    554: 'L’importo richiesto è pari a zero o vuoto',
    562: 'Non siamo riusciti a elaborare il tuo pagamento, riprova. Se il problema persiste, contattaci tramite la chat dal vivo o via e-mail {email}',
    564: 'Il Paese non corrisponde al canale di pagamento',
    565: 'Codice banca non corretto',
    566: 'Metodo di pagamento non trovato',
    567: 'Questo metodo di pagamento non è attualmente disponibile. Ci scusiamo per i disagi causati',
    581: 'Ti sei iscritto correttamente alla promozione. Ricorda che per poter prelevare i fondi è necessario effettuare un deposito.',
    584: 'Non hai sconti applicabili',
    590: 'Impossibile trovare il conto del trasferimento in uscita',
    591: 'Impossibile trovare il conto del trasferimento in entrata',
    593: 'Non è stato possibile elaborare il trasferimento di fondi dal conto selezionato perché contiene un credito.',
    594: 'Il trasferimento di fondi da questo conto non è consentito',
    session_timeout: 'Sessione scaduta, reindirizzamento alla pagina di login.',
  },
};
