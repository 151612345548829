const { customerService } = require('@/constants/customerService')

import Vue from 'vue'

Vue.mixin({
  computed: {
    regulator() {
      return this.$store.state.common.regulator
    },
    countryCODE() {
      return this.$store.state.common.countryCode
    },
    GLOBAL_DOMAIN_WEBSITE() {
      return this.$store.state.regulator.domainWebSite || this.$platform.info.getUrl(this.regulator)
    },
    GLOBAL_DOMAIN_WEBSITECN() {
      return this.$store.state.regulator.domainWebSiteCn || this.$platform.info.getUrl(this.regulator)
    },
    GLOBAL_CONTACT_EMAIL() {
      return this.$store.state.regulator.contactEmail || this.$platform.info.getEmail(this.regulator, this.countryCODE)
    },
    GLOBAL_ACCOUNT_EMAIL() {
      return (
        this.$store.state.regulator.accountEmail ||
        this.$platform.info.getAccountEmail(this.regulator, this.countryCODE)
      )
    },
    GLOBAL_DEPOSIT_EMAIL() {
      return this.$store.state.regulator.depositEmail || this.$platform.info.getDepositEmail(this.regulator)
    },
  },
  methods: {
    showLiveChat() {
      const regUpperCase = this.regulator.toUpperCase()
      const convrsDiv = document.querySelector('.convrs-chat-mainicon')
      const convrsChatbox = document.querySelector('.convrs-chat-mainicon2')
      const customerServiceArray = customerService[regUpperCase]?.zendesk2 || []
      const customerServiceKakaoArray = customerService[regUpperCase]?.kakao || []
      const showConvrs = !customerServiceArray.includes(parseInt(this.countryCODE))
      const showKakao = customerServiceKakaoArray.includes(parseInt(this.countryCODE))

      // show kakao
      if (showKakao) {
        document.querySelector('#kakao-talk-channel-chat-button').style.visibility = 'visible'
        return
      }

      if (window.$zopim != undefined) {
        $zopim.livechat.window.show()
      }

      if (showConvrs && convrsDiv && convrsChatbox) {
        const clickEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
        convrsDiv.dispatchEvent(clickEvent)
        convrsChatbox.dispatchEvent(clickEvent)
      }
    },
  },
})
