export default {
  home: {
    RebateAccountNumber: 'Nomor akun rabat',
    totalCommission: 'komisi total',
    APPLY_FOR_A_REBATE: 'MENDAFTAR RABAT',
    availableBalance: 'saldo tersedia',
    WITHDRAW_REBATE: 'TARIK RABAT',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'TRANSFER RABAT KE AKUN',
    TOTAL_VOLUME: 'VOLUME TOTAL',
    NET_FUNDING: 'PENDANAAN BERSIH',
    DEPOSITS: 'DEPOSIT / SETORAN',
    WITHDRAW: 'PENARIKAN',
    OPEND_ACCOUNT: 'PEMBUKAAN AKUN',
    PERFORMANCE: 'KINERJA',
    MOST_TRADED_INSTRUMENTS: 'INSTRUMEN YANG PALING SERING DIPERDAGANGKAN',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'AKUN DENGAN KINERJA TERATAS BULAN INI',
    RECENTLY_OPENED_ACCOUNTS: 'AKUN YANG BARU DIBUKA',
    ACCOUNT_MANAGER: 'PENGELOLA AKUN',
    Account: 'Akun',
    Name: 'Nama',
    Volume: 'Volume',
    Rebate: 'Rabat',
    Balance: 'Saldo',
    ContactClient: 'Hubungi Klien',
    applyRebateSuccessTip:
      'Selamat, kami telah menerima permintaan rabat Anda. | {amount} akan segera ditransfer ke Akun rabat {account} . | Setelah diproses, Anda akan bisa menarik atau mentransfer dana Anda.',
    HOVER:
      'Rabat hari sebelumnya akan dihitung dan diproses antara jam 09:00-10.00 AEST setiap hari (06:00-08:00 GMT+7-Jakarta Time)',
    blacklist: 'Akun ini tidak bisa memperoleh rabat saat ini, harap hubungi manajer akun Anda.',
  },
  liveChat: { header: 'Berbicara langsung ' },
  contactUs: {
    tip: 'Jika anda memiliki pertanyaan atau menginginkan informasi lebih lanjut, silahkan hubungi kami melalui daftar kontak berikut. <br> Kami berusaha menanggapi semua pertanyaan dalam waktu 24 jam',
  },
  fileUploader: {
    supportedFormat: 'Jenis file yang didukung: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Jenis file yang didukung: {files}',
    maxSize: 'Ukuran file unggahan maksimum: {maxSize} MB',
    maxFileNum: 'Nomor file maksimum: {maxFileNum}',
    failedFileExtension: 'Unggah jenis file yang benar',
  },
  notification: {
    pendingIdentityProof: {
      header: 'PENGOLAHAN APLIKASI AKUN',
      id: 'Tanda Pengenal adalah',
      poa: 'Alamatnya adalah',
      both: 'Tanda Pengenal dan Bukti alamat adalah',
      content: 'Bukti {doc} anda saat ini sedang diproses dan akan segera diselesaikan.',
    },
  },
  identityProof: {
    home: {
      header: 'APLIKASI AKUN TIDAK LENGKAP',
      content:
        'Halo {name}, permohonan akun anda hampir selesai, namun anda masih perlu mengirimkan dokumen tambahan untuk menyelesaikan akun anda.',
    },
    popup: {
      header:
        'Halo {name}, sebelum akun live anda dapat diselesaikan, anda harus mengunggah dokumen berikut untuk verifikasi.',
      addressProofSpan:
        ' <strong>\n        <b>Konfirmasi Alamat</b>\n        </strong>\n        <br />\n        <I>\n          Kirim <b>satu</b> dari dokumen berikut\n          <br /><br />\n        </I>\n        - Salinan dari tagihan terkini </br>(tidak lebih dari 3 bulan)\n        <br />\n        <b class="or">atau</b><br />\n        - salinan dari rekening koran terbaru </br> (tidak lebih dari 3 bulan)\n        <br />\n        <br />\n        <b>PENTING !</b> Nama dalam dokumen HARUS dengan jelas menunjukan nama lengkap dan alamat anda saat ini.\n        <br />',
    },
    reasonType: {
      4: 'Dokumen Tidak Lengkap',
      5: 'Dokumen Kurang Jelas',
      6: 'Salinan Berwarna Diperlukan',
      8: 'Nama Pembukaan Akun Tidak Sesuai',
      9: 'Tanggal Penerbitan Bukti Alamat Tidak Memenuhi Syarat',
    },
    reason: 'Dokumentasi anda tidak dapat diverifikasi, <br> Alasan: {reason}. Silahkan coba lagi.',
    success: 'Terima kasih. Kami telah berhasil menerima dokumen anda dan sedang memprosesnya untuk verifikasi.',
  },
  ibAccounts: {
    ibAccount: 'Akun IB',
    subIbs: 'Akun Sub IB',
    tradingList: 'Daftar Trading',
    OpeningList: 'Daftar Pembukaan',
    closeList: 'Daftar Penutupan',
    tradingListColumn: {
      ticket: 'IDTiket',
      openTime: 'Jam Buka',
      type: 'Tipe',
      volume: 'Volume',
      item: 'Item',
      openPrice: 'Harga Pembukaan',
      closeTime: 'Jam Tutup',
      closePrice: 'Harga Penutupan',
      commission: 'Komisi',
      profit: 'Keuntungan',
    },
  },
  withdraw: {
    warning: {
      desc: 'Harap diperhatikan hal-hal berikut:',
      desc1: 'Pengguna akhir akan dikenakan biaya transfer bank, yang biasanya berkisar antara 135 hingga 250 JPY.',
      desc2:
        'Permintaan penarikan yang dilakukan setelah jam 14.00 mungkin tertunda atau diproses pada hari berikutnya.',
    },
    default: {
      selectCC: 'Pilih Kartu Kredit',
      selectBA: 'Pilih Akun Bank',
      anotherCC: 'Masukan kartu kredit secara manual',
      anotherBA: 'Tambahkan akun bank',
      remember: 'Ingat akun saya',
    },
    transfer: {
      info: '{platform} akan mengganti biaya bank yang dikenakan untuk <u> SATU </u> penarikan per bulan. Penarikan tambahan dalam sebulan akan dikenakan biaya bank sebesar 20 unit mata uang penarikan anda.',
      label: {
        upload: 'Harap unggah salinan rekening koran anda (terkini hingga 3 bulan terakhir)',
      },
      formValidation: { upload: 'Harap unggah rekening koran' },
    },
    REBATE_WITHDRAW_REQUEST: 'PERMINTAAN PENARIKAN RABAT',
    PleaseSelectDesiredWithdrawMethod: 'Silahkan pilih metode penarikan yang diinginkan',
    BankName: 'Nama Bank',
    bankCode: 'Kode Bank',
    bankAccountCurrency: 'Mata Uang Akun Bank',
    bankBranchCode: 'Kode cabang',
    BankAccountsName: 'Nama Akun Bank',
    FasaPayAccountName: 'Nama Akun Fasapay',
    AustralianBankName: 'Nama Bank Australia',
    BankAccountNumber: 'Nomor Akun Bank',
    FasaPayAccountNumber: 'Nomor Akun Fasapay',
    BankAccountBeneficiary: 'Akun Bank Penerima',
    BSB: 'BSB',
    SortCode: 'Kode urut',
    skrillEmail: 'Email Skrill ',
    NetellerEmail: 'Email Neteller',
    PerfectMoneyEmail: 'Email Perfect Money',
    BitwalletEmail: 'Email Bitwallet',
    SticpayEmail: 'Email SticPay',
    BitcoinEmail: 'Alamat Dompet Bitcoin',
    USDTEmail: 'Alamat Dompet Tether',
    BankAccountName: 'Nama Pemilik Rekening Bank',
    BankBranch: 'Kantor Cabang Bank (3 digit nomor cabang)',
    BankAccountType: 'Jenis Rekening Bank',
    Province: 'Provinsi',
    City: 'Kota',
    BankAddress: 'Alamat Bank',
    AccountNumberIBAN: 'Nomor Akun / IBAN',
    BankBeneficiaryName: 'Nama Pemilik Rekening Bank',
    BankAccountBeneficiaryName: 'Nama Akun Bank Penerima',
    AccountHoldersAddress: 'Alamat Pemilik Akun',
    SelectWithdrawCard: 'Pilih Kartu Penarikan Anda',
    Swift: 'Swift',
    SwiftAud: 'Swift (Akun Non AUD)',
    ABA_SortCodeABA: 'ABA/ Kode urut',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: 'Catatan Penting',
    ifscCode: 'Kode IFSC',
    uploadDocument: 'Harap unggah salinan rekening koran anda (terkini hingga 3 bulan terakhir)',
    YourPaymentGreaterThanAvailableBalance: 'Pembayaran anda melebihi saldo yang tersedia',
    integer: 'Hanya boleh berisi bilangan bulat',
    CannotUseMoreThanTwoDecimalValues:
      'Anda tidak dapat menggunakan bilangan dan bilangan negatif yang melebihi dua angka desimal',
    YourPaymentIsBeingProcessed: 'Permohonan penarikan anda telah diajukan',
    Itcannotbe0: 'Tidak boleh 0',
    noCardWarn:
      'Anda tidak memiliki kartu yang tersedia untuk melakukan penarikan, silahkan tautkan kartu anda di dalam Detail Penarikan terlebih dahulu',
    amtLarger: 'Jumlah penarikan minimal adalah {currencyType}{minLimit}. Saldo di bawah 30 harus ditarik secara penuh',
    blackList:
      'Sayangnya saat ini Anda tidak dapat mengirimkan permintaan penarikan. Harap hubungi {supportEmail} untuk informasi lebih lanjut',
    NetellerChargeAlert:
      'Penarikan Neteller dibatasi hingga $500.000 per transaksi, dan biaya 2% (dibatasi pada $30) berlaku.',
    FasaPayChargeAlert: 'FasaPay mengenakan biaya transaksi 0,5% untuk setiap penarikan.',
    SkrillChargeAlert: 'Skrill membebankan biaya transaksi 1% untuk setiap penarikan.',
    cryptoAlert:
      'Harap dicatat bahwa kami tidak dapat melakukan deposit atau penarikan melalui BUSDT, pastikan alamat dan Mata Uang Kripto Anda sesuai dengan alamat rantai dan mata uang yang kami terima atau Anda bisa kehilangan dana Anda.',
    name: 'Nama',
    phone: 'Nomor Telepon',
    address: 'Alamat',
    email: 'Email',
    status: {
      withdrawalSubmitted: 'Diajukan / Dikirimkan',
      withdrawalCanceled: 'Dibatalkan',
      withdrawalAuditing: 'Sedang diproses',
      withdrawalRejected: 'Ditolak',
      withdrawalAccepted: 'Sedang diproses',
      withdrawalPayFailed: 'Gagal',
      withdrawalSuccess: 'Diproses',
      withdrawalFailed: 'Gagal',
      withdrawalPending: 'Tertunda',
      withdrawalPartialFailed: 'Gagal',
      transferSubmitted: 'Sedang diproses',
      transferSuccess: 'Diproses',
      transferRejected: 'Ditolak',
      transferFailed: 'Gagal',
      transferProcessing: 'Sedang diproses',
    },
    formErrorMsg: {
      bankName: 'Nama Bank harus diisi',
      bsb: 'BSB harus diisi',
      sortCode: 'Sort Code diperlukan',
      beneName: 'Nama Pemilik Rekening Bank harus diisi',
      bankCodeReq: 'Kode Bank diperlukan',
      bankBranchCodeReq: 'Kode cabang wajib diisi',
      accNum: 'Nomor Akun Bank harus diisi',
      swift: 'Swift harus diisi',
      bankAddress: 'Alamat Bank harus diisi',
      holderAddress: 'Alamat Penerima harus diisi',
      amt: 'Jumlah harus diisi',
      firstSixDigits: 'Silahkan isi 6 digit pertama',
      lastFourDigits: 'Silahkan isi 4 digit pertama',
      branchName: 'Nama cabang harus diisi',
      email: 'Email harus diisi',
      accName: 'Nama akun harus diisi',
      province: 'Provinsi Bank harus diisi',
      city: 'Kota Bank harus diisi',
      bankCard: 'Silahkan pilih kartu',
      emailFormat: 'Silahkan isikan alamat email yang tepat',
      amt0: 'Jumlah tidak boleh 0',
      ifscCode: 'Kode IFSC harus diisi',
      bitcoin: 'Alamat Dompet Bitcoin diperlukan',
      bitcoinAddressLength: 'Alamat wallet bitcoin harus di antara 26 dan 42 karakter',
      usdt: 'Alamat USDT Wallet diperlukan',
      dynamicReq: '{fieldname} diperlukan',
      uploadDocument: 'Harap unggah rekening koran',
    },
    note: "Akibat adanya perubahan baru-baru ini, bank kami mungkin tidak dapat memproses transfer antar bank internasional bagi para klien yang terdaftar di beberapa negara.<br/>\n      Bila Anda terpaksa melakukan penarikan melalui transfer bank, harap hubungi layanan Dukungan Pelanggan kami di nomor telepon +44 2080 363 883 atau pada <a href='mailto:{mail}'>{mail}</a>.<br/>\n      Kami mohon maaf atas ketidaknyamanan ini.",
    minAmountWarn:
      'Jumlah penarikan yang diajukan kurang dari jumlah pengajuan minimum portal pembayaran. Harap tambahkan dana ke saldo Anda hingga melampaui jumlah minimum sebelum mengajukan permintaan penarikan. Informasi selengkapnya dapat dilihat di <a href="https://{regulatorUrl}/clients/accounts/withdraw/">sini</a>. ',
  },
  rebate: { status: { processed: 'Diproses', processing: 'Sedang diproses', rejected: 'Ditolak' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'PERMOHONAN TRANSFER RABAT',
    transferAmountIsGreaterThanAvailableBalance: 'Jumlah transfer anda melebihi jumlah saldo yang tersedia',
    yourRebateTransferHasBeenProcessed: 'Transfer rabat anda sudah diajukan',
    PleaseEnterTransferInformation: 'Silahkan isi dengan informasi transfer yang tepat',
    tradingAccount: 'Akun Perdagangan',
    rebateAccount: 'Akun Rabat',
    account: 'Akun',
    blackList: 'Akun ini telah dibatasi untuk melakukan transfer antar akun, harap hubungi {supportEmail}',
  },
  transferHistory: {
    payStatus: {
      processing: 'Sedang diproses',
      paid: 'Terbayar',
      unpaid: 'Belum terbayar',
      rejected: 'Ditolak',
    },
  },
  common: {
    liveChat: { desc: 'Butuh bantuan? Berbicara langsung' },
    lang: {
      english: 'Bahasa Inggris',
      chinese: 'Bahasa Mandarin',
      french: 'Bahasa Perancis',
      thai: 'Bahasa Thailand',
      german: 'Bahasa Jerman',
      spanish: 'Bahasa Spanyol',
      malay: 'Bahasa Melayu',
      viet: 'Bahasa Vietnam',
      indo: 'Bahasa Indonesia',
      arabic: 'Bahasa Arab',
      japanese: 'Bahsas Jepang',
    },
    button: {
      confirm: 'KONFIRMASI',
      cancel: 'BATAL',
      identityProof: 'LENGKAPI PERMOHONAN',
      upload: 'UNGGAH',
      selectFile: 'Pilih File',
      fundnow: 'DANAI SEKARANG',
      iunderstand: 'Saya mengerti',
    },
    withdrawChannel: {
      hongkongbanktransfer: 'Transfer Bank Lokal Hong Kong',
      banktransfer: 'Transfer Bank',
      aus: 'Transfer Bank - Australia',
      int: 'Transfer Bank - Internasional',
      chn: 'Transfer Bank - China',
      uk: 'Transfer Bank - UK',
      skrill: 'Skrill ',
      skrill_neteller: 'Skrill/Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      fasapay: 'Fasapay',
      thailandinstantbankwiretransfer: 'Wire Transfer Bank Instan Thailand ',
      malaysiainstantbankwiretransfer: 'Transfer Bank Instan Malaysia',
      banktransferbpaypolipay: 'Transfer Bank/Bpay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Cryptocurrency-Bitcoin',
      cryptocurrencyusdt: 'Cryptocurrency-USDT',
      nigeriainstantbankwiretransfer: 'Wire Transfer Bank Instan Nigeria',
      vietnaminstantbankwiretransfer: 'Wire Transfer Bank Instan Vietnam ',
      indonesiainstantbankwiretransfer: 'Transfer Bank Instan Indonesia',
      philippinesinstantbankwiretransfer: 'Transfer Bank Instan Filipina',
      southafricainstantbankwiretransfer: 'Wire Transfer Bank Instan Afrika Selatan',
      indiainstantbanktransfer: 'Wire Transfer Bank Instan India',
      laosinstantbankwiretransfer: 'Transfer Instan Antar Bank Laos',
      ugandabanktransfer: 'Transfer Bank Lokal Uganda',
      rwandabanktransfer: 'Transfer Bank Lokal Rwanda',
      zambiabanktransfer: 'Transfer Bank Lokal Zambia',
      congobanktransfer: 'Transfer Bank Lokal Kongo',
      cameroonbanktransfer: 'Transfer Bank Lokal Kamerun',
      burundibanktransfer: 'Transfer Bank Lokal Burundi',
      kenyabanktransfer: 'Transfer Bank Lokal Kenya',
      ghanabanktransfer: 'Transfer Bank Lokal Ghana',
      tanzaniabanktransfer: 'Transfer Bank Lokal Tanzania',
      brazilbanktransfer: 'Transfer Bank Instan Brazil',
      mexicobanktransfer: 'Transfer Bank Instan Meksiko',
      internetbankingmexico: 'Perbankan Elektronik (Meksiko)',
      internetbankingbrazil: 'Perbankan Elektronik (Brazil)',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      transfer: 'Transfer ke akun',
    },
    country: { international: 'Internasional', australia: 'Australia', uk: 'UK' },
    field: { country_region: 'Negara/Wilayah', yes: 'Ya', no: 'Tidak' },
    keys: {
      SUBMIT: 'KIRIMKAN',
      FROM: 'DARI',
      UPDATE: 'PEMBARUAN',
      STATUS: 'STATUS',
      TO: 'KE',
      DATE: 'TANGGAL',
      pleaseSelect: 'Silahkan Pilih',
      amount: 'Jumlah',
      actualAmount: '  Penarikan aktual = ',
      rebateAccount: 'Akun Rabat',
      comingSoon: 'Segera hadir',
      tradingHistory: 'RIWAYAT PERDAGANGAN',
      noData: 'Data tidak tersedia',
      NAME: 'NAMA',
      EMAIL: 'EMAIL',
      ACCTYPE: 'TIPE AKUN',
      PLATFORM: 'PLATFORM',
      BASECURRENCY: 'BASIS MATA UANG',
      DATERANGE: 'RENTANG TANGGAL',
      DEST: 'TUJUAN',
      ACCNUM: 'NOMOR AKUN',
      BALANCE: 'SALDO',
      NONE: 'Tidak ada',
      ALL: 'Semua',
      PROFITLOST: 'Laba Rugi',
      MARGINLEVEL: 'Level Margin (%)',
      ACCOUNTEQUITY: 'Ekuitas Akun',
      CREDIT: 'Kredit',
      DOWNLOAD: 'UNDUHAN',
    },
    dateRange: {
      today: 'Hari ini',
      yesterday: 'Kemarin',
      lastSevenDays: '7 hari terakhir',
      lastThirtyDays: '30 hari terakhir',
      weekToDate: 'Minggu sampai saat ini',
      monthToDate: 'Bulan Sampai Saat Ini',
      quarterToDate: 'Kuartal sampai saat ini',
      previousWeek: 'Minggu lalu',
      previousMonth: 'Bulan lalu',
      previousQuarter: 'Kuartal lalu',
    },
    products: {
      forex: 'FOREX',
      commodities: 'KOMODITI',
      indices: 'INDEKS',
      crypto: 'KRIPTO',
      bond: 'Obligasi',
      bonds: 'Obligasi',
      gold: 'EMAS',
      minigold: 'MINI EMAS',
      goldfutures: 'MASA DEPAN EMAS',
      bitcoin: 'BITCOIN',
      silver: 'PERAK',
      silverfutures: 'MASA DEPAN PERAK',
      oil: 'MINYAK',
      oilfutures: 'MASA DEPAN MINYAK',
      index: 'INDEKS',
      indexfutures: 'INDEKS BERJANGKA',
      etf: 'ETF',
      forexfutures: 'FOREX',
      stock: 'MASA DEPAN FOREX',
    },
  },
  withdrawalFreeze: {
    header: 'Withdraw dana tidak dapat dilakukan sementara waktu',
    desc: 'Dikarenakan perubahan password masuk Anda, withdraw dana tidak dapat dilakukan sementara waktu selama T+{day} hari karena alasan keamanan. Pembatasan ini diharapkan akan berakhir dalam {hour} jam.',
    desc2:
      'Dikarenakan perubahan alamat email/nomor telepon masuk Anda, withdraw dana tidak dapat dilakukan sementara waktu selama T+{day} hari. Pembatasan ini diharapkan akan berakhir dalam {hour} jam.',
  },
  report: {
    rebate: {
      column: {
        name: 'NAMA',
        accountNumber: 'NOMOR AKUN',
        accountType: 'TIPE AKUN',
        volume: 'Volume',
        rebate: 'Rabat',
        totalRebate: 'RABAT TOTAL',
        date: 'TANGGAL',
        instrument: 'INSTRUMEN',
        notionalvalue: 'NILAI TAK KONVENSIONAL',
      },
      dropdown: { allInstruments: 'Semua Instrumen', all: 'Semua', instruments: 'Instrumen' },
      others: {
        rebate: 'RABAT',
        instrumentsTraded: 'INSTRUMEN YANG DIPERDAGANGKAN',
        lots: 'Lot',
        totalRebate: 'Rabat Total',
        rebates: 'Rabat',
      },
    },
    btn: { showAll: 'Tampilkan Semua', hide: 'Bersembuny' },
  },
  ibReport: { openedAccs: 'AKUN DIBUKA', funding: 'PENDANAAN', account: 'AKUN' },
  lead: { demoAccounts: 'AKUN DEMO' },
  pendingAccounts: {
    errorDoc:
      'Pemohon berikut telah mengisi fromulir aplikasi online namun belum memberikan bukti identitas (ID) untuk melengkapi akun mereka.',
    acceptedDocType: 'Bentuk tanda pengenal yang bisa diterima meliputi:',
    primaryDoc: 'Tanda Pengenal Utama',
    passport: 'Salinan paspor internasional yang masih berlaku (halaman bertanda tangan)',
    id: 'Salinan Kartu Tanda Penduduk (KTP) yang dikeluarkan oleh pemerintah',
    dl: 'Salinan Surat Izin Mengemudi (SIM) yang masih berlaku',
    address: 'Tanda Pengenal Tambahan',
    addressList: [
      'Salinan dari tagihan rumah tangga terbaru. Misalnya: Gas atau listrik dengan nama dan alamat tempat tinggal anda (tidak boleh lebih dari 3 bulan)',
      'Salinan laporan kartu kredit atau rekening koran anda (bukan berupa cetakan online)',
    ],
    tip: 'Dimohon untuk mengingatkan pemohon untuk menyediakan salinan dari SALAH SATU dokumen tanda pengenal utama yang diterima dan tanda pengenal tambahan ke',
  },
  rebatePaymentHistory: {
    rebateHistory: 'REBAT',
    withdrawHistory: 'PENARIKAN',
    transferHistory: 'TRANSFER',
  },
  referralLinks: {
    copyLink: 'SALIN TAUTAN',
    downloadPage: 'Unduh APLIKASI',
    campLang: 'Bahasa Kampanye',
    cpy: 'SALIN',
    alreadyCPY: 'TERSALIN!',
    liveAcc: 'Akun Live',
    demoAcc: 'Akun Demo',
    homePage: 'Beranda',
    promotion: 'Tautan promosi acara',
    hintTitle: 'Apa itu tautan promosi acara?',
    hintMessage:
      'Tautan promosi acara adalah metode promosi baru yang disediakan bagi IB. Tautan yang dibagikan akan memandu pengguna ke halaman acara bonus dan melengkapi pendaftaran. Tingkat kesuksesan pendaftaran pengguna dapat ditingkatkan secara efektif dengan membagikan tautan tersebut.',
    hintShortLink: 'Bagikan menggunakan tautan singkat',
    hintShortLinkTitle: 'Apa itu tautan singkat?',
    hintShortLinkMessage:
      'Tautan singkat adalah aturan pembuatan tautan yang bisa melindungi tautan bersama Anda dengan lebih baik agar tidak diubah oleh orang lain, sehingga melindungi hak pengembangan IB Anda',
    customButton: 'menyesuaikan',
    copyButton: 'salinan',
    customInviteCodeHeader: 'kode undangan khusus',
    currentCode: 'kode undangan saat ini',
    newCode: 'kode undangan baru',
    notice: 'Catatan: Kode undangan memiliki setidaknya 6 parameter. Huruf, angka, simbol non-khusus dapat dimasukkan',
    inviteCodeFormatError: 'Format kode undangan salah, mohon diedit lagi',
    inviteCodeAlreadyExist: 'Kode undangan telah didaftarkan terlebih dahulu oleh orang lain, silakan masukkan kembali',
    pleaseEnter: 'silahkan masuk',
    inviteCodeHeader: 'Kode undangan promosi',
    inviteCode: 'Kode undangan',
  },
  settings: {
    info: 'INFO',
    reg: 'TERDAFTAR PADA',
    CustomerInfo: 'informasi pengguna',
    name: 'Nama',
    email: 'Surel',
    phone: 'Nomor telepon',
    address: 'Alamat',
  },
  unfundedAcc: {
    neverFundedAccs: 'AKUN TIDAK PERNAH DIDANAI',
    prevFundedZero: 'PERNDAH DIDANAI (SALDO NOL)',
    accBalance: 'SALDO AKUN',
  },
  multiib: { level: 'LEVEL {level}', noib: 'Tidak ada sub-IB di bawah akun ini' },
  AWCDialog: { optIn: 'IKUTI SEKARANG', noThanks: 'TIDAK, TERIMA KASIH' },
  header: { bckToCP: 'KEMBALI KE PORTAL KLIEN', logout: 'KELUAR' },
  menu: {
    dashboard: 'DASBOR / PAPAN INSTRUMEN ',
    ibReport: 'LAPORAN IB',
    rebateReport: 'LAPORAN RABAT',
    iblevel: 'IB MULTI-LEVEL',
    ibAccs: 'AKUN IB',
    leads: 'LEAD',
    pendingAccs: 'AKUN DITUNDA',
    unfundedAccs: 'AKUN BELUM DIDANAI',
    payHistory: 'RIWAYAT TRANSAKSI',
    ibProfile: 'PROFIL IB',
    refLinks: 'TAUTAN REFERAL',
    contactUs: 'HUBUNGI KAMI',
    switchCP: 'GANTI KE PORTAL KLIEN',
  },
  footer: {
    riskWarn:
      'Peringatan risiko: Trading Forex dan CFD melibatkan risiko yang signifikan dan dapat mengakibatkan hilangnya modal yang anda investasikan. Perdagangan produk dengan leverage mungkin tidak cocok untuk semua investor. Sebelum berdagang, harap pertimbangkan tingkat pengalaman anda, tujuan investasi, dan dapatkan nasihat keuangan independen jika perlu. Harap baca dokumen legal kami di situs web dan pastikan bahwa anda sepenuhnya memahami risikonya sebelum anda membuat keputusan perdagangan apa pun.',
    riskWarn_fsc:
      'Peringatan risiko: Derivatif adalah instrumen kompleks dan memiliki risiko tinggi kehilangan uang dengan cepat karena leverage. Anda harus mempertimbangkan apakah Anda memahami cara kerja Derivatif dan apakah Anda mampu mengambil risiko tinggi kehilangan uang Anda.',
  },
  responseMsg: {
    410: 'Validasi parameter gagal',
    411: 'Kata kunci Salah',
    420: 'Pengguna tidak ditemukan',
    421: 'Setel Ulang Kata Sandi ditolak',
    490: 'Harap jangan mengirimkan informasi kartu secara berganda',
    500: 'Terjadi kesalahan.',
    501: 'Permohonan gagal. Silakan coba lagi nanti.',
    505: 'Gagal mengunggah berkas',
    510: 'Kode SMS salah',
    511: 'Tidak dapat mengirim kode SMS ke ponsel anda',
    520: 'Gagal masuk. Silahkan coba lagi.',
    521: 'Seorang pengguna sudah masuk di perangkat ini!',
    522: 'Silakan login lagi',
    523: 'Silakan login lagi',
    524: 'Mohon maaf, alamat email anda tidak dikenali',
    525: 'Username atau password salah',
    526: 'Recaptcha tidak cocok',
    527: 'Pengguna bukan seorang IB',
    528: 'Pengguna tidak ditemukan',
    529: 'Username atau password salah',
    530: 'Kode tidak valid',
    531: 'Kode tidak valid',
    540: 'Akun trading anda tidak dapat ditemukan',
    541: 'Akun Rabat tidak dapat ditemukan',
    542: 'Anda tidak diizinkan untuk membuka akun tambahan',
    544: 'Anda tidak diperbolehkan mendaftarkan akun tambahan hingga Bukti Identitas Anda diverifikasi',
    550: 'Nomor rekening salah',
    551: 'Saldo anda tidak mencukupi',
    554: 'Jumlah yang diminta nol atau null',
    562: 'Kami tidak dapat memproses pembayaran anda, coba lagi. Jika tetap terjadi masalah, silakan hubungi kami melalui obrolan langsung, atau email {email}',
    564: 'Negara tidak sesuai dengan portal pembayaran',
    565: 'Kode Bank Salah',
    566: 'Metode pembayaran tidak ditemukan',
    567: 'Mohon maaf metode pembayaran ini saat ini tidak tersedia. Kami mohon maaf atas ketidaknyamanan yang terjadi',
    581: 'anda telah berhasil mengikuti promosi. Harap dicatat bahwa anda harus melakukan deposit untuk menarik dana.',
    584: 'Anda tidak memiliki rabat yang berlaku',
    590: 'Akun transfer keluar tidak dapat ditemukan',
    591: 'Akun transfer masuk tidak dapat ditemukan',
    593: 'Kami tidak dapat memproses transfer dana Anda dari akun yang dipilih karena akun Anda mengandung kredit.',
    594: 'Transfer dana dari akun ini tidak diizinkan',
    session_timeout: 'Sesi berakhir, mengarahkan ke laman login',
  },
}
