import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' // element-ui lang
import elementZhTWLocale from 'element-ui/lib/locale/lang/zh-TW' // element-ui lang
import elementFrLocale from 'element-ui/lib/locale/lang/fr'
import elementKrLocale from 'element-ui/lib/locale/lang/ko'
import elementJaLocale from 'element-ui/lib/locale/lang/ja'
import elementThLocale from 'element-ui/lib/locale/lang/th'
import elementViLocale from 'element-ui/lib/locale/lang/vi'
import elementEsLocale from 'element-ui/lib/locale/lang/es'
import elementPtLocale from 'element-ui/lib/locale/lang/pt'
import elementDeLocale from '@/lang/element-ui/de'
import elementIdLocale from 'element-ui/lib/locale/lang/id'
import elementArLocale from 'element-ui/lib/locale/lang/ar'
import elementRuLocale from 'element-ui/lib/locale/lang/ru-RU'
import elementMnLocale from 'element-ui/lib/locale/lang/mn'
import elementKmLocale from 'element-ui/lib/locale/lang/km'
import elementNlLocale from '@/lang/element-ui/nl'
import elementItLocale from 'element-ui/lib/locale/lang/it'
import elementPlLocale from 'element-ui/lib/locale/lang/pl'
import elementMsLocale from '@/lang/element-ui/ms'

import enLocale from './resources/en'
import zhLocale from './resources/zh'
import zhTwLocale from './resources/zh_TW'
import frLocale from './resources/fr'
import krLocale from './resources/kr'
import jaLocale from './resources/ja'
import thLocale from './resources/th'
import viLocale from './resources/vi'
import esLocale from './resources/es'
import ptLocale from './resources/pt'
import deLocale from './resources/de'
import idLocale from './resources/id'
import arLocale from './resources/ar'
import ruLocale from './resources/ru'
import mnLocale from './resources/mn'
import kmLocale from './resources/km'
import nlLocale from './resources/nl'
import itLocale from './resources/it'
import msLocale from './resources/ms'
import plLocale from './resources/pl'

Vue.use(VueI18n)

const messages = {
  en_US: {
    ...enLocale,
    ...elementEnLocale,
  },
  zh_CN: {
    ...zhLocale,
    ...elementZhLocale,
  },
  zh_TW: {
    ...zhTwLocale,
    ...elementZhTWLocale,
  },
  fr_FR: {
    ...frLocale,
    ...elementFrLocale,
  },
  ko: {
    ...krLocale,
    ...elementKrLocale,
  },
  ja: {
    ...jaLocale,
    ...elementJaLocale,
  },
  th: {
    ...thLocale,
    ...elementThLocale,
  },
  vi: {
    ...viLocale,
    ...elementViLocale,
  },
  es: {
    ...esLocale,
    ...elementEsLocale,
  },
  pt: {
    ...ptLocale,
    ...elementPtLocale,
  },
  de: {
    ...deLocale,
    ...elementDeLocale,
  },
  id: {
    ...idLocale,
    ...elementIdLocale,
  },
  ar: {
    ...arLocale,
    ...elementArLocale,
  },
  ru: {
    ...ruLocale,
    ...elementRuLocale,
  },
  mn: {
    ...mnLocale,
    ...elementMnLocale,
  },
  km: {
    ...kmLocale,
    ...elementKmLocale,
  },
  nl: {
    ...nlLocale,
    ...elementNlLocale,
  },
  it: {
    ...itLocale,
    ...elementItLocale,
  },
  ms: {
    ...msLocale,
    ...elementMsLocale,
  },
  pl: {
    ...plLocale,
    ...elementPlLocale,
  },
}

const i18n = new VueI18n({
  messages, // set locale messages
  fallbackLocale: 'en_US',
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n
