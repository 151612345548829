export const customerService = {
  FSC: {
    zendesk2: [
      1, // China
      4635, // Cambodia
      6877, // Indonesia
      5860, // Japan
      7036, //  Korea(the Republic of)
      4850, //  Lao People's Democratic Republic
      5015, // Malaysia
      5527, //  Mongolia
      5589, // Myanmar
      5751, // Nepal
      4131, // Philippines
      6645, // Singapore
      7043, //Taiwan(Province of China)
      6163, //Thailand
      6991, //Vietnam
      7041, // South Korea
    ],
    convrs: [
      3340, 3276, 3290, 7027, 3481, 3490, 7049, 5748, 7047, 3346, 6729, 7050, 3512, 3399, 3588, 3624, 7028, 7046, 3665,
      3809, 3686, 3673, 3817, 3781, 3795, 3808, 7051, 3636, 4711, 7025, 7008, 7010, 7055, 7056, 4186, 4713, 4809, 4221,
      4233, 4540, 3981, 3982, 4070, 3416, 5961, 7032, 4093, 3423, 7058, 4126, 4137, 7059, 4127, 7060, 4624, 4183, 5859,
      4611, 7061, 4232, 4231, 7062, 4253, 6404, 7063, 4564, 4574, 4254, 4305, 7064, 4514, 3714, 6765, 7065, 6767, 6777,
      6713, 7066, 6976, 4266, 4759, 4535, 4735, 4546, 4869, 4838, 4877, 4896, 4910, 4978, 5010, 4986, 5144, 5156, 7070,
      5161, 5160, 7071, 5619, 5617, 7035, 7073, 5606, 5618, 5681, 5750, 7075, 6647, 5767, 5786, 5796, 7076, 7077, 3685,
      5804, 3389, 3589, 5826, 3635, 3567, 3600, 5558, 3715, 4700, 4917, 7039, 6053, 7084, 6056, 5511, 6054, 6050, 6025,
      6003, 5987, 6024, 5997, 6133, 6145, 7085, 6057, 6110, 6121, 7087, 5932, 6146, 3959, 3974, 7091, 6268, 6277, 6278,
      6305, 6385, 7092, 6304, 6459, 6517, 3382, 6907, 6544, 6565, 6396, 7095, 7096, 6996, 5696, 5987, 6024, 5997, 6645,
      6084, 6094, 6581, 5909, 6163, 6991, 7031, 7045, 6766, 5557, 5605, 5155, 3983, 7082, 7083, 7042, 7043, 7089, 6432,
      6431, 6430, 7043, 7089, 7044, 7037, 4676, 6742, 6695, 5176, 7052, 6458, 3818, 3865, 4688, 4575,
    ],
    kakao: [
      7041, // South Korea
    ],
  },
  FCA: {
    // zendesk1: [
    //     3340, 3276, 3290, 7027, 3481, 3490, 7049, 5748, 7047, 3346, 6729, 7050, 3512, 3554,
    //     3399, 3588, 3624, 7028, 7046, 3665, 3701, 3809, 3686, 3673, 3817, 3781, 3795, 3808,
    //     7051, 3636, 7052, 6458, 3674, 3818, 3865, 4635, 4688, 4575, 4711, 7025, 7008, 7010,
    //     1, 7055, 4186, 4713, 4809, 4221, 7057, 4233, 6016, 4661, 3899, 4540, 3981, 3982,
    //     4070, 3416, 5961, 7032, 4093, 3464, 3423, 4126, 4137, 4138, 4101, 7059, 4127, 7060,
    //     4624, 4183, 5859, 3915, 4611, 7061, 6633, 4232, 4231, 7062, 4253, 6404, 7063, 4564,
    //     4254, 4305, 7064, 4514, 7033, 6673, 3714, 6845, 6877, 6765, 3436, 7065, 6767, 6777,
    //     6713, 5860, 7066, 6976, 4266, 4759, 4535, 4735, 4546, 4850, 4810, 4869, 4838, 4877,
    //     4896, 4910, 4897, 4912, 4978, 5010, 5015, 4986, 5144, 5009, 5156, 7070, 5161, 5160,
    //     7071, 5619, 5617, 5527, 7035, 7073, 5606, 5618, 5589, 5681, 5750, 5751, 4482, 7075,
    //     6647, 5767, 5786, 5796, 7076, 7077, 3685, 5804, 3389, 3589, 5826, 3635, 3567, 3600,
    //     5558, 4131, 3716, 5828, 3715, 4700, 4935, 4917, 7039, 6053, 7084, 6056, 5511, 6054,
    //     6050, 6025, 6003, 5987, 6024, 5997, 6645, 6084, 6094, 6133, 6145, 5696, 7085, 6581,
    //     6057, 6110, 6121, 7087, 5909, 5932, 6146, 6163, 3974, 7091, 6268, 6277, 6278, 6305,
    //     6385, 7092, 6304, 6459, 6517, 3382, 6907, 6544, 6565, 6396, 6991, 7095, 7096, 6996,
    //     7056, 7031, 7045, 7058, 4574, 6766, 7041, 7036, 5557, 5605, 5155, 3983, 7082, 7083,
    //     7042, 7043, 3959, 6432, 6431, 6430, 7044, 7037, 4676, 6742, 6695, 5176,
    // ],
  },
  SCB: {
    zendesk2: [],
    convrs: [
      3554, // Austria
      3701, // Belgium
      3674, // Bulgaria
      7057, // Croatia
      6016, // Cyprus
      4661, // Czech Republic
      3899, // Denmark
      3464, // Estonia
      4138, // Finland
      4101, // France
      3915, // Germany
      6633, // Greece
      6673, // Hungary
      6845, // India
      3436, // Ireland
      4810, // Latvia
      4897, // Lithuania
      4912, // Luxembourg
      5009, // Malta
      4482, // Netherlands
      3716, // Poland
      5828, // Portugal
      4935, // Romania
      6084, // Slovakia
      6094, // Slovenia
      6581, // Spain
      5909, // Sweden
    ],
    kakao: [
      7041, // South Korea
    ],
  },
}
