import { instance, instanceExternal } from '@/axios/index.js'
import qs from 'qs'

const baseUrl = '/api/'
const cpUrl = '/cp/api/'

const queryCurrencyUrl = `${cpUrl}transfer/currencyList`
const queryCurrencyTo = `${cpUrl}transfer/currencyRate`

const loginUrl = `${baseUrl}login/`
const homeUrl = `${baseUrl}home/`
const withdrawalUrl = `${baseUrl}withdrawal/`
const transferUrl = `${baseUrl}transfer/`
const transferCPUrl = `${cpUrl}transfer/`
const contactUrl = `${baseUrl}contact/`
const commissionUrl = `${baseUrl}commission/`
const tradeaccountUrl = `${baseUrl}tradeaccount/`
const TradeRebateVolumeUrl = `${baseUrl}TradeRebateVolume/`
const foldlineUrl = `${baseUrl}foldline/`
const pieUrl = `${baseUrl}pie/`
const depositUrl = `${cpUrl}deposit/`
const accountUrl = `${cpUrl}account/`
const withdrawalUrlCp = `${cpUrl}withdrawal/`
const userProfileUrl = `${baseUrl}userProfile/`
const ibreportUrl = `${baseUrl}ibreport/`
const reportUrl = `${baseUrl}report/`
const rebateUrl = `${baseUrl}rebate/`
const ibaccountUrl = `${baseUrl}ibaccount/`
const menuUrl = `${baseUrl}menu/`
const unfundedaccountUrl = `${baseUrl}unfundedaccount/`
const googleTranslateBasic = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyCKuPDmPYUTdOFUitGsQzH3rLgs58B4_I0`
const notificationUrl = `${baseUrl}notification/enabled`
const systemUrl = `${baseUrl}system/`
const paymentSelectionUrl = `${baseUrl}payment/`
const fileUrl = `${baseUrl}file/`
const identifyProofUrl = `${cpUrl}identityProof/`
const ShortLinkUrl = `https://vigco.co/api/v1/shortlink/generates`
const referralLinks = `${baseUrl}invitecode/`
const mfaUrl = `${baseUrl}mfa/`

/** login */
export const apiTo_login_by_token = headers => instance.post(`${loginUrl}to_login_by_token`, '', headers)
export const apiTo_logout = () => instance.get(`${loginUrl}to_logout`)
export const apiGet_cp_url = () => instance.get(`${loginUrl}get_cp_url`)

/** file */
export const apiDelete_file = data => instance.post(`${fileUrl}delete`, data)

/** identityProof */
export const verificationUrl = `${baseUrl}verification/`
export const apiVerificationIdentityProofs = data => instance.post(`${identifyProofUrl}verification`, data)
export const apiInsertIdentityProofs = data => instance.post(`${identifyProofUrl}insert`, data)
export const apiCheckRecordExists = data => instance.post(`${identifyProofUrl}check_record_exists`, data)
export const apiGet_last_identifiy_proof = id =>
  instance.get(`${identifyProofUrl}get_last_identifiy_proof?documentType=${id}`)
export const apiAttach_new_document = data => instance.post(`${identifyProofUrl}attach_new_document`, data)

/** security */
export const apiGetSecurity = data => instance.get(`${baseUrl}security/getSecuritys`)

/** home */
export const apiSetlanguage = data => instance.post(`${userProfileUrl}setlanguage`, qs.stringify(data))
export const apiQueryRebatesList = () => instance.get(`${homeUrl}queryRebatesList`)
export const apiQueryAvailableBalance = data => instance.post(`${homeUrl}queryAvailableBalance`, qs.stringify(data))
export const apiQueryTotalCommission = data => instance.post(`${homeUrl}queryTotalCommission`, qs.stringify(data))
export const apiGetNewOpenUserTotal = data => instance.post(`${homeUrl}getNewOpenUserTotal`, qs.stringify(data))

/** token */
export const apiRequire_token = () => instance.post(`${depositUrl}require_token`)

/** withdrawal */
export const apiSendVerificationCode = () => instance.post(`${withdrawalUrlCp}sendEmailVerificationCode`)
export const apiSendEmailVerificationCode = data => instance.post(`${mfaUrl}sendEmailVerificationCode`, data)

export const apiLocalDepositor_depositor = data => instance.get(`${depositUrl}localdepositor/depositors`, data)

export const apiApplyWithdrawal = (data, token) =>
  instance.post(`${withdrawalUrl}applyWithdrawal`, qs.stringify(data), { headers: { token: token } })
export const apiCardInformation = data => instance.get(`${withdrawalUrlCp}queryUnionpayCard`, { param: data })
export const apiGetWithdrawalData = data => instance.post(`${withdrawalUrl}getWithdrawalData`, qs.stringify(data))
export const apiQueryBlacklist = () => instance.get(`${withdrawalUrl}queryBlacklist`)
export const apiCancelWithdraw = data =>
  instance.post(`${withdrawalUrl}cancelWithdrawalOrder?withdrawalId=${data.withdrawalId}`)
export const apiWithdrawalQueryRateUSDByTargetCurrency = targetCurrency =>
  instance.get(`${withdrawalUrl}queryRateUSDByTargetCurrency/${targetCurrency}`)
export const apiGetWithdrawalBankCode = data => instance.get(`${withdrawalUrl}queryBankCode`, data)
export const apiGetWithdrawalBankBranchCode = bankCode => instance.get(`${withdrawalUrl}queryAllByBankCode/${bankCode}`)
export const apiBee_documentTypes = (data, token) =>
  instance.get(`${depositUrl}beeteller/documentTypes`, data, { headers: { token: token } })

/** Payment */
export const apiPaymentSelection = data => instance.post(`${paymentSelectionUrl}info/getPaymentInfoList/`, data)

/** transfer */
export const apiToApplyTransferView = data => instance.post(`${transferUrl}toApplyTransferView`, qs.stringify(data))
//获取可以转换的币种
export const apiGetFormCurrencyData = data => instance.get(`${queryCurrencyUrl}?formCurrency=${data}`)
//获取币种之间的汇率
export const apiGetFormCurrencyTo = data =>
  instance.get(`${queryCurrencyTo}?formCurrency=${data.formCurrency}&&toCurrency=${data.toCurrency}`)

export const apiApplyTransfer = (data, token) =>
  instance.post(`${transferUrl}applyTransfer`, qs.stringify(data), { headers: { token: token } })
export const apiRebateTransferBlacklist = () => instance.get(`${transferCPUrl}queryBlacklist`)

/** contact */
export const apiOwnerinfo = () => instance.post(`${contactUrl}ownerinfo`)
export const apiContactinfo = data => instance.post(`${contactUrl}contactinfo`, qs.stringify(data))

/** commission */
export const apiApplyCommission = data => instance.post(`${commissionUrl}applyCommission`, qs.stringify(data))

/** tradeaccount */
export const apiGetNearestOpenAccount = data =>
  instance.post(`${tradeaccountUrl}getNearestOpenAccount`, qs.stringify(data))
export const apiGetPendingAccount = data => instance.post(`${tradeaccountUrl}getPendingAccount`, data)

export const apiCheckEquity = data => instance.get(`${accountUrl}check_equity?mt4Account=${data}`)

/** TradeRebateVolume */
export const apiQueryRebateVolumeList = data =>
  instance.post(`${TradeRebateVolumeUrl}queryRebateVolumeList`, qs.stringify(data))

/** foldline */
export const apiFoldline = (url, data) => instance.post(`${foldlineUrl}${url}`, qs.stringify(data))

/** pie */
export const apiQueryPieTotalVolumeByGoods = data =>
  instance.post(`${pieUrl}queryPieTotalVolumeByGoods`, qs.stringify(data))

/** ibreport */
export const apiQueryIbReportData = data => instance.post(`${ibreportUrl}queryIbReportData`, data)
export const apiRebatetreeData = data => instance.get(`${reportUrl}rebate-tree?rebateAccount=${data}`)

/** report */
export const apiRebate_report = data => instance.post(`${reportUrl}rebate-report`, data)
export const apiTrade_report = data => instance.post(`${reportUrl}commission-detail`, data)
export const apiRebatePayerTradeDetails = data => instance.post(`${reportUrl}rebate-report/all-data`, data)
export const apiPayer_trade_details = data => instance.post(`${reportUrl}rebate-report/payer-trade-details`, data)
export const apiGet_instruments = data =>
  instance.get(`${reportUrl}get-instruments`, {
    params: data,
  })

/** rebate */
export const apiRebate = (url, data) => instance.post(`${rebateUrl}${url}`, qs.stringify(data))

/** ib account */
export const apiIbAccounts = (url, data) => instance.post(`${ibaccountUrl}${url}`, qs.stringify(data))
export const apiIbAccounts_AccNmber_Open = (url, data) => instance.post(`${ibaccountUrl}${url}`, qs.stringify(data))
export const apiIbAccounts_AccNmber_Close = (url, data) => instance.post(`${ibaccountUrl}${url}`, qs.stringify(data))

/** menu */
export const apiLeads = data => instance.post(`${menuUrl}leads`, data)

/** unfunded account */
export const apiGetAccsByUrl = (url, data) => instance.post(`${unfundedaccountUrl}${url}`, qs.stringify(data))

/** all retail account */
export const apiRetailAccs = data => instance.post(`${unfundedaccountUrl}queryRetailAccounts`, qs.stringify(data))
export const apiTransactionHistory = data =>
  instance.post(`${ibaccountUrl}queryDepositWithdrawalHistory`, qs.stringify(data))

export const apiGetOpenList = data => instance.post(`${ibaccountUrl}open_trade_list`, qs.stringify(data))
export const apiGetClosedList = data => instance.post(`${ibaccountUrl}close_trade_list`, qs.stringify(data))

/* referralLinks */
export const apiGetShortLink = data => instanceExternal.post(ShortLinkUrl, data)
export const apiGetInviteCodeByIbCount = ibAccount =>
  instance.get(`${referralLinks}get_invite_code_by_ib_count?ibAccount=${ibAccount}`)
export const apiCustomInviteCode = data => instance.post(`${referralLinks}custom_invitation_code`, data)

/** google translation */
export const apiGetTranslation = data => instanceExternal.post(`${googleTranslateBasic}`, data)

/** notification */
export const apiGetNotification = () => instance.get(`${notificationUrl}`)

/** systemUrl */
export const apiInformation = () => instance.get(`${systemUrl}information`)

/** get prf */
export const apiDownloadCertificationPDF = () => instance.get(`${baseUrl}pdf/downloadCertificationPDF`)
