export default {
  home: {
    RebateAccountNumber: 'Rabatt-Kontonummer',
    totalCommission: 'Gesamtprovision',
    APPLY_FOR_A_REBATE: 'RABATT BEANTRAGEN',
    availableBalance: 'verfügbares Guthaben',
    WITHDRAW_REBATE: 'RABATT AUSZAHLEN',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'RABATT AUF IHR KONTO ÜBERWEISEN',
    TOTAL_VOLUME: 'GESAMTVOLUMEN',
    NET_FUNDING: 'NETTO-FINANZIERUNG',
    DEPOSITS: 'EINZAHLUNGEN',
    WITHDRAW: 'AUSZAHLUNGEN',
    OPEND_ACCOUNT: 'ERÖFFNETES KONTO',
    PERFORMANCE: 'PERFORMANCE',
    MOST_TRADED_INSTRUMENTS: 'MEIST GEHANDELTE INSTRUMENTE',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'KONTEN MIT DER BESTEN PERFORMANCE IN DIESEM MONAT',
    RECENTLY_OPENED_ACCOUNTS: 'KÜRZLICH ERÖFFNETE KONTEN',
    ACCOUNT_MANAGER: 'KONTOMANAGER',
    Account: 'Konto',
    Name: 'Name',
    Volume: 'Volumen',
    Rebate: 'Rabatt',
    Balance: 'Guthaben',
    ContactClient: 'Kundenkontakt',
    applyRebateSuccessTip:
      'Herzlichen Glückwunsch, wir haben Ihren Rabattantrag erhalten. | {amount} wird in Kürze auf das Rabattkonto {account} überwiesen. | Sobald die Bearbeitung abgeschlossen ist, können Sie Ihr Geld abheben oder überweisen.',
    HOVER:
      'Die Rabatte des Vortages werden täglich zwischen 09:00 - 11:00 AEST berechnet und verarbeitet.',
    blacklist:
      'Für dieses Konto können derzeit keine Rabatte angewendet werden. Bitte wenden Sie sich an Ihren Account Manager.',
  },
  liveChat: { header: 'Live-Chat' },
  contactUs: {
    tip: 'Wenn Sie Fragen haben oder weitere Informationen wünschen, kontaktieren Sie uns bitte über eine der unten stehenden Kontaktmöglichkeiten.<br> Wir bemühen uns, alle Anfragen innerhalb von 24 Stunden zu beantworten.',
  },
  fileUploader: {
    supportedFormat: 'Unterstützte Dateitypen: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Unterstützte Dateitypen: {files}',
    maxSize: 'Maximale Dateigröße beim Hochladen: {maxSize}MB',
    maxFileNum: 'Maximale Dateianzahl: {maxFileNum}',
    failedFileExtension: 'Bitte laden Sie den richtigen Dateityp hoch',
  },
  notification: {
    pendingIdentityProof: {
      header: 'KONTOANTRAGSBEARBEITUNG',
      id: 'Ausweis ist',
      poa: 'Adresse ist',
      both: 'Ausweis und Adressnachweis sind',
      content: 'Ihr Nachweis von {doc} wird derzeit bearbeitet und in Kürze fertiggestellt.',
    },
  },
  identityProof: {
    home: {
      header: 'KONTOANTRAG UNVOLLSTÄNDIG',
      content:
        'Hallo {name}, Ihr Kontoantrag ist fast vollständig, aber Sie müssen noch zusätzliche Unterlagen einreichen, um Ihr Konto abzuschließen.',
    },
    popup: {
      header:
        'Hallo {name}, bevor Ihr Live-Konto fertiggestellt werden kann, müssen Sie das folgende Dokument zur Verifizierung hochladen.',
      addressProofSpan:
        '<strong>\n        <b>Adresse bestätigen</b>\n        </strong>\n        <br />\n        <I>\n        Reichen Sie <b>eines</b> der folgenden Dokumente ein\n        <br /><br />\n        </I>\n        - Kopie einer aktuellen Nebenkostenabrechnung </br>(nicht älter als 3 Monate)\n        <b class=or">oder</b><br />\n        - Kopie eines aktuellen Kontoauszugs </br>(nicht älter als 3 Monate)\n        <br />\n        <br />\n        <b>WICHTIG !</b> Der Name auf dem Dokument MUSS deutlich Ihren vollständigen Namen und Ihre aktuelle Adresse zeigen.\n        <br />',
    },
    reasonType: {
      4: 'Unvollständige Dokumente',
      5: 'Ungeklärte Dokumente',
      6: 'Farbige Kopie erforderlich',
      8: 'Kontoeröffnung Name stimmt nicht überein',
      9: 'Adressnachweis Ausstellungsdatum nicht qualifiziert',
    },
    reason:
      'Ihre Unterlagen konnten nicht verifiziert werden,<br> Grund: {reason}. Bitte versuchen Sie es erneut.',
    success:
      'Vielen Dank! Wir haben Ihre Unterlagen erfolgreich erhalten und bearbeiten diese derzeit für die Verifizierung.',
  },
  ibAccounts: {
    ibAccount: 'IB-Konto',
    subIbs: 'IB-Unterkonto',
    tradingList: 'Tradingliste',
    OpeningList: 'Eröffnungsliste',
    closeList: 'Schließliste',
    tradingListColumn: {
      ticket: 'TicketID',
      openTime: 'Eröffnungszeit',
      type: 'Typ',
      volume: 'Volumen',
      item: 'Element',
      openPrice: 'Eröffnungskurs',
      closeTime: 'Schließzeit',
      closePrice: 'Schlusskurs',
      commission: 'Provision',
      profit: 'Gewinn',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Kreditkarte wählen',
      selectBA: 'Bankkonto wählen',
      anotherCC: 'Kreditkarte manuell eingeben',
      anotherBA: 'Bankkonto hinzufügen',
      remember: 'Mein Konto merken',
    },
    transfer: {
      info: '{platform} wird die Bankgebühr für <u>EINE</u> Auszahlung pro Monat zurückerstatten. Für weitere Auszahlungen innerhalb des Monats fällt eine Bankgebühr von 20 Einheiten Ihrer Auszahlungswährung an.',
      label: {
        upload:
          'Bitte laden Sie eine Kopie Ihres Kontoauszugs hoch (aktuell für die letzten 3 Monate).',
      },
      formValidation: { upload: 'Bitte laden Sie einen Kontoauszug hoch.' },
    },
    REBATE_WITHDRAW_REQUEST: 'ANTRAG AUF RABATTAUSZAHLUNG',
    PleaseSelectDesiredWithdrawMethod: 'Bitte wählen Sie die gewünschte Auszahlungssmethode',
    BankName: 'Bank Name',
    bankCode: 'Bankleitzahl',
    bankBranchCode: 'Filialcode',
    bankAccountCurrency: 'Währung des Bankkontos',
    BankAccountsName: 'Bankkonto Name',
    FasaPayAccountName: 'FasaPay Kontoname',
    AustralianBankName: 'Australische Bank Name',
    BankAccountNumber: 'Bankkontonummer',
    FasaPayAccountNumber: 'FasaPay-Kontonummer',
    BankAccountBeneficiary: 'Bankkonto Begünstigter',
    BSB: 'BSB',
    SortCode: 'Bankleitzahl',
    skrillEmail: 'Skrill E-Mail',
    NetellerEmail: 'Neteller-E-Mail',
    PerfectMoneyEmail: 'Perfect Money-E-Mail',
    BitwalletEmail: 'Bitwallet E-Mail',
    SticpayEmail: 'SticPay E-Mail',
    BitcoinEmail: 'Bitcoin-Wallet Adresse',
    USDTEmail: 'Tether-Wallet Adresse',
    BankBranch: 'Bankfiliale',
    Province: 'Provinz',
    City: 'Stadt',
    BankAddress: 'Bank Adresse',
    AccountNumberIBAN: 'Kontonummer/IBAN',
    BankBeneficiaryName: 'Bank Name des Begünstigten',
    BankAccountBeneficiaryName: 'Bankkonto Name des Begünstigten',
    AccountHoldersAddress: 'Adresse des Kontoinhabers',
    SelectWithdrawCard: 'Wählen Sie Ihre Auszahlungskarte',
    Swift: 'Swift',
    SwiftAud: 'Swift (Nicht-AUD-Konten)',
    ABA_SortCodeABA: 'ABA/Sort-Code',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: 'Wichtige Hinweise',
    ifscCode: 'IFSC-Code',
    uploadDocument:
      'Bitte laden Sie eine Kopie Ihres Kontoauszugs hoch (aktuell für die letzten 3 Monate).',
    YourPaymentGreaterThanAvailableBalance: 'Ihre Zahlung ist höher als das verfügbare Guthaben',
    integer: 'Nur die Eingabe von ganzen Zahlen wird unterstützt',
    CannotUseMoreThanTwoDecimalValues:
      'Sie können keine Zahlen und negativen Zahlen mit mehr als zwei Dezimalstellen verwenden',
    YourPaymentIsBeingProcessed: 'Ihr Auszahlungsantrag wurde eingereicht',
    Itcannotbe0: 'Es kann nicht 0 sein',
    noCardWarn:
      'Sie haben keine verfügbare Karte für die Auszahlung, bitte verknüpfen Sie Ihre Karte zuerst in den Auszahlungsdetails',
    amtLarger:
      'Der Mindestauszahlungsbetrag beträgt {currencyType}{minLimit}. Guthaben unter 30 müssen in voller Höhe ausgezahlt werden',
    blackList:
      'Leider können Sie derzeit keine Auszahlungsanfrage stellen. Bitte kontaktieren Sie {supportEmail} für weitere Informationen',
    NetellerChargeAlert:
      'Neteller-Auszahlungen sind auf 500.000 US-Dollar pro Transaktion begrenzt und es fällt eine Gebühr von 2 % (maximal 30 US-Dollar) an.',
    FasaPayChargeAlert: 'FasaPay berechnet für jede Auszahlung eine Transaktionsgebühr von 0,5%.',
    SkrillChargeAlert: 'Skrill erhebt eine Transaktionsgebühr von 1% für jede Auszahlung.',
    cryptoAlert:
      'Bitte beachten Sie, dass wir keine Einzahlungen oder Auszahlungen über BUSDT vornehmen können. Bitte stellen Sie sicher, dass die Adresse und die Kryptowährung mit der von uns akzeptierten Chain und Währung übereinstimmen, sonst könnten Sie die Gelder verlieren.',
    name: 'Name',
    phone: 'Telefonnummer',
    address: 'Adresse',
    email: 'E-Mail',
    status: {
      withdrawalSubmitted: 'Abgesendet',
      withdrawalCanceled: 'Abgebrochen',
      withdrawalAuditing: 'In Bearbeitung',
      withdrawalRejected: 'Abgelehnt',
      withdrawalAccepted: 'In Bearbeitung',
      withdrawalPayFailed: 'Gescheitert',
      withdrawalSuccess: 'Verarbeitet',
      withdrawalFailed: 'Gescheitert',
      withdrawalPending: 'Ausstehend',
      withdrawalPartialFailed: 'Gescheitert',
      transferSubmitted: 'In Bearbeitung',
      transferSuccess: 'Verarbeitet',
      transferRejected: 'Abgelehnt',
      transferFailed: 'Gescheitert',
      transferProcessing: 'In Bearbeitung',
    },
    formErrorMsg: {
      bankName: 'Bankname ist erforderlich',
      bsb: 'BSB ist erforderlich',
      sortCode: 'Sort Code ist erforderlich',
      beneName: 'Bank Name des Begünstigten ist erforderlich',
      bankCodeReq: 'Bankleitzahl ist erforderlich',
      bankBranchCodeReq: 'Filialcode ist erforderlich',
      accNum: 'Bankkontonummer ist erforderlich',
      swift: 'Swift ist erforderlich',
      bankAddress: 'Bankadresse ist erforderlich',
      holderAddress: 'Inhaberadresse ist erforderlich',
      amt: 'Betrag ist erforderlich',
      firstSixDigits: 'Bitte geben Sie die ersten 6 Ziffern ein',
      lastFourDigits: 'Bitte geben Sie die letzten 4 Ziffern ein',
      branchName: 'Filialname ist erforderlich',
      email: 'E-Mail ist erforderlich',
      accName: 'Kontoname ist erforderlich',
      province: 'Bank Provinz ist erforderlich',
      city: 'Bank Stadt ist erforderlich',
      bankCard: 'Bitte wählen Sie eine Karte',
      emailFormat: 'Bitte korrekte E-Mail Adresse eingeben',
      amt0: 'Betrag kann nicht 0 sein',
      ifscCode: 'IFSC-Code ist erforderlich',
      bitcoin: 'Bitcoin-Wallet-Adresse ist erforderlich',
      bitcoinAddressLength: 'Bitcoin-Wallet-Adresse muss zwischen 26 und 42 Zeichen betragen',
      usdt: 'USDT-Wallet-Adresse ist erforderlich',
      dynamicReq: '{fieldname} ist erforderlich',
      uploadDocument: 'Bitte laden Sie einen Kontoauszug hoch.',
    },
    note: "Aufgrund aktueller Änderungen ist unsere Bank möglicherweise nicht in der Lage, internationale Banküberweisungen für Kunden zu bearbeiten, die in einigen Ländern registriert sind.<br/>\n      Wenn Sie eine Auszahlung per internationaler Banküberweisung vornehmen müssen, wenden Sie sich bitte an unseren Kundensupport unter +44 2080 363 883 oder <a href='mailto:{mail}'>{mail}</a>.<br/>\n      Wir entschuldigen uns für die Unannehmlichkeiten.",
    minAmountWarn:
      'Der beantragte Auszahlungsbetrag ist niedriger als der Mindestbetrag für das verwendete Zahlungsportal. Bitte laden Sie Ihren Kontostand auf/über den Mindestbetrag auf, bevor Sie eine Auszahlung beantragen. Weitere Informationen finden Sie <a href="https://{regulatorUrl}/clients/accounts/withdraw/">hier</a>. ',
  },
  rebate: {
    status: { processed: 'Verarbeitet', processing: 'In Bearbeitung', rejected: 'Abgelehnt' },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'RABATT-ÜBERWEISUNGSANTRAG',
    transferAmountIsGreaterThanAvailableBalance:
      'Ihr Überweisungsbetrag ist größer als das verfügbare Guthaben',
    yourRebateTransferHasBeenProcessed: 'Ihre Rabatt-Überweisung wurde eingereicht',
    PleaseEnterTransferInformation: 'Bitte geben Sie genaue Überweisungsinformationen ein',
    tradingAccount: 'Trading-Konto',
    rebateAccount: 'Rabatt-Konto',
    account: 'Konto',
    blackList:
      'Dieses Konto wurde für Transfers zwischen Konten gesperrt. Bitte kontaktieren Sie {supportEmail}',
  },
  transferHistory: {
    payStatus: {
      processing: 'In Bearbeitung',
      paid: 'Bezahlt',
      unpaid: 'Unbezahlt',
      rejected: 'Abgelehnt',
    },
  },
  common: {
    liveChat: { desc: '<li><p>Brauchen Sie Hilfe?</p><span>Live- Chat</span></li>' },
    lang: {
      english: 'Englisch',
      chinese: 'Chinesisch',
      french: 'Französisch',
      thai: 'Thailändisch',
      german: 'Deutsch',
      spanish: 'Spanisch',
      malay: 'Malaiisch',
      viet: 'Vietnamesisch',
      indo: 'Indonesisch',
      arabic: 'Arabisch',
      japanese: 'japanisch',
    },
    button: {
      confirm: 'BESTÄTIGEN',
      cancel: 'ABBRECHEN',
      identityProof: 'ANTRAG AUSFÜLLEN',
      upload: 'HOCHLADEN',
      selectFile: 'Datei auswählen',
      fundnow: 'JETZT EINZAHLEN',
    },
    withdrawChannel: {
      hongkongbanktransfer: 'Lokale Banküberweisung (Hongkong)',
      banktransfer: 'Banküberweisung',
      aus: 'Banküberweisung - Australien',
      int: 'Banküberweisung - International',
      chn: 'Banküberweisung - China',
      uk: 'Banküberweisung - UK',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill/Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      fasapay: 'Fasapay',
      thailandinstantbankwiretransfer: 'Thailand Sofortige Banküberweisung',
      malaysiainstantbankwiretransfer: 'Malaysia Sofortige Banküberweisung',
      banktransferbpaypolipay: 'Banküberweisung/BPay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Kryptowährung-Bitcoin',
      cryptocurrencyusdt: 'Kryptowährung-USDT',
      nigeriainstantbankwiretransfer: 'Nigerianische Sofortüberweisung',
      vietnaminstantbankwiretransfer: 'Vietnamesische Sofortüberweisung',
      indonesiainstantbankwiretransfer: 'Indonesien Sofortüberweisung',
      philippinesinstantbankwiretransfer: 'Philippinische Sofortige Banküberweisung',
      southafricainstantbankwiretransfer: 'Südafrikanische Instant BankÜberweisung',
      indiainstantbanktransfer: 'Indien Sofortige Banküberweisung',
      laosinstantbankwiretransfer: 'Laos Sofortige Banküberweisung',
      ugandabanktransfer: 'Uganda lokale Banküberweisung',
      rwandabanktransfer: 'Ruanda lokale Banküberweisung',
      zambiabanktransfer: 'Sambia lokale Banküberweisung',
      congobanktransfer: 'Kongo lokale Banküberweisung',
      cameroonbanktransfer: 'Kamerun lokale Banküberweisung',
      burundibanktransfer: 'Burundi lokale Banküberweisung',
      kenyabanktransfer: 'Kenia lokale Banküberweisung',
      ghanabanktransfer: 'Ghana lokale Banküberweisung',
      tanzaniabanktransfer: 'Tansania lokale Banküberweisung',
      brazilbanktransfer: 'Brasilianische Sofortige Banküberweisung',
      mexicobanktransfer: 'Mexiko Sofortige Banküberweisung',
      internetbankingmexico: 'Internet-Banking (Mexiko)',
      internetbankingbrazil: 'Internet-Banking (Brasilien)',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      transfer: 'Überweisung auf Konten',
    },
    country: { international: 'International', australia: 'Australien', uk: 'UK' },
    field: { country_region: 'Land/Region', yes: 'Ja', no: 'Nein' },
    keys: {
      SUBMIT: 'ABSENDEN',
      FROM: 'VON',
      UPDATE: 'AKTUALISIEREN',
      STATUS: 'STATUS',
      TO: 'AN',
      DATE: 'DATUM',
      pleaseSelect: 'Bitte auswählen',
      amount: 'Betrag',
      actualAmount: '  Tatsächliche Auszahlungen = ',
      rebateAccount: 'Rabatt-Konto',
      comingSoon: 'demnächst',
      tradingHistory: 'TRADING-HISTORIE',
      noData: 'Keine Daten',
      NAME: 'NAME',
      EMAIL: 'E-MAIL',
      ACCTYPE: 'KONTO-TYP',
      PLATFORM: 'PLATTFORM',
      BASECURRENCY: 'BASISWÄHRUNG',
      DATERANGE: 'DATUMSBEREICH',
      DEST: 'ZIEL',
      ACCNUM: 'KONTONUMMER',
      BALANCE: 'GUTHABEN',
      NONE: 'Keine',
      ALL: 'Alle',
      PROFITLOST: 'Gewinn verloren',
      MARGINLEVEL: 'Margin-Level (%)',
      ACCOUNTEQUITY: 'Kontokapital',
      CREDIT: 'Gutschrift',
    },
    dateRange: {
      today: 'Heute',
      yesterday: 'Gestern',
      lastSevenDays: 'Letzte 7 Tage',
      lastThirtyDays: 'Letzte 30 Tage',
      weekToDate: 'Woche bis heute',
      monthToDate: 'Monat bis heute',
      quarterToDate: 'Quartal bis heute',
      previousWeek: 'Vorherige Woche',
      previousMonth: 'Vorheriger Monat',
      previousQuarter: 'Vorheriges Quartal',
    },
    products: { forex: 'FOREX', commodities: 'ROHSTOFFE', indices: 'INDIZES', crypto: 'CRYPTO' },
  },
  report: {
    rebate: {
      column: {
        name: 'NAME',
        accountNumber: 'KONTONUMMER',
        accountType: 'KONTO-TYP',
        volume: 'Volumen',
        rebate: 'Rabatt',
        totalRebate: 'GESAMTRABATT',
        date: 'DATUM',
        instrument: 'INSTRUMENT',
        notionalvalue: 'NOMINALWERT',
      },
      dropdown: { allInstruments: 'Alle Instrumente', all: 'Alle', instruments: 'Instrumente' },
      others: {
        rebate: 'RABATT',
        instrumentsTraded: 'GEHANDELTE INSTRUMENTE',
        lots: 'Lots',
        totalRebate: 'GESAMTRABATT',
        rebates: 'Rabatte',
      },
    },
  },
  ibReport: { openedAccs: 'ERÖFFNETE KONTEN', funding: 'FINANZIERUNG', account: 'KONTO' },
  lead: { demoAccounts: 'DEMO-KONTEN' },
  pendingAccounts: {
    errorDoc:
      'Die folgenden Antragsteller haben das Online-Antragsformular ausgefüllt, müssen aber noch einen Identifikationsnachweis (ID) erbringen, um ihr Konto abschließen zu können.',
    acceptedDocType: 'Akzeptierte Formen der ID sind:',
    primaryDoc: 'Primäre-ID',
    passport: 'Eine Kopie Ihres gültigen internationalen Reisepasses (Unterschriftenseite)',
    id: 'Eine Kopie Ihres staatlich ausgestellten Personalausweises',
    dl: 'Eine Kopie Ihres gültigen Führerscheins',
    address: 'Sekundäre-ID',
    addressList: [
      'Eine Kopie einer aktuellen Rechnung eines öffentlichen Versorgungsunternehmens (z. B. Gas oder Strom), die Ihren Namen und Ihre Wohnadresse enthält (nicht älter als 3 Monate)',
      'Eine Kopie Ihrer Kreditkartenabrechnung oder Ihres Kontoauszugs (keine Online-Ausdrucke)',
    ],
    tip: 'Bitte fordern Sie Antragsteller auf, eine Kopie EINES der akzeptierten primären und sekundären Ausweisdokumente vorzulegen, um ',
  },
  rebatePaymentHistory: {
    rebateHistory: 'RABATTE',
    withdrawHistory: 'AUSZAHLUNGEN',
    transferHistory: 'ÜBERWEISUNGEN',
  },
  referralLinks: {
    downloadPage: 'Lade App herunter',
    campLang: 'Kampagnen-Sprache',
    cpy: 'KOPIE',
    alreadyCPY: 'KOPIERT!',
    liveAcc: 'Live-Konto',
    demoAcc: 'Demo-Konto',
    homePage: 'Startseite',
    promotion: 'Event-Promotion-Link',
    hintTitle: 'Was ist ein Event-Promotion-Link?',
    hintMessage:
      'Der Event-Promotion-Link ist eine neue Promotion-Methode, die für IB zur Verfügung gestellt wird. Der Link führt die Nutzer zur Seite mit dem Bonus-Event und schließt die Registrierung ab. Die Erfolgsquote bei der Registrierung kann durch das Teilen des Links effektiv verbessert werden.',
    hintShortLink: 'Teilen mit Kurzlink',
    hintShortLinkTitle: 'Was ist ein Kurzlink?',
    hintShortLinkMessage:
      'Der Kurzlink ist eine Regel zur Linkgenerierung, die Ihren geteilten Link besser davor schützt, von anderen geändert zu werden und somit auch Ihre IB-Entwicklungsrechte schützt.',
    customButton: 'anpassen',
    copyButton: 'Kopieren',
    customInviteCodeHeader: 'benutzerdefinierter Einladungscode',
    currentCode: 'aktueller Einladungscode',
    newCode: 'neuer Einladungscode',
    notice:
      'Hinweis: Der Einladungscode hat mindestens 6 Parameter. Es können Buchstaben, Zahlen und Nicht- Sonderzeichen eingegeben werden',
    inviteCodeFormatError:
      'Das Format des Einladungscodes ist falsch, bitte bearbeiten Sie ihn erneut',
    inviteCodeAlreadyExist:
      'Der Einladungscode wurde präventiv von anderen registriert, bitte erneut eingeben',
    pleaseEnter: 'Bitte eingeben',
    inviteCodeHeader: 'Promotion-Einladungscode',
    inviteCode: 'Einladungscode',
  },
  settings: { info: 'INFO', reg: 'REGISTRIERT AUF' },
  unfundedAcc: {
    neverFundedAccs: 'NIE FINANZIERTE KONTEN',
    prevFundedZero: 'ZUVOR GEDECKT (KEIN GUTHABEN)',
    accBalance: 'KONTOSTAND',
  },
  multiib: { level: 'EBENE {level}', noib: 'Es gibt keine Sub-IBs unter diesem Konto.' },
  AWCDialog: { optIn: 'JETZT ANMELDEN', noThanks: 'NEIN DANKE' },
  header: { bckToCP: 'ZURÜCK ZUM KUNDENPORTAL', logout: 'AUSLOGGEN' },
  menu: {
    dashboard: 'DASHBOARD',
    ibReport: 'IB-BERICHT',
    rebateReport: 'RABATT-BERICHT',
    iblevel: 'MULTI-LEVEL-IB',
    ibAccs: 'IB-KONTEN',
    leads: 'LEADS',
    pendingAccs: 'AUSSTEHENDE KONTEN',
    unfundedAccs: 'UNGEDECKTE KONTEN',
    payHistory: 'TRANSAKTIONSHISTORIE',
    ibProfile: 'IB-PROFIL',
    refLinks: 'EMPFEHLUNGSLINKS',
    contactUs: 'KONTAKT',
    switchCP: 'ZUM KUNDENPORTAL WECHSELN',
  },
  footer: {
    riskWarn:
      'Risiko-Warnung: Das Traden mit Forex und CFDs birgt ein erhebliches Risiko und kann zum Verlust Ihres eingesetzten\n        Kapitals führen. Das Traden von Hebelprodukten ist möglicherweise nicht für alle Anleger geeignet. Bevor Sie traden, berücksichtigen Sie bitte\n         ihren Erfahrungsstand und Ihre Anlageziele und lassen Sie sich bei Bedarf von einem unabhängigen Finanzberater beraten.\n        Bitte lesen Sie unsere rechtlichen Dokumente auf unserer Website und stellen Sie sicher, dass Sie die Risiken vollständig verstehen, bevor Sie irgendwelche\n        Trading-Entscheidungen treffen.',
  },
  responseMsg: {
    410: 'Parametervalidierung fehlgeschlagen',
    411: 'Falsches Passwort',
    420: 'Benutzer existiert nicht',
    421: 'Passwortrücksetzung verweigert',
    490: 'Bitte geben Sie keine doppelten Karteninformationen ein',
    500: 'Es ist ein Fehler aufgetreten.',
    501: 'Antrag fehlgeschlagen. Bitte versuchen Sie es später erneut.',
    505: 'Datei-Upload fehlgeschlagen',
    510: 'Falscher SMS-Code',
    511: 'Es konnte kein SMS-Code an Ihr Telefon gesendet werden',
    520: 'Anmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.',
    521: 'Es ist bereits ein Benutzer auf diesem Gerät angemeldet!',
    522: 'Bitte melden Sie sich erneut an',
    523: 'Bitte melden Sie sich erneut an',
    524: 'Leider wurde Ihre E-Mail-Adresse nicht erkannt',
    525: 'Falscher Benutzername oder falsches Passwort',
    526: 'Recaptcha stimmt nicht überein',
    527: 'Benutzer ist kein IB',
    528: 'Benutzer existiert nicht',
    529: 'Falscher Benutzername oder falsches Passwort',
    530: 'Code ungültig',
    531: 'Code ungültig',
    540: 'Ihr Trading-Konto konnte nicht gefunden werden',
    541: 'Kann Rabatt-Konto nicht finden',
    542: 'Sie dürfen kein zusätzliches Konto eröffnen',
    544: 'Sie können kein weiteres Konto beantragen, bis Ihr Identitätsnachweis verifiziert wurde',
    550: 'Falsche Kontonummer',
    551: 'Ihr Guthaben ist nicht ausreichend',
    554: 'Der angeforderte Betrag ist Null oder nicht gültig',
    562: 'Wir konnten Ihre Zahlung nicht verarbeiten, bitte versuchen Sie es erneut. Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte per Live-Chat oder E-Mail {email}',
    564: 'Land stimmt nicht mit dem Zahlungskanal überein',
    565: 'Falsche Bankleitzahl',
    566: 'Zahlungsmethode nicht gefunden',
    567: 'Leider ist diese Zahlungsmethode derzeit nicht verfügbar. Wir bitten um Ihr Verständnis',
    581: 'Sie haben sich erfolgreich für die Promotion angemeldet. Bitte beachten Sie, dass Sie eine Einzahlung vornehmen müssen, um Geld auszahlen zu können.',
    584: 'Sie haben keine anwendbaren Rabatte',
    590: 'Transfer-Out-Konto nicht gefunden',
    591: 'Kann Einbuchungskonto nicht finden',
    593: 'Wir können Ihren Geldtransfer von dem ausgewählten Konto nicht bearbeiten, da Ihr Konto Guthaben enthält.',
    594: 'Geldtransfer von diesem Konto ist nicht erlaubt',
    session_timeout: 'Session-Timeout, Weiterleitung zur Anmeldeseite.',
  },
};
