// 進行同步執行
export default {
  setDomainWebSite(state, value) {
    state.domainWebSite = value
  },
  setDomainWebSiteCn(state, value) {
    state.domainWebSiteCn = value
  },
  setContactEmail(state, value) {
    state.contactEmail = value
  },
  setAccountEmail(state, value) {
    state.accountEmail = value
  },
  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      state[key] = null
    })
  },
}
