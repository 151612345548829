export default {
  home: {
    RebateAccountNumber: '리베이트 계좌번호',
    totalCommission: '커미션 합계',
    APPLY_FOR_A_REBATE: '리베이트 신청',
    availableBalance: '사용 가능한 잔액',
    WITHDRAW_REBATE: '리베이트 출금 ',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: '계정으로 리베이트 이체',
    TOTAL_VOLUME: '총 볼륨',
    NET_FUNDING: '순 입금',
    DEPOSITS: '입금',
    WITHDRAW: '인출',
    OPEND_ACCOUNT: '개설 완료 계좌',
    PERFORMANCE: '실적',
    MOST_TRADED_INSTRUMENTS: '가장 많이 거래된 상품',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: '이달의 우수 실적 계좌',
    RECENTLY_OPENED_ACCOUNTS: '최근 개설 계좌',
    ACCOUNT_MANAGER: '계좌관리자',
    Account: '금액',
    Name: '성명',
    Volume: '볼륨',
    Rebate: '리베이트',
    Balance: '잔액',
    ContactClient: '연락하기',
    applyRebateSuccessTip:
      '귀하의 리베이트 신청이 접수되었습니다.  | {amount} 은 영업일 1일 이내에 리베이트 계좌 {account}로 이체될 예정입니다. | 리베이트 금액이 계좌로 반영 완료 후, 출금 또는 거래 계좌로 이체가 가능합니다. ',
    HOVER:
      'IB 커미션은 일일 정산을 원칙으로 하고 있으며, 전 날의 커미션은 *호주 동부 표준시 기준 매일 09:00 – 11:00 에 정산되어 표기됩니다. ',
    blacklist: '이 계정은 현재 환불을 받을 수 없습니다. 계정 관리자에게 문의하십시오.',
  },
  liveChat: { header: '실시간 채팅' },
  contactUs: {
    tip: '문의사항이 있으실 경우 아래의 방법을 통해 연락하시기 바랍니다. 24시간 내로 고객지원팀이 귀하의 질문에 회신해 드리도록 하겠습니다. ',
  },
  fileUploader: {
    supportedFormat: '지원 파일 형태 : png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: '지원 파일 형태: {files}',
    maxSize: '파일 업로드 크기: {maxSize} MB',
    maxFileNum: '최대 파일업로드 개수:{maxFileNum}',
    failedFileExtension: '올바른 파일 형식을 업로드하세요.',
  },
  notification: {
    pendingIdentityProof: {
      header: '계좌 개설 심사중',
      id: '신분증명',
      poa: '주소증명',
      both: '신분증명 및 주소증명',
      content: '귀하가 제출하신 {doc}서류는 담당부서에서 심사중이며 곧 승인이 완료됩니다.',
    },
  },
  identityProof: {
    home: {
      header: '계좌 개설 미완료',
      content: '안녕하세요. {name}님, 계좌개설이 거의 완료되었습니다. 다음의 자료들을 제출해주십시오.',
    },
    popup: {
      header: '안녕하세요. {name}님, 실계좌 개설을 완료하기 전 아래의 자료들을 업로드 해주십시오.',
      addressProofSpan:
        '<strong>\n      <b>주소확인</b>\n      </strong>\n      <br />\n      <I>\n      다음 서류를<b>제출</b>해 주시길 바랍니다.\n        <br /><br />\n      </I>\n      - 공과금(수도,전기,가스) 고지서 사본 (최근 3개월 이내 본인명의로 발급된 출력본)\n      <br />\n      <b class="or">or</b><br />\n      - 은행 명세서 사본 (최근 3개월 이내 거래 내역 출력본)\n      <br />\n      <br />\n      <b>주의!</b> 파일명을 반드시 영문명으로 변경하여 업로드해 주시기 바랍니다. (예: Gil Dong Hong)\n      <br />',
    },
    reasonType: {
      4: '미완료된 문서',
      5: '통과되지 않은 문서',
      6: '컬러 사본 필요',
      8: '계정 개설 이름 불일치',
      9: '주소 증명 문서 발급 날짜가 적합하지 않음',
    },
    reason: '문서를 인증할 수 없습니다.<br> 이유: {reason}. 다시 시도해주세요.',
    success: '감사합니다. 귀하의 자료는 성공적으로 접수되었으며 현재 담당부서에서 심사중입니다.',
  },
  ibAccounts: {
    ibAccount: 'IB 계좌',
    subIbs: 'SUB IB 계좌',
    tradingList: '트레이딩 목록',
    OpeningList: '개장 리스트',
    closeList: '마감 목록',
    tradingListColumn: {
      ticket: '티켓ID',
      openTime: '오픈 시간',
      type: '유형',
      volume: '총량',
      item: '품목',
      openPrice: '오픈 가격',
      closeTime: '마감 시간',
      closePrice: '마감 가격',
      commission: '수수료',
      profit: '이익',
    },
  },
  withdraw: {
    warning: {
      desc: '다음 사항에 유의하시기 바랍니다:',
      desc1: '최종 사용자는 일반적으로 135~250엔 범위의 은행 송금 수수료가 발생합니다.',
      desc2: '오후 2시 이후 출금 요청은 익일에 지연 또는 처리될 수 있습니다.',
    },
    default: {
      selectCC: '신용카드 선택',
      selectBA: '은행 계좌 선택',
      anotherCC: '신용카드를 수동으로 입력하세요.',
      anotherBA: '은행 계좌 추가',
      remember: '내 계정 기억',
    },
    transfer: {
      info: '{platform}은 매 월 1회 은행 송금 출금 수수료를 무료로 면제합니다. 한 달 안에 추가로 인출할 경우 인출 통화의 20불의 은행 송금 수수료가 발생합니다.',
      label: { upload: '은행 명세서를 업로드해주십시오. (최근 3개월 거래내역 포함)' },
      formValidation: { upload: '이체내역서를 업로드해주십시오.' },
    },
    REBATE_WITHDRAW_REQUEST: '리베이트 출금 신청',
    PleaseSelectDesiredWithdrawMethod: '출금 방식을 선택해주십시오',
    BankName: '수취인 거래은행',
    bankCode: '은행 코드',
    bankAccountCurrency: '은행 계좌 통화',
    bankBranchCode: '지점 코드',
    BankAccountsName: '예금주',
    FasaPayAccountName: 'FasaPay 계정이름',
    AustralianBankName: '호주은행명',
    BankAccountNumber: '은행계좌번호',
    FasaPayAccountNumber: 'FasaPay 계정번호',
    BankAccountBeneficiary: '계좌 수령인',
    BSB: 'BSB',
    SortCode: '식별 코드',
    skrillEmail: 'Skrill 이메일',
    NetellerEmail: 'Neteller 이메일',
    PerfectMoneyEmail: 'Perfect Money 이메일',
    SticpayEmail: 'SticPay 이메일',
    BitwalletEmail: 'Bitwallet 이메일',
    BitcoinEmail: 'Bitcoin 지갑 주소',
    USDTEmail: 'Tether 지갑 주소',
    BankAccountName: '수취인명',
    BankBranch: '은행지점 (3자리 지점 번호)',
    BankAccountType: '은행 계좌 타입',
    Province: '시/도',
    City: '시/군/구',
    BankAddress: '은행주소',
    AccountNumberIBAN: '계좌번호/IBAN',
    BankBeneficiaryName: '수취인명',
    BankAccountBeneficiaryName: '계좌 수령인',
    AccountHoldersAddress: '예금주 주소',
    SelectWithdrawCard: '출금하고자 하는 카드를 선택 하십시오.',
    Swift: 'Swift코드',
    SwiftAud: 'Swift코드 (호주계좌 제외)',
    ABA_SortCodeABA: 'ABA/Sort Code',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: '중요사항',
    ifscCode: 'IFSC 코드',
    uploadDocument: '은행 명세서를 업로드해주십시오. (최근 3개월 거래내역 포함)',
    YourPaymentGreaterThanAvailableBalance: '입력하신 지불 금액이 잔액을 초과합니다',
    integer: '정수 입력만 지원됩니다.',
    CannotUseMoreThanTwoDecimalValues: '소수점 이하 두 자리 숫자와 0이하의 숫자는 입력이 불가합니다',
    YourPaymentIsBeingProcessed: '귀하의 출금신청이 접수되었습니다. ',
    Itcannotbe0: "0'은 입력이 불가합니다.",
    noCardWarn: '출금 가능한 카드가 존재하지 않습니다. 출금 세부정보에 카드 정보를 먼저 등록하시기 바랍니다. ',
    amtLarger: '최소 인출 금액은 {currencyType} {minLimit}입니다. 30 미만 잔액은 전액 인출되어야합니다.',
    blackList: '죄송합니다. 현재 인출 요청을 제출할 수 없습니다. 자세한 내용은 {supportEmail}에 문의하십시오.',
    NetellerChargeAlert: 'Neteller 인출은 거래 당 $ 500,000로 제한되며 2 % 수수료 ($ 30로 제한)가 적용됩니다.',
    FasaPayChargeAlert: 'FasaPay는 모든 출금에 대해 0.5 %의 거래 수수료를 부과합니다.',
    SkrillChargeAlert: 'Skrill은 모든 출금에 대해 1 %의 거래 수수료를 부과합니다.',
    cryptoAlert:
      'BUSDT를 통해 입금 또는 출금할 수 없습니다. 주소와 암호화폐가 허용되는 체인 및 통화와 일치하는지 확인하세요. 그렇지 않으면 자금을 잃을 수 있습니다.',
    name: '성명',
    phone: '연락처',
    address: '상세주소',
    email: '이메일',
    status: {
      withdrawalSubmitted: '제출됨',
      withdrawalCanceled: '취소됨',
      withdrawalAuditing: '처리중',
      withdrawalRejected: '거절',
      withdrawalAccepted: '처리중',
      withdrawalPayFailed: '실패',
      withdrawalSuccess: '처리됨',
      withdrawalFailed: '실패',
      withdrawalPending: '대기중',
      withdrawalPartialFailed: '실패',
      transferSubmitted: '처리중',
      transferSuccess: '처리됨',
      transferRejected: '거절',
      transferFailed: '실패',
      transferProcessing: '처리중',
    },
    formErrorMsg: {
      bankName: '은행명을 입력하십시오.',
      bsb: 'BSB를 입력하십시오.',
      sortCode: 'Sort Code를 입력하십시오.',
      beneName: '계좌예금주를 입력하십시오.',
      bankCodeReq: '은행 코드는 필수 항목입니다.',
      bankBranchCodeReq: '지점 코드는 필수 항목입니다.',
      accNum: '은행계좌번호를 입력하십시오.',
      swift: 'Swift코드',
      bankAddress: '은행주소',
      holderAddress: '예금주 주소',
      amt: '금액을 입력하십시오.',
      firstSixDigits: '앞의 6자리를 입력해주십시오',
      lastFourDigits: '마지막 4자리를 입력해주십시오',
      branchName: '분점명',
      email: '이메일',
      accName: '예금주',
      province: '은행지점명',
      city: '은행주소(City시/구/군)를 입력하십시오.',
      bankCard: '카드를 선택하십시오.',
      emailFormat: '올바른 이메일을 입력해 주십시오.',
      amt0: '0 이상의 금액을 입력하십시오.',
      ifscCode: 'IFSC 코드는 필수 항목입니다',
      bitcoin: 'Bitcoin 지갑 주소를 입력해주십시오',
      bitcoinAddressLength: '비트코인 지갑 주소는 26~42자 사이여야 합니다',
      usdt: 'USDT 지갑 주소를 입력해주십시오',
      dynamicReq: '{fieldname} 은 필수입력 사항입니다.',
      uploadDocument: '이체내역서를 업로드해주십시오.',
    },
    note: "최근 변경 사항으로 인해 일부 국가에서 가입된 고객의 국제 은행 송금을 처리하지 못할 수 있습니다.<br/>\n      국제 은행 송금을 통해 인출해야 하는 경우 +44 2080 363 883 또는 <a href='mailto:{mail}'>{mail}</a>으로 고객 지원에 문의해주세요.<br/>\n      불편을 드려 죄송합니다.",
    minAmountWarn:
      '적용된 출금 금액이 적용된 결제 게이트웨이의 최소 금액보다 적습니다. 출금 요청을 제출하기 전에 계정 잔액을 최소 금액 이상으로 유지해주세요. 자세한 내용은 <a href="https://{regulatorUrl}/clients/accounts/withdraw/">여기에서</a> 확인할 수 있습니다.',
  },
  rebate: { status: { processed: '처리됨', processing: '처리중', rejected: '거절' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: '리베이트 이체 신청',
    transferAmountIsGreaterThanAvailableBalance: '이체금액이 잔액을 초과했습니다',
    yourRebateTransferHasBeenProcessed: '귀하의 리베이트 이체가 접수되었습니다',
    PleaseEnterTransferInformation: '정확한 이체 정보를 입력하십시오',
    tradingAccount: '거래 계좌',
    rebateAccount: '리베이트 계좌',
    account: '금액',
    blackList: '계정 간 이체가 제한된 계정입니다. {supportEmail}로 문의하시기 바랍니다.',
  },
  transferHistory: {
    payStatus: { processing: '처리중', paid: '지불', unpaid: '미지불', rejected: '거절' },
  },
  common: {
    liveChat: { desc: '<li><p>도움이 필요하신가요?</p><span>실시간 채팅</span></li>' },
    lang: {
      english: '영어',
      chinese: '중국어',
      french: '프랑스어',
      thai: '태국어',
      german: '독일어',
      spanish: '스페인어',
      malay: '말레이어',
      viet: '베트남어',
      indo: '인도네시아어',
      arabic: '아랍어',
      japanese: '일본어',
    },
    button: {
      confirm: '확인',
      cancel: '취소',
      identityProof: '지원서작성',
      upload: '업로드',
      selectFile: '찾아보기',
      fundnow: '입금하기',
      iunderstand: '이해합니다',
    },
    withdrawChannel: {
      hongkongbanktransfer: '홍콩 지역 은행 송금',
      banktransfer: '은행송금',
      aus: '호주은행송금',
      int: '국제은행송금',
      chn: '중국은행송금',
      uk: '은행송금영국',
      unionpay: '유니온페이',
      thailandinstantbankwiretransfer: '태국 은행 계좌이체',
      malaysiainstantbankwiretransfer: '말레이시아 은행 즉시이체',
      banktransferbpaypolipay: '은행송금/Bpay/PoliPay',
      cryptocurrencybitcoin: '암호 화폐-Bitcoin',
      cryptocurrencyusdt: '암호 화폐-USDT',
      nigeriainstantbankwiretransfer: '나이지리아 은행계좌이체',
      vietnaminstantbankwiretransfer: '베트남 은행계좌이체',
      indonesiainstantbankwiretransfer: '인도네시아 은행계좌이체',
      philippinesinstantbankwiretransfer: '필리핀 제도 은행계좌이체',
      southafricainstantbankwiretransfer: '남아프리카 은행 즉시이체',
      indiainstantbanktransfer: '인도 은행 즉시이체',
      laosinstantbankwiretransfer: '라오스 즉시 은행 송금',
      ugandabanktransfer: '우간다 국내 은행이체',
      rwandabanktransfer: '르완다 국내 은행 이체',
      zambiabanktransfer: '잠비아 국내 은행 이체',
      congobanktransfer: '콩고 국내 은행 이체',
      cameroonbanktransfer: '카메룬 국내 은행 이체',
      burundibanktransfer: '부룬디 국내 은행 이체',
      kenyabanktransfer: '케냐 국내 은행 이체',
      ghanabanktransfer: '가나 국내 은행 이체',
      tanzaniabanktransfer: '탄자니아 국내 은행 이체',
      brazilbanktransfer: '브라질 은행계좌이체',
      mexicobanktransfer: '멕시코 은행 즉시이체',
      internetbankingmexico: '인터넷뱅킹 (멕시코)',
      internetbankingbrazil: '인터넷뱅킹 (브라질)',
      astropay: 'Astropay',
      transfer: '계정으로 이체',
    },
    country: { international: '국제은행송금', australia: '호주', uk: '영국' },
    field: { country_region: '국가/지역', yes: '예', no: '아니오' },
    keys: {
      SUBMIT: '제출하기',
      FROM: '부터',
      UPDATE: '업데이트',
      TO: '까지',
      DATE: '일시',
      pleaseSelect: '선택하십시오.',
      amount: '금액',
      actualAmount: '  실제 출금 = ',
      rebateAccount: '리베이트 계좌',
      comingSoon: '커밍순',
      tradingHistory: '거래 내역',
      noData: '데이터 없음',
      NAME: '이름',
      EMAIL: '이메일',
      ACCTYPE: '계좌유형',
      PLATFORM: '플랫폼',
      BASECURRENCY: '기준통화',
      DATERANGE: '리베이트 발생 시기',
      STATUS: '처리현황',
      DEST: '목적',
      ACCNUM: '계좌번호',
      BALANCE: '잔액',
      NONE: '없음',
      ALL: '모두',
      PROFITLOST: '이익 손실',
      MARGINLEVEL: '마진율(%)',
      ACCOUNTEQUITY: '계정 자본',
      CREDIT: '크레딧',
      DOWNLOAD: '다운로드',
    },
    dateRange: {
      today: '금일',
      yesterday: '전일',
      lastSevenDays: '7일 이전',
      lastThirtyDays: '30일 이전',
      weekToDate: '이번 주',
      monthToDate: '당월합계',
      quarterToDate: '분기별 합계',
      previousWeek: '지난 주',
      previousMonth: '지난 달',
      previousQuarter: '지난 분기',
    },
    products: {
      forex: '외환',
      commodities: '원자재',
      indices: '지수',
      crypto: '가상화폐',
      bond: '채권',
      bonds: '채권',
      gold: '골드',
      minigold: '미니골드',
      goldfutures: '골드 선물',
      bitcoin: '비트코인',
      silver: '은',
      silverfutures: '은선물',
      oil: '원유',
      oilfutures: '원유 선물',
      index: '주가지수 (인덱스)',
      indexfutures: '주가지수 선물',
      etf: '상장 지수 펀드',
      forexfutures: '외환',
      stock: '외환 선물',
    },
  },
  withdrawalFreeze: {
    header: '출금이 일시적으로 지원되지 않습니다',
    desc: '로그인 비밀번호 변경으로 인해 계좌 자금의 안전을 위해 T+{day}일 동안 출금이 일시적으로 지원되지 않습니다. 이 제한은 {hour} 시간 후에 해제될 예정입니다.',
    desc2:
      '로그인 이메일/전화번호 변경으로 인해 T+{day} 동안 출금이 일시적으로 지원되지 않습니다. 이 제한은 {hour} 시간 후에 해제될 예정입니다.',
  },
  report: {
    rebate: {
      column: {
        name: '이름',
        accountNumber: '계좌번호',
        accountType: '계좌유형',
        volume: '볼륨',
        rebate: '리베이트',
        totalRebate: '리베이트 합계',
        date: '일시',
        instrument: '거래 상품',
        notionalvalue: '명목 가치',
      },
      dropdown: { allInstruments: '모든 거래 상품', all: '모두', instruments: '거래 상품' },
      others: {
        rebate: '리베이트',
        instrumentsTraded: '거래 상품',
        lots: '랏',
        totalRebate: '리베이트 합계',
        rebates: '리베이트',
      },
    },
    btn: { showAll: '모두 표시', hide: '숨기기' }
  },
  ibReport: { openedAccs: '개설 완료 계좌', funding: '입출금 내역', account: '금액' },
  lead: { demoAccounts: '데모계좌' },
  pendingAccounts: {
    errorDoc:
      '다음 신청자들은 온라인으로 개설 신청서를 작성했지만, 최종 단계에서 신분증명 (ID) 자료를 제출하지 않았습니다.',
    acceptedDocType: '본인 인증 자료',
    primaryDoc: '신분증명자료',
    passport: '여권 (서명란 포함)',
    id: '신분증(앞/뒤)',
    dl: '운전면허증(앞/뒤)',
    address: '주소증명자료',
    addressList: [
      '본인 명의로 발급된 가스,수도,전기 고지서 중 택1 (3개월 이내 발급본)',
      '신용카드 명세서 또는 은행 명세서 원본(복사본 불가)',
    ],
    tip: '신분 증명 자료를 다음의 주소로 첨부하시기 바랍니다. ',
  },
  rebatePaymentHistory: {
    rebateHistory: '리베이트 내역',
    withdrawHistory: '출금 내역',
    transferHistory: '이체내역',
  },
  referralLinks: {
    copyLink: '링크 복사',
    downloadPage: '앱 다운로드',
    campLang: '언어',
    cpy: '복사',
    alreadyCPY: '복사됨',
    liveAcc: '실계좌',
    demoAcc: '데모계좌',
    homePage: '홈페이지',
    promotion: '이벤트 홍보 링크',
    hintTitle: '이벤트 홍보 링크란?',
    hintMessage:
      '이벤트 홍보 링크는 IB에 제공되는 새로운 홍보 방식입니다. 이 공유 링크는 사용자를 보너스 이벤트 페이지로 안내하고, 등록을 완료합니다. 사용자 등록 성공율을 이 링크의 공유를 통해 효과적으로 개선할 수 있습니다.',
    hintShortLink: '빨라가기 링크를 통해 공유하기',
    hintShortLinkTitle: '빨라가기 링크란?',
    hintShortLinkMessage:
      '빨리가기 링크는 링크 생성 규칙입니다. 귀하의 공유 링크를 타인이 변경하는 것으로부터 더욱 효과적으로 보호할 수 있으며, 따라서 귀하의 IB 개발 권리를 보호할 수 있습니다.',
    inviteCodeHeader: '프로모션 초대 코드',
    inviteCode: '초대 코드',
    customButton: '사용자 정의',
    copyButton: '복사',
    customInviteCodeHeader: '맞춤 초대 코드',
    currentCode: '현재 초대 코드',
    newCode: '새 초대 코드',
    notice: '참고: 초대 코드에는 최소 6개의 매개변수 가 있습니다. 문자, 숫자, 비특수기호 입력 가능',
    inviteCodeFormatError: '초대 코드의 형식이 잘 못되었습니다. 다시 수 정하세요',
    inviteCodeAlreadyExist: '다른 사람이 먼저 초대 코드를 등록했습니다. 다시 입력하세요.',
    pleaseEnter: '들어 오세요',
  },
  settings: {
    info: '내 정보',
    reg: '등록',
    CustomerInfo: '고객 정보',
    name: '이름',
    email: '이메일',
    phone: '전화 번호',
    address: '주소',
  },
  unfundedAcc: {
    neverFundedAccs: '미예치 계좌',
    prevFundedZero: '예치 계좌 (현재 잔액없음)',
    accBalance: '계좌 잔액',
  },
  multiib: { level: '{level} 단계', noib: '이 계정에는 하위 IB가 없습니다.' },
  AWCDialog: { optIn: '지금 참여하기', noThanks: '고맙지 만 사양 할게' },
  header: { bckToCP: '고객 포털사이트로 되돌아가기', logout: '로그아웃' },
  menu: {
    dashboard: '대시보드',
    ibReport: 'IB 보고서',
    rebateReport: '리베이트 보고서',
    iblevel: '다단계 IB',
    ibAccs: 'IB 계좌',
    leads: '리즈',
    pendingAccs: '대기중 계좌',
    unfundedAccs: '계좌 예치 현황',
    payHistory: '거래내역',
    ibProfile: 'IB 프로필',
    refLinks: '관련 링크',
    contactUs: '연락하기',
    switchCP: '고객 포털사이트로 이동',
  },
  footer: {
    riskWarn:
      '위험고지 : CFD 차액결제거래는 증거금을 동반하는 거래 상품으로 고 위험 투자 방식이며, 높은 위험성을 수반합니다. 해외파생상품거래에서 발생할 수 있는 손실규모는 위탁증거금에 한정되지 않으며, 따라서 예탁한 위탁증거금 전액을 손실로 상실할 수 있을 뿐 아니라 손실 금액이 예탁금을 초과할 수도 있습니다. 모든 매매 손실은 투자자에게 귀속되며, 모든 투자자에게 적합하다고 할 수 없습니다. 투자자는 개인의 경험과 투자 목적, 자금의 출처에 대해 발생할 수 있는 위험성을 숙지하시길 바랍니다. 법률 문서를 필히 참고하시길 바랍니다.',
    riskWarn_fsc:
      '위험 경고: 파생상품은 복잡한 도구이며 레버리지로 인해 빠르게 손실을 입을 위험이 큽니다. 파생상품이 어떻게 작동하는지 이해하고 있는지, 그리고 돈을 잃을 위험을 감수할 여유가 있는지 고려해야 합니다.',
  },
  responseMsg: {
    410: '매개변수 검증에 실패하였습니다',
    411: '비밀번호 불일치',
    420: '사용자가 존재하지 않습니다',
    421: '비밀번호 재설정이 거부되었습니다',
    490: '중복된 카드 정보입니다. ',
    500: '에러가 발생했습니다.',
    501: '실행이 취소되었습니다. 나중에 다시 시도하십시오.',
    505: '파일 업로드에 실패하였습니다',
    510: '잘못된 SMS 코드',
    511: '휴대 전화로 SMS 코드를 보낼 수 없습니다',
    520: '로그인에 실패했습니다. 다시 로그인하시기 바랍니다.',
    521: '사용자가 이미 동일 기기에 로그인했습니다.',
    522: '다시 로그인해주십시오.',
    523: '다시 로그인해주십시오.',
    524: '귀하의 이메일 주소가 유효하지 않습니다. ',
    525: '아이디 또는 비밀번호가 일치하지 않습니다.',
    526: '보안문자가 일치하지 않습니다. 재입력해 하시길 바랍니다.',
    527: '사용자가 IB가 아닙니다',
    528: '사용자가 존재하지 않습니다',
    529: '아이디 또는 비밀번호가 일치하지 않습니다.',
    530: '잘못된 코드',
    531: '잘못된 코드',
    540: '귀하의 거래 계좌를 찾을 수 없습니다.',
    541: '리베이트 계좌를 찾을 수 없습니다.',
    542: '같은 이름으로 계좌를 개설 할 수 없습니다.',
    544: '신분증이 인증될 때까지 추가 계정을 신청할 수 없습니다',
    550: '계좌번호 불일치',
    551: '잔액이 부족합니다.',
    554: '요청하신 금액이 0 또는 무효입니다.',
    564: '국가가 결제 채널과 일치하지 않습니다',
    565: '은행코드 불일치',
    566: '지불 방식을 찾을 수 없습니다.',
    581: '프로모션에 성공적으로 선택했습니다. 자금을 인출하려면 입금해야합니다.',
    584: '요청 가능한 리베이트가 존재하지 않습니다.',
    590: '송금 계좌를 찾을 수 없습니다.',
    591: '귀하의 은행 계좌를 찾을 수 없습니다.',
    593: '귀하의 계정에 크레딧이 포함되어 있기 때문에 선택한 계정에서 자금 이체를 처리할 수 없습니다.',
    594: '이 계정에서 자금 이체는 허용되지 않습니다',
    session_timeout: '장 시간 로그인 하지 않았습니다. 재 로그인 해주십시오.',
  },
}
