// 進行同步執行
import { VERIFICATION_COUNT } from '@/constants/withdraw'
// window.verificationTimer 是加密货币验证码的倒计时定时器
export default {
  setCount(state, value) {
    state.count = value
  },
  clearTimer(state) {
    clearInterval(window.verificationTimer)
    window.verificationTimer = null
  },
  setCodeBtnDisabled(state, value) {
    state.codeBtnDisabled = value
  },
  setInitialState(state) {
    state.count = VERIFICATION_COUNT
    clearInterval(window.verificationTimer)
    window.verificationTimer = null
    state.codeBtnDisabled = false
  },
}
