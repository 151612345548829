import { polyfill } from 'es6-promise'
import Vue from 'vue'
import App from './App'
import router from './router'
import ElementUI from 'element-ui'
import '@/assets/css/element-ui-reset.scss'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
import VueClipboard from 'vue-clipboard2'
import Vue2Filters from 'vue2-filters'
import store from './store'
import '@/util/common'
import '@babel/polyfill'
import directives from '@/util/directives'
import { handleError } from '@/util/handleError'
import '@/util/filter'
import config from './config'
import '@/mixins/global'
import Storage from 'vue-ls'

const options = {
  namespace: 'vuejs__', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
}

Vue.use(Storage, options)

// 国际化
import i18n from '@/lang'

import * as echarts from 'echarts'
import moment from 'moment'

//引入公共css样式
import '@/assets/css/global/global.scss'
import '@/assets/css/utilities/utilities.scss'

Vue.component(CollapseTransition.name, CollapseTransition)

for (let key in directives) {
  Vue.directive(key, directives[key])
}

Vue.use(ElementUI)
Vue.use(Vue2Filters)
Vue.use(VueClipboard)

Vue.prototype.echarts = echarts
Vue.prototype.$moment = moment
Vue.prototype.bus = new Vue()
Vue.prototype.$platform = config
Vue.prototype.$handleError = handleError

Vue.config.productionTip = false

new Vue({
  el: '#app',
  i18n,
  store,
  router,
  components: {
    App,
  },
  template: '<App/>',
})
