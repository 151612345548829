export default {
  home: {
    RebateAccountNumber: 'Ребате дансны дугаар',
    totalCommission: 'Нийт шимтгэл',
    APPLY_FOR_A_REBATE: 'Ребате-д бүртгүүлэх',
    availableBalance: 'Боломжит үлдэгдэл',
    WITHDRAW_REBATE: 'Ребате татан авалт хийх',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'Ребате-аа өөрийн дансруу шилжүүлэх',
    TOTAL_VOLUME: 'Нийт хэмжээ',
    NET_FUNDING: 'Нийт санхүүжилт',
    DEPOSITS: 'Орлого',
    WITHDRAW: 'Татан авалт',
    OPEND_ACCOUNT: 'ДАНС НЭЭВ',
    PERFORMANCE: 'ГҮЙЦЭТГЭЛ',
    MOST_TRADED_INSTRUMENTS: 'Хамгийн их арилжаалагдсан хэрэгслүүд',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'Энэ сарын ТОП ашигтай данснууд',
    RECENTLY_OPENED_ACCOUNTS: 'Ойрын хугацаанд нээгдсэн данснууд',
    ACCOUNT_MANAGER: 'Данс хариуцсан менежер',
    Account: 'Данс',
    Name: 'Нэр',
    Volume: 'Хэмжээ',
    Rebate: 'Ребате',
    Balance: 'Үлдэгдэл',
    ContactClient: 'Үйлчлүүлэгчтэй холбоо барих',
    applyRebateSuccessTip:
      'Баяр хүргэе, бид таны ребате хүсэлтийг хүлээн авлаа. / {amount} удахгүй ребате дансанд {amount} тань шилжинэ. | Боловсруулсны дараа та хөрөнгөө татаж авах эсвэл шилжүүлэх боломжтой.',
    HOVER:
      'Өмнөх өдрийн Ребате-үүд өдөр бүр AEST 09:00 - 11:00 цагийн хооронд тооцож, боловсруулдаг.',
    blacklist:
      'Энэ данс одоогоор буцаан олголт авах боломжгүй байна, данс хариуцсан менежертэйгээ холбогдоно уу.',
  },
  liveChat: { header: 'Шууд чат' },
  contactUs: {
    tip: 'Хэрэв та асуулт асуух эсвэл нэмэлт мэдээлэл авахыг хүсвэл доорхи холбоо барих хаягийн аль нэгээр дамжуулан бидэнтэй холбоо бариарай. <br> Бид бүх асуултанд 24 цагийн дотор хариу өгөхийг хичээх болно.',
  },
  fileUploader: {
    supportedFormat: 'Дэмжигдсэн файлын төрлүүд: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Дэмжигдсэн файлын төрлүүд: {files}',
    maxSize: 'Байршуулах файлын дээд хэмжээ: {maxSize} MB',
    maxFileNum: 'Файлын дээд хэмжээ: {maxFileNum}',
    failedFileExtension: 'Зөв файлын төрлийг байршуулна уу',
  },
  notification: {
    pendingIdentityProof: {
      header: 'ДАНСНЫ БҮРТГЭЛ БОЛОВСРУУЛАХ',
      id: 'ID нь',
      poa: 'Хаяг нь',
      both: 'Үнэмлэх болон хаягын тодорхойлолт ',
      content: 'Таны {doc} нотолгоог одоо боловсруулж байгаа бөгөөд удахгүй эцэслэх болно.',
    },
  },
  identityProof: {
    home: {
      header: 'ДАНСНЫ БҮРТГЭЛ ДУУСААГҮЙ БАЙНА ',
      content:
        'Сайн уу, {name}, таны дансны өргөдөл бараг дууссан байгаа боловч та бүртгэлээ эцэслэхийн тулд нэмэлт баримт бичиг ирүүлэх шаардлагатай хэвээр байна.',
    },
    popup: {
      header:
        'Сайн уу, {name}, таны шууд дансыг эцэслэхээс өмнө баталгаажуулахын тулд дараахь баримт бичгийг байршуулах шаардлагатай.',
      addressProofSpan:
        '<strong>\n      <b> Хаягаа баталгаажуулна уу </b>\n      </strong>\n      <br />\n      <I>\n      Доорх баримт бичгийн <b> нэг </b> -ийг оруулна уу\n      <br /> <br />\n      </I>\n      - Ойрын хугацааны хэрэглээний төлбөрийн хуулбар </br> (3 сараас хэтрэхгүй)\n      <b class = or "> or </b> <br />"\n      - Ойрын хугацааны банкны мэдэгдлийн хуулбар </br> (3 сараас хэтрэхгүй)\n      <b> ЧУХАЛ! </b> Баримт бичиг дээрх нэр нь овог нэр, одоогийн хаягаа тодорхой харуулах ёстой.\n      <br />',
    },
    reasonType: {
      4: 'Бүрэн бус баримт бичиг',
      5: 'Бүртгэгдээгүй баримт бичиг',
      6: 'Өнгөт хуулбар шаардлагатай',
      8: 'Данс нээлтийн нэр тохирохгүй байна',
      9: 'Хаягын нотлох баримтын огноо танигдсангүй',
    },
    reason:
      'Таны баримт бичгийг баталгаажуулах боломжгүй байна, <br> Шалтгаан: {reason}. Дахин оролдоно уу.',
    success:
      'Баярлалаа. Бид таны бичиг баримтыг амжилттай хүлээн авч, баталгаажуулахаар боловсруулж байна.',
  },
  ibAccounts: {
    ibAccount: 'IB Данс',
    subIbs: 'Дэд IB данс',
    tradingList: 'Арилжааны жагсаалт',
    OpeningList: 'Нээлтийн жагсаалт',
    closeList: 'Хаалтын жагсаалт',
    tradingListColumn: {
      ticket: 'Тасалбарын ID',
      openTime: 'Нээх цаг',
      type: 'Төрөл',
      volume: 'Хэмжээ',
      item: 'Зүйл',
      openPrice: 'Нээлтийн үнэ',
      closeTime: 'Хаах цаг',
      closePrice: 'Хаалтын үнэ',
      commission: 'Шимтгэл',
      profit: 'Ашиг',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Кредит картаа сонгох',
      selectBA: 'Банкны дансаа сонгох',
      anotherCC: 'Гар аргаар кредит картаа оруулах',
      anotherBA: 'Банкны данс нэмэх',
      remember: 'Данс санах',
    },
    transfer: {
      info: '{platform} нь сард <u> ONE </u> татан төвлөрүүлсэн тохиолдолд банкны шимтгэлийг нөхөн төлнө. Сарын дотор нэмэлт татан авалт хийхэд таны авах валютын 20 нэгжтэй тэнцэх хэмжээний банкны хураамж шаардагдана.',
      label: { upload: 'Банкны хуулгын хуулбарыг байршуулна уу (сүүлийн 3 сард хүчинтэй)' },
      formValidation: { upload: 'Банкны хуулгыг байршуулна уу' },
    },
    REBATE_WITHDRAW_REQUEST: 'Ребате татан авалт хийх хүсэлт',
    PleaseSelectDesiredWithdrawMethod: 'Шаардлагатай татан авах хүсэлтийн аргаа сонгоно уу.',
    BankName: 'Банкны нэр',
    bankCode: 'Банкны код',
    bankBranchCode: 'Банкны салбар',
    bankAccountCurrency: 'Банкны дансны валютын төрөл',
    BankAccountsName: 'Банкны дансны нэр',
    FasaPayAccountName: 'FasaPay дансны нэр',
    AustralianBankName: 'Австралийн банкны нэр',
    BankAccountNumber: 'Банкны дансны дугаар',
    FasaPayAccountNumber: 'FasaPay дансны дугаар',
    BankAccountBeneficiary: 'Банкны дансны ашиг хүртэгч',
    BSB: 'BSB',
    SortCode: 'Sort код',
    skrillEmail: 'Skrill и-мэйл',
    NetellerEmail: 'Neteller и-мэйл',
    PerfectMoneyEmail: 'Perfect Money и-мэйл',
    BitwalletEmail: 'Bitwallet и-мэйл',
    SticpayEmail: 'SticPay и-мэйл',
    BitcoinEmail: 'Bitcoin түрийвч хаяг',
    USDTEmail: 'Tether түрийвч хаяг',
    BankBranch: 'Банкны салбар',
    Province: 'Аймаг',
    City: 'Хот',
    BankAddress: 'Банкны хаяг',
    AccountNumberIBAN: 'Дансны дугаар / IBAN',
    BankBeneficiaryName: 'Банк хүлээн авагчийн нэр',
    BankAccountBeneficiaryName: 'Банкны дансны хүлээн авагчийн нэр',
    AccountHoldersAddress: 'Данс эзэмшигчийн гэрийн хаяг',
    SelectWithdrawCard: 'Зарлага хийх картаа сонгоно уу',
    Swift: 'Свифт',
    SwiftAud: 'Свифт (AUD бус данс)',
    ABA_SortCodeABA: 'ABA / sort код',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: 'Чухал тэмдэглэл',
    ifscCode: 'IFSC Code',
    uploadDocument: 'Банкны хуулгын хуулбарыг байршуулна уу (сүүлийн 3 сард хүчинтэй)',
    name: 'Нэр',
    phone: 'Утасны дугаар',
    address: 'Хаяг',
    email: 'И-мэйл',
    YourPaymentGreaterThanAvailableBalance: 'Таны төлбөр боломжтой үлдэгдлээс их байна',
    integer: 'Зөвхөн бүхэл тоо оруулах боломжтой',
    CannotUseMoreThanTwoDecimalValues:
      'Та хоёроос дээш аравтын бутархай тоонууд болон сөрөг тоонуудыг ашиглах боломжгүй',
    YourPaymentIsBeingProcessed: 'Таны зарлагын өргөдөл орууллаа',
    Itcannotbe0: '0 байж болохгүй',
    noCardWarn: 'Танд зарлагын карт байхгүй байна. Эхлээд картаа холбоно уу',
    amtLarger:
      'Зарлагын доод хэмжээ нь {currencyType}{minLimit} байна.  30 доллараас доош үлдэгдлийг бүрэн хэмжээгээр авах ёстой',
    blackList:
      'Харамсалтай нь та одоогоор зарлагын хүсэлт гаргах боломжгүй байна.  Дэлгэрэнгүй мэдээллийг {supportEmail} хаягаар авна уу',
    NetellerChargeAlert:
      'Neteller-ээр хийх зарлага нь $500,000 хүртэл байдаг ба 2%-ийн шимтгэлтэйгээс гадна',
    FasaPayChargeAlert: 'FasaPay нь зарлагын гүйлгээ бүрээс 0.5% -ийн төлбөр авдаг.',
    SkrillChargeAlert: 'Skrill татан авалт бүртээ 1% гүйлгээний хураамж авдаг.',
    cryptoAlert:
      'Бид BUSDT -ээр дамжуулан мөнгө байршуулах, буцааж авах боломжгүй гэдгийг анхаарна уу, Cryptocurrency -ийн хаяг, хүлээн зөвшөөрсөн сүлжээ, валюттай тохирч байгаа эсэхийг шалгаарай, эс тэгвэл та сангаа алдаж магадгүй юм.',
    status: {
      withdrawalSubmitted: 'Оруулсан',
      withdrawalCanceled: 'Цуцалсан',
      withdrawalAuditing: 'Боловсруулж байна',
      withdrawalRejected: 'Цуцлагдсан',
      withdrawalAccepted: 'Боловсруулж байна',
      withdrawalPayFailed: 'Амжилтгүй',
      withdrawalSuccess: 'Боловсруулсан',
      withdrawalFailed: 'Амжилтгүй',
      withdrawalPending: 'Хүлээгдэж байна',
      withdrawalPartialFailed: 'Амжилтгүй',
      transferSubmitted: 'Боловсруулж байна',
      transferSuccess: 'Боловсруулсан',
      transferRejected: 'Цуцлагдсан',
      transferFailed: 'Амжилтгүй',
      transferProcessing: 'Боловсруулж байна',
    },
    formErrorMsg: {
      bankName: 'Банкны нэр шаардлагатай',
      bsb: 'BSB шаардлагатай',
      sortCode: 'Sort Code хэрэгтэй',
      beneName: 'Хүлээн авагчийн нэр хэрэгтэй',
      bankCodeReq: 'Бaнкны код шаардлагатай',
      bankBranchCodeReq: 'Банкны салбар шаардлагатай',
      accNum: 'Банкны дансны дугаар шаардлагатай',
      swift: 'Свифт шаардлагатай',
      bankAddress: 'Банкны хаяг шаардлагатай',
      holderAddress: 'Эзэмшигчийн хаяг шаардлагатай',
      amt: 'Хэмжээ шаардлагатай',
      firstSixDigits: 'Эхний 6 орон оруулна уу',
      lastFourDigits: 'Сүүлийн 4 орон оруулна уу',
      branchName: 'Салбарын нэр шаардлагатай',
      email: 'И-мэйл оруулах шаардлагатай',
      accName: 'Дансны нэр шаардлагатай',
      province: 'Банк муж шаардлагатай',
      city: 'Банк хот шаардлагатай',
      bankCard: 'Картаа сонгоно уу',
      emailFormat: 'Зөв и-мэйл хаягаа оруулна уу',
      amt0: 'Дүн 0 байж болохгүй',
      ifscCode: 'IFSC код шаардлагатай',
      bitcoin: 'Bitcoin түрийвч хаяг шаардлагатай',
      bitcoinAddressLength:
        'Биткойнны wallet address заавал 26-42 тоо болон үсэгнээс бүрдсэн байх шаардлагатай',
      usdt: 'USDT түрийвч хаяг шаардлагатай',
      dynamicReq: '{fieldname} шаардлагатай',
      uploadDocument: 'Банкны хуулгыг байршуулна уу',
    },
    note: "Сүүлийн үеийн өөрчлөлтөөс шалтгаалан манай банк Зарим улсад бүртгэлтэй харилцагчдад олон улсын банкны шилжүүлэг хийх боломжгүй болж байна.<br/>\n      Хэрэв та олон улсын банкны шилжүүлгээр дамжуулан татгалзах естой бол манай харилцагчийн дэмжлэгтэй холбоо барина уу+44 2080 363 883 эсвэл at <a href='mailto:{mail}'>{mail}</a>.<br/>\n      Бид тохиромжгүй төлөө уучлал гуйж байна.",
    minAmountWarn:
      'Өргөдөл гаргах төлбөр нь ашигласан төлбөрийн гарцын доод хэмжээнээс бага байна. Төлбөр хийх хүсэлт гаргахаас өмнө дансныхаа үлдэгдлийг хамгийн бага хэмжээнд хүртэл бөглөнө үү. Дэлгэрэнгүй мэдээллийг <a href="https://{regulatorUrl}/clients/accounts/withdraw/">эндээс</a> авах боломжтой.',
  },
  rebate: {
    status: { processed: 'Боловсруулсан', processing: 'Боловсруулж байна', rejected: 'Цуцлагдсан' },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'Ребате гүйлгээний хүсэлт',
    transferAmountIsGreaterThanAvailableBalance: 'Таны шилжүүлгийн дүн нь үлдэгдэлээс их байна',
    yourRebateTransferHasBeenProcessed: 'Таны Ребате гүйлгээг оруулсан',
    PleaseEnterTransferInformation: 'Шилжүүлгийн үнэн зөв мэдээллийг оруулна уу',
    tradingAccount: 'Арилжааны данс',
    rebateAccount: 'Ребате данс',
    account: 'Данс',
    blackList:
      'Энэ аккаунтын данс хооронд гүйлгээ хийх эрх хязгаарлагдсан байна, {supportEmail} руу хандана уу',
  },
  transferHistory: {
    payStatus: {
      processing: 'Боловсруулж байна',
      paid: 'Төлөгдсөн ',
      unpaid: 'Төлөгдөөгүй',
      rejected: 'Цуцлагдсан',
    },
  },
  common: {
    liveChat: { desc: '<li> <p> Тусламж хэрэгтэй байна уу? </p> <span> Шууд чат </span> </li>' },
    lang: {
      english: 'Англи хэл',
      chinese: 'Хятад',
      french: 'Франц',
      thai: 'Тайланд',
      german: 'Герман',
      spanish: 'Испани',
      malay: 'Малай',
      viet: 'Вьетнам',
      indo: 'Индонез',
      arabic: 'Араб хэл',
      japanese: 'Япон',
    },
    button: {
      confirm: 'БАТАЛГААЖУУЛАХ',
      cancel: 'Цуцлах',
      identityProof: 'Хүсэлтээ дуусгах',
      upload: 'Оруулах',
      selectFile: 'Файл сонгох',
      fundnow: 'Санхүүжүүлэх',
      iunderstand: 'Ойлголоо',
    },
    withdrawChannel: {
      hongkongbanktransfer: 'Хон Конгийн дотоодын банк',
      banktransfer: 'Банкны шилжүүлэг',
      aus: 'Банкны шилжүүлэг - Австрали',
      int: 'Банкны шилжүүлэг - Олон улсын',
      chn: 'Банкны шилжүүлэг - Хятад',
      uk: 'Банкны шилжүүлэг - Их Британи',
      skrill: 'Скрилл',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Нетеллер',
      unionpay: 'Холбооны төлбөр',
      fasapay: 'FasaPay',
      thailandinstantbankwiretransfer: 'Тайландын банкны шууд дамжуулалт',
      malaysiainstantbankwiretransfer: 'Малайзын шуурхай банкны шилжүүлэг',
      banktransferbpaypolipay: 'Банкны шилжүүлэг / BPay / PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Криптовалют-Bitcoin',
      cryptocurrencyusdt: 'Криптовалют-USDT',
      nigeriainstantbankwiretransfer: 'Нигерийн шуурхай банкны шилжүүлэг',
      vietnaminstantbankwiretransfer: 'Вьетнамын шуурхай банкны шилжүүлэг',
      indonesiainstantbankwiretransfer: 'Индонезийн шуурхай банкны шилжүүлэг',
      philippinesinstantbankwiretransfer: 'Филиппиний шуурхай банкны шилжүүлэг',
      southafricainstantbankwiretransfer: 'Өмнөд Африкийн шуурхай банкны шилжүүлэг',
      indiainstantbanktransfer: 'Энэтхэгийн шуурхай банкны шилжүүлэг',
      laosinstantbankwiretransfer: 'Лаосийн банкны хурдан шилжүүлэг',
      brazilbanktransfer: 'Бразилийн шуурхай банкны шилжүүлэг',
      mexicobanktransfer: 'Мексик шуурхай банкны шилжүүлэг',
      internetbankingmexico: 'Интернет банк (Мексик)',
      internetbankingbrazil: 'Интернет банк (Бразил)',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      transfer: 'Дансанд шилжүүлэх',
    },
    country: { international: 'Олон улсын', australia: 'Австрали', uk: 'Нэгдсэн Вант Улс' },
    field: { country_region: 'Улс / бүс', yes: 'Тийм', no: 'Үгүй' },
    keys: {
      SUBMIT: 'Оруулах',
      FROM: 'ААС',
      UPDATE: 'ШИНЭЧЛЭХ',
      STATUS: 'БАЙДАЛ',
      TO: 'TO',
      DATE: 'Огноо',
      pleaseSelect: 'Сонгоно уу',
      amount: 'Хэмжээ',
      actualAmount: '  Бодит зарлагын гүйлгээ = ',
      rebateAccount: 'Ребате данс',
      comingSoon: 'тун удахгүй',
      tradingHistory: 'ХУДАЛДААНЫ ТҮҮХ',
      noData: 'Өгөгдөл алга',
      NAME: 'Нэр',
      EMAIL: 'И-мэйл',
      ACCTYPE: 'ДАНСНЫ ТӨРӨЛ',
      PLATFORM: 'Платформ',
      BASECURRENCY: 'Суурь валют',
      DATERANGE: 'ХУГАЦААНЫ ХҮРЭЭ',
      DEST: 'ЗОРИЛГО',
      ACCNUM: 'ДАНСНЫ ДУГААР',
      BALANCE: 'Баланс',
      NONE: 'Аль нь ч биш',
      ALL: 'Бүгд',
      PROFITLOST: 'Алдсан ашиг',
      MARGINLEVEL: 'Маржийн түвшин (%)',
      ACCOUNTEQUITY: 'Дансны хөрөнгө',
      CREDIT: 'Кредит',
    },
    dateRange: {
      today: 'Өнөөдөр',
      yesterday: 'Өчигдөр',
      lastSevenDays: 'Сүүлийн 7 хоног',
      lastThirtyDays: 'Сүүлийн 30 хоног',
      weekToDate: 'Өнөөдрийг хүртэл долоо хоног',
      monthToDate: 'Сараас өдөрлүү',
      quarterToDate: 'Улирал өнөөдрийг хүртэл',
      previousWeek: 'Өмнөх долоо хоног',
      previousMonth: 'Өмнөх сар',
      previousQuarter: 'Өмнөх улирал',
    },
    products: { forex: 'Форекс', commodities: 'ТАВААР', indices: 'ИНДЕКС', crypto: 'CRYPTO' },
  },
  withdrawalFreeze: {
    header: 'Зарлага одоогийн байдлаар дэмжигдээгүй',
    desc: 'Та өөрийн нэвтрэх нууц үгт өөрчлөлт хийсэн байгаа бөгөөд зарлага гаргах хүсэлтийг T+{day} өдрөөр хязгаарлав. {hour}цагт уг хязгаарлалтыг авах таамагтай байна.',
    desc2:
      'Та өөрийн утасны дугаар болон и-мэйл хаягийн өөрчлөлт хийсэн байгаа бөгөөд зарлага гаргах хүсэлтийг T+{day} өдрөөр хязгаарлав. {hour}цагт уг хязгаарлалтыг авах таамагтай байна.',
  },
  report: {
    rebate: {
      column: {
        name: 'Нэр',
        accountNumber: 'ДАНСНЫ ДУГААР',
        accountType: 'ДАНСНЫ ТӨРӨЛ',
        volume: 'Хэмжээ',
        rebate: 'Ребате',
        totalRebate: 'Нийт буцаалт',
        date: 'Огноо',
        instrument: 'Хэрэгсэл',
        notionalvalue: 'НЭРЛЭСЭН ҮНЭ',
      },
      dropdown: { allInstruments: 'Бүх хэрэгслүүд', all: 'Бүгд', instruments: 'Хэрэгсэлүүд ' },
      others: {
        rebate: 'Ребате',
        instrumentsTraded: 'Арилжааны хэрэгсэлүүд ',
        lots: 'Лот',
        totalRebate: 'Нийт буцаалт',
        rebates: 'Хөнгөлөлт',
      },
    },
  },
  ibReport: { openedAccs: 'Нээгдсэн данснууд ', funding: 'САНХҮҮЖИЛТ', account: 'Данс' },
  lead: { demoAccounts: 'ДЕМО ДАНСНУУД ' },
  pendingAccounts: {
    errorDoc:
      'Дараахь өргөдөл гаргагчид онлайн өргөдлийн маягтыг бөглөсөн боловч дансаа эцэслэн тогтоохын тулд таних бичиг баримт (ID) ирүүлээгүй байна.',
    acceptedDocType: 'Хүлээн зөвшөөрөгдсөн үнэмлэхний хэлбэрт дараахь зүйлс орно.',
    primaryDoc: 'Анхан шатны үнэмлэх',
    passport: 'Таны хүчин төгөлдөр олон улсын паспортын хуулбар (гарын үсгийн хуудас)',
    id: 'Танай засгийн газрын олгосон иргэний үнэмлэхний  хуулбар.',
    dl: 'Таны хүчин төгөлдөр жолооны үнэмлэхний хуулбар',
    address: 'Хоёрдогч үнэмлэх',
    addressList: [
      'Ойрын хугацааны ахуйн хэргэлээний төлбөрийн хуулбар. Жишээ нь. Таны нэр, оршин суух хаяг зэргийг багтаасан цахилгааны төлбөр (3 сараас хэтрэхгүй)',
      'Таны зээлийн картын хуулбар эсвэл банкны хуулгын хуулбар (онлайн хэвлэхгүй)',
    ],
    tip: 'Өргөдөл гаргагчдаас хүлээн авсан анхан болон дунд шатны иргэний үнэмлэхний аль нэгийн хувийг өгөхийг дэмжинэ үү',
  },
  rebatePaymentHistory: {
    rebateHistory: 'Хөнгөлөлт',
    withdrawHistory: 'Татан авалтууд',
    transferHistory: 'Гүйлгээ',
  },
  referralLinks: {
    downloadPage: 'APP татаж авах',
    campLang: 'Кампанит хэл',
    cpy: 'Хуулах',
    alreadyCPY: 'Хуулсан ',
    liveAcc: 'Данс ',
    demoAcc: 'Демо данс ',
    homePage: 'Нүүр хуудас ',
    promotion: 'Эвент сурталчилгааны линк',
    hintTitle: 'Эвент сурталчилгааны линк гэж юу вэ?',
    hintMessage:
      'Эвент сурталчилгааны линк нь IB-д зориулж гаргасан шинэ идэвхжүүлэлтийн арга юм. Хуваалцсан линк нь хэрэглэгчдийг бүртгэлээ бүрэн дуусгах хуудас болон бонус эвент хуудас руу аваачих болно. Линкийг хуваалцах нь хэрэглэгчийн бүртгэл амжилттай болох үзүүлэлтийг үр дүнтэйгээр сайжруулдаг.',
    hintShortLink: 'Богиносгосон линк ашиглан хуваалцах',
    hintShortLinkTitle: 'Богиносгосон линк гэж юу вэ?',
    hintShortLinkMessage:
      'Богиносгосон линк гэдэг нь таны хуваалцсан линкийг бусад хүн өөрчлөхөөс хамгаалснаар таны IB хөгжүүлэлтийн эрхийг мөн хамгаалах зорилготой линк юм.',
    customButton: 'тохируулах',
    copyButton: 'хуулбарлах',
    customInviteCodeHeader: 'захиалгат урилгын код',
    currentCode: 'одоогийн урилгын код',
    newCode: 'шинэ урилгын код',
    notice:
      'Жич: Урилгын код нь дор хаяж 6 параметртэй байна. Үсэг, тоо, тусгай бус тэмдэг оруулах боломжтой',
    inviteCodeFormatError: 'Урилгын кодын формат буруу байна, дахин засна уу',
    inviteCodeAlreadyExist: 'Урилгын кодыг бусад хүмүүс урьдчилан бүртгүүлсэн тул дахин оруулна уу',
    pleaseEnter: 'оруулна уу',
    inviteCodeHeader: 'Урамшууллын урилгын код',
    inviteCode: 'Урилгын код',
  },
  settings: { info: 'Мэдээлэл', reg: 'БҮРТГҮҮЛСЭН' },
  unfundedAcc: {
    neverFundedAccs: 'ХЭЗЭЭ Ч САНХҮҮЖҮҮЛЖ БАЙГААГҮЙ ДАНС ',
    prevFundedZero: 'УРЬДЧИЛАН САНХҮҮЖИЛСЭН (0 ҮЛДЭГДЭЛ)',
    accBalance: 'ДАНСНЫ ҮЛДЭГДЭЛ',
  },
  multiib: { level: 'LEVEL {level}', noib: 'Энэ дансанд дэд IB байхгүй байна.' },
  AWCDialog: { optIn: 'ОДОО ОРОЛЦОХ', noThanks: 'ҮГҮЙ ЭЭ БАЯРЛАЛАА' },
  header: { bckToCP: 'ЗАХИАЛАГЧИЙН ПОРТАЛД БУЦАХ', logout: 'ГАРАХ' },
  menu: {
    dashboard: 'Хянах самбар',
    ibReport: 'IB тайлан',
    rebateReport: 'Ребате тайлан',
    iblevel: 'Олон түвшний IB',
    ibAccs: 'IB данснууд',
    leads: 'ТЭРГҮҮЛЭГЧ НАР',
    pendingAccs: 'хҮЛЭЭГДЭЖ БУЙ ДАНСНУУД',
    unfundedAccs: 'САНХҮҮЖҮҮЛЭЭГҮЙ ДАНС',
    payHistory: 'Гүйлгээний түүх',
    ibProfile: 'IB профайл',
    refLinks: 'Урьлагын холбоос',
    contactUs: 'Бидэнтэй холбогдох',
    switchCP: 'ХАРИЛЦАГЧИЙН ПОРТАЛ - руу шилжих',
  },
  footer: {
    riskWarn:
      'Эрсдлийн сэрэмжлүүлэг: Forex болон CFD-ийн арилжаа нь ихээхэн эрсдэл дагуулдаг тул хөрөнгө оруулалтын хөрөнгөө алдахад хүргэж болзошгүй юм. Хөшүүрэгтэй бүтээгдэхүүнийг арилжаалах нь бүх хөрөнгө оруулагчдад тохирохгүй байж магадгүй юм. Арилжаа хийхээс өмнө туршлага, хөрөнгө оруулалтын зорилгоо анхаарч, шаардлагатай бол бие даасан санхүүгийн зөвлөгөө аваарай.\n  Манай вэбсайт дахь хууль эрх зүйн баримт бичгүүдийг уншиж, аливаа арилжааны талаар шийдвэр гаргахаасаа өмнө эрсдлийг бүрэн ойлгосон эсэхээ баталгаажуулна уу.\n  Та өөрийн тевшинд таарах хөрөнгө оруулалтын зөвлөх болон бие даана шийдвэр гаргахад туслах тусламж хэрэгцээтэй тохиолдолд\n  Бидний хуулийн материалуудтай танилцаж, арилжааны рискээ тохируулаж арилжааны шийдвэр гаргах хэрэгтэй ба эдгээр мэдээллүүд нь вебсайт дээр байх болно.',
  },
  responseMsg: {
    410: 'Параметрийн баталгаажуулалт амжилтгүй болсон',
    411: 'нууц үг тохирсонгүй',
    420: 'Хэрэглэгч байхгүй',
    421: 'Нууц үг Дахин тохируулах татгалзсан',
    490: 'Давхардсан картын мэдээллийг ирүүлэхгүй байхыг хүсье',
    500: 'Алдаа гарлаа.',
    501: 'Програм амжилтгүй боллоо. Дараа дахин оролдож үзнэ үү.',
    505: 'Файл оруулах амжилтгүй болсон',
    510: 'SMS код буруу байна',
    511: 'Таны утас руу SMS код илгээх боломжгүй байна',
    520: 'Нэвтэрч чадсангүй. Дахин оролдоно уу.',
    521: 'Хэрэглэгч энэ төхөөрөмж дээр аль хэдийн нэвтэрсэн байна!',
    522: 'Дахин нэвтэрнэ үү',
    523: 'Дахин нэвтэрнэ үү',
    524: 'Харамсалтай нь таны имэйл хаяг танигдсангүй',
    525: 'Хэрэглэгчийн нэр эсвэл нууц үг буруу байна',
    526: 'Recaptcha таарахгүй байна',
    527: 'Хэрэглэгч IB биш юм',
    528: 'Хэрэглэгч байхгүй',
    529: 'Хэрэглэгчийн нэр эсвэл нууц үг буруу байна',
    530: 'Хүчингүй Код',
    531: 'Хүчингүй Код',
    540: 'Таны арилжааны данс олдсонгүй',
    541: 'Ребате дансыг олж чадахгүй байна',
    542: 'Та нэмэлт данс нээлгэх эрхгүй',
    544: 'Та ID таны баталгаа шалгаж хүртэл нэмэлт данс хэрэглэх зөвшөөрөл байхгүй байна',
    550: 'Дансны дугаар буруу байна',
    551: 'Таны үлдэгдэл хангалтгүй байна',
    554: 'Хүссэн дүн нь тэг эсвэл хүчингүй байна',
    562: 'Бид таны төлбөрийг боловсруулж чадсангүй, дахин оролдоно уу. Хэрэв асуудал хэвээр байвал шууд чатаар эсвэл и-мэйл {email} хаягаар холбогдоорой.',
    564: 'Улс төлбөрийн суваг таарахгүй байна',
    565: 'Банкны код буруу байна',
    566: 'Төлбөрийн хэрэгсэл олдсонгүй',
    567: 'Харамсалтай нь энэ төлбөрийн хэрэгсэл одоогоор боломжгүй байна. Тохиромжгүй байдал үүссэнд хүлцэл өчье',
    581: 'Та урамшууллыг амжилттай сонгов. Хөрөнгө татахын тулд заавал мөнгө байршуулах ёстойг анхаарна уу.',
    584: 'Танд холбогдох хөнгөлөлт байхгүй байна',
    590: 'Шилжүүлгийн дансыг олж чадахгүй байна',
    591: 'Шилжүүлгийн дансыг олж чадахгүй байна',
    593: 'Таны дансанд кредит орсон тул сонгосон данснаас таны мөнгө шилжүүлэх ажлыг хийх боломжгүй байна.',
    594: 'Энэ данснаас хөрөнгө шилжүүлэх зөвшөөрөл байхгүй байна',
    session_timeout: 'Улирал дууссан, нэвтрэх хуудас руу чиглүүлж байна.',
  },
};
