<template>
  <div>
    <!-- Kakao Talk -->
    <div
      id="kakao-talk-channel-chat-button"
      data-channel-public-id="_wxdLxnb"
      data-title="consult"
      data-size="small"
      data-color="yellow"
      data-shape="pc"
      data-support-multiple-densities="true"
    ></div>

    <router-view />
  </div>
</template>

<script>
import { createAdroll, createGtmFsc, createGtmFsc2, createMeta } from './util/dynamicScript';
const { customerService } = require('@/constants/customerService');

export default {
  methods: {
    showKakaoTalk() {
      const customerServiceKakaoArray = customerService[this.regulator]?.kakao || [];
      const showKakao = !customerServiceKakaoArray.includes(parseInt(this.countryCODE));

      // show kakao
      if (showKakao) {
        document.querySelector('#kakao-talk-channel-chat-button').style.visibility = 'visible';
        document.querySelector('#kakao-talk-channel-chat-button').style.bottom = '8%';
      }
    }
  },
  mounted() {
    // check for kakao talk countries
    this.showKakaoTalk();

    createAdroll(this.regulator);
    createGtmFsc(this.regulator);
    createGtmFsc2(this.regulator);
    createMeta(this.regulator);
  }
};
</script>
