export default {
  home: {
    RebateAccountNumber: 'Numer konta prowizyjnego',
    totalCommission: 'całkowita prowizja',
    APPLY_FOR_A_REBATE: 'ZŁÓŻ WNIOSEK O PROWIZJĘ',
    availableBalance: 'dostępne saldo',
    WITHDRAW_REBATE: 'WYPŁAĆ PROWIZJĘ',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'PRZELEJ PROWIZJĘ NA SWOJE KONTO',
    TOTAL_VOLUME: 'CAŁKOWITY WOLUMEN',
    NET_FUNDING: 'ZASILENIE NETTO',
    DEPOSITS: 'WPŁATY',
    WITHDRAW: 'WYPŁATY',
    OPEND_ACCOUNT: 'OTWARTE KONTA',
    PERFORMANCE: 'WYNIK',
    MOST_TRADED_INSTRUMENTS: 'NAJCZĘŚCIEJ UŻYWANE W HANDLU INSTRUMENTY',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'KONTA Z NAJLEPSZYMI WYNIKAMI W TYM MIESIĄCU',
    RECENTLY_OPENED_ACCOUNTS: 'OSTATNIO OTWARTE KONTA',
    ACCOUNT_MANAGER: 'MENEDŻER KONTA',
    Account: 'Konto',
    Name: 'Imię i nazwisko',
    Volume: 'Wolumen',
    Rebate: 'Prowizja',
    Balance: 'Saldo',
    ContactClient: 'Kontakt z klientem',
    applyRebateSuccessTip:
      'Gratulujemy, otrzymaliśmy Twoją prośbę o prowizję. | Kwota {amount} wkrótce zostanie przelana na konto prowizyjne {account}. | Po przetworzeniu będziesz mógł wypłacić lub przelać swoje środki.',
    HOVER:
      'Prowizje z poprzedniego dnia są obliczane i przetwarzane codziennie między 9:00 a 11:00 AEST.',
    blacklist:
      'To konto nie może w tej chwili wypłacić prowizji, skontaktuj się z menedżerem konta.',
  },
  rate: {
    rate: 'Kursy wymiany ({oldCurrency}/{newCurrency}): ',
    result: {
      rateError: 'Pobieranie kursu {oldCurrency} do {newCurrency} nie powiodło się.',
      channelError: 'Pobieranie kanału płatności nie powiodło się.',
    },
  },
  liveChat: { header: 'Czat na żywo' },
  contactUs: {
    tip: 'Jeśli masz pytania lub chcesz uzyskać więcej informacji, skontaktuj się z nami za pomocą poniższych danych kontaktowych.<br> Dokładamy wszelkich starań, aby odpowiadać na wszystkie zapytania w ciągu 24 godzin.',
  },
  notification: {
    pendingIdentityProof: {
      header: 'PRZETWARZANIE WNIOSKU O KONTO',
      id: 'ID jest',
      poa: 'Adres jest',
      both: 'Dowód osobisty i potwierdzenie adresu są',
      content:
        'Twoje potwierdzenie {doc} jest obecnie przetwarzane i zostanie wkrótce sfinalizowane.',
    },
  },
  identityProof: {
    home: {
      header: 'NIEKOMPLETNY WNIOSEK O OTWARCIE KONTA',
      content:
        'Cześć, {name}! Twój wniosek o otwarcie konta jest prawie kompletny, ale nadal musisz dostarczyć dodatkowe dokumenty, aby sfinalizować proces otwierania konta.',
    },
    popup: {
      header:
        'Cześć, {name}! Zanim Twoje konto live będzie mogło zostać sfinalizowane, musisz przesłać następujący dokument do weryfikacji.',
      addressProofSpan:
        '<strong>\n <b>Potwierdź adres</b>\n </strong>\n <br />\n <I>\n Prześlij <b>jeden</b> z poniższych dokumentów\n <br /><br />\n </I>\n – Kopia ostatniego rachunku za usługi komunalne </br>(nie starsza niż 3 miesięcy)\n <br />\n <b class=or">lub</b><br />\n – Kopia ostatniego wyciągu z konta bankowego </br> (nie starsza niż 3 miesięcy)\n <br />\n <br />\n <b>WAŻNE !</b> Imię i nazwisko na dokumencie MUSZĄ wyraźnie wskazywać Twoje pełne imię i nazwisko oraz aktualny adres.\n <br />"',
    },
    reasonType: {
      4: 'Niekompletne dokumenty',
      5: 'Niewypełnione dokumenty',
      6: 'Wymagana kolorowa kopia',
      8: 'Niedopasowanie imienia i nazwiska na koncie',
      9: 'Data wydania dowodu adresu nie została zakwalifikowana',
    },
    reason:
      'Twoja dokumentacja nie mogła zostać zweryfikowana,<br> Powód: {reason}. Spróbuj ponownie.',
    success:
      'Dziękujemy. Otrzymaliśmy Twoje dokumenty i obecnie przetwarzamy je w celu weryfikacji.',
  },
  fileUploader: {
    supportedFormat: 'Obsługiwane typy plików: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Maksymalny rozmiar przesłanego pliku: {maxSize} MB',
    maxFileNum: 'Maksymalna liczba plików: {maxFileNum}',
    failedFileExtension: 'Prześlij prawidłowy typ pliku',
  },
  ibAccounts: { ibAccount: 'Konto IB', subIbs: 'Konto podrzędne IB' },
  withdraw: {
    default: {
      selectCC: 'Wybierz kartę kredytową',
      selectBA: 'Wybierz konto bankowe',
      anotherCC: 'Wprowadź ręcznie kartę kredytową',
      anotherBA: 'Dodaj konto bankowe',
      remember: 'Zapamiętaj moje konto',
    },
    transfer: {
      info: '{platform} zwróci opłatę bankową naliczoną za <u>JEDNĄ</u> wypłatę w miesiącu. Dodatkowe wypłaty w ciągu miesiąca pociągną za sobą opłatę bankową w wysokości 20 jednostek Twojej waluty wypłaty.',
      note: 'W celu przetworzenia wniosku o wypłatę prosimy o dostarczenie paszportu. W przypadku kont firmowych prosimy o dostarczenie faktury na adres {email}',
      label: { upload: 'Prosimy o przesłanie kopii wyciągu bankowego (z ostatnich 3 miesięcy)' },
      formValidation: { upload: 'Prosimy o przesłanie wyciągu z konta bankowego' },
    },
    REBATE_WITHDRAW_REQUEST: 'WNIOSEK O WYPŁATĘ PROWIZJI',
    PleaseSelectDesiredWithdrawMethod: 'Wybierz preferowaną metodę wypłaty',
    bankCode: 'Kod banku',
    bankBranchCode: 'Kod oddziału',
    bankAccountCurrency: 'Waluta konta bankowego',
    BankName: 'Nazwa banku',
    BankAccountsName: 'Nazwa konta bankowego',
    FasaPayAccountName: 'Nazwa konta FasaPay',
    AustralianBankName: 'Nazwa banku australijskiego',
    BankAccountNumber: 'Numer konta bankowego',
    FasaPayAccountNumber: 'Numer konta FasaPay',
    BankAccountBeneficiary: 'Banku odbiorcy',
    BSB: 'BSB',
    SortCode: 'Kod Sort',
    skrillEmail: 'E-mail Skrill',
    NetellerEmail: 'E-mail Neteller',
    PerfectMoneyEmail: 'E-mail Perfect Money',
    BitwalletEmail: 'E-mail Bitwallet',
    SticpayEmail: 'E-mail StricPay',
    BitcoinEmail: 'Adres portfela Bitcoin',
    USDTEmail: 'Adres portfela Tether',
    USDTChain: 'Łańcuch',
    BankBranch: 'Oddział banku',
    Province: 'Województwo',
    City: 'Miasto',
    BankAddress: 'Adres banku',
    AccountNumberIBAN: 'Numer konta/IBAN',
    BankBeneficiaryName: 'Nazwa banku odbiorcy',
    BankAccountBeneficiaryName: 'Nazwa konta banku odbiorcy',
    AccountHoldersAddress: 'Adres posiadacza konta',
    SelectWithdrawCard: 'Wybierz swoją kartę do wypłaty',
    Swift: 'Swift',
    SwiftAud: 'Swift (konta inne niż AUD)',
    ABA_SortCodeABA: 'Kod ABA/Sort',
    DocumentType: 'Typ dokumentu',
    DocumentDetails: 'Szczegóły dokumentu',
    PixKeyType: 'Typ klucza PIX',
    PixKey: 'Klucz PIX',
    AccountType: 'Typ konta',
    AccountDetails: 'Szczegóły konta',
    ImportantNotes: 'Ważne uwagi',
    ifscCode: 'Kod IFSC',
    uploadDocument: 'Prosimy o przesłanie kopii wyciągu bankowego (z ostatnich 3 miesięcy)',
    name: 'Imię i nazwisko',
    phone: 'Telefon',
    address: 'Adres',
    email: 'E-mail',
    vpa: 'VPA',
    YourPaymentGreaterThanAvailableBalance: 'Twoja wpłata jest większa niż dostępne saldo',
    integer: 'Obsługiwane są tylko liczby całkowite',
    CannotUseMoreThanTwoDecimalValues:
      'Nie można używać liczb i liczb ujemnych z więcej niż dwoma miejscami po przecinku',
    YourPaymentIsBeingProcessed: 'Twój wniosek o wypłatę został złożony',
    Itcannotbe0: 'Nie może wynosić 0',
    noCardWarn:
      'Nie masz żadnej dostępnej karty, aby dokonać wypłaty, połącz najpierw swoją kartę w Informacjach o wypłacie',
    amtLarger:
      'Minimalna kwota wypłaty wynosi {currencyType} {minLimit}. Salda poniżej 30 muszą być wypłacone w całości',
    blackList:
      'Niestety, nie możesz obecnie złożyć wniosku o wypłatę. Skontaktuj się z {supportEmail}, aby uzyskać więcej informacji',
    NetellerChargeAlert:
      'Wypłaty za pośrednictwem Neteller są ograniczone do 500 000 USD w ramach jednej transakcji i wiążą się z prowizją w wysokości 2% (do 30 USD).',
    FasaPayChargeAlert: 'FasaPay pobiera 0,5% opłaty transakcyjnej za każdą wypłatę.',
    SkrillChargeAlert: 'Skrill pobiera 1% opłaty transakcyjnej za każdą wypłatę.',
    cryptoAlert:
      'Pamiętaj, że nie możemy wpłacać ani wypłacać środków za pośrednictwem BUSDT. Upewnij się, że adres i kryptowaluta odpowiadają łańcuchowi i walucie, którą akceptujemy – w przeciwnym razie możesz stracić środki.',
    usdtNote:
      'Obecna opłata za gaz ERC20 jest znacznie wyższa niż zwykle, w związku z czym może wystąpić duże opóźnienie w przetwarzaniu Twojej transakcji. Alternatywnie możesz wypłacić środki za pośrednictwem TRC20, podając adres odpowiadającego portfela, lub zdecydować się na uiszczenie opłaty za gaz, aby przyspieszyć wypłatę środków ERC20.',
    status: {
      withdrawalSubmitted: 'Przesłano',
      withdrawalCanceled: 'Anulowano',
      withdrawalAuditing: 'Przetwarzanie',
      withdrawalRejected: 'Odrzucono',
      withdrawalAccepted: 'Przetwarzanie',
      withdrawalPayFailed: 'Nie powiodło się',
      withdrawalSuccess: 'Przetworzono',
      withdrawalFailed: 'Nie powiodło się',
      withdrawalPending: 'Oczekuje',
      withdrawalPartialFailed: 'Nie powiodło się',
      transferSubmitted: 'Przetwarzanie',
      transferSuccess: 'Przetworzono',
      transferRejected: 'Odrzucono',
      transferFailed: 'Nie powiodło się',
      transferProcessing: 'Przetwarzanie',
    },
    formErrorMsg: {
      bankCodeReq: 'Kod banku jest wymagany',
      bankBranchCodeReq: 'Oddział banku jest wymagany',
      bankName: 'Nazwa baku jest wymagana',
      bsb: 'BSB jest wymagane',
      sortCode: 'Kod Sort jest wymagany',
      beneName: 'Nazwa banku odbiorcy jest wymagana',
      accNum: 'Numer konta bankowego jest wymagany',
      swift: 'Swift jest wymagany',
      bankAddress: 'Adres banku jest wymagany',
      holderAddress: 'Adres posiadacza jest wymagany',
      amt: 'Kwota jest wymagana',
      firstSixDigits: 'Wprowadź 6 pierwszych cyfr',
      lastFourDigits: 'Wprowadź 4 ostatnie cyfry',
      branchName: 'Nazwa oddziału jest wymagana',
      email: 'Adres e-mail jest wymagany',
      accName: 'Nazwa konta jest wymagana',
      province: 'Województwo banku jest wymagane',
      city: 'Miejscowość banku jest wymagana',
      bankCard: 'Wybierz kartę',
      emailFormat: 'Wprowadź poprawny adres e-mail',
      amt0: 'Kwota nie może być równa 0',
      ifscCode: 'Kod IFSC jest wymagany',
      bitcoin: 'Adres portfela Bitcoin jest wymagany',
      bitcoinFormat: 'Nieprawidłowy format adresu portfela Bitcoin',
      bitcoinAddressLength: 'Adres portfela Bitcoin musi zawierać od 26 do 42 znaków',
      usdt: 'Adres portfela USDT jest wymagany',
      chain: 'Łańcuch jest wymagany',
      walletFormatIncorrect: 'Nieprawidłowy format adresu portfela {type}',
      dynamicReq: 'Pole {fieldname} jest wymagane',
      uploadDocument: 'Prosimy o przesłanie wyciągu z konta bankowego',
    },
    note: "Ze względu na ostatnie zmiany nasz bank może nie być w stanie przetwarzać międzynarodowych przelewów bankowych dla klientów zarejestrowanych w niektórych krajach.<br/>Jeśli musisz wypłacić środki za pomocą międzynarodowych przelewów bankowych, skontaktuj się z naszym Działem Obsługi Klienta pod numerem +44 2080 363 883 lub za pośrednictwem adresu <a href='mailto:{mail}'>{mail}</a>.<br/>Przepraszamy za niedogodności",
    minAmountWarn:
      'Zastosowana kwota wypłaty jest niższa niż minimum dla zastosowanej bramki płatniczej. Przed wysłaniem zlecenia wypłaty prosimy o uzupełnienie stanu konta do/powyżej minimum. Więcej informacji na ten temat znajdziesz <a href= href=https://{regulatorUrl}/clients/accounts/withdraw/">tutaj</a>."',
    negativeBalance:
      'Ponieważ Twoje inne konto(a) – {accounts} – ma obecnie ujemne saldo, nie możemy przyjąć Twojego zlecenia wypłaty. Uzupełnij ujemne saldo lub skontaktuj się z menedżerem konta w celu odpisania salda.',
  },
  rebate: {
    status: { processed: 'Przetworzono', processing: 'Przetwarzanie', rejected: 'Odrzucono' },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'ZLECENIE RZELEWU PROWIZJI',
    transferAmountIsGreaterThanAvailableBalance:
      'Twoja kwota przelewu jest większa niż dostępne saldo',
    yourRebateTransferHasBeenProcessed: 'Twój przelew z prowizją został wysłany',
    PleaseEnterTransferInformation: 'Wprowadź dokładne dane przelewu',
    tradingAccount: 'Konto handlowe',
    rebateAccount: 'Konto prowizyjne',
    account: 'Konto',
    blackList:
      'To konto ma zakaz wykonywania przelewów między kontami, skontaktuj się z {supportEmail}',
  },
  transferHistory: {
    payStatus: {
      processing: 'Przetwarzanie',
      paid: 'Zapłacono',
      unpaid: 'Nieopłacono',
      rejected: 'Odrzucono',
    },
  },
  common: {
    liveChat: { desc: '<li><p>Potrzebujesz pomocy?</p><span>Czat na żywo</span></li>' },
    lang: {
      english: 'angielski',
      chinese: 'chiński',
      french: 'francuski',
      thai: 'tajski',
      german: 'niemiecki',
      spanish: 'hiszpański',
      malay: 'malajski',
      viet: 'wietnamski',
      indo: 'indonezyjski',
      arabic: 'arabski',
    },
    button: {
      confirm: 'POTWIERDŹ',
      cancel: 'ANULUJ',
      identityProof: 'WYPEŁNIJ WNIOSEK',
      upload: 'ZAŁADUJ',
      selectFile: 'Wybierz plik',
      fundnow: 'ZASIL TERAZ',
    },
    withdrawChannel: {
      hongkongbanktransfer: 'Lokalny przelew bankowy (Hongkong)',
      banktransfer: 'Przelew bankowy',
      aus: 'Przelew bankowy (Australia)',
      int: 'Przelew bankowy (międzynarodowy)',
      chn: 'Przelew bankowy (Chiny)',
      uk: 'Przelew bankowy (Wielka Brytania)',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      fasapay: 'FasaPay',
      thailandinstantbankwiretransfer: 'Natychmiastowy przelew bankowy (Tajlandia)',
      malaysiainstantbankwiretransfer: 'Natychmiastowy przelew bankowy (Malezja)',
      banktransferbpaypolipay: 'Przelew bankowy/BPay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Kryptowaluta-Bitcoin',
      cryptocurrencyusdt: 'Kryptowaluta-USDT',
      nigeriainstantbankwiretransfer: 'Natychmiastowy przelew bankowy (Nigeria)',
      vietnaminstantbankwiretransfer: 'Natychmiastowy przelew bankowy (Wietnam)',
      indonesiainstantbankwiretransfer: 'Natychmiastowy przelew bankowy (Indonezja)',
      philippinesinstantbankwiretransfer: 'Natychmiastowy przelew bankowy (Filipiny)',
      southafricainstantbankwiretransfer: 'Natychmiastowy przelew bankowy (RPA)',
      indiainstantbanktransfer: 'Natychmiastowy przelew bankowy (Indie)',
      laosinstantbankwiretransfer: 'Natychmiastowy przelew bankowy (Laos)',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      transfer: 'Przelew na konta',
      dasshpeupi: 'UPI',
      perfectmoney: 'Perfect Money',
      ugandabanktransfer: 'Lokalny przelew bankowy (Uganda)',
      rwandabanktransfer: 'Lokalny przelew bankowy (Rwanda)',
      zambiabanktransfer: 'Lokalny przelew bankowy (Zambia)',
      congobanktransfer: 'Lokalny przelew bankowy (Kongo)',
      cameroonbanktransfer: 'Lokalny przelew bankowy (Kamerun)',
      burundibanktransfer: 'Lokalny przelew bankowy (Burundi)',
      kenyabanktransfer: 'Lokalny przelew bankowy (Kenia)',
      ghanabanktransfer: 'Lokalny przelew bankowy (Ghana)',
      ghanamobilemoney: 'Ghana Mobile Money',
      tanzaniabanktransfer: 'Lokalny przelew bankowy (Tanzania)',
      brazilbanktransfer: 'Natychmiastowy przelew bankowy (Brazylia)',
      mexicobanktransfer: 'Natychmiastowy przelew bankowy (Meksyk)',
      internetbankingmexico: 'Bankowość internetowa (Meksyk)',
      internetbankingbrazil: 'Bankowość internetowa (Brazylia)',
    },
    country: { international: 'Międzynarodowy', australia: 'Australia', uk: 'Wielka Brytania' },
    field: { country_region: 'Kraj/Region', yes: 'Tak', no: 'Nie' },
    keys: {
      SUBMIT: 'PRZEŚLIJ',
      FROM: 'OD',
      UPDATE: 'ZAKTUALIZUJ',
      STATUS: 'STATUS',
      TO: 'DO',
      DATE: 'DATA',
      pleaseSelect: 'Wybierz',
      amount: 'Kwota',
      actualAmount: ' Rzeczywiste wypłaty = ',
      rebateAccount: 'Konto prowizyjne',
      comingSoon: 'już wkrótce',
      tradingHistory: 'HISTORIA TRANSAKCJI',
      noData: 'Brak danych',
      NAME: 'NAZWA',
      EMAIL: 'E-MAIL',
      ACCTYPE: 'TYP KONTA',
      PLATFORM: 'PLATFORMA',
      BASECURRENCY: 'WALUTA BAZOWA',
      DATERANGE: 'ZAKRES DATY',
      DEST: 'ADRES DOCELOWY',
      ACCNUM: 'NUMER KONTA',
      BALANCE: 'SALDO',
      NONE: 'Brak',
      ALL: 'Wszystkie',
    },
    dateRange: {
      today: 'Dzisiaj',
      yesterday: 'Wczoraj',
      lastSevenDays: 'Ostatnie 7 dni',
      lastThirtyDays: 'Ostatnie 30 dni',
      weekToDate: 'Tydzień do daty',
      monthToDate: 'Miesiąc do daty',
      quarterToDate: 'Kwartał do daty',
      previousWeek: 'Poprzedni tydzień',
      previousMonth: 'Poprzedni miesiąc',
      previousQuarter: 'Poprzedni kwartał',
    },
    products: {
      forex: 'FOREX',
      commodities: 'TOWARY',
      indices: 'WSKAŹNIKI',
      crypto: 'KRYPTOWALUTY',
    },
  },
  report: {
    rebate: {
      column: {
        name: 'NAZWA',
        accountNumber: 'NUMER KONTA',
        accountType: 'TYP KONTA',
        volume: 'Wolumen',
        rebate: 'Prowizja',
        totalRebate: 'ŁĄCZNA PROWIZJA',
        date: 'DATA',
        instrument: 'INSTRUMENT',
        notionalvalue: 'WARTOŚĆ NOMINALNA',
      },
      dropdown: {
        allInstruments: 'Wszystkie instrumenty',
        all: 'Wszystkie',
        instruments: 'Instrumenty',
      },
      others: {
        rebate: 'PROWIZJA',
        instrumentsTraded: 'INSTRUMENTY BĘDĄCE W OBROCIE',
        lots: 'Loty',
        totalRebate: 'Łączna prowizja',
        rebates: 'Prowizje',
      },
    },
  },
  ibReport: { openedAccs: 'OTWARTE KONTA', funding: 'ZASILENIE', account: 'KONTO' },
  lead: { demoAccounts: 'KONTO DEMO' },
  pendingAccounts: {
    errorDoc:
      'Poniżsi wnioskodawcy wypełnili formularz zgłoszeniowy online, ale muszą jeszcze dostarczyć dowód tożsamości (ID), aby sfinalizować otwarcie swojego konta.',
    acceptedDocType: 'Akceptowane formy dokumentu tożsamości to:',
    primaryDoc: 'Podstawowy dokument tożsamości',
    passport: 'Kopia ważnego paszportu międzynarodowego (strona z podpisem)',
    id: 'Kopia wydanego przez rząd krajowego dowodu osobistego',
    dl: 'Kopia ważnego prawa jazdy',
    address: 'Drugi dokument tożsamości',
    addressList: [
      'Kopia ostatniego rachunku za usługi komunalne. Np. za gaz lub prąd, który zawiera Twoje nazwisko i miejsce zamieszkania (nie starszy niż 3 miesiące)',
      'Kopia wyciągu z karty kredytowej lub wyciągu bankowego (nie wydruk z bankowości internetowej)',
    ],
    tip: 'Zachęcamy wnioskodawców do dostarczenia kopii JEDNEGO z akceptowanych podstawowych i dodatkowych dokumentów tożsamości na ',
  },
  rebatePaymentHistory: {
    rebateHistory: 'PROWIZJE',
    withdrawHistory: 'WYPŁATY',
    transferHistory: 'PRZELEWY',
    cancelWithdraw: 'Czy chcesz anulować zlecenie wypłaty?',
    cancelError:
      'Twoja wypłata jest w tej chwili przetwarzana, dlatego nie możemy przyjąć Twojego wniosku o anulowanie jej. Aby uzyskać więcej informacji, możesz skontaktować się z nami, korzystając z funkcji czatu na żywo.',
  },
  referralLinks: {
    downloadPage: 'Pobierz aplikację',
    campLang: 'Język kampanii',
    cpy: 'KOPIUJ',
    alreadyCPY: 'SKOPIOWANO!',
    liveAcc: 'Konto live',
    demoAcc: 'Konto demo',
    homePage: 'Strona główna',
    promotion: 'Link promujący wydarzenie',
    hintTitle: 'Czym jest link promocyjny wydarzenie?',
    hintMessage:
      'Link promujący wydarzenie to nowa metoda promocji udostępniona przez IB. Link udostępniający prowadzi użytkowników na stronę wydarzenia bonusowego i kończy rejestrację. Dzięki udostępnieniu linku można skutecznie zwiększyć liczbę rejestracji użytkowników.',
    hintShortLink: 'Udostępnianie za pomocą krótkiego linku',
    hintShortLinkTitle: 'Czym jest krótki link?',
    hintShortLinkMessage:
      'Krótki link to specjalnie wygenerowany link, który może lepiej zabezpieczyć Twój udostępniony link przed zmianą przez innych, a tym samym chronić twoje prawa do rozwoju IB',
    customButton: 'dostosuj',
    copyButton: 'Kopiuj',
    customInviteCodeHeader: 'niestandardowy kod zaproszenia',
    currentCode: 'aktualny kod zaproszenia',
    newCode: 'nowy kod zaproszenia',
    notice:
      'Uwaga: kod zaproszenia ma co najmniej 6 parametrów. Można wprowadzać litery, cyfry, symbole inne niż specjalne',
    inviteCodeFormatError: 'Format kodu zaproszenia jest nieprawidłowy, edytuj go ponownie',
    inviteCodeAlreadyExist:
      'Kod zaproszenia został prewencyjnie zarejestrowany przez innych, wprowadź ponownie',
    pleaseEnter: 'Podaj',
    inviteCodeHeader: 'Kod zaproszenia promocyjnego',
    inviteCode: 'Kod zaproszenia',
  },
  settings: { info: 'INFORMACJE', reg: 'REJESTRACJA' },
  unfundedAcc: {
    neverFundedAccs: 'NIGDY NIEZASILANE KONTA',
    prevFundedZero: 'WCZEŚNIEJ ZASILANE (ZEROWE SALDO)',
    accBalance: 'SALDO KONTA',
  },
  multiib: { level: 'POZIOM {level}', noib: 'To konto nie ma żadnych podrzędnych IB' },
  AWCDialog: { optIn: 'ZAPISZ SIĘ TERAZ', noThanks: 'NIE, DZIĘKI' },
  header: { bckToCP: 'WRÓĆ DO PORTALU KLIENTA', logout: 'WYLOGUJ SIĘ' },
  menu: {
    dashboard: 'PANEL',
    ibReport: 'RAPORT IB',
    rebateReport: 'RAPORT DOTYCZĄCY PROWIZJI',
    iblevel: 'WIELOPOZIOMOWY IB',
    ibAccs: 'KONTA IB',
    leads: 'LEADY',
    pendingAccs: 'KONTA OCZEKUJĄCE',
    unfundedAccs: 'KONTA NIEZASILONE',
    payHistory: 'HISTORIA TRANSAKCJI',
    ibProfile: 'PROFIL IB',
    refLinks: 'LINKI POLECAJĄCE',
    contactUs: 'SKONTAKTUJ SIĘ Z NAMI',
    switchCP: 'PRZEJDŹ NA PORTAL KLIENTA',
  },
  footer: {
    riskWarn:
      'Ostrzeżenie o ryzyku: Handel na rynku Forex i CFD wiąże się ze znacznym ryzykiem i może spowodować utratę zainwestowanego kapitału. Handel produktami z dźwignią finansową może nie być odpowiedni dla wszystkich inwestorów. Przed dokonaniem transakcji weź pod uwagę swój poziom doświadczenia, cele inwestycyjne i w razie potrzeby zasięgnij niezależnej porady finansowej.',
  },
  responseMsg: {
    410: 'Walidacja parametrów nie powiodła się',
    411: 'Nieprawidłowe hasło',
    420: 'Użytkownik nie istnieje',
    421: 'Odmowa zresetowania hasła',
    490: 'Nie przesyłaj powielonych danych karty',
    500: 'Wystąpił błąd.',
    501: 'Aplikowanie nie powiodło się. Spróbuj ponownie później.',
    505: 'Przesyłanie plików nie powiodło się',
    510: 'Nieprawidłowy kod SMS',
    511: 'Nie można wysłać kodu SMS na Twój telefon',
    520: 'Logowanie nie powiodło się. Spróbuj ponownie.',
    521: 'Użytkownik jest już zalogowany na tym urządzeniu!',
    522: 'Zaloguj się ponownie',
    523: 'Zaloguj się ponownie',
    524: 'Niestety, Twój adres e-mail nie został rozpoznany',
    525: 'Nieprawidłowa nazwa użytkownika lub hasło',
    526: 'Recaptcha nie pasuje',
    527: 'Użytkownik nie jest IB',
    528: 'Użytkownik nie istnieje',
    529: 'Nieprawidłowa nazwa użytkownika lub hasło',
    530: 'Niepoprawny kod',
    531: 'Niepoprawny kod',
    540: 'Nie można znaleźć Twojego konta handlowego',
    541: 'Nie można znaleźć konta prowizyjnego',
    542: 'Nie możesz otworzyć dodatkowego konta',
    544: 'Nie możesz złożyć wniosku o otwarcie dodatkowego konta do czasu weryfikacji Twojego dowodu tożsamości',
    550: 'Nieprawidłowy numer konta',
    551: 'Twoje saldo jest niewystarczające',
    554: 'Żądana kwota jest zerowa lub nieważna',
    562: 'Nie udało nam się przetworzyć Twojej płatności, spróbuj ponownie. Jeśli problem nadal będzie występować, skontaktuj się z nami przez czat na żywo lub e-mail {email}',
    564: 'Kraj nie pasuje do kanału płatności',
    565: 'Nieprawidłowy kod banku',
    566: 'Nie znaleziono metody płatności',
    567: 'Niestety, ta metoda płatności jest obecnie niedostępna. Przepraszamy za wszelkie niedogodności',
    581: 'Bierzesz teraz udział w promocji. Pamiętaj, że aby wypłacić środki, musisz dokonać wpłaty.',
    584: 'Nie masz żadnych dostępnych prowizji',
    590: 'Nie można znaleźć konta do przelewów wychodzących',
    591: 'Nie można znaleźć konta do przelewów przychodzących',
    593: 'Nie możemy wykonać przelewu środków z wybranego konta, ponieważ na Twoim koncie znajduje się kredyt.',
    594: 'Przelew środków z tego konta nie jest dozwolony',
    session_timeout: 'Przekroczony czasu sesji, trwa przekierowywanie na stronę logowania.',
  },
};
