export default {
  home: {
    RebateAccountNumber: 'Rekeningnummer korting',
    totalCommission: 'totale commissie',
    APPLY_FOR_A_REBATE: 'EEN KORTING AANVRAGEN',
    availableBalance: 'beschikbaar saldo',
    WITHDRAW_REBATE: 'OPNAMEKORTING',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'KORTING OVERSCHRIJVEN NAAR UW REKENING',
    TOTAL_VOLUME: 'TOTAAL VOLUME',
    NET_FUNDING: 'NETTO STORTING',
    DEPOSITS: 'STORTINGEN',
    WITHDRAW: 'OPNEMEN',
    OPEND_ACCOUNT: 'GEOPENDE REKENING',
    PERFORMANCE: 'PRESTATIES',
    MOST_TRADED_INSTRUMENTS: 'MEEST VERHANDELDE INSTRUMENTEN',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'BEST PRESTERENDE REKENINGEN VOOR DEZE MAAND',
    RECENTLY_OPENED_ACCOUNTS: 'ONLANGS GEOPENDE REKENINGEN',
    ACCOUNT_MANAGER: 'ACCOUNT MANAGER',
    Account: 'Rekening',
    Name: 'Naam',
    Volume: 'Volume',
    Rebate: 'Korting',
    Balance: 'Saldo',
    ContactClient: 'Contact opnemen met klant',
    applyRebateSuccessTip:
      'Gefeliciteerd, we hebben uw rebate-anvraag ontvangen. | {amount} wordt binnenkort naar de kortingsrekening {account}. | Eenmaal verwerkt, kunt u uw geld opnemen of overbrengen.',
    HOVER: 'De Rebates van de vorige dag worden berekend en verwerkt tussen 09:00 - 11:00 per dag.',
    blacklist:
      'Het is momenteel niet mogelijk om op deze rekening een kickback fee te ontvangen. Neem contact op met uw accountmanager.',
  },
  liveChat: { header: 'Live Chat' },
  contactUs: {
    tip: 'Als u vragen hebt of meer informatie wilt, kunt u contact met ons opnemen via een van de onderstaande contactgegevens.<br> Wij streven ernaar alle vragen binnen 24 uur te beantwoorden.',
  },
  notification: {
    pendingIdentityProof: {
      header: 'VERWERKING REKENINGAANVRAAG',
      id: 'ID is',
      poa: 'Adres is',
      both: 'ID en bewijs van adres zijn',
      content:
        'Uw bewijs van {doc} wordt momenteel verwerkt en zal binnenkort worden voltooid. Om de verwerking van uw live rekening te versnellen, kunt u nu geld storten op uw rekening en prioriteit krijgen bij het openen.',
    },
  },
  identityProof: {
    home: {
      header: 'REKENINGAANVRAAG ONVOLLEDIG',
      content:
        'Hallo {name}, uw rekeningaanvraag is bijna voltooid, maar u moet nog steeds aanvullende documentatie indienen om uw rekening te voltooien.',
    },
    popup: {
      header:
        'Hallo {name}, voordat uw live rekening kan worden afgerond, moet u het volgende document uploaden ter verificatie.',
      addressProofSpan:
        '<strong><b>Bevestig adres</b></strong><br /><I> <b>Een</b> van de onderstaande<br /><br />documenten indienen</I> - Kopie van een recente energierekening </br>(niet ouder dan 3 maanden)<br /><b class=or">of</b><br />- Kopie van een recent bankafschrift </br> (niet ouder dan 3 maanden)<br /><br /><b>BELANGRIJK!</b> De naam op het document MOET duidelijk uw volledige naam en huidige adres vermelden.<br />"',
    },
    reasonType: {
      4: 'Onvolledige documenten',
      5: 'Niet-goedgekeurde documenten',
      6: 'Kleurrijke kopie vereist',
      8: 'Naam te openen rekening komt niet overeen',
      9: 'Uitgifte adresbewijs niet goedgekeurd',
    },
    reason:
      'Uw documentatie kon niet worden geverifieerd,<br> reden: {reason}. Probeer het opnieuw.',
    success:
      'Dank u. We hebben uw documenten ontvangen en verwerken ze momenteel voor verificatie.',
  },
  fileUploader: {
    supportedFormat: 'Ondersteunde bestandstypen: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Maximale bestandsgrootte voor uploaden: {maxSize}MB',
    maxFileNum: 'Maximaal bestandsnummer: {maxFileNum}',
    failedFileExtension: 'Upload het juiste bestandstype',
  },
  ibAccounts: {
    ibAccount: 'IB-rekening',
    subIbs: 'Sub IB-rekening',
    tradingList: 'Lista transakcji',
    OpeningList: 'Lista otwartych pozycji',
    closeList: 'Lista zamkniętych transakcji',
    tradingListColumn: {
      ticket: 'ID zgłoszenia',
      openTime: 'Czas otwarcia',
      type: 'Typ',
      volume: 'Wolumen',
      item: 'Pozycja',
      openPrice: 'Cena otwarcia',
      closeTime: 'Czas zamknięcia',
      closePrice: 'Cena zamknięcia',
      commission: 'Prowizja',
      profit: 'Zysk',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Creditcard selecteren',
      selectBA: 'Bankrekening selecteren',
      anotherCC: 'Creditcard handmatig invoeren',
      anotherBA: 'Bankrekening toevoegen',
      remember: 'Mijn rekening onthouden',
    },
    transfer: {
      info: '{platform} vergoedt de bankkosten die in rekening worden gebracht voor <u>ÉÉN</u> opname per maand. Extra opnames binnen de maand brengen bankkosten met zich mee van 20 eenheden van uw opnamevaluta.',
      note: 'Om uw opnameverzoek te verwerken, geef dan uw paspoort op, voor bedrijfsaccounts, geef factuur op {email}',
      label: {
        upload: 'Upload een kopie van uw bankafschrift (huidig tot de afgelopen 3 maanden)',
      },
      formValidation: { upload: 'Upload een bankafschrift' },
    },
    REBATE_WITHDRAW_REQUEST: 'VERZOEK TOT OPNAME KORTING',
    PleaseSelectDesiredWithdrawMethod: 'Selecteer de gewenste opnamemethode',
    bankCode: 'Bankcode',
    bankBranchCode: 'Filiaalcode',
    bankAccountCurrency: 'Bankrekening Valuta',
    BankName: 'Banknaam',
    BankAccountsName: 'Naam bankrekening',
    FasaPayAccountName: 'FasaPay-rekeningnaam',
    AustralianBankName: 'Naam Australische bank',
    BankAccountNumber: 'Bankrekeningnummer',
    FasaPayAccountNumber: 'FasaPay-rekeningnummer',
    BankAccountBeneficiary: 'Begunstigde bankrekening',
    BSB: 'BSB',
    skrillEmail: 'E-mail van Skrill',
    NetellerEmail: 'E-mailadres van Neteller',
    BitwalletEmail: 'Bitwallet e-mail',
    SticpayEmail: 'E-mailadres SticPay',
    BitcoinEmail: 'Bitcoin Wallet-adres',
    USDTEmail: 'Tether Wallet-adres',
    USDTChain: 'Keten',
    BankBranch: 'Bankfiliaal',
    Province: 'Provincie',
    City: 'Plaats',
    BankAddress: 'Bankadres',
    AccountNumberIBAN: 'Bankrekeningnummer/IBAN',
    BankBeneficiaryName: 'Naam bankbegunstigde',
    BankAccountBeneficiaryName: 'Naam begunstigde bankrekening',
    AccountHoldersAddress: 'Adres rekeninghouder',
    SelectWithdrawCard: 'Selecteer uw opnamekaart',
    Swift: 'Swift',
    SwiftAud: 'SWIFT (niet-AUD-rekeningen)',
    ABA_SortCodeABA: 'ABA/Sort-code',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: 'Belangrijke opmerkingen',
    ifscCode: 'IFSC-code',
    name: 'Naam',
    phone: 'Telefoonnummer',
    address: 'Adres',
    email: 'E-mailadres',
    vpa: 'VPA',
    YourPaymentGreaterThanAvailableBalance: 'Uw betaling is hoger dan het beschikbare saldo',
    integer: 'Alleen de invoer van hele getallen wordt ondersteund',
    CannotUseMoreThanTwoDecimalValues:
      'U kunt geen getallen en negatieve getallen met meer dan twee decimalen gebruiken',
    YourPaymentIsBeingProcessed: 'Uw aanvraag tot opname is verstuurd',
    Itcannotbe0: 'Het kan niet 0 zijn',
    noCardWarn: 'U hebt geen beschikbare kaart om intrekking te maken, koppelt u eerst uw kaart in het eerst opnemen.',
    amtLarger:
      'Het minimale intrekkingbedrag is {currencyType} {minLimit}. Saldi onder 30 moeten volledig worden ingetrokken',
    blackList:
      'Helaas kunt u momenteel geen verzoek tot opname indienen. Neem contact op met {supportEmail} voor meer informatie',
    NetellerChargeAlert:
      'Neteller-opnames zijn beperkt tot $ 500.000 per transactie en er geldt een toeslag van 2% (maximaal $ 30).',
    FasaPayChargeAlert: 'FasaPay brengt 0,5% transactiekosten in rekening voor elke opname.',
    SkrillChargeAlert: 'Skrill brengt 1% transactiekosten in rekening voor elke opname.',
    cryptoAlert:
      'Houd er rekening mee dat we niet kunnen storten of opnemen via BUSDT, zorg ervoor dat het adres en de cryptovaluta overeenkomen met de chain en valuta die we accepteren, anders kunt u al uw geld verliezen.',
    usdtNote:
      'De huidige gaskosten van ERC20 is aanzienlijk hoger dan normaal en als gevolg daarvan kan er een langdurige vertraging zijn bij de verwerking van uw transactie. Als alternatief kunt u fondsen opnemen via TRC20 door het Correspolesporte-adres te verstrekken, of u kunt ervoor kiezen om de gaskosten te betalen om uw ERC20-opname te versnellen.',
    status: {
      withdrawalSubmitted: 'Ingediend',
      withdrawalCanceled: 'Geannuleerd',
      withdrawalAuditing: 'Bezig met verwerken',
      withdrawalRejected: 'Afgewezen',
      withdrawalAccepted: 'Bezig met verwerken',
      withdrawalPayFailed: 'Mislukt',
      withdrawalSuccess: 'Verwerkt',
      withdrawalFailed: 'Mislukt',
      withdrawalPending: 'In behandeling',
      withdrawalPartialFailed: 'Mislukt',
      transferSubmitted: 'Bezig met verwerken',
      transferSuccess: 'Verwerkt',
      transferRejected: 'Afgewezen',
      transferFailed: 'Mislukt',
      transferProcessing: 'Bezig met verwerken',
    },
    formErrorMsg: {
      bankCodeReq: 'Bankcode is vereist',
      bankBranchCodeReq: 'Filiaalcode is vereist',
      bankName: 'Banknaam is verplicht',
      bsb: 'BSB is verplicht',
      beneName: 'Naam bankbegunstigde is vereist',
      accNum: 'Bankrekeningnummer is verplicht',
      swift: 'Swift is vereist',
      bankAddress: 'Bankadres is vereist',
      holderAddress: 'Adres houder is vereist',
      amt: 'Bedrag is verplicht',
      firstSixDigits: 'Voer de eerste 6 cijfers in',
      lastFourDigits: 'Voer de laatste 4 cijfers in',
      branchName: 'Naam filiaal is verplicht',
      email: 'E-mailadres is verplicht',
      accName: 'Rekeningnaam is vereist',
      province: 'Provincie bank is verplicht',
      city: 'Plaats bank is verplicht',
      bankCard: 'Selecteer een kaart',
      emailFormat: 'Voer het juiste e-mailadres in',
      amt0: 'Bedrag kan niet 0 zijn',
      ifscCode: 'IFSC-code is vereist',
      bitcoin: 'Adres USDT Wallet is verplicht',
      bitcoinFormat: 'Onjuiste bitcoin portemonnee-adresformaat',
      usdt: 'Adres USDT Wallet is verplicht',
      chain: 'Ketting is vereist',
      walletFormatIncorrect: 'Onjuiste {type} Portemonnee-adresformaat',
      dynamicReq: '{fieldname} is verplicht',
    },
    note: "Vanwege recente wijzigingen kan onze bank niet in staat zijn om internationale bankoverschrijdende transfers te verwerken voor klanten die in sommige landen zijn geregistreerd.<br/>Als u moet opnemen via internationale bankoverschrijvingen, neem dan contact op met onze klantenondersteuning op +44 2080 363 883 of op <a href='mailto:{mail}'>{mail}</a>.<br/>\n    Onze excuses voor het ongemakken.",
    minAmountWarn:
      'Het toegepaste ontwenningsbedrag is minder dan het minimum voor de toegepaste betalingsgateway. Vul uw accountsaldo aan / gedurende het minimum aan voordat u een opnameverzoek indient. Meer informatie is te vinden <a href="https://{regulatorurl}/clients/accounts/withdraw/">hier</a>.',
  },
  rebate: {
    status: { processed: 'Verwerkt', processing: 'Bezig met verwerken', rejected: 'Afgewezen' },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'VERZOEK OM KORTINGSOVERSCHRIJVING',
    transferAmountIsGreaterThanAvailableBalance:
      'Uw overschrijvingsbedrag is hoger dan het beschikbare saldo',
    yourRebateTransferHasBeenProcessed: 'Uw kortingsoverschrijving is verstuurd',
    PleaseEnterTransferInformation: 'Voer nauwkeurige overschrijvingsinformatie in',
    tradingAccount: 'Handelsrekening',
    rebateAccount: 'Kortingsrekening',
    account: 'Rekening',
    blackList:
      'Deze rekening is geblokkeerd voor overschrijvingen tussen rekeningen. Neem contact op met {supportEmail}',
  },
  transferHistory: {
    payStatus: {
      processing: 'Bezig met verwerken',
      paid: 'Betaald',
      unpaid: 'Niet betaald',
      rejected: 'Afgewezen',
    },
  },
  common: {
    liveChat: { desc: '<li><p>Hulp nodig?</p><span>Live chat</span></li>' },
    lang: {
      english: 'Engels',
      chinese: 'Chinees',
      french: 'Frans',
      thai: 'Thais',
      german: 'Duits',
      spanish: 'Spaans',
      malay: 'Maleisisch',
      viet: 'Vietnamees',
      indo: 'Indonesisch',
      arabic: 'Arabisch',
      japanese: 'Japans',
    },
    button: {
      confirm: 'BEVESTIGEN',
      cancel: 'ANNULEREN',
      identityProof: 'VOLLEDIGE TOEPASSING',
      upload: 'UPLOADEN',
      selectFile: 'Selecteer bestand',
      fundnow: 'FONDS NU',
    },
    withdrawChannel: {
      banktransfer: 'Bankoverschrijving',
      aus: 'Bankoverschrijving - Australië',
      int: 'Bankoverschrijving - Internationaal',
      chn: 'Bankoverschrijving - China',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      fasapay: 'FasaPay',
      hongkongbanktransfer: 'Hong Kong lokale bankoverschrijving',
      thailandinstantbankwiretransfer: 'Thailand directe bankoverschrijving',
      malaysiainstantbankwiretransfer: 'Maleisië directe bankoverschrijving',
      banktransferbpaypolipay: 'Bankoverschrijving/B-betaling/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Cryptovaluta-bitcoin',
      cryptocurrencyusdt: 'Cryptovaluta-USDT',
      nigeriainstantbankwiretransfer: 'Nigeriaanse directe bankoverschrijving',
      vietnaminstantbankwiretransfer: 'Vietnamese directe bankoverschrijving',
      indonesiainstantbankwiretransfer: 'Indonesië directe bankoverschrijving',
      philippinesinstantbankwiretransfer: 'Filipijnse directe bankoverschrijving',
      southafricainstantbankwiretransfer: 'Directe bankoverschrijving Zuid-Afrika',
      indiainstantbanktransfer: 'Directe bankoverschrijving India',
      laosinstantbankwiretransfer: 'Directe bankoverschrijving Laos',
      sticpay: 'SticPay',
      transfer: 'Overboeken naar rekeningen',
      dasshpeupi: 'UPI',
      ugandabanktransfer: 'Oeganda lokale bankoverschrijving',
      rwandabanktransfer: 'Rwanda lokale bankoverschrijving',
      zambiabanktransfer: 'Zambia lokale bankoverschrijving',
      congobanktransfer: 'Congo lokale bankoverschrijving',
      cameroonbanktransfer: 'Kameroen Lokale bankoverschrijving',
      burundibanktransfer: 'Burundi lokale bankoverschrijving',
      kenyabanktransfer: 'Kenia lokale bankoverschrijving',
      ghanabanktransfer: 'Ghana Local Bank Transfer',
      tanzaniabanktransfer: 'Tanzania lokale bankoverschrijving',
    },
    country: { international: 'Internationaal', australia: 'Australië' },
    field: { country_region: 'Land/regio' },
    keys: {
      SUBMIT: 'VERZENDEN',
      FROM: 'VANAF',
      UPDATE: 'BIJWERKEN',
      STATUS: 'STATUS',
      TO: 'NAAR',
      DATE: 'DATUM',
      pleaseSelect: 'Selecteer',
      amount: 'Bedrag',
      actualAmount: '  Daadwerkelijke opnames = ',
      rebateAccount: 'Kortingsrekening',
      comingSoon: 'binnenkort beschikbaar',
      tradingHistory: 'HANDELSGESCHIEDENIS',
      noData: 'Geen gegevens',
      NAME: 'NAAM',
      EMAIL: 'E-MAIL',
      ACCTYPE: 'REKENINGTYPE',
      PLATFORM: 'PLATFORM',
      BASECURRENCY: 'BASISVALUTA',
      DATERANGE: 'DATUMBEREIK',
      DEST: 'BESTEMMING',
      ACCNUM: 'REKENINGNUMMER',
      BALANCE: 'SALDO',
      NONE: 'Geen',
      ALL: 'Allemaal',
      PROFITLOST: 'Utracony zysk',
      MARGINLEVEL: 'Poziom depozytu zabezpieczającego (%)',
      ACCOUNTEQUITY: 'Kapitał konta',
      CREDIT: 'Środki',
    },
    dateRange: {
      today: 'Vandaag',
      yesterday: 'Gisteren',
      lastSevenDays: 'Afgelopen 7 dagen',
      lastThirtyDays: 'Afgelopen 30 dagen',
      weekToDate: 'Week tot vandaag',
      monthToDate: 'Maand tot vandaag',
      quarterToDate: 'Kwartaal tot vandaag',
      previousWeek: 'Vorige week',
      previousMonth: 'Vorige maand',
      previousQuarter: 'Vorig kwartaal',
    },
    products: { forex: 'FOREX', commodities: 'GRONDSTOFFEN', indices: 'INDICES', crypto: 'CRYPTO' },
  },
  report: {
    rebate: {
      column: {
        name: 'NAAM',
        accountNumber: 'REKENINGNUMMER',
        accountType: 'REKENINGTYPE',
        volume: 'Volume',
        rebate: 'Korting',
        totalRebate: 'TOTALE KORTING',
        date: 'DATUM',
        instrument: 'INSTRUMENT',
        notionalvalue: 'NOMINALE WAARDE',
      },
      dropdown: {
        allInstruments: 'Alle instrumenten',
        all: 'Allemaal',
        instruments: 'Instrumenten',
      },
      others: {
        rebate: 'KORTING',
        instrumentsTraded: 'VERHANDELDE INSTRUMENTEN',
        lots: 'Partijen',
        totalRebate: 'Totale korting',
        rebates: 'Kortingen',
      },
    },
  },
  ibReport: { openedAccs: 'GEOPENDE REKENINGEN', funding: 'FINANCIERING', Account: 'REKENING' },
  lead: { demoAccounts: 'DEMOREKENINGEN' },
  pendingAccounts: {
    errorDoc:
      'De volgende aanvragers hebben het online aanvraagformulier ingevuld, maar moeten nog een identiteitsbewijs overleggen om hun rekening af te ronden.',
    acceptedDocType: 'Geaccepteerde vormen van ID zijn:',
    primaryDoc: 'Primaire ID',
    passport: 'Een kopie van uw geldige internationale paspoort (handtekeningpagina)',
    id: 'Een kopie van uw door de overheid uitgegeven nationale identiteitskaart',
    dl: 'Een kopie van uw geldige rijbewijs',
    address: 'Secundaire ID',
    addressList: [
      'Een kopie van een recente openbare energierekening. Bijv. Gas- of elektriciteitsrekening die uw naam en woonadres bevat (niet ouder dan 3 maanden)',
      'Een kopie van uw creditcard- of bankafschrift (geen online afdrukken)',
    ],
    tip: 'Moedig aanvragers aan om een kopie van ÉÉN van de geaccepteerde primaire en secundaire ID-documenten te verstrekken aan ',
  },
  rebatePaymentHistory: {
    rebateHistory: 'KORTINGEN',
    withdrawHistory: 'OPNAMES',
    transferHistory: 'OVERSCHRIJVINGEN',
  },
  referralLinks: {
    downloadPage: 'Download app',
    campLang: 'Taal van de campagne',
    cpy: 'KOPIËREN',
    alreadyCPY: 'GEKOPIEERD!',
    liveAcc: 'Live-rekening',
    demoAcc: 'Demo-rekening',
    homePage: 'Startpagina',
    promotion: 'Link voor evenementpromotie',
    hintTitle: 'Wat is een link voor evenementpromotie?',
    hintMessage:
      'De link voor evenementpromotie is een nieuwe promotiemethode die aan de IB wordt aangeboden. De link leidt gebruikers naar de bonusevenementpagina en voltooit de registratie. Het succespercentage van de gebruikersregistratie kan effectief worden verbeterd door de link te delen.',
    hintShortLink: 'Delen via korte link',
    hintShortLinkTitle: 'Wat is een korte link?',
    hintShortLinkMessage:
      'Een korte link is een regel voor het genereren van links, die uw gedeelde link beter kan beschermen tegen wijzigingen door anderen, waardoor uw IB-ontwikkelrechten worden beschermd',
    customButton: 'aanpassen',
    copyButton: 'kopiëren',
    customInviteCodeHeader: 'aangepaste uitnodigingscode',
    currentCode: 'huidige uitnodigingscode',
    newCode: 'nieuwe uitnodigingscode',
    notice:
      'Let op: De uitnodigingscode heeft minimaal 6 parameters. Letters, cijfers, niet-speciale symbolen kunnen worden ingevoerd',
    inviteCodeFormatError: 'Het formaat van de uitnodigingscode is onjuist, bewerk deze opnieuw',
    inviteCodeAlreadyExist:
      'De uitnodigingscode is preventief geregistreerd door anderen, gelieve opnieuw in te voeren',
    pleaseEnter: 'Kom binnen alstublieft',
    inviteCodeHeader: 'Promotie-uitnodigingscode',
    inviteCode: 'Uitnodigingscode',
  },
  settings: { info: 'INFO', reg: 'GEREGISTREERD OP' },
  unfundedAcc: {
    neverFundedAccs: 'NOOIT GEFINANCIERDE REKENINGEN',
    prevFundedZero: 'EERDER GEFINANCIERD (NULSALDO)',
    accBalance: 'REKENINGSALDO',
  },
  multiib: { level: 'NIVEAU {level}', noib: "Er zijn geen sub-IB's onder deze rekening." },
  AWCDialog: { optIn: 'NU DEELNEMEN', noThanks: 'NEE BEDANKT' },
  header: { bckToCP: 'TERUG NAAR KLANTENPORTAAL', logout: 'UITLOGGEN' },
  menu: {
    dashboard: 'DASHBOARD GEBRUIKEN',
    ibReport: 'IB-RAPPORT',
    rebateReport: 'KORTINGSRAPPORT',
    iblevel: 'IB MET MEERDERE NIVEAUS',
    ibAccs: 'IB-REKENINGEN',
    leads: 'LEADS',
    pendingAccs: 'LOPENDE REKENINGEN',
    unfundedAccs: 'REKENINGEN ZONDER SALDO',
    payHistory: 'TRANSACTIEGESCHIEDENIS',
    ibProfile: 'IB-PROFIEL',
    refLinks: 'VERWIJZINGSLINKS',
    contactUs: 'NEEM CONTACT MET ONS OP',
    switchCP: 'OVERSCHAKELEN NAAR CLIENTPORTAL',
  },
  footer: {
    riskWarn:
      "Risicowaarschuwing: Trading Forex en CFD's omvat een aanzienlijk risico en kan resulteren in het verlies van uw geïnvesteerde\n    kapitaal. Het handelen van leveraged producten zijn mogelijk niet geschikt voor alle investeerders. Vóór het handelen, neem dan mee\n    Overweging Uw niveau van ervaring, beleggingsdoelstellingen en indien nodig onafhankelijk financieel advies inwinnen.\n    Lees onze juridische documenten op onze website en zorg ervoor dat u de risico's volledig begrijpt voordat u er een boekt\n    handelsbeslissingen.",
  },
  responseMsg: {
    410: 'Validatie van parameter mislukt',
    411: 'Onjuist wachtwoord',
    420: 'Gebruiker bestaat niet',
    421: 'Opnieuw instellen wachtwoord geweigerd',
    490: 'Dien geen dubbele kaartinformatie in',
    500: 'Er is een fout opgetreden.',
    501: 'Toepassing mislukt. Probeer het later opnieuw.',
    505: 'Uploaden van bestand mislukt',
    510: 'Onjuiste sms-code',
    511: 'Kan sms-code niet naar uw telefoon verzenden',
    520: 'Inloggen mislukt. Probeer het opnieuw.',
    521: 'Er is al een gebruiker ingelogd op dit apparaat!',
    522: 'Log opnieuw in',
    523: 'Log opnieuw in',
    524: 'Helaas is uw e-mailadres niet herkend',
    525: 'Onjuiste gebruikersnaam of wachtwoord',
    526: 'Recaptcha komt niet overeen',
    527: 'Gebruiker is geen IB',
    528: 'Gebruiker bestaat niet',
    529: 'Onjuiste gebruikersnaam of wachtwoord',
    530: 'Code ongeldig',
    531: 'Code ongeldig',
    540: 'Uw handelsrekening kon niet worden gevonden',
    541: 'Kan kortingsrekening niet vinden',
    542: 'U mag geen extra rekening openen',
    544: 'U mag geen extra rekening aanvragen totdat uw Bewijs van ID geverifieerd is',
    550: 'Onjuist rekeningnummer',
    551: 'Uw saldo is onvoldoende',
    554: 'Aangevraagd bedrag is nul',
    562: 'We konden uw betaling niet verwerken, probeer het opnieuw. Als het probleem aanhoudt, neem dan contact met ons op via live chat of e-mail naar {email}',
    564: 'Land komt niet overeen met het betalingskanaal',
    565: 'Onjuiste bankcode',
    566: 'Betaalmethode niet gevonden',
    567: 'Helaas is deze betaalmethode momenteel niet beschikbaar. Onze excuses voor het eventuele ongemak',
    581: 'U heeft zich aangemeld voor de promotie. Houd er rekening mee dat u een storting moet doen om geld op te nemen.',
    584: 'U hebt geen toepasselijke kortingen',
    590: 'Kan rekening voor uitboeken niet vinden',
    591: 'Kan rekening voor inkomende overschrijving niet vinden',
    593: 'Wij zijn niet in staat om uw overschrijving van de gekozen rekening te verwerken, omdat uw rekening krediet bevat.',
    594: 'Overschrijving van geld van deze rekening is niet toegestaan',
    session_timeout: 'Sessietime-out, omleiden naar inlogpagina.',
  },
};
