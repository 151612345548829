export default {
  home: {
    RebateAccountNumber: 'Número da conta de comissão',
    totalCommission: 'Comissão Total',
    APPLY_FOR_A_REBATE: 'SOLICITE A COMISSÃO',
    availableBalance: 'Saldo Disponível',
    WITHDRAW_REBATE: 'SACAR COMISSÃO',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'TRANSFERIR',
    TOTAL_VOLUME: 'VOLUME TOTAL',
    NET_FUNDING: 'DEPÓSITO LÍQUIDO',
    DEPOSITS: 'DEPÓSITOS',
    WITHDRAW: 'SACAR',
    OPEND_ACCOUNT: 'CONTA ABERTA',
    PERFORMANCE: 'DESEMPENHO',
    MOST_TRADED_INSTRUMENTS: 'INSTRUMENTOS MAIS NEGOCIADOS',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'CONTAS COM MELHOR DESEMPENHO PARA ESTE MÊS',
    RECENTLY_OPENED_ACCOUNTS: 'CONTAS ABERTAS RECENTEMENTE',
    ACCOUNT_MANAGER: 'GERENTE DE CONTAS',
    Account: 'Conta',
    Name: 'Nome',
    Volume: 'Volume',
    Rebate: 'COMISSÃO',
    Balance: 'Saldo',
    ContactClient: 'Cliente de contato',
    applyRebateSuccessTip:
      'Parabéns, recebemos sua solicitação de reembolso. | {amount} será transferido para a conta de reembolso {account} dentro de 1 dia útil. | Você pode sacar ou transferir fundos assim que seu reembolso for processado.',
    HOVER: 'As comissões do dia anterior são calculados e processados ​​diariamente entre 9h e 11h AEST.',
    blacklist: 'Esta conta não pode obter uma compensação no momento, por favor, contacte o seu gestor de conta.',
  },
  liveChat: { header: 'Chat ao vivo' },
  contactUs: {
    tip: 'Se você tiver dúvidas ou quiser mais informações, entre em contato conosco através de um dos dados de contato abaixo. Faremos o possível para responder a todas as perguntas dentro de 24 horas.',
  },
  fileUploader: {
    supportedFormat: 'Tipos de ficheiros suportados: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Tipos de ficheiros suportados: {files}',
    maxSize: 'Tamanho máximo do ficheiro para carregamento: {maxSize}MB',
    maxFileNum: 'Número máximo de ficheiros: {maxFileNum}',
    failedFileExtension: 'Carregue o tipo de arquivo correto',
  },
  notification: {
    pendingIdentityProof: {
      header: 'PROCESSAMENTO DA INSCRIÇÃO DE CONTA',
      id: 'ID',
      poa: 'endereço',
      both: 'O ID e o comprovativo de endereço são',
      content: 'O seu Comprovativo de {doc} esta sendo processado no momento, e será finalizado em breve.',
    },
  },
  identityProof: {
    home: {
      header: 'INSCRIÇÃO DE CONTA INCOMPLETA',
      content:
        'Olá, {name}, a sua inscrição de conta está quase completa, no entanto, ainda é necessário enviar documentação adicional para finalizar a sua conta.',
    },
    popup: {
      header:
        'Olá, {name}, antes que a sua conta real possa ser finalizada, é necessário fazer o carregamento do seguinte documento para verificação.',
      addressProofSpan:
        '<strong>\n      <b>Confirmar Endereço</b>\n      </strong>\n      <br />\n      <I>\n        Submeta <b>um</b> dos documentos abaixo\n        <br /><br />\n      </I>\n      - Cópia de uma Fatura de Serviços recente </br> (com até 3 meses de validade)\n      <br />\n      <b class="or">or</b><br />\n      -  Cópia de um extrato bancário recente </br> (com até 3 meses de validade)\n      <br />\n      <br />\n      <b>IMPORTANTE !</b> O documento DEVE mostrar claramente o seu nome completo e endereço atual.\n      <br />',
    },
    reasonType: {
      4: 'Documentos Incompletos',
      5: 'Documentos Ilegíveis',
      6: 'Cópia a Cores Obrigatória',
      8: 'Divergência do Nome de Abertura da Conta',
      9: 'Data de Emissão do Comprovativo de Morada Não Qualificada',
    },
    reason: 'Não foi possível verificar a sua documentação,<br> Motivo: {reason}. Por favor, tente novamente.',
    success: 'Obrigado. Recebemos os seus documentos com sucesso e estamos a processá-los para verificação.',
  },
  ibAccounts: {
    ibAccount: 'Conta IB',
    subIbs: 'Conta Sub IB',
    tradingList: 'Lista de Negociação',
    OpeningList: 'Lista de Abertura',
    closeList: 'Fechar Lista',
    tradingListColumn: {
      ticket: 'TicketID',
      openTime: 'Horário de Abertura',
      type: 'Tipo',
      volume: 'Volume',
      item: 'Item',
      openPrice: 'Preço de Abertura',
      closeTime: 'Horário de Fecho',
      closePrice: 'Preço de Fecho',
      commission: 'Comissão',
      profit: 'Lucro',
    },
  },
  withdraw: {
    warning: {
      desc: 'Por favor, esteja ciente das seguintes regras:',
      desc1: 'O usuário final incorrerá em uma taxa de transferência bancária, que normalmente varia de 135 a 250 JPY.',
      desc2: 'Os pedidos de saque efetuados após as 14h poderão ser adiados ou processados ​​no dia seguinte.',
    },
    default: {
      selectCC: 'Selecione o cartão de crédito',
      selectBA: 'Selecione a Conta Bancária',
      anotherCC: 'Digite o cartão de crédito manualmente',
      anotherBA: 'Adicionar conta bancária',
      remember: 'Lembrar minha conta',
    },
    transfer: {
      info: 'A {platform} irá reembolsar a taxa bancária cobrada por <u>UM</u> levantamento por mês. Os levantamentos adicionais dentro do mês incorrem numa taxa bancária de 20 unidades da sua moeda de levantamento.',
      label: {
        upload: 'Por favor, faça o carregamento de uma cópia do seu extrato bancário (dos últimos 3 meses correntes)',
      },
      formValidation: { upload: 'Por favor, faça o envio de um extrato bancário' },
    },
    REBATE_WITHDRAW_REQUEST: 'SOLICITAR LEVANTAMENTO DE COMISSÃO',
    PleaseSelectDesiredWithdrawMethod: 'Selecione o método de levantamento desejado',
    BankName: 'Nome do banco',
    bankCode: 'Código do Banco',
    bankAccountCurrency: 'Moeda da Conta Bancária',
    bankBranchCode: 'Código de sucursal',
    BankAccountsName: 'Nome das contas bancárias',
    FasaPayAccountName: 'Nome da conta FasaPay',
    AustralianBankName: 'Nome do banco australiano',
    BankAccountNumber: 'Número da conta de banco',
    FasaPayAccountNumber: 'Número da conta FasaPay',
    BankAccountBeneficiary: 'Beneficiário da conta bancária',
    BSB: 'BSB',
    SortCode: 'Sort code',
    skrillEmail: 'Email Skrill',
    NetellerEmail: 'Neteller Email',
    PerfectMoneyEmail: 'Perfect Money Email',
    BitwalletEmail: 'Bitwallet Email',
    SticpayEmail: 'Email SticPay',
    BitcoinEmail: 'Endereço da carteira Bitcoin',
    USDTEmail: 'Endereço da carteira Tether',
    BankAccountName: 'Nome do Beneficiário Bancário',
    BankBranch: 'Agência Bancária (Número da Agência composta por 3 dígitos)',
    BankAccountType: 'Tipo de Conta Bancária',
    Province: 'Província',
    City: 'Cidade',
    BankAddress: 'Endereço do banco',
    AccountNumberIBAN: 'Número da conta / IBAN',
    BankBeneficiaryName: 'Nome do beneficiário do banco',
    BankAccountBeneficiaryName: 'Nome do beneficiário da conta bancária',
    AccountHoldersAddress: 'Endereço do titular da conta',
    SelectWithdrawCard: 'Selecione o seu cartão de retirada',
    Swift: 'Swift',
    SwiftAud: 'Swift (contas não AUD)',
    ABA_SortCodeABA: 'ABA / Código de classificação',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: 'Notas importantes',
    ifscCode: 'Código IFSC',
    uploadDocument:
      'Por favor, faça o carregamento de uma cópia do seu extrato bancário (dos últimos 3 meses correntes)',
    YourPaymentGreaterThanAvailableBalance: 'Seu pagamento é maior que o saldo disponível',
    integer: 'Só é suportada a entrada de números inteiros',
    CannotUseMoreThanTwoDecimalValues: 'Você não pode usar números e números negativos com mais de duas casas decimais',
    YourPaymentIsBeingProcessed: 'Seu pedido de levantamento foi enviado',
    Itcannotbe0: 'Não pode ser 0',
    noCardWarn:
      'Você não tem nenhum cartão disponível para fazer levantamentos, primeiro vincule seu cartão aos Detalhes de saque',
    amtLarger:
      'O valor mínimo de retirada é de US{currencyType} {minLimit}. Os saldos abaixo de 30 devem ser retirados na totalidade',
    blackList:
      'Infelizmente, não pode atualmente submeter um pedido de levantamento. Por favor contacte {supportEmail} para mais informações',
    NetellerChargeAlert:
      'As retiradas do Neteller são limitadas a $ 500.000 por transação e aplica-se uma taxa de 2% (limitada a $ 30).',
    FasaPayChargeAlert: 'O FasaPay cobra uma taxa de transação de 0,5% para cada retirada.',
    SkrillChargeAlert: 'Skrill cobra taxa de transação de 1% para cada retirada.',
    cryptoAlert:
      'Por favor, tenha em atenção que não podemos depositar ou sacar via BUSDT, por favor, certifique-se de que o endereço e a moeda Criptomoeda correspondem à corrente e moeda que aceitamos ou poderá perder o fundo.',
    name: 'Nome',
    phone: 'Número de Telefone',
    address: 'Endereço',
    email: 'E-mail',
    status: {
      withdrawalSubmitted: 'Submetido',
      withdrawalCanceled: 'Cancelado',
      withdrawalAuditing: 'Em processamento',
      withdrawalRejected: 'Rejeitado',
      withdrawalAccepted: 'Em processamento',
      withdrawalPayFailed: 'Falhou',
      withdrawalSuccess: 'Processado',
      withdrawalFailed: 'Falhou',
      withdrawalPending: 'Pendente',
      withdrawalPartialFailed: 'Falhou',
      transferSubmitted: 'Em processamento',
      transferSuccess: 'Processado',
      transferRejected: 'Rejeitado',
      transferFailed: 'Falhou',
      transferProcessing: 'Em processamento',
    },
    formErrorMsg: {
      bankName: 'Nome do banco é obrigatório',
      bsb: 'BSB é obrigatório',
      sortCode: 'O Sort Code é obrigatório',
      beneName: 'O nome do beneficiário do banco é obrigatório',
      bankCodeReq: 'O Código bancário é obrigatório',
      bankBranchCodeReq: 'O Código da sucursal é obrigatório',
      accNum: 'O número da conta bancária é obrigatório',
      swift: 'Swift é necessário',
      bankAddress: 'O endereço do banco é obrigatório',
      holderAddress: 'O endereço do titular é obrigatório',
      amt: 'Quantidade é necessária',
      firstSixDigits: 'Digite os 6 primeiros dígitos',
      lastFourDigits: 'Insira os 4 últimos dígitos',
      branchName: 'Nome da filial é obrigatório',
      email: 'E-mail é obrigatório',
      accName: 'O nome da conta é obrigatório',
      province: 'A província do banco é necessária',
      city: 'Cidade do banco é obrigatória',
      bankCard: 'Selecione um cartão',
      emailFormat: 'Por favor, insira o endereço de e-mail correto',
      amt0: 'A quantidade não pode ser 0',
      ifscCode: 'O Código IFSC é obrigatório',
      bitcoin: 'O endereço da Carteira Bitcoin é obrigatório',
      bitcoinAddressLength: 'O endereço da carteira de Bitcoin deve ter entre 26 e 42 caracteres',
      usdt: 'O endereço da Carteira USDT é obrigatório',
      dynamicReq: '{fieldname} é obrigatório',
      uploadDocument: 'Por favor, faça o envio de um extrato bancário',
    },
    note: "Devido a alterações recentes, o nosso banco poderá não conseguir processar transferências bancárias internacionais para clientes registados nalguns países.<br/>\n      Se tiver de efetuar levantamentos através de transferências bancárias internacionais, por favor contacte o nosso Apoio ao Cliente pelo telefone +44 2080 363 883 ou pelo endereço <a href='mailto:{mail}'>{mail}</a>.<br/>\n      Pedimos desculpa pelos inconvenientes.",
    minAmountWarn:
      'O montante de levantamento solicitado é inferior ao mínimo do portal de pagamento solicitado. Por favor carregue o saldo da sua conta no montante mínimo ou superior antes de submeter um pedido de levantamento. Poderá consultar mais informações <a href="https://{regulatorUrl}/clients/accounts/withdraw/">aqui</a>.',
  },
  rebate: {
    status: { processed: 'Processado', processing: 'Em processamento', rejected: 'Rejeitado' },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'PEDIDO DE TRANSFERÊNCIA DE COMISSÃO',
    transferAmountIsGreaterThanAvailableBalance: 'O valor da sua transferência é maior do que o saldo disponível',
    yourRebateTransferHasBeenProcessed: 'sua transferência de reembolso foi enviada',
    PleaseEnterTransferInformation: 'Insira informações de transferência precisas',
    tradingAccount: 'Conta de negociação',
    rebateAccount: 'Conta de comissão',
    account: 'Conta',
    blackList: 'Esta conta foi restringida de fazer transferências entre contas, por favor, contacte {supportEmail}',
  },
  transferHistory: {
    payStatus: {
      processing: 'Em Processamento',
      paid: 'Pago',
      unpaid: 'Por pagar',
      rejected: 'Rejeitado',
    },
  },
  common: {
    selectIb: 'Selecione IB',
    liveChat: { desc: '<li><p>Precisa de ajuda?</p><span>Chat ao Vivo</span></li>' },
    lang: {
      english: 'Inglês',
      chinese: 'chinês',
      french: 'francês',
      thai: 'tailandês',
      german: 'alemão',
      spanish: 'espanhol',
      malay: 'malaio',
      viet: 'vietnamita',
      indo: 'indonésio',
      arabic: 'árabe',
      japanese: 'japonês',
    },
    button: {
      confirm: 'CONFIRME',
      cancel: 'CANCELAR',
      identityProof: 'INSCRIÇÃO COMPLETA',
      upload: 'CARREGAR',
      selectFile: 'Selecionar Ficheiro',
      fundnow: 'FINANCIAR AGORA',
      iunderstand: 'Eu entendo',
    },
    withdrawChannel: {
      hongkongbanktransfer: 'Transferência Bancária Local de Hong Kong',
      banktransfer: 'Transferência bancária',
      aus: 'Transferência Bancária - Austrália',
      int: 'Transferência Bancária - Internacional',
      chn: 'Transferência Bancária - China',
      uk: 'Transferência Bancária - Reino Unido',
      thailandinstantbankwiretransfer: ' Transferência Bancária Instantânea da Tailândia',
      malaysiainstantbankwiretransfer: 'Transferência Bancária Instantânea Malaia',
      banktransferbpaypolipay: 'Transferência Bancária / BPay / PoliPay',
      cryptocurrencybitcoin: 'Criptomoeda-Bitcoin',
      cryptocurrencyusdt: 'Criptomoeda-USDT',
      nigeriainstantbankwiretransfer: 'Transferência Bancária Instantânea Nigeriana',
      vietnaminstantbankwiretransfer: 'Transferência Bancária Instantânea Vietnamita',
      indonesiainstantbankwiretransfer: 'Transferência Bancária Instantânea Indonésia',
      philippinesinstantbankwiretransfer: 'Transferência Bancária Instantânea da Philippine',
      southafricainstantbankwiretransfer: 'Transferência Bancária Instantânea da África do Sul',
      indiainstantbanktransfer: 'Transferência Bancária Instantânea India',
      laosinstantbankwiretransfer: 'Transferência Bancária Instantânea de Laos',
      ugandabanktransfer: 'Transferência de banco local de Uganda',
      rwandabanktransfer: 'Transferência de banco local de Ruanda',
      zambiabanktransfer: 'Transferência de banco local da Zâmbia',
      congobanktransfer: 'Transferência de banco local do Congo',
      cameroonbanktransfer: 'Transferência de banco local de Camarões',
      burundibanktransfer: 'Transferência bancária local do Burundi',
      kenyabanktransfer: 'Transferência bancária local do Quênia',
      ghanabanktransfer: 'Transferência de banco local de Gana',
      tanzaniabanktransfer: 'Transferência de banco local da Tanzânia',
      brazilbanktransfer: 'Transferência Bancária Instantânea Brasil',
      mexicobanktransfer: 'Transferência Bancária Instantânea México',
      internetbankingmexico: 'Serviço Bancário Online (México)',
      internetbankingbrazil: 'Serviço Bancário Online (Brasil)',
      astropay: 'Astropay',
      transfer: 'Transferência para contas',
    },
    country: { international: 'Internacional', australia: 'Austrália', uk: 'Reino Unido' },
    field: { country_region: 'País / Região', yes: 'Sim', no: 'Não' },
    keys: {
      SUBMIT: 'ENVIAR',
      FROM: 'DE',
      UPDATE: 'ATUALIZAR',
      TO: 'PARA',
      DATE: 'DATA',
      pleaseSelect: 'Por favor selecione',
      amount: 'Montante',
      actualAmount: '  Levantamentos efetivos = ',
      rebateAccount: 'Conta de comissão',
      comingSoon: 'em breve',
      tradingHistory: 'HISTÓRIA DE NEGOCIAÇÃO',
      noData: 'Sem dados',
      NAME: 'NOME',
      EMAIL: 'E-MAIL',
      MOBILE: 'CELULAR',
      ACCTYPE: 'TIPO DE CONTA',
      PLATFORM: 'PLATAFORMA',
      BASECURRENCY: 'MOEDA BASE',
      DATERANGE: 'INTERVALO DE DATA',
      STATUS: 'STATUS',
      DEST: 'DESTINO',
      ACCNUM: 'NÚMERO DA CONTA',
      BALANCE: 'SALDO',
      NONE: 'Nenhum',
      ALL: 'Todos',
      PROFITLOST: 'Perda',
      MARGINLEVEL: 'Nível de Margem (%)',
      ACCOUNTEQUITY: 'Equidade da Conta',
      CREDIT: 'Crédito',
      DOWNLOAD: 'DOWNLOADS',
    },
    dateRange: {
      today: 'Hoje',
      yesterday: 'Ontem',
      lastSevenDays: 'Últimos 7 dias',
      lastThirtyDays: 'Últimos 30 dias',
      weekToDate: 'Semana até a data',
      monthToDate: 'Do mês até a data',
      quarterToDate: 'Trimestre até a data',
      previousWeek: 'Semana anterior',
      previousMonth: 'Mês anterior',
      previousQuarter: 'Trimestre Anterior',
    },
    products: {
      forex: 'FOREX',
      commodities: 'COMMODITIES',
      indices: 'ÍNDICES',
      crypto: 'CRIPTO',
      bond: 'títulos',
      bonds: 'títulos',
      gold: 'OURO',
      minigold: 'MINI OURO',
      goldfutures: 'FUTUROS DE OURO',
      bitcoin: 'BITCOIN',
      silver: 'PRATA',
      silverfutures: 'FUTUROS DE PRATA',
      oil: 'PETRÓLEO',
      oilfutures: 'FUTUROS DE PETRÓLEO',
      index: 'ÍNDICE',
      indexfutures: 'FUTUROS DE ÍNDICE',
      etf: 'ETF',
      forexfutures: 'FOREX',
      stock: 'FUTUROS DE FOREX',
    },
  },
  withdrawalFreeze: {
    header: 'As retiradas não são temporariamente suportadas',
    desc: 'Devido à modificação da sua senha de login, os saques não são temporariamente suportados por T+{day} dias para garantir a segurança da sua conta. Esta restrição deverá ser levantada em {hour} horas.',
    desc2:
      'Devido à modificação do seu e-mail/número de telefone de login, os saques não são temporariamente suportados por T+{day}  dias. Esta restrição deverá ser levantada em {hour} horas.',
  },
  report: {
    rebate: {
      column: {
        name: 'NOME',
        accountNumber: 'NÚMERO DA CONTA',
        accountType: 'TIPO DE CONTA',
        volume: 'VOLUME',
        rebate: 'COMISSÃO',
        totalRebate: 'COMISSÃO TOTAL',
        date: 'DATA',
        instrument: 'INSTRUMENTO',
        notionalvalue: 'VALOR NOCIONAL',
      },
      dropdown: {
        allInstruments: 'Todos os instrumentos',
        all: 'Todos',
        instruments: 'Instrumentos',
      },
      others: {
        rebate: 'COMISSÃO',
        instrumentsTraded: 'INSTRUMENTOS NEGOCIADOS',
        lots: 'Quantidade',
        totalRebate: 'Desconto Total',
        rebates: 'Comissão',
      },
    },
    btn: { showAll: 'Mostrar Tudo', hide: 'Ocultar' },
    trade: {
      column: {
        account: 'Conta',
        ticket: 'Ticket',
        platform: 'Plataforma',
        symbol: 'Moeda Base',
        type: 'Tipo',
        closeTime: 'Close Time',
        profit: 'Lucro',
        commission: 'Comissão',
      },
    },
  },
  ibReport: { openedAccs: 'CONTAS ABERTAS', funding: 'DEPÓSITO', account: 'CONTA' },
  lead: { demoAccounts: ' CONTAS DEMO' },
  pendingAccounts: {
    errorDoc:
      'Os seguintes candidatos preencheram o formulário de inscrição online, mas ainda precisam fornecer documentos para finalizar sua conta.',
    acceptedDocType: 'As os documentos aceitos incluem:',
    primaryDoc: 'Documento de Identificação',
    passport: 'Uma cópia do seu passaporte internacional válido (página de assinatura)',
    id: 'Uma cópia da carteira de identidade nacional emitida pelo governo',
    dl: 'Uma cópia de sua carteira de motorista válida',
    divc: 'Cópia do seu cartão de eleitor',
    dric: 'Cópia do seu RG - Registro Geral',
    address: 'Comprovante de Residência',
    addressList: [
      'Uma cópia de uma conta de serviço público recente. Por exemplo, gás ou eletricidade que contenha seu nome e endereço residencial (não mais de 3 meses)',
      'Uma cópia do extrato do seu cartão de crédito ou extrato bancário (documentos online não são aceitos)',
    ],
    tip: 'Aconselhe os clientes a fornecer uma cópia de apenas um dos documentos de identidade e comprovante de residência sugeridos acima aceitos por',
  },
  rebatePaymentHistory: {
    rebateHistory: 'HISTÓRICO DE COMISSÃO',
    withdrawHistory: ' HISTÓRICO DE LEVANTAMENTOS',
    transferHistory: 'HISTÓRICO DE TRANSFERÊNCIAS',
  },
  referralLinks: {
    copyLink: 'COPIAR LINK',
    downloadPage: 'Baixar aplicativo',
    campLang: 'Idioma da campanha',
    selectCountryOrLang: 'Selecione o país do seu cliente ou idioma preferido',
    cpy: 'CÓPIA DE',
    alreadyCPY: 'COPIADO!',
    liveAcc: 'Conta Real',
    demoAcc: 'Conta Demo',
    homePage: 'Pagina inicial',
    promotion: 'Hiperligação para promoção de eventos',
    hintTitle: 'O que é uma hiperligação de promoção de eventos?',
    hintMessage:
      'A hiperligação de promoção de eventos é um novo método de promoção fornecido ao IB. A hiperligação partilhada guiará os utilizadores para a página do evento de bónus e completará o registo. A taxa de sucesso do registo do utilizador pode ser efetivamente melhorada através da partilha da hiperligação.',
    hintShortLink: 'Partilhe utilizando a hiperligação curta',
    hintShortLinkTitle: 'O que é uma hiperligação curta?',
    hintShortLinkMessage:
      'A hiperligação curta é uma regra de geração de hiperligações, que pode proteger melhor a sua hiperligação partilhada de ser alterada por outros, protegendo assim os seus direitos de desenvolvimento IB',
    customButton: 'customizar',
    copyButton: 'cópia de',
    customInviteCodeHeader: 'código de convite personalizado',
    currentCode: 'código de convite atual',
    newCode: 'novo código de convite',
    notice:
      'Nota: O código de convite tem pelo menos 6 parâmetros. Letras, números, símbolos não especiais podem ser inseridos',
    inviteCodeFormatError: 'O formato do código de convite está errado, edite-o novamente',
    inviteCodeAlreadyExist: 'O código de convite foi registrado preventivamente por outras pessoas. Digite novamente',
    pleaseEnter: 'Por favor, insira',
    inviteCodeHeader: 'Código de convite da promoção',
    inviteCode: 'Código de Convite',
  },
  settings: {
    info: 'INFORMAÇÃO',
    reg: 'REGISTADO EM',
    CustomerInfo: 'Informações do cliente',
    name: 'Nome e sobrenome',
    email: 'E-mail',
    phone: 'Número de telefone',
    address: 'Endereço',
  },
  unfundedAcc: {
    neverFundedAccs: ' CONTAS NUNCA FINANCIADAS',
    prevFundedZero: 'FINANCIADO ANTERIORMENTE (SALDO ZERO)',
    accBalance: 'SALDO DA CONTA',
  },
  multiib: { level: 'NÍVEL {level}', noib: 'Não há sub-IBs sob esta conta.' },
  AWCDialog: { optIn: 'ADIRA AGORA', noThanks: 'NÃO, OBRIGADO' },
  header: { bckToCP: 'VOLTAR AO PORTAL DO CLIENTE', logout: 'SAIR', welcome: 'Seja bem-vindo!' },
  menu: {
    dashboard: 'PAINEL DE CONTROLE',
    ibReport: 'Relatório IB ',
    rebateReport: 'RELATÓRIO DE COMISSÃO',
    iblevel: "SUB IB'S",
    ibAccs: 'CONTAS IB',
    leads: 'LEADS',
    pendingAccs: 'CONTAS PENDENTES',
    unfundedAccs: 'CONTAS NÃO FINANCIADAS',
    payHistory: 'HISTÓRICO DE TRANSAÇÕES',
    ibProfile: 'PERFIL IB',
    refLinks: 'LINKS DE REFERÊNCIA',
    contactUs: 'CONTATE-NOS',
    switchCP: 'MUDAR PARA O PORTAL DO CLIENTE',
    allRetailAccs: 'CONTA DOS CLIENTES',
  },
  footer: {
    riskWarn:
      'Aviso de risco: Negociar Forex e CFDs envolve um risco significativo e pode resultar na perda do seu investimento\n    capital. A comercialização de produtos alavancados pode não ser adequada para todos os investidores. Antes de negociar, por favor, leve em consideração\n    seu nível de experiência, objetivos de investimento e procure aconselhamento financeiro independente, se necessário.\n    Por favor, leia nossos documentos legais em nosso site e certifique-se de compreender totalmente os riscos antes de fazer qualquer\n    decisões de negociação.',
    riskWarn_fsc:
      'Aviso de Risco: Os derivativos são instrumentos complexos e apresentam um elevado risco de perder dinheiro rapidamente devido à alavancagem. Você deve considerar se entende como os derivativos funcionam e se pode correr o alto risco de perder seu dinheiro.',
  },
  allRetailAccs: {
    type: {
      all: 'Todos',
      funded: 'Com Depósito',
      unfunded: 'Sem Depósito',
      deposit: 'Deposit',
      withdrawal: 'Withdrawal',
    },
    filter: { name: 'Nome', email: 'E-mail', account: 'Account' },
    placeholder: 'Pesquise por e-mail/nome/conta/mobile',
    search: 'Pesquise',
  },
  responseMsg: {
    410: 'A validação dos parâmetros falhou',
    411: 'Palavra-passe incorreta',
    420: 'O utilizador não existe',
    421: 'Redefinição de Palavra-passe negada',
    490: 'Por favor, não submeta informações duplicadas do cartão',
    500: 'Ocorreu um erro.',
    501: 'A Inscrição falhou. Por favor, tente novamente mais tarde.',
    505: 'O carregamento de ficheiros falhou',
    510: 'Código SMS incorreto',
    511: 'Não foi possível enviar o código SMS para o seu telefone',
    520: 'Falha na autenticação. Por favor, tente novamente.',
    521: 'Um utilizador já está ligado neste dispositivo!',
    522: 'Por favor, inicie a sessão novamente',
    523: 'Por favor, inicie a sessão novamente',
    524: 'Infelizmente o seu endereço de e-mail não foi reconhecido',
    525: 'Nome de utilizador ou palavra-passe incorretos',
    526: 'O recaptcha não corresponde',
    527: 'O utilizador não é um IB',
    528: 'O utilizador não existe',
    529: 'Nome de utilizador ou palavra-passe incorretos',
    530: 'Código inválido',
    531: 'Código inválido',
    540: 'Não foi possível encontrar a sua conta de negociação',
    541: 'Não foi possível encontrar a conta de compensação',
    542: 'Você não tem permissão para abrir uma conta adicional',
    544: 'Não está autorizado a candidatar-se a uma conta adicional até que o seu Comprovativo de Identificação seja verificado',
    550: 'Número de conta incorreto',
    551: 'O seu saldo é insuficiente',
    554: 'O valor solicitado é zero ou nulo',
    562: 'Não foi possível processar seu pagamento, por favor tente novamente. Se o problema persistir, favor entrar em contato conosco via chat, ou e-mail {email}.',
    564: 'O país não corresponde ao canal de pagamento',
    565: 'Código Bancário incorreto',
    566: 'Método de pagamento não encontrado',
    581: 'Você optou com sucesso pela promoção. Observe que você deve fazer um depósito a fim de sacar fundos.',
    584: 'Não tem compensações aplicáveis',
    590: 'Não foi possível encontrar a conta da transferência realizada',
    591: 'Não foi possível encontrar a conta da transferência recebida',
    593: 'Não podemos processar a sua transferência de fundos da conta selecionada devido à sua conta conter crédito.',
    594: 'A transferência de fundos a partir desta conta não é permitida',
    session_timeout: 'A sessão chegou ao tempo limite, você será redirecionando para a página de login.',
  },
}
