// 進行異步執行
import { apiInformation } from '@/resource'
import store from '@/store'

const country = require('@/constants/country.js')

const fca = require('@/regulator/fca.js')
const fsc = require('@/regulator/fsc.js')
const scb = require('@/regulator/scb.js')

const regulator = { fca, fsc, scb }

// Only apply to FSC's asiaEmailGroup and Thailand
function setContactEmail() {
  const reg = store.state.common.regulator
  const countryCode = store.state.common.countryCode

  const asiaEmailGroup = [
    country.countryCodeEnum.AUSTRALIA, // AU
    country.countryCodeEnum.CAMBODIA, // KH
    country.countryCodeEnum.CHINA, // CN
    country.countryCodeEnum.HONG_KONG, // HK
    country.countryCodeEnum.INDONESIA, // ID
    country.countryCodeEnum.SOUTH_KOREA, // KR
    country.countryCodeEnum.LAO_PEOPLE_REPUBLIC, // LA
    country.countryCodeEnum.MALAYSIA, // MY
    country.countryCodeEnum.MONGOLIA, // MN
    country.countryCodeEnum.MYANMAR, // MM
    country.countryCodeEnum.NEW_ZEALAND, // NZ
    country.countryCodeEnum.PHILIPPINES, // PH
    country.countryCodeEnum.SINGAPORE, // SG
    country.countryCodeEnum.TAIWAN, // TW
    country.countryCodeEnum.VIETNAM, // VN
    country.countryCodeEnum.THAILAND, // TH
  ]

  if (reg === 'fsc' && (asiaEmailGroup.includes(parseInt(countryCode)) || countryCode == 6163)) {
    if (asiaEmailGroup.includes(parseInt(countryCode))) {
      return regulator[reg].info.asiaEmail
    }

    return regulator[reg].info.email || ''
  }
}

export default {
  async actionGetInformation({ commit, state }) {
    return new Promise((resolve, reject) => {
      apiInformation().then(result => {
        if (result.data.domainWebSite) {
          commit('setDomainWebSite', result.data.domainWebSite)
          commit('setDomainWebSiteCn', result.data.domainWebSiteCn)
          commit('setContactEmail', result.data.contactEmail)
          commit('setAccountEmail', result.data.accountEmail)

          let newContactEmail = setContactEmail()

          if (newContactEmail) {
            commit('setContactEmail', newContactEmail)
            commit('setAccountEmail', newContactEmail)
          }
        } else {
          console.log('error')
        }
      })
    })
  },
}
