module.exports = {
  info: {
    url: 'www.infinox.co.uk',
    phone: '0800 208 8115',
    email: 'support@infinox.com',
    accountEmail: 'support@Infinoxmarkets.co.uk',
    liveChatVisible: false,
  },
  riskValue: '69.36%',
  menus: [
    {
      name: 'menu.dashboard',
      path: '/home',
      icon: 'home',
    },
    {
      name: 'menu.ibReport',
      path: '/ibreport',
      icon: 'ibreport',
    },
    {
      name: 'menu.rebateReport',
      path: '/rebatereport',
      icon: 'rebatereport',
    },
    {
      name: 'menu.tradeReport',
      path: '/tradereport',
      icon: 'tradereport',
    },
    {
      name: 'menu.iblevel',
      path: '/iblevel',
      icon: 'iblevel',
    },
    {
      name: 'menu.ibAccs',
      path: '/ibaccounts',
      icon: 'ibaccounts',
    },
    {
      name: 'menu.pendingAccs',
      path: '/pendingaccounts',
      icon: 'pendingaccounts',
    },
    {
      name: 'menu.leads',
      path: '/leads',
      icon: 'leads',
    },
    // {
    //   name: 'menu.unfundedAccs',
    //   path: '/unfundedaccounts',
    //   icon: 'unfundedaccounts',
    // },
    {
      name: 'menu.allRetailAccs',
      path: '/allretailaccounts',
      icon: 'allRetailAccounts',
    },
    {
      name: 'menu.payHistory',
      path: '/rebatePaymentHistory',
      icon: 'rebatePaymentHistory',
    },
    {
      name: 'menu.ibProfile',
      path: '/settings',
      icon: 'settings',
    },
    {
      name: 'menu.refLinks',
      path: '/referralLinks',
      icon: 'referralLinks',
    },
    {
      name: 'menu.contactUs',
      path: '/contactUs',
      icon: 'contactUs',
    },
  ],
  accountTypeMaps: {
    0: 'ALL',
    1: 'Standard STP',
    2: 'Raw ECN',
    3: 'Standard STP & Binary Options',
    4: 'Raw ECN & Binary Options',
    5: 'Binary Options Only',
    6: 'Swap free STP',
    7: 'Swap free ECN',
    8: 'PRO',
    10: 'PAMM',
    101: 'Standard STP', // MT5 Standard STP
    11: 'Net STP',
    12: 'Net ECN',
    13: 'Standard STP', // Hedge STP
    14: 'Raw ECN', // Hedge ECN
    15: 'Swap free STP', // MT5 Swap free STP
    16: 'Swap free ECN', // MT5 Swap free ECN
    17: 'PRO ECN', //MT5 PRO ECN
    21: 'Spread Betting STP',
  },
  withdrawSetup: {
    checkMethodMinAmount: false,
    cancelWithdraw: true,
  },
}
