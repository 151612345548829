export default {
  home: {
    RebateAccountNumber: 'Номер скидочного счета',
    totalCommission: 'Общая комиссия',
    APPLY_FOR_A_REBATE: 'ПОДАТЬ ЗАЯВЛЕНИЕ НА РИБЕЙТ',
    availableBalance: 'Доступные средства',
    WITHDRAW_REBATE: 'ВЫВЕСТИ СУММУ СКИДКИ СО СЧЕТА',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'ПЕРЕВЕСТИ СУММУ СКИДКИ НА ВАШ СЧЕТ',
    TOTAL_VOLUME: 'ОБЩИЙ ОБЪЕМ',
    NET_FUNDING: 'ЧИСТОЕ ФИНАНСИРОВАНИЕ',
    DEPOSITS: 'ПОПОЛНЕНИЯ СЧЕТА',
    WITHDRAW: 'ВЫВОД СРЕДСТВ СО СЧЕТА',
    OPEND_ACCOUNT: 'ОТКРЫТЫЙ СЧЕТ',
    PERFORMANCE: 'РЕЗУЛЬТАТИВНОСТЬ',
    MOST_TRADED_INSTRUMENTS: 'САМЫЕ ТОРГУЕМЫЕ ИНСТРУМЕНТЫ',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'ЛУЧШИЕ СЧЕТА В ЭТОМ МЕСЯЦЕ',
    RECENTLY_OPENED_ACCOUNTS: 'НЕДАВНО ОТКРЫТЫЕ СЧЕТА',
    ACCOUNT_MANAGER: 'МЕНЕДЖЕР СЧЕТА',
    Account: 'Счет',
    Name: 'Имя',
    Volume: 'Объем',
    Rebate: 'Скидка',
    Balance: 'Остаток средств',
    ContactClient: 'Связаться с клиентом',
    applyRebateSuccessTip:
      'Баяр хүргэе, бид таны хөнгөлөлтийн хүсэлтийг хүлээн авсан. / {amount} удахгүй хөнгөлөлтийн дансанд {account} шилжинэ. | Боловсруулсны дараа та хөрөнгөө буцааж авах эсвэл шилжүүлэх боломжтой болно.',
    HOVER:
      'Скидки предыдущего дня рассчитываются и обрабатываются ежедневно с 09:00 до 11:00 по Австралийскому восточному стандартному времени.',
    blacklist:
      'В данный момент этот счет не может принимать ребейты. Пожалуйста, свяжитесь с вашим менеджером по работе с клиентами.',
  },
  liveChat: { header: 'Онлайн чат' },
  contactUs: {
    tip: 'Если у вас есть вопросы или вы хотите получить дополнительную информацию, свяжитесь с нами по приведенным ниже контактным ссылкам. <br> Мы постараемся ответить на все ваши вопросы в течение 24 часов.',
  },
  fileUploader: {
    supportedFormat: 'Поддерживаемые типы файлов: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Поддерживаемые типы файлов: {files}',
    maxSize: 'Максимальный размер загружаемого файла: {maxSize} МБ',
    maxFileNum: 'Максимальное количество файлов: {maxFileNum}',
    failedFileExtension: 'Пожалуйста, загрузите файл правильного типа',
  },
  notification: {
    pendingIdentityProof: {
      header: 'ОБРАБОТКА ЗАЯВЛЕНИЯ НА ОТКРЫТИЕ СЧЕТА',
      id: 'Документ, удостоверяющий личность - ',
      poa: 'Адрес -',
      both: 'Документ, удостоверяющий личность и документ, подтверждающий адрес',
      content:
        'Ваш документ, подтверждающий {doc}, в настоящее время обрабатывается и результат обработки станет известен в ближайшее время.',
    },
  },
  identityProof: {
    home: {
      header: 'НЕПОЛНОЕ ЗАЯВЛЕНИЕ НА ОТКРЫТИЕ СЧЕТА',
      content:
        'Привет, {name}! Процесс открытия счета почти завершен, однако, вам необходимо предоставить дополнительную документацию для завершения процесса открытия счета.',
    },
    popup: {
      header:
        'Привет, {name}! Прежде чем процесс открытия реального счета будет завершен, вам необходимо загрузить еще один документ для проверки.',
      addressProofSpan:
        '<strong>\n      <b> Подтвердить адрес </b>\n      </strong>\n      <br />\n      <I>\n        Отправить <b> один </b> из документов ниже\n       <br /><br />\n      </I>\n      - Копия последнего счета за коммунальные услуги </br> (за последние 3 месяцев)\n      <br />\n      <b class = or "> или </b> <br />"\n      - Копия последней банковской выписки со счета </br> (за последние 3 месяцев)\n      <br />\n      <br />\n      <b> ВАЖНО ! </b> В документе ДОЛЖНО быть указано ваше полное имя и текущий адрес.\n      <br />',
    },
    reasonType: {
      4: 'Неполные документы',
      5: 'Нечеткие изображения документов',
      6: 'Необходимо загрузить цветную копию',
      8: 'Несоответствие имени лица, открывающего счет',
      9: 'Не указана дата выдачи документа о подтверждении адреса ',
    },
    reason: 'Вашу документацию не удалось проверить, <br> Причина: {reason}. Попробуйте еще раз.',
    success:
      'Спасибо! Мы получили ваши документы и в настоящее время обрабатываем их для проверки.',
  },
  ibAccounts: {
    ibAccount: 'Счет в IB',
    subIbs: 'Дополнительный счет в IB',
    tradingList: 'Торговый список',
    OpeningList: 'Список открытия',
    closeList: 'Список закрытия',
    tradingListColumn: {
      ticket: 'ID тикета',
      openTime: 'Время открытия',
      type: 'Тип',
      volume: 'Объем',
      item: 'Пункт',
      openPrice: 'Цена открытия',
      closeTime: 'Время закрытия',
      closePrice: 'Цена закрытия',
      commission: 'Комиссия',
      profit: 'Прибыль',
    },
  },
  withdraw: {
    default: {
      selectCC: 'Выбрать кредитную карту',
      selectBA: 'Выбрать банковский счет',
      anotherCC: 'Ввести номер кредитной карты вручную',
      anotherBA: 'Добавить банковский счет',
      remember: 'Запомнить мой счет',
    },
    transfer: {
      info: '{platform} возместит комиссию банка, взимаемую за <u> ОДИН </u> вывод средств со счета в месяц. За дополнительные снятия средств в течение месяца взимается комиссия банка в размере 20 единиц валюты вывода.',
      label: {
        upload:
          'Загрузите копию своей банковской выписки со счета (актуальной за последние 3 месяца)',
      },
      formValidation: { upload: 'Загрузите банковскую выписку' },
    },
    REBATE_WITHDRAW_REQUEST: 'ЗАПРОС НА ВЫВОД СУММЫ СКИДКИ',
    PleaseSelectDesiredWithdrawMethod: 'Выберите способ вывода средств со счета',
    BankName: 'Название банка',
    bankCode: 'Код банка',
    bankBranchCode: 'Код отделения',
    bankAccountCurrency: 'Валюта банковского счёта',
    BankAccountsName: 'Имя банковского счета',
    FasaPayAccountName: 'Имя счета FasaPay',
    AustralianBankName: 'Наименование австралийского банка',
    BankAccountNumber: 'Номер банковского счета',
    FasaPayAccountNumber: 'Номер счета FasaPay',
    BankAccountBeneficiary: 'Банковский счет получателя',
    BSB: 'Код BSB',
    SortCode: 'Идентификационный код банка',
    skrillEmail: 'Адрес электронной почты Skrill',
    NetellerEmail: 'Адрес электронной почты Neteller',
    PerfectMoneyEmail: 'Адрес электронной почты Perfect Money',
    BitwalletEmail: 'Адрес электронной почты Bitwallet',
    SticpayEmail: 'Адрес электронной почты SticPay',
    BitcoinEmail: 'Адрес биткойн-кошелька',
    USDTEmail: 'Адрес кошелька Tether',
    BankBranch: 'Филиал банка',
    Province: 'Область/регион',
    City: 'Город',
    BankAddress: 'Адрес банка',
    AccountNumberIBAN: 'Номер счета/IBAN',
    BankBeneficiaryName: 'Название банка получателя',
    BankAccountBeneficiaryName: 'Имя банковского счета получателя',
    AccountHoldersAddress: 'Адрес владельца счета',
    SelectWithdrawCard: 'Выбрать карту для вывода средств со счета',
    Swift: 'SWIFT',
    SwiftAud: 'SWIFT (счета не в австралийских долларах)',
    ABA_SortCodeABA: 'ABA/Номер отделения банка',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: 'Важные примечания',
    ifscCode: 'Код индийской финансовой системы IFSC',
    uploadDocument:
      'Загрузите копию своей банковской выписки со счета (актуальной за последние 3 месяца)',
    YourPaymentGreaterThanAvailableBalance: 'Сумма платежа превышает доступный баланс на счете',
    integer: 'Можно вводить только целые числа',
    CannotUseMoreThanTwoDecimalValues:
      'Вы не можете использовать числа и отрицательные числа с более чем двумя десятичными знаками после запятой.',
    YourPaymentIsBeingProcessed: 'Ваше заявление на вывод средств отправлено',
    Itcannotbe0: 'Значение не может быть равно 0',
    noCardWarn:
      'У вас нет карты для вывода средств со счета, сначала привяжите свою карту к счету в разделе «Сведения о выводе средств со счета».',
    amtLarger:
      'Минимальная сумма для вывода средств со счета - {currencyType}{minLimit}. Остатки средств на счете менее 30 должны быть выведены полностью.',
    blackList:
      'К сожалению, в настоящее время вы не можете отправить запрос на вывод средств. Обратитесь по адресу {supportEmail} для получения дополнительной информации.',
    NetellerChargeAlert:
      '«Снятие средств в Neteller ограничено 500 000 долларов за транзакцию, при этом применяется комиссия в размере 2% (не более 30 долларов США)».',
    FasaPayChargeAlert:
      'Система FasaPay удерживает комиссию за операцию в размере 0,5% за каждый вывод средств со счета.',
    SkrillChargeAlert:
      'Система Skrill удерживает комиссию за операцию в размере 1% за каждый вывод средств со счета.',
    cryptoAlert:
      'Пожалуйста, обратите внимание, что мы не можем вносить или выводить средства через BUSDT, пожалуйста, убедитесь, что адрес и криптовалюта соответствуют цепочке и валюте, которые мы принимаем, или вы можете потерять средства.',
    status: {
      withdrawalSubmitted: 'Отправлено',
      withdrawalCanceled: 'Отменено',
      withdrawalAuditing: 'Обработка',
      withdrawalRejected: 'Отклонено',
      withdrawalAccepted: 'Обработка',
      withdrawalPayFailed: 'Не удалось',
      withdrawalSuccess: 'Обработано',
      withdrawalFailed: 'Не удалось',
      withdrawalPending: 'В ожидании',
      withdrawalPartialFailed: 'Не удалось',
      transferSubmitted: 'Обработка',
      transferSuccess: 'Обработано',
      transferRejected: 'Отклонено',
      transferFailed: 'Не удалось',
      transferProcessing: 'Обработка',
    },
    formErrorMsg: {
      bankName: 'Необходимо указать название банка',
      bsb: 'Необходимо указать код BSB',
      sortCode: 'Необходимо указать код Sort Code',
      beneName: 'Необходимо указать название банка-получателя',
      bankCodeReq: 'Требуется код банка',
      bankBranchCodeReq: 'Требуется код отделения',
      accNum: 'Необходимо указать номер банковского счета',
      swift: 'Необходимо указать SWIFT',
      bankAddress: 'Необходимо указать адрес банка',
      holderAddress: 'Необходимо указать адрес владельца',
      amt: 'Необходимо указать сумму',
      firstSixDigits: 'Введите первые 6 цифр номера карты',
      lastFourDigits: 'Введите последние 4 цифры номера карты',
      branchName: 'Необходимо указать наименование филиала',
      email: 'Необходимо указать адрес электронной почты',
      accName: 'Необходимо указать имя счета',
      province: 'Необходимо указать область местонахождения банка',
      city: 'Необходимо указать город местонахождения банка',
      bankCard: 'Выберите карту',
      emailFormat: 'Введите правильный адрес электронной почты',
      amt0: 'Сумма не может быть равна 0',
      ifscCode: 'Необходимо указать код индийской финансовой системы IFSC',
      bitcoin: 'Необходимо указать адрес биткойн-кошелька',
      bitcoinAddressLength: 'Адрес биткоин-кошелька должен содержать от 26 до 42 символов',
      usdt: 'Необходимо указать адрес кошелька USDT',
      dynamicReq: '{fieldname} обязательное поле',
      uploadDocument: 'Загрузите банковскую выписку',
    },
    note: "Ввиду недавних изменений наш банк может быть не в состоянии обработать международные банковские переводы для клиентов, проживающих в некоторых странах.<br/>\n      Если вам необходимо вывести средства с помощью международных банковских переводов, свяжитесь со службой поддержки клиентов по телефону +44 2080 363 883 или по <a href='mailto:{mail}'>{mail}</a>.<br/>\n      Приносим извинения за неудобства.",
    minAmountWarn:
      'Указанная сумма вывода меньше минимального порога для данного платежного шлюза. Пожалуйста, пополните баланс до минимального или сверх минимума, прежде чем отправлять запрос на вывод средств. Более подробную информацию можно найти <a href="https://{regulatorUrl}/clients/accounts/withdraw/">здесь</a>.',
  },
  rebate: { status: { processed: 'Обработано', processing: 'Обработка', rejected: 'Отклонено' } },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'ЗАПРОС НА ПЕРЕВОД РИБЕЙТА',
    transferAmountIsGreaterThanAvailableBalance:
      'Сумма вашего перевода превышает доступный баланс средств на счете',
    yourRebateTransferHasBeenProcessed: 'Заявление на перевод рибейта отправлено',
    PleaseEnterTransferInformation: 'Введите точную информацию о переводе',
    tradingAccount: 'Торговый счет',
    rebateAccount: 'Скидочный счет',
    account: 'Счет',
    blackList:
      ' В данной учетной записи зарещено переводить средства между счетами. Пожалуйста, напишите нам н {supportEmail}',
  },
  transferHistory: {
    payStatus: {
      processing: 'Обработка',
      paid: 'Оплачено',
      unpaid: 'Неоплачено',
      rejected: 'Отклонено',
    },
  },
  common: {
    liveChat: { desc: '<li> <p> Нужна помощь? </p> <span> Онлайн чат </span> </li>' },
    lang: {
      english: 'английский',
      chinese: 'Китайский',
      french: 'Французский',
      thai: 'Тайский',
      german: 'Немецкий',
      spanish: 'Испанский',
      malay: 'Малайский',
      viet: 'Вьетнамский',
      indo: 'Индонезийский',
      arabic: 'Арабский',
      japanese: 'Японский',
    },
    button: {
      confirm: 'ПОДТВЕРДИТЬ',
      cancel: 'ОТМЕНИТЬ',
      identityProof: 'ЗАПОЛНИТЬ ЗАЯВЛЕНИЕ',
      upload: 'Загрузка',
      selectFile: 'Выбрать файл',
      fundnow: 'ПОПОЛНИТЬ СЧЕТ ПРЯМО СЕЙЧАС',
    },
    withdrawChannel: {
      hongkongbanktransfer: 'Местный Банковский перевод в Гонконге',
      banktransfer: 'Банковский перевод',
      aus: 'Банковский перевод - Австралия',
      int: 'Банковский перевод - Международный',
      chn: 'Банковский перевод - Китай',
      uk: 'Банковский перевод - Великобритания',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      fasapay: 'FasaPay',
      thailandinstantbankwiretransfer: 'Система быстрых банковских переводов в Таиланде',
      malaysiainstantbankwiretransfer: 'Система быстрых банковских переводов в Малайзии',
      banktransferbpaypolipay: 'Банковский перевод/BPay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Криптовалюта - Биткойн',
      cryptocurrencyusdt: 'Криптовалюта - USDT',
      nigeriainstantbankwiretransfer: 'Система быстрых банковских переводов в Нигерии',
      vietnaminstantbankwiretransfer: 'Система быстрых банковских переводов во Вьетнаме',
      indonesiainstantbankwiretransfer: 'Система быстрых банковских переводов в Индонезии',
      philippinesinstantbankwiretransfer: 'Мгновенный банковский перевод на Филиппинах',
      southafricainstantbankwiretransfer: 'Система быстрых банковских переводов в ЮАР',
      indiainstantbanktransfer: 'Система быстрых банковских переводов в Индии',
      laosinstantbankwiretransfer: 'Мгновенный банковский перевод в Лаосе',
      mexicobanktransfer: 'Система быстрых банковских переводов в Мексика',
      brazilbanktransfer: 'Система быстрых банковских переводов в Бразилии',
      internetbankingmexico: 'Интернет-банк (Мексика)',
      internetbankingbrazil: 'Интернет-банк (Бразилия)',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      transfer: 'Перевод на счета',
    },
    country: {
      international: 'Международный',
      australia: 'Австралия',
      uk: 'Объединенное Королевство',
    },
    field: { country_region: 'Страна/регион', yes: 'Да ', no: 'Нет' },
    keys: {
      SUBMIT: 'ОТПРАВИТЬ',
      FROM: 'ОТ КОГО/ОТКУДА',
      UPDATE: 'ОБНОВИТЬ',
      STATUS: 'СТАТУС',
      TO: 'КОМУ/КУДА',
      DATE: 'ДАТА',
      pleaseSelect: 'Выберите',
      amount: 'Сумма',
      actualAmount: '  Фактические выплаты = ',
      rebateAccount: 'Скидочный счет',
      comingSoon: 'Уже скоро',
      tradingHistory: 'ИСТОРИЯ ТОРГОВЫХ ОПЕРАЦИЙ',
      noData: 'Нет данных',
      NAME: 'ИМЯ',
      EMAIL: 'ЭЛЕКТРОННАЯ ПОЧТА',
      ACCTYPE: 'ТИП СЧЕТА',
      PLATFORM: 'ПЛАТФОРМА',
      BASECURRENCY: 'ОСНОВНАЯ ВАЛЮТА',
      DATERANGE: 'ДИАПАЗОН ДАТ',
      DEST: 'ПУНКТ НАЗНАЧЕНИЯ',
      ACCNUM: 'НОМЕР СЧЕТА',
      BALANCE: 'БАЛАНС',
      NONE: 'Нет',
      ALL: 'Все',
      PROFITLOST: 'Упущенная прибыль',
      MARGINLEVEL: 'Уровень маржи (%)',
      ACCOUNTEQUITY: 'Средства на счете',
      CREDIT: 'Кредит',
    },
    dateRange: {
      today: 'Сегодня',
      yesterday: 'Вчера',
      lastSevenDays: 'За последние 7 дней',
      lastThirtyDays: 'За последние 30 дней',
      weekToDate: 'За неделю до сегодняшнего дня',
      monthToDate: 'С начала месяца по сегодняшний день',
      quarterToDate: 'За квартал до сегодняшнего дня',
      previousWeek: 'За прошлую неделю',
      previousMonth: 'За прошлый месяц',
      previousQuarter: 'За предыдущий квартал',
    },
    products: {
      forex: 'ВАЛЮТНЫЙ РЫНОК',
      commodities: 'СЫРЬЕВЫЕ ТОВАРЫ',
      indices: 'ИНДЕКСЫ',
      crypto: 'КРИПТОВАЛЮТЫ',
    },
  },
  report: {
    rebate: {
      column: {
        name: 'ИМЯ',
        accountNumber: 'НОМЕР СЧЕТА',
        accountType: 'ТИП СЧЕТА',
        volume: 'Объем',
        rebate: 'Скидка',
        totalRebate: 'ПОЛНАЯ СУММА СКИДКИ',
        date: 'ДАТА',
        instrument: 'ИНСТРУМЕНТ',
        notionalvalue: 'УСЛОВНАЯ ЦЕННОСТЬ',
      },
      dropdown: { allInstruments: 'Все инструменты', all: 'Все', instruments: 'Инструменты' },
      others: {
        rebate: 'СКИДКА',
        instrumentsTraded: 'ТОРГОВЫЕ ИНСТРУМЕНТЫ',
        lots: 'Лоты',
        totalRebate: 'Общая скидка',
        rebates: 'Скидки',
      },
    },
  },
  ibReport: { openedAccs: 'ОТКРЫТЫЕ СЧЕТА', funding: 'ФИНАНСИРОВАНИЕ', account: 'СЧЕТ' },
  lead: { demoAccounts: 'ДЕМОНСТРАЦИОННЫЕ СЧЕТА' },
  pendingAccounts: {
    errorDoc:
      'Заявители ниже заполнили онлайн заявление, но еще не предоставили документ, удостоверяющий личность (ID), чтобы завершить оформление своего счета.',
    acceptedDocType: 'К документам, удостоверяющим личность, относятся:',
    primaryDoc: 'Основной документ, удостоверяющий личность',
    passport: 'Копия действующего международного паспорта (включая страницу с подписью)',
    id: 'Копия удостоверения личности, выданного государственным органом',
    dl: 'Копия действующего водительского удостоверения',
    address: 'Вторичный документ, удостоверяющий личность',
    addressList: [
      'Копия счета за коммунальные услуги. Например, счет за газ или электричество с указанием вашего имени и адреса проживания (за последние 3 месяца).',
      'Копия выписки по кредитной карте или банковской выписки со счета (не распечатка из Интернета)',
    ],
    tip: 'Просим заявителей предоставить копию ОДНОГО из первичных и вторичных документов, удостоверяющих личность, в',
  },
  rebatePaymentHistory: {
    rebateHistory: 'Скидки',
    withdrawHistory: 'ВЫВОД СРЕДСТВ СО СЧЕТА',
    transferHistory: 'ПЕРЕВОД СРЕДСТВ',
  },
  referralLinks: {
    downloadPage: 'Скачать приложение',
    campLang: 'Язык рекламной акции',
    cpy: 'КОПИРОВАТЬ',
    alreadyCPY: 'Скопировано!',
    liveAcc: 'Реальный счет',
    demoAcc: 'Демонстрационный счет',
    homePage: 'Домашняя страница',
    promotion: 'Ссылка для продвижения события',
    hintTitle: 'Что такое ссылка для продвижения события?',
    hintMessage:
      'Ссылка для продвижения события - это новый метод продвижения для ПБ. Эта ссылка направляет пользователей на страницу бонусного события и завершает регистрацию. Процент успешной регистрации пользователей может быть эффективно улучшен благодаря совместному использованию ссылки.',
    hintShortLink: 'Поделиться с помощью короткой ссылки',
    hintShortLinkTitle: 'Что такое короткая ссылка?',
    hintShortLinkMessage:
      'Короткая ссылка - это сгенерированная особым образом ссылка, которая может лучше защитить вашу ссылку, которой вы хотите поделиться, от изменения другими, тем самым защищая ваши права на развитие ПБ.',
    customButton: 'настроить',
    copyButton: 'копировать',
    customInviteCodeHeader: 'пользовательский код приглашения',
    currentCode: 'текущий пригласительный код',
    newCode: 'новый пригласительный код',
    notice:
      'Примечание. Код приглашения имеет не менее 6 параметров. Можно вводить буквы, цифры, неспециальные символы',
    inviteCodeFormatError: 'Неверный формат кода приглашения. Измените его еще раз.',
    inviteCodeAlreadyExist:
      'Код приглашения был предварительно зарегистрирован другими пользователями, пожалуйста, введите его повторно',
    pleaseEnter: 'пожалуйста, введите',
    inviteCodeHeader: 'Пригласительный код акции',
    inviteCode: 'Код приглашения',
  },
  settings: { info: 'ИНФОРМАЦИЯ', reg: 'ЗАРЕГИСТРИРОВАНО' },
  unfundedAcc: {
    neverFundedAccs: 'НИКОГДА НЕ ПОПОЛНЯВШИЕСЯ СЧЕТА',
    prevFundedZero: 'ПОПОЛНЯВШИЕСЯ В ПРОШЛОМ (НУЛЕВОЙ ОСТАТОК)',
    accBalance: 'ОСТАТОК НА СЧЕТЕ',
  },
  multiib: { level: 'УРОВЕНЬ {level}', noib: 'В этом счете нет суб-IB' },
  AWCDialog: { optIn: 'УЧАСТВОВАТЬ', noThanks: 'НЕТ, СПАСИБО' },
  header: { bckToCP: 'НАЗАД В ПОРТАЛ КЛИЕНТА', logout: 'ВЫЙТИ ИЗ СИСТЕМЫ' },
  menu: {
    dashboard: 'ИНФОРМАЦИОННАЯ ПАНЕЛЬ',
    ibReport: 'ОТЧЕТ IB',
    rebateReport: 'ОТЧЕТ О СКИДКЕ',
    iblevel: 'МНОГОУРОВНЕВЫЙ IB',
    ibAccs: 'IB СЧЕТА',
    leads: 'ОРИЕНТИРЫ',
    pendingAccs: 'ОЖИДАЮЩИЕ СЧЕТА',
    unfundedAccs: 'ПУСТЫЕ СЧЕТА',
    payHistory: 'ИСТОРИЯ ТОРГОВЫХ ОПЕРАЦИЙ',
    ibProfile: 'ПРОФИЛЬ IB ',
    refLinks: 'РЕФЕРАЛЬНЫЕ ССЫЛКИ',
    contactUs: 'СВЯЖИТЕСЬ С НАМИ',
    switchCP: 'ПЕРЕЙТИ НА КЛИЕНТСКИЙ ПОРТАЛ',
  },
  footer: {
    riskWarn:
      'Предупреждение о рисках: торговля на валютном рынке и рынке контрактов на разницу связана со значительным риском и может привести к потере всех вложенных средств.\n    Торговля продуктами с кредитным плечом  подходит не для всех инвесторов. Перед торговлей проверьте\n    свой уровень опыта, инвестиционные цели и, при необходимости обратитесь, за независимой финансовой консультацией.\n    Ознакомьтесь с нормативными документами на нашем веб-сайте и убедитесь, что вы полностью понимаете риски, прежде чем принимать какие-либо\n    торговые решения.',
  },
  responseMsg: {
    410: 'Ошибка проверки параметров',
    411: 'Неверный пароль',
    420: 'Пользователь не существует',
    421: 'Отказано в сбросе пароля',
    490: 'Не отправляйте информацию о дублирующей карте',
    500: 'Произошла ошибка.',
    501: 'Ошибка приложения. Повторите попытку позже.',
    505: 'Не удалось загрузить файл',
    510: 'Неверный SMS-код',
    511: 'Невозможно отправить SMS-код на ваш номер телефона',
    520: 'Ошибка входа в систему. Попробуйте еще раз.',
    521: 'Пользователь уже вошел в систему на этом устройстве!',
    522: 'Войдите в систему еще раз',
    523: 'Войдите в систему еще раз',
    524: 'К сожалению, ваш адрес электронной почты не распознан',
    525: 'Неверное имя пользователя или пароль',
    526: 'Введены неправильные проверочные данные',
    527: 'Пользователь не является ПБ',
    528: 'Пользователь не существует',
    529: 'Неверное имя пользователя или пароль',
    530: 'Неверный код',
    531: 'Неверный код',
    540: 'Ваш торговый счет не найден',
    541: 'Не удается найти скидочный счет',
    542: 'Вам не разрешено открывать дополнительный счет',
    544: 'Вам не разрешается подавать заявку на дополнительный счет до тех пор, пока не будет подтверждено ваше удостоверение личности',
    550: 'Неверный номер счета',
    551: 'У вас недостаточно средств на счете',
    554: 'Запрошенная сумма равна нулю или имеет недействительное значение',
    562: 'Нам не удалось обработать ваш платеж, попробуйте еще раз. Если проблема не исчезнет, ​​свяжитесь с нами в чате или по электронной почте {email}',
    564: 'Страна не соответствует платежному каналу',
    565: 'Неверный код банка',
    566: 'Такой способ оплаты не найден',
    567: 'К сожалению, этот способ оплаты в настоящее время недоступен. Приносим извинения за причиненные неудобства.',
    581: 'Вы успешно приняли участие в промо-акции. Пожалуйста, обратите внимание, что для вывода средств со счета необходимо его пополнить.',
    584: 'У вас нет применимых скидок',
    590: 'Не удается найти счет для вывода средств',
    591: 'Не удается найти счет для ввода средств',
    593: 'Мы не можем обработать перевод с выбранного счета из-за того, что на вашем счете имеются кредитные средства.',
    594: 'Перевод средств с этого счета запрещен',
    session_timeout: 'Тайм-аут сеанса, перенаправление на страницу входа в систему.',
  },
};
