export default {
  home: {
    RebateAccountNumber: 'Nombor akaun rebat',
    totalCommission: 'jumlah komisen',
    APPLY_FOR_A_REBATE: 'MOHON REBAT',
    availableBalance: 'Baki yang ada',
    WITHDRAW_REBATE: 'PENGELUARAN REBAT',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: 'PINDAHKAN REBAT KE DALAM AKAUN ANDA',
    TOTAL_VOLUME: 'JUMLAH KESELURUHAN',
    NET_FUNDING: 'PEMBIAYAAN BERSIH',
    DEPOSITS: 'DEPOSIT',
    WITHDRAW: 'PENGELUARAN',
    OPEND_ACCOUNT: 'AKAUN DIBUKA',
    PERFORMANCE: 'PRESTASI',
    MOST_TRADED_INSTRUMENTS: 'INSTRUMEN PALING BANYAK DIPERDAGANGKAN',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: 'AKAUN BERPRESTASI TERBAIK UNTUK BULAN INI',
    RECENTLY_OPENED_ACCOUNTS: 'AKAUN YANG BARU DIBUKA',
    ACCOUNT_MANAGER: 'PENGURUS AKAUN',
    Account: 'Akaun',
    Name: 'Nama',
    Volume: 'Jumlah',
    Rebate: 'Rebat',
    Balance: 'Baki',
    ContactClient: 'Hubungi Pelanggan',
    applyRebateSuccessTip:
      'Tahniah, kami telah menerima permintaan rebat anda. | {amount} akan dipindahkan ke Akaun rebat {account} tidak lama lagi. | Setelah diproses, anda akan dapat mengeluarkan atau memindahkan dana anda.',
    HOVER: 'Rebat hari sebelumnya dikira dan diproses di antara 09:00 - 11:00 AEST setiap hari.',
  },
  rate: {
    rate: 'Kadar Pertukaran({oldCurrency}/{newCurrency}): ',
    result: {
      rateError: 'Pengesanan kadar {oldCurrency} hingga {newCurrency} gagal.',
      channelError: 'Pengesanan saluran pembayaran gagal.',
    },
  },
  liveChat: { header: 'Live Chat' },
  contactUs: {
    tip: 'Sekiranya anda mempunyai pertanyaan atau ingin maklumat lebih lanjut, sila hubungi kami melalui salah satu maklumat hubungan di bawah.<br> Kami akan berusaha untuk menjawab semua pertanyaan dalam masa 24 jam.',
  },
  notification: {
    pendingIdentityProof: {
      header: 'PEMPROSESAN PERMOHONAN AKAUN',
      id: 'ID ialah',
      poa: 'Alamat ialah',
      both: 'ID dan Bukti alamat ialah',
      content: 'Bukti {doc} anda sedang diproses dan akan dimuktamadkan tidak lama lagi.',
    },
  },
  identityProof: {
    home: {
      header: 'PERMOHONAN AKAUN TIDAK LENGKAP',
      content:
        'Hai {name}, permohonan akaun anda hampir selesai, namun anda masih perlu menyerahkan dokumentasi tambahan untuk memuktamadkan akaun anda.',
    },
    popup: {
      header: `Hai {name}, sebelum akaun langsung anda boleh dimuktamadkan, anda dikehendaki memuat naik dokumen berikut untuk pengesahan.`,
      addressProofSpan: `<strong>
            <b>Confirm Address</b>
            </strong>
            <br />
            <I>
              Submit <b>one</b> of the documents below
              <br /><br />
            </I>
            - Salinan Bil Utiliti </br>terkini (tidak melebihi 3 bulan)
            <br />
            <b class="or">or</b><br />
            - Salinan Penyata Bank terkini </br> (tidak melebihi 3 bulan)
            <br />
            <br />
            <b>PENTING !</b> Nama pada dokumen MESTI menunjukkan dengan jelas nama penuh dan alamat semasa anda.
            <br />`,
    },
    reasonType: {
      4: 'Dokumen Tidak Lengkap',
      5: 'Dokumen Tidak Jelas',
      6: 'Salinan Berwarna Diperlukan',
      8: 'Nama Pembukaan Akaun Tidak Sepadan',
      9: 'Tarikh Keluaran Bukti Alamat Tidak Layak',
    },
    reason: 'Dokumentasi anda tidak dapat disahkan,<br> Sebab',
    success: 'Terima kasih. Kami telah berjaya menerima dokumen anda dan sedang memprosesnya untuk pengesahan.',
  },
  fileUploader: {
    supportedFormat: 'Jenis fail yang disokong: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Saiz fail muat naik maksimum: {maxSize}MB',
    maxFileNum: 'Nombor fail maksimum:  {maxFileNum}',
    failedFileExtension: 'Sila muat naik jenis fail yang betul',
  },
  ibAccounts: {
    ibAccount: 'Akaun IB',
    subIbs: 'Akaun Sub IB',
    tradingList: 'Senarai Dagangan',
    OpeningList: 'Senarai Pembukaan',
    closeList: 'Senarai Penutupan',
    tradingListColumn: {
      ticket: 'IDTiket',
      openTime: 'Waktu Buka',
      type: 'Jenis',
      volume: 'Jumlah',
      item: 'Barangan',
      openPrice: 'Harga Buka',
      closeTime: 'Masa Tutup',
      closePrice: 'Harga Tutup',
      commission: 'Komisen',
      profit: 'Untung',
    },
  },
  withdraw: {
    warning: {
      desc: 'Sila ambil perhatian tentang perkara berikut:',
      desc1: 'Pengguna akhir akan dikenakan yuran pindahan bank, yang biasanya berkisar antara 135 hingga 250 JPY.',
      desc2:
        'Permintaan pengeluaran yang dibuat selepas jam 2 petang mungkin ditangguhkan atau diproses pada hari berikutnya.',
    },
    default: {
      selectCC: 'Pilih Kad Kredit',
      selectBA: 'Pilih Akaun Bank',
      anotherCC: 'Masukkan kad kredit secara manual',
      anotherBA: 'Tambah akaun bank',
      remember: 'Ingat akaun saya',
    },
    transfer: {
      info: '{platform} akan membayar balik yuran bank yang dikenakan untuk pengeluaran <u>ONE</u> setiap bulan. Pengeluaran tambahan dalam bulan tersebut akan dikenakan bayaran bank sebanyak 20 unit mata wang pengeluaran anda.',
      note: 'Untuk memproses permintaan pengeluaran anda, sila berikan pasport anda, untuk akaun syarikat, sila berikan invois kepada {email}',
      label: {
        upload: 'Sila muat naik salinan penyata bank anda (semasa hingga 3 bulan lepas)',
      },
      formValidation: {
        upload: 'Sila muat naik penyata bank',
      },
    },
    REBATE_WITHDRAW_REQUEST: 'PERMINTAAN PENGELUARAN REBAT',
    PleaseSelectDesiredWithdrawMethod: 'Sila pilih kaedah pengeluaran yang dikehendaki',
    bankCode: 'Kod bank',
    bankBranchCode: 'Kod Cawangan',
    bankAccountCurrency: 'Mata Wang Akaun Bank',
    BankName: 'Nama bank',
    BankAccountsName: 'Nama Akaun Bank',
    FasaPayAccountName: 'Nama Akaun FasaPay',
    AustralianBankName: 'Nama Bank Australia',
    BankAccountNumber: 'Nombor akaun bank',
    FasaPayAccountNumber: 'Nombor Akaun FasaPay',
    BankAccountBeneficiary: 'Penerima Akaun Bank',
    BSB: 'BSB',
    SortCode: 'Kod Sort',
    skrillEmail: 'E-mel Skrill',
    NetellerEmail: 'E-mel Neteller',
    PerfectMoneyEmail: 'E-mel Perfect Money ',
    BitwalletEmail: 'E-mel Bitwallet',
    SticpayEmail: 'E-mel SticPay',
    BitcoinEmail: 'Alamat Dompet Bitcoin',
    USDTEmail: 'Alamat Dompet Tether',
    USDTChain: 'Rantaian',
    BankAccountName: 'Nama Akaun Bank',
    BankBranch: 'Cawangan Bank (nombor cawangan 3 digit)',
    BankAccountType: 'Jenis Akaun Bank',
    Province: 'Wilayah',
    City: 'Bandar',
    BankAddress: 'Alamat bank',
    AccountNumberIBAN: 'Nombor Akaun/IBAN',
    BankBeneficiaryName: 'Nama Penerima Bank',
    BankAccountBeneficiaryName: 'Nama Penerima Akaun Bank',
    AccountHoldersAddress: 'Alamat Pemegang Akaun',
    SelectWithdrawCard: 'Pilih Kad Pengeluaran Anda',
    Swift: 'Swift',
    SwiftAud: 'Swift(Akaun Bukan AUD)',
    ABA_SortCodeABA: 'Kod ABA/Sort',
    DocumentType: 'Jenis dokumen',
    DocumentDetails: 'Butiran Dokumen',
    PixKeyType: 'Jenis Kunci PIX',
    PixKey: 'Kunci PIX',
    AccountType: 'Jenis Akaun',
    AccountDetails: 'Butiran Akaun',
    ImportantNotes: 'Nota penting',
    ifscCode: 'Kod IFSC',
    uploadDocument: 'Sila muat naik salinan penyata bank anda (semasa hingga 3 bulan lepas)',
    name: 'Nama',
    phone: 'Telefon',
    address: 'Alamat',
    email: 'E-mel',
    vpa: 'VPA',
    YourPaymentGreaterThanAvailableBalance: 'Bayaran anda lebih besar daripada baki yang ada',
    integer: 'Hanya input integer disokong',
    CannotUseMoreThanTwoDecimalValues:
      'Anda tidak boleh menggunakan Nombor dan Nombor negatif dengan lebih daripada dua tempat perpuluhan',
    YourPaymentIsBeingProcessed: 'Permohonan pengeluaran anda telah diserahkan',
    Itcannotbe0: 'Ia tidak boleh 0',
    noCardWarn:
      'Anda tidak mempunyai kad yang tersedia untuk membuat pengeluaran, sila pautkan kad anda dalam Butiran Pengeluaran terlebih dahulu',
    amtLarger: 'Amaun pengeluaran minimum ialah ${minLimit}. Baki di bawah 30 mesti dikeluarkan sepenuhnya',
    blackList: `Malangnya anda kini tidak dapat mengemukakan permintaan pengeluaran. Sila hubungi {supportEmail} untuk maklumat lanjut`,
    NetellerChargeAlert:
      'Pengeluaran Neteller dihadkan kepada $500,000 setiap transaksi dan yuran 2% (terhad pada $30) dikenakan.',
    FasaPayChargeAlert: 'FasaPay mengenakan 0.5% yuran transaksi untuk setiap pengeluaran.',
    SkrillChargeAlert: 'Skrill mengenakan 1% yuran transaksi untuk setiap pengeluaran.',
    cryptoAlert:
      'Sila ambil perhatian bahawa kami tidak dapat mendeposit atau mengeluarkan melalui BUSDT, sila pastikan alamat dan mata wang Kripto sepadan dengan rantaian dan mata wang yang kami terima atau anda mungkin kehilangan dana.',
    usdtNote:
      'Yuran gas semasa ERC20 adalah jauh lebih tinggi daripada biasa dan akibatnya mungkin terdapat kelewatan yang panjang dalam pemprosesan transaksi anda. Sebagai alternatif, anda boleh mengeluarkan dana melalui TRC20 dengan memberikan alamat dompet yang sepadan, atau anda boleh memilih untuk membayar yuran gas untuk mempercepatkan pengeluaran ERC20 anda.',
    status: {
      withdrawalSubmitted: 'Diserahkan',
      withdrawalCanceled: 'Dibatalkan',
      withdrawalAuditing: 'Memproses',
      withdrawalRejected: 'Ditolak',
      withdrawalAccepted: 'Memproses',
      withdrawalPayFailed: 'Gagal',
      withdrawalSuccess: 'Telah di proses',
      withdrawalFailed: 'Gagal',
      withdrawalPending: 'Masih belum selesai',
      withdrawalPartialFailed: 'Gagal',
      transferSubmitted: 'Memproses',
      transferSuccess: 'Telah di proses',
      transferRejected: 'Ditolak',
      transferFailed: 'Gagal',
      transferProcessing: 'Memproses',
    },
    formErrorMsg: {
      bankCodeReq: 'Kod Bank diperlukan',
      bankBranchCodeReq: 'Kod cawangan diperlukan',
      bankName: 'Nama Bank diperlukan',
      bsb: 'BSB diperlukan',
      sortCode: 'Kod Sort diperlukan',
      beneName: 'Nama Penerima Bank diperlukan',
      accNum: 'Nombor Akaun Bank diperlukan',
      swift: 'Swift diperlukan',
      bankAddress: 'Alamat bank diperlukan',
      holderAddress: 'Alamat pemegang diperlukan',
      amt: 'Jumlah diperlukan',
      firstSixDigits: 'Sila Masukkan 6 digit pertama',
      lastFourDigits: 'Sila Masukkan 4 digit Terakhir',
      branchName: 'Nama cawangan diperlukan',
      email: 'E-mel diperlukan',
      accName: 'Nama akaun diperlukan',
      province: 'Wilayah bank diperlukan',
      city: 'Bandar Bank diperlukan',
      bankCard: 'Sila pilih kad',
      emailFormat: 'Sila masukkan alamat e-mel yang betul',
      amt0: 'Jumlah tidak boleh 0',
      ifscCode: 'Kod IFSC diperlukan',
      bitcoin: 'Alamat Dompet Bitcoin diperlukan',
      bitcoinFormat: 'Format alamat dompet Bitcoin salah',
      bitcoinAddressLength: 'Alamat dompet Bitcoin mestilah antara 26 dan 42 aksara',
      usdt: 'Alamat Dompet USDT diperlukan',
      chain: 'Rantaian diperlukan',
      walletFormatIncorrect: 'Format alamat dompet {type} salah',
      dynamicReq: '{fieldname} diperlukan',
      uploadDocument: 'Sila muat naik penyata bank',
    },
    note: `Disebabkan oleh perubahan terkini, bank kami mungkin tidak dapat memproses pindahan bank antarabangsa untuk pelanggan yang berdaftar di beberapa negara.< br /> Jika anda mesti mengeluarkan melalui pindahan bank antarabangsa, sila hubungi Sokongan Pelanggan kami di + 44 2080 363 883 atau di < a a href = 'mailto:{mail}' > { mail }</ >.< br />
        Kami memohon maaf atas kesulitan.`,
    minAmountWarn:
      "Jumlah pengeluaran yang dipohon adalah kurang daripada minimum untuk gerbang pembayaran yang dipohon. Sila tambah nilai baki akaun anda kepada/lebih minimum sebelum menghantar permintaan pengeluaran. Maklumat lanjut boleh didapati <a href='https://{regulatorUrl}/clients/accounts/withdraw/'>sini</a>.",
    negativeBalance:
      'Memandangkan akaun anda yang lain {accounts} pada masa ini mempunyai baki negatif, kami tidak boleh menerima permintaan pengeluaran anda. Sila tambah nilai baki negatif atau hubungi pengurus akaun anda untuk menghapus kira baki akaun anda.',
  },
  rebate: {
    status: {
      processed: 'Telah di proses',
      processing: 'Memproses',
      rejected: 'Ditolak',
    },
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: 'PERMINTAAN PEMINDAHAN REBAT',
    transferAmountIsGreaterThanAvailableBalance: 'Jumlah pemindahan anda lebih besar daripada baki yang ada',
    yourRebateTransferHasBeenProcessed: 'Pemindahan rebat anda telah dihantar',
    PleaseEnterTransferInformation: 'Sila masukkan maklumat pemindahan yang tepat',
    tradingAccount: 'Akaun Perdagangan',
    rebateAccount: 'Akaun Rebat',
    account: 'Akaun',
    blackList: 'Akaun ini telah dihadkan daripada membuat pemindahan antara akaun, sila hubungi {supportEmail}',
  },
  transferHistory: {
    payStatus: {
      processing: 'Memproses',
      paid: 'Dibayar',
      unpaid: 'Tidak dibayar',
      rejected: 'Ditolak',
    },
  },
  common: {
    liveChat: { desc: '<li><p>Perlu Bantuan?</p><span>Sembang Langsung</span></li>' },
    lang: {
      english: 'Inggeris',
      chinese: 'Bahasa Cina',
      french: 'Perancis',
      thai: 'Thai',
      german: 'Jerman',
      spanish: 'Sepanyol',
      malay: 'Melayu',
      viet: 'Vietnam',
      indo: 'Indonesia',
      arabic: 'Arab',
    },
    button: {
      confirm: 'SAHKAN',
      cancel: 'BATAL',
      identityProof: 'LENGKAPKAN PERMOHONAN',
      upload: 'MUAT NAIK',
      selectFile: 'Pilih fail',
      fundnow: 'BIAYAI SEKARANG',
      iunderstand: 'Saya faham',
    },
    withdrawChannel: {
      hongkongbanktransfer: 'Pemindahan Bank Tempatan Hong Kong',
      banktransfer: 'Pemindahan bank',
      aus: 'Pemindahan Bank - Australia',
      int: 'Pemindahan Bank - Antarabangsa',
      chn: 'Pemindahan Bank - China',
      uk: 'Pemindahan Bank - UK',
      skrill: 'Skrill',
      skrill_neteller: 'Skrill / Neteller',
      neteller: 'Neteller',
      unionpay: 'Union Pay',
      fasapay: 'FasaPay',
      thailandinstantbankwiretransfer: 'Pindahan Wayar Bank Segera Thailand',
      malaysiainstantbankwiretransfer: 'Pemindahan Bank Segera Malaysia',
      banktransferbpaypolipay: 'Pindahan Bank/BPay/PoliPay',
      bitwallet: 'Bitwallet',
      cryptocurrencybitcoin: 'Mata Wang Kripto-Bitcoin',
      cryptocurrencyusdt: 'Mata Wang Kripto-USDT',
      nigeriainstantbankwiretransfer: 'Pemindahan Wayar Bank Segera Nigeria',
      vietnaminstantbankwiretransfer: 'Pemindahan Wayar Bank Segera Vietnam',
      indonesiainstantbankwiretransfer: 'Pemindahan Bank Segera Indonesia',
      philippinesinstantbankwiretransfer: 'Pemindahan Bank Segera Filipina',
      southafricainstantbankwiretransfer: 'Pindahan Wayar Bank Segera Afrika Selatan',
      indiainstantbanktransfer: 'Pemindahan Wayar Bank Segera India',
      laosinstantbankwiretransfer: 'Pindahan Wayar Bank Segera Laos',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      transfer: 'Pindahkan ke akaun',
      dasshpeupi: 'UPI',
      perfectmoney: 'Wang Sempurna',
      ugandabanktransfer: 'Pemindahan Bank Tempatan Uganda',
      rwandabanktransfer: 'Pemindahan Bank Tempatan Rwanda',
      zambiabanktransfer: 'Pemindahan Bank Tempatan Zambia',
      congobanktransfer: 'Pemindahan Bank Tempatan Congo',
      cameroonbanktransfer: 'Pemindahan Bank Tempatan Cameroon',
      burundibanktransfer: 'Pindahan Bank Tempatan Burundi',
      kenyabanktransfer: 'Pemindahan Bank Tempatan Kenya',
      ghanabanktransfer: 'Pindahan Bank Tempatan Ghana',
      ghanamobilemoney: 'Wang Mudah Alih Ghana',
      tanzaniabanktransfer: 'Pemindahan Bank Tempatan Tanzania',
      brazilbanktransfer: 'Pindahan Bank Segera Brazil',
      mexicobanktransfer: 'Pindahan Segera Bank Mexico',
      internetbankingmexico: 'Perbankan Internet (Mexico)',
      internetbankingbrazil: 'Perbankan Internet (Brazil)',
    },
    country: { international: 'International', australia: 'Australia', uk: 'United Kingdom' },
    field: { country_region: 'Negara/Wilayah', ya: 'Ya', tidak: 'Tidak' },
    keys: {
      SUBMIT: 'HANTAR',
      FROM: 'DARI',
      UPDATE: 'KEMASKINI',
      STATUS: 'STATUS',
      TO: 'KEPADA',
      DATE: 'TARIKH',
      pleaseSelect: 'Sila pilih',
      amount: 'Jumlah',
      rebateAccount: 'Akaun Rebat',
      actualAmount: ' Jumlah sebenar = ',
      comingSoon: 'akan datang',
      tradingHistory: 'SEJARAH PERDAGANGAN',
      noData: 'Tiada data',
      NAME: 'NAMA',
      EMAIL: 'E-MEL',
      ACCTYPE: 'JENIS AKAUN',
      PLATFORM: 'PLATFORM',
      BASECURRENCY: 'MATAWANG ASAS',
      DATERANGE: 'JULAT TARIKH',
      DEST: 'DESTINASI',
      ACCNUM: 'NOMBOR AKAUN',
      BALANCE: 'BAKI',
      NONE: 'Tiada',
      ALL: 'Semua',
      PROFITLOST: 'Untung Rugi',
      MARGINLEVEL: 'Tahap Margin(%)',
      ACCOUNTEQUITY: 'Ekuiti Akaun',
      CREDIT: 'Kredit',
      DOWNLOAD: 'MUAT TURUN',
    },
    dateRange: {
      today: 'Hari ini',
      yesterday: 'Semalam',
      lastSevenDays: '7 Hari Terakhir',
      lastThirtyDays: '30 Hari Terakhir',
      weekToDate: 'Minggu Ke Tarikh',
      monthToDate: 'Bulan Kepada Tarikh',
      quarterToDate: 'Suku Setakat Ini',
      previousWeek: 'Minggu sebelumnya',
      previousMonth: 'Bulan sebelumnya',
      previousQuarter: 'Suku sebelumnya',
    },
    products: {
      forex: 'FOREX',
      commodities: 'KOMODITI',
      indices: 'INDEKS',
      crypto: 'MATA WANG KRIPTO',
      bond: 'bon',
      bonds: 'bon',
      gold: 'Emas',
      minigold: 'Emas mini',
      goldfutures: 'Niaga hadapan emas',
      bitcoin: 'Mata wang kripto',
      silver: 'Perak',
      silverfutures: 'Niaga hadapan perak',
      oil: 'Minyak',
      oilfutures: 'Niaga hadapan minyak mentah',
      index: 'Indeks',
      indexfutures: 'Indeks hadapan',
      etf: 'ETF',
      forexfutures: 'Forex',
      stock: 'Niaga hadapan forex',
    },
  },
  withdrawalFreeze: {
    header: 'Pengeluaran wang tidak dapat dilakukan buat sementara waktu',
    desc: 'Disebabkan pengubahsuaan kata laluan log masuk anda, pengeluaran tidak dapat dilakukan buat sementara waktu selama T+{day} hari atas dasar keselamatan. Sekatan ini dijangka akan diangkat dalam {hour} jam.',
    desc2:
      'Disebabkan pengubahsuaian e-mel log masuk/nombor telefon anda, pengeluaran tidak dapat dilakukan buat sementara waktu selama T+{day} hari. Sekatan ini dijangka akan ditarik balik dalam {hour} jam.',
  },
  report: {
    rebate: {
      column: {
        name: 'NAMA',
        accountNumber: 'NOMBOR AKAUN',
        accountType: 'JENIS AKAUN',
        volume: 'Isipadu',
        rebate: 'Rebat',
        totalRebate: 'JUMLAH REBAT',
        date: 'TARIKH',
        instrument: 'INSTRUMEN',
        notionalvalue: 'NILAI NOSIONAL',
      },
      dropdown: {
        allInstruments: 'Semua alatan',
        all: 'Semua',
        instruments: 'Instrumen',
      },
      others: {
        rebate: 'REBAT',
        instrumentsTraded: 'INSTRUMEN YANG DIPERDAGANGKAN',
        lots: 'Lot',
        totalRebate: 'Jumlah Rebat',
        rebates: 'Rebat',
      },
    },
    btn: { showAll: 'Tunjukkan semua', hide: 'Sembunyi' },
  },
  ibReport: { openedAccs: 'OPENED ACCOUNTS', funding: 'FUNDING', account: 'ACCOUNT' },
  lead: { demoAccounts: 'DEMO ACCOUNTS' },
  pendingAccounts: {
    errorDoc:
      'Pemohon berikut telah mengisi borang permohonan dalam talian tetapi masih belum memberikan bukti pengenalan diri (ID) untuk memuktamadkan akaun mereka.',
    acceptedDocType: 'Bentuk ID yang diterima termasuk',
    primaryDoc: 'ID Utama',
    passport: 'Salinan pasport antarabangsa anda yang sah (halaman tandatangan)',
    id: 'Salinan kad pengenalan kebangsaan yang dikeluarkan oleh kerajaan anda',
    dl: 'Salinan lesen memandu anda yang sah',
    address: 'ID Sekunder',
    addressList: [
      'Salinan bil utiliti awam baru-baru ini. Contohnya Gas atau elektrik yang mengandungi nama dan alamat kediaman anda (tidak lebih dari 3 bulan)',
      'Salinan penyata kad kredit atau penyata bank anda (tiada cetakan dalam talian)',
    ],
    tip: 'Sila galakkan pemohon untuk memberikan salinan SATU dokumen ID primer dan sekunder yang diterima kepada ',
  },
  rebatePaymentHistory: {
    rebateHistory: 'REBAT',
    withdrawHistory: 'PENGELUARAN',
    transferHistory: 'PINDAH',
    cancelWithdraw: 'Adakah anda ingin Membatalkan Permintaan Pengeluaran?',
    cancelError:
      'Pengeluaran anda sedang diproses pada masa ini. Oleh itu, kami tidak boleh menerima permintaan pembatalan anda. Untuk maklumat lanjut, anda boleh menghubungi kami menggunakan ciri sembang langsung.',
  },
  referralLinks: {
    copyLink: 'SALIN PAUTAN',
    downloadPage: 'Muat turun APP',
    campLang: 'Bahasa Kempen',
    cpy: 'SALINAN',
    alreadyCPY: 'DISALIN! ',
    liveAcc: 'Akaun Langsung',
    demoAcc: 'Akaun Demo',
    homePage: 'Halaman Utama',
    promotion: 'Pautan promosi acara',
    hintTitle: 'Apakah pautan promosi acara?',
    hintMessage:
      'Pautan promosi acara adalah kaedah promosi baru yang diberikan kepada IB. Pautan perkongsian akan membimbing pengguna ke halaman acara bonus dan melengkapkan pendaftaran. Kadar kejayaan pendaftaran pengguna dapat ditingkatkan dengan berkesan dengan berkongsi pautan.',
    hintShortLink: 'Kongsi menggunakan pautan pendek',
    hintShortLinkTitle: 'Apakah pautan pendek?',
    hintShortLinkMessage:
      'Pautan pendek ialah peraturan penjanaan pautan, yang boleh melindungi pautan kongsian anda dengan lebih baik daripada diubah oleh orang lain, dengan itu melindungi hak pembangunan IB anda',
    customButton: 'menyesuaikan',
    copyButton: 'salinan',
    customInviteCodeHeader: 'kod jemputan tersuai',
    currentCode: 'kod jemputan semasa',
    newCode: 'kod jemputan baharu',
    notice:
      'Nota: Kod jemputan mempunyai sekurang-kurangnya 6 parameter. Huruf, nombor, simbol bukan khas boleh dimasukkan',
    inviteCodeFormatError: 'Format kod jemputan salah, sila edit semula',
    inviteCodeAlreadyExist: 'Kod jemputan telah didaftarkan terlebih dahulu oleh orang lain, sila masukkan semula',
    pleaseEnter: 'sila masukkan',
    inviteCodeHeader: 'Kod jemputan promosi',
    inviteCode: 'Kod Jemputan',
  },
  settings: {
    info: 'INFO',
    reg: 'REGISTERED ON',
    CustomerInfo: 'maklumat pelanggan',
    name: 'Nama',
    email: 'E-mel',
    phone: 'Nombor telefon',
    address: 'Alamat',
  },
  unfundedAcc: {
    neverFundedAccs: 'AKAUN TIDAK PERNAH DIBIAYAI',
    prevFundedZero: 'SEBELUM INI DIBIAYAI (BAKI SIFAR)',
    accBalance: 'BAKI AKAUN',
  },
  multiib: {
    level: 'TAHAP {level}',
    noib: 'Tiada sub-IB di bawah akaun ini.',
  },
  AWCDialog: {
    optIn: 'PILIH MASUK SEKARANG',
    noThanks: 'TIDAK, TERIMA KASIH',
  },
  header: { bckToCP: 'BACK TO CLIENT PORTAL', logout: 'LOG OUT' },
  menu: {
    dashboard: 'PAPAN PEMUKA',
    ibReport: 'LAPORAN IB',
    rebateReport: 'LAPORAN REBAT',
    iblevel: 'IB PELBAGAI PERINGKAT',
    ibAccs: 'AKAUN IB',
    leads: 'MENERAJU',
    pendingAccs: 'AKAUN TERTUNDA',
    unfundedAccs: 'AKAUN YANG TIDAK DIBIAYAI',
    payHistory: 'SEJARAH TRANSAKSI',
    ibProfile: 'PROFIL IB',
    refLinks: 'PAUTAN RUJUKAN',
    contactUs: 'HUBUNGI KAMI',
    switchCP: 'TUKAR KE PORTAL PELANGGAN',
  },
  footer: {
    riskWarn: `Amaran risiko: Berdagang Forex dan CFD melibatkan risiko yang besar dan boleh mengakibatkan kehilangan modal yang anda laburkan.
         Perdagangan produk berleveraj mungkin tidak sesuai untuk semua pelabur. 
         Sebelum berdagang, sila pertimbangkan tahap pengalaman anda, objektif pelaburan dan dapatkan nasihat kewangan bebas jika perlu.    
         Sila baca dokumen undang-undang kami di laman web kami dan pastikan anda memahami sepenuhnya risiko sebelum anda membuat sebarang 
         keputusan perdagangan.`,
    riskWarn_fsc:
      'Amaran risiko: Derivatif ialah instrumen yang kompleks dan mengandungi risiko yang tinggi untuk kerugian wang dengan pantas kerana leveraj. Anda seharusnya mempertimbangkan sama ada anda memahami cara Derivatif berfungsi dan sama ada anda mampu mengambil risiko yang tinggi untuk kerugian wang anda.',
  },
  responseMsg: {
    //4xx warning
    410: 'Pengesahan parameter gagal',
    411: 'Kata laluan salah',
    420: 'Pengguna tidak wujud',
    421: 'Tetapan semula kata laluan ditolak',
    490: 'Sila jangan serahkan maklumat kad pendua',
    //5xx error
    500: 'Ralat telah berlaku.',
    501: 'Permohonan gagal. Sila cuba sebentar lagi.',
    505: 'Muat naik fail gagal',
    510: 'Kod SMS salah',
    511: 'Tidak dapat menghantar kod SMS ke telefon anda',
    520: 'Daftar masuk gagal. Sila cuba lagi.',
    521: 'Pengguna sudah log masuk pada peranti ini!',
    522: 'Sila log masuk semula',
    523: 'Sila log masuk semula',
    524: 'Malangnya alamat e-mel anda tidak dikenali',
    525: 'Nama pengguna atau kata laluan salah',
    526: 'Recaptcha tidak sepadan',
    527: 'Pengguna bukan IB',
    528: 'Pengguna tidak wujud',
    529: 'Nama pengguna atau kata laluan salah',
    530: 'Kod tidak sah',
    531: 'Kod tidak sah',
    540: `Akaun dagangan anda tidak dapat ditemui`,
    541: 'Tidak dapat mencari akaun rebat',
    542: 'Anda tidak dibenarkan membuka akaun tambahan',
    544: 'Anda tidak dibenarkan memohon akaun tambahan sehingga Bukti ID anda disahkan',
    550: 'Nombor akaun salah',
    551: 'Baki anda tidak mencukupi',
    554: 'Jumlah yang diminta adalah sifar atau kosong',
    562: 'Kami tidak dapat memproses pembayaran anda, sila cuba lagi. Jika masalah berterusan, sila hubungi kami melalui sembang langsung, atau e-mel {email}',
    564: 'Negara tidak sepadan dengan saluran pembayaran',
    565: 'Kod Bank Salah',
    566: 'Kaedah pembayaran tidak ditemui',
    567: 'Malangnya kaedah pembayaran ini tidak tersedia pada masa ini. Kami memohon maaf atas sebarang kesulitan yang berlaku',
    581: 'Anda telah berjaya mengikut serta dalam promosi. Sila ambil perhatian bahawa anda mesti membuat deposit untuk mengeluarkan dana.',
    584: `Anda tidak mempunyai sebarang rebat yang berkenaan`,
    590: 'Tidak dapat mencari akaun pindahan keluar',
    591: 'Tidak dapat mencari akaun pindahan masuk',
    593: 'Kami tidak dapat memproses pindahan dana anda daripada akaun yang dipilih kerana akaun anda mengandungi kredit.',
    594: 'Pemindahan dana daripada akaun ini tidak dibenarkan',
    session_timeout: 'Tamat masa sesi, mengubah hala ke halaman log masuk.',
  },
}
