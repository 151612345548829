export default {
  home: {
    RebateAccountNumber: '返傭賬號',
    totalCommission: '總傭金',
    APPLY_FOR_A_REBATE: '申請傭金',
    availableBalance: '余額',
    WITHDRAW_REBATE: '出金',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: '轉賬',
    TOTAL_VOLUME: '交易量',
    NET_FUNDING: '凈入金',
    DEPOSITS: '總入金',
    WITHDRAW: '總出金',
    OPEND_ACCOUNT: '已開賬號數',
    PERFORMANCE: '趨勢圖',
    MOST_TRADED_INSTRUMENTS: '交易產品分布',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: '本月業績表現TOP',
    RECENTLY_OPENED_ACCOUNTS: '近期新開戶',
    ACCOUNT_MANAGER: '客戶經理',
    Account: '賬號',
    Name: '姓名',
    Volume: '交易量',
    Rebate: '傭金',
    Balance: '余額',
    ContactClient: '聯系客戶',
    applyRebateSuccessTip:
      '祝賀你，我們收到了您的申請要求。 | {amount}將在1個工作日內轉入返傭賬戶{account}。 | 一旦您的申請處理完畢，您可以提取或轉移資金。',
    HOVER: '前一天的傭金將會在澳大利亞東部時間9至11時之間計算',
    blacklist: '您的賬號目前暫時無法提交返傭申請，請聯系您的客戶經理',
  },
  liveChat: { header: '在線聊天' },
  contactUs: {
    tip: '如果您想要獲得更多的信息或者有任何問題, 請通過以下聯系方式聯系我們. 我們將在24小時內盡快回覆您.',
  },
  fileUploader: {
    supportedFormat: '文件支持類型: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: '文件支持類型: {files}',
    maxSize: '最大文件大小: {maxSize}MB',
    maxFileNum: '最多文件數: {maxFileNum}',
    failedFileExtension: '請上傳正確的文件類型',
  },
  notification: {
    pendingIdentityProof: {
      header: '帳戶信息不完整',
      id: '身份',
      poa: '地址',
      both: '身份/地址',
      content: '您的 {doc} 證明目前正在處理中，將很快完成。',
    },
  },
  identityProof: {
    home: {
      header: '帳戶信息不完整',
      content: '您好，{name}，您的帳戶申請已接近完成，但是您仍然需要提交其他文件以驗證通過您的帳戶。',
    },
    popup: {
      header: '您好 {name}, 我們需要更多的賬戶信息以處理您的賬戶申請. 請提供以下所需文件:',
      addressProofSpan: `<strong>
      <b>確認您的地址</b>
      </strong>
      <br />
      <I>請提交<b>以下文件之一</b>：
        <br /><br />
      </I>
      - 近期的水電費賬單</br>(最近3個月內)
      <br />
      <b class="or">或</b><br />
      - 近期的銀行對賬單</br>(最近3個月內)
      <br />
      <br />
      <b>請注意:</b> 您的全名及目前的地址必須在您提供的以上文件中清晰可見。<br />`,
    },
    reasonType: {
      4: '不完整的文件',
      5: '不清楚的文件',
      6: '需要提供彩色覆印件',
      8: '開戶名稱不一致',
      9: '地址證明簽發日期不合符規定',
    },
    reason: '我們無法審核您的文件，原因: {reason}，請重新上傳',
    success: '感謝您上傳的文件, 我們將會盡快對文件進行審核。',
  },
  rebate: {
    status: {
      processed: '已完成',
      processing: '處理中',
      rejected: '被拒絕',
    },
  },
  ibAccounts: {
    ibAccount: '返傭賬號',
    subIbs: '子代理返傭賬號',
    tradingList: '交易清單',
    OpeningList: '持倉列表',
    closeList: '已平倉列表',
    tradingListColumn: {
      ticket: '交易單號',
      openTime: '開倉時間',
      type: '類型',
      volume: '交易量',
      item: '產品名稱',
      openPrice: '開盤價',
      closeTime: '關倉時間',
      closePrice: '收盤價',
      commission: '返傭',
      profit: '盈虧',
    },
  },
  withdraw: {
    warning: {
      desc: '請注意以下事項：',
      desc1: '使用者需承擔銀行轉帳手續費，通常在135至250日圓之間。',
      desc2: '下午2點後提出的提款申請可能會延遲或於次日處理。',
    },
    banklist: {
      agriculturalbankofchina: '中國農業銀行',
      bankofbeijing: '北京銀行',
      bankofchina: '中國銀行',
      bankofcommunications: '交通銀行',
      bankofdongguan: '東莞銀行',
      bankofguangzhou: '廣州銀行',
      bankofhangzhou: '杭州銀行',
      bankoflanzhoucoltd: '蘭州銀行股份有限公司',
      bankofningbo: '寧波銀行',
      bankofshanghai: '上海銀行',
      chinabohaibank: '渤海銀行',
      chinaciticbank: '中信銀行',
      chinaconstructionbank: '中國建設銀行',
      chinaeverbrightbank: '光大銀行',
      chinaindustrialbank: '興業銀行',
      chinamerchantsbank: '招商銀行',
      chinaminshengbank: '中國民生銀行',
      chinazheshangbank: '浙商銀行',
      guandongdevelopmentbank: '廣發銀行',
      huaxiabank: '華夏銀行',
      industrialandcommercialbankofchina: '中國工商銀行',
      jiangsunantongruralcommercialbank: '江蘇南通農村商業銀行',
      pinganbank: '平安銀行',
      postalsavingsbankofchina: '中國郵政儲蓄銀行',
      shanghaipudongdevelopmentbank: '浦發銀行',
      shanxiruralcreditcooperative: '山西農村信用社',
      shenzhendevelopbank: '深圳發展銀行',
      wenlingruralcommercialbank: '溫嶺農商銀行',
      zhejiangyilururalcommercialbank: '浙江挏廬農村商業銀行',
    },
    default: {
      withdrawlFeeAlert: '銀聯出金低於100美元，將收取20美元的手續費。',
      selectCC: '選擇信用卡',
      selectBA: '選擇銀行賬戶',
      anotherCC: '手動輸入信用卡',
      anotherBA: '添加銀行帳戶',
      remember: '記住我的帳號',
    },
    transfer: {
      info: '{platform} 會墊付每個月第一次出金所產生的銀行費用. 當月內的其他出金將會產生20單位出金貨幣的銀行費用.',
      label: {
        upload: '請上傳一張近3個月銀行對賬單的截圖、照片或覆印件',
      },
      formValidation: {
        upload: '請上傳銀行帳單',
      },
    },
    REBATE_WITHDRAW_REQUEST: '出金申請',
    PleaseSelectDesiredWithdrawMethod: '請選擇所需的出金方法',
    BankName: '銀行名稱',
    bankCode: '銀行代碼',
    bankBranchCode: '分行代碼',
    bankAccountCurrency: '銀行賬戶貨幣',
    BankAccountsName: '銀行賬戶名',
    FasaPayAccountName: 'FasaPay賬戶姓名',
    AustralianBankName: '澳洲銀行名稱',
    BankAccountNumber: '銀行賬號',
    FasaPayAccountNumber: 'FasaPay 賬戶號碼',
    BankAccountBeneficiary: '銀行收款人姓名',
    BSB: 'BSB信息',
    SortCode: '銀行代碼(Sort Code)',
    skrillEmail: 'Skrill郵箱',
    NetellerEmail: 'Neteller郵箱',
    PerfectMoneyEmail: 'Perfect Money郵箱',
    SticpayEmail: 'Sticpay郵箱',
    BitwalletEmail: 'Bitwallet郵箱',
    BitcoinEmail: 'Bitcoin錢包地址',
    USDTEmail: 'Tether錢包地址',
    BankAccountName: '收款人姓名',
    BankBranch: '分行名稱（3碼分行代號）',
    BankAccountType: '銀行帳戶類型',
    Province: '省',
    City: '市',
    BankAddress: '銀行地址',
    AccountNumberIBAN: '銀行賬號',
    BankBeneficiaryName: '銀行收款人姓名',
    BankAccountBeneficiaryName: '銀行賬戶收款人姓名',
    AccountHoldersAddress: '賬戶持有人地址',
    SelectWithdrawCard: '請選擇出金卡',
    Swift: 'Swift代碼',
    SwiftAud: 'Swift代碼(非澳元賬號)',
    ABA_SortCodeABA: 'Sort代碼',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: '重要信息',
    ifscCode: 'IFSC 代碼',
    uploadDocument: '請上傳一張近3個月銀行對賬單的截圖、照片或覆印件',
    YourPaymentGreaterThanAvailableBalance: '您的出金金額大於可用余額',
    integer: '僅支持整數輸入',
    plnWithdraw: '金額必須小於或等於zł {money} PLN',
    CannotUseMoreThanTwoDecimalValues: '無法使用超過兩位小數的數值和負數',
    YourPaymentIsBeingProcessed: '您的出金申請已經提交',
    Itcannotbe0: '出金不能為0',
    noCardWarn: '沒有有效的卡, 請先綁卡',
    amtLarger: '最低出金金額為{currencyType}{minLimit}。 如果您的余額小於 30 必須全額提取',
    blackList: `很遺憾，您目前無法提交提款請求。請聯系{supportEmail}取得更多信息`,
    NetellerChargeAlert: 'Neteller 出金限額為每筆交易 500,000 美元，並收取 2% 的手續費（上限為 30 美元）。',
    FasaPayChargeAlert: 'FasaPay 對每次出金收取 0.5% 的手續費。',
    SkrillChargeAlert: 'Skrill 對每次出金收取 1% 的手續費。',
    cryptoAlert:
      '請注意，我們無法通過 BUSDT 進行存款或取款，請確保地址和加密貨幣與我們接受的鏈和貨幣匹配，否則您可能會損失資金。',
    usdtNote:
      '受ERC20的礦工費上漲影響，出金處理時間也相應延遲。為了保障您的出金處理速度，您可以選擇使用TRC20，或支付較高的礦工費申請出金，以加快您的出金申請進度。',
    name: '姓名',
    phone: '電話',
    address: '詳細地址',
    email: '電子郵件',
    status: {
      withdrawalSubmitted: '已提交',
      withdrawalCanceled: '取消',
      withdrawalAuditing: '處理中',
      withdrawalRejected: '拒絕',
      withdrawalAccepted: '處理中',
      withdrawalPayFailed: '失敗',
      withdrawalSuccess: '已完成',
      withdrawalFailed: '失敗',
      withdrawalPending: '等候中',
      withdrawalPartialFailed: '失敗',
      transferSubmitted: '處理中',
      transferSuccess: '已完成',
      transferRejected: '拒絕',
      transferFailed: '失敗',
      transferProcessing: '處理中',
    },
    formErrorMsg: {
      bankName: '請填寫銀行名稱',
      bsb: '請填寫BSB',
      sortCode: '請填寫Sort Code',
      beneName: '請填寫帳戶名',
      bankCodeReq: '銀行代碼是必需的',
      bankBranchCodeReq: '分行代碼是必需的',
      accNum: '請填寫帳戶號碼',
      swift: '請填寫Swift',
      bankAddress: '請填寫銀行地址',
      holderAddress: '請填寫持卡人地址',
      amt: '請填寫金額',
      firstSixDigits: '請填寫卡號前六位',
      lastFourDigits: '請填寫卡號前四位',
      branchName: '請填寫分行名稱',
      email: '請填寫電子郵件',
      accName: '請填寫銀行賬戶名',
      province: '請填寫銀行所在省',
      city: '請填寫銀行所在市',
      bankCard: '請選擇一張卡',
      emailFormat: '請填寫正確的電郵地址',
      amt0: '金額不能為0',
      ifscCode: '請填寫IFSC 代碼',
      bitcoin: '請填寫Bitcoin錢包地址',
      bitcoinAddressLength: '比特幣錢包地址必須在 26 到 42 個字符之間',
      usdt: '請填寫USDT錢包地址',
      dynamicReq: '請填寫{fieldname}',
      uploadDocument: '請上傳銀行帳單',
    },
    note: `由於最近的變化，我行可能無法為在某些國家注冊的客戶辦理國際銀行轉賬。<br/>
      如果您必須通過國際銀行轉賬取現，請與我們的客戶支持聯系：+ 44 2080 363 883 或<a href='mailto:{mail}'>{mail}</a>.<br/>
      給您帶來的不便我們深表歉意。`,
    minAmountWarn:
      '申請的提款金額小於申請的支付網關的最低金額。在提交提款請求之前，請將您的賬戶余額充值至/超過最低限額。更多信息可以在<a href="https://{regulatorUrl}/clients/accounts/withdraw/">這里</a>找到。',
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: '轉賬申請',
    transferAmountIsGreaterThanAvailableBalance: '您的轉賬金額大於可用余額',
    yourRebateTransferHasBeenProcessed: '您的轉賬申請已提交',
    PleaseEnterTransferInformation: '請輸入準確的轉賬信息',
    tradingAccount: '交易賬號',
    rebateAccount: '返傭賬號',
    account: '賬號',
    blackList: '此賬戶已被限制進行賬戶間轉賬，請聯系 {supportEmail}',
  },
  transferHistory: {
    payStatus: {
      processing: '處理中',
      paid: '已付款',
      unpaid: '未付款',
      rejected: '被拒絕',
    },
  },
  common: {
    liveChat: { desc: '<li><p>需要幫助?</p><span>在線客服</span></li>' },
    lang: {
      english: '英文',
      chinese: '中文',
      french: '法文',
      thai: '泰文',
      german: '德文',
      spanish: '西班牙文',
      malay: '馬來西亞文',
      viet: '越南文',
      indo: '印尼文',
      arabic: '阿拉拍文',
      japanese: '日文',
    },
    button: {
      confirm: '確認',
      cancel: '取消',
      identityProof: '完成帳戶申請',
      upload: '上傳',
      selectFile: '上傳文件',
      fundnow: '即刻入金',
      iunderstand: '我明白了',
    },
    withdrawChannel: {
      hongkongbanktransfer: '香港本地銀行轉賬',
      banktransfer: '銀行轉帳',
      aus: '銀行轉賬 (澳大利亞)',
      int: '銀行轉賬 (國際)',
      chn: '銀行轉賬 (中國)',
      uk: '銀行轉帳 (英國)',
      unionpay: '銀聯轉帳',
      thailandinstantbankwiretransfer: '泰國銀行即時轉賬',
      malaysiainstantbankwiretransfer: '馬來西亞銀行即時轉賬',
      banktransferbpaypolipay: '銀行轉帳/BPay銀行轉帳/PoLi支付',
      cryptocurrencybitcoin: '加密貨幣-Bitcoin',
      cryptocurrencyusdt: '加密貨幣-USDT',
      nigeriainstantbankwiretransfer: '尼日利亞銀行即時轉賬',
      vietnaminstantbankwiretransfer: '越南銀行即時轉賬',
      indonesiainstantbankwiretransfer: '印度尼西亞銀行即時轉賬',
      philippinesinstantbankwiretransfer: '菲律賓銀行即時轉賬',
      southafricainstantbankwiretransfer: '南非銀行即時轉賬',
      indiainstantbanktransfer: '印度銀行即時轉賬',
      laosinstantbankwiretransfer: '老撾即時銀行電匯',
      ugandabanktransfer: '烏幹達當地銀行轉賬',
      rwandabanktransfer: '盧旺達當地銀行轉賬',
      zambiabanktransfer: '讚比亞當地銀行轉賬',
      congobanktransfer: '剛果當地銀行轉賬',
      cameroonbanktransfer: '喀麥隆當地銀行轉賬',
      burundibanktransfer: '布隆迪當地銀行轉賬',
      kenyabanktransfer: '肯尼亞當地銀行轉賬',
      ghanabanktransfer: '加納當地銀行轉賬',
      tanzaniabanktransfer: '坦桑尼亞當地銀行轉賬',
      brazilbanktransfer: '巴西銀行即時轉賬',
      mexicobanktransfer: '墨西哥銀行即時轉賬',
      internetbankingmexico: '線上銀行 (墨西哥)',
      internetbankingbrazil: '線上銀行 (巴西)',
      astropay: 'Astropay',
      transfer: '轉賬至賬號',
    },
    country: { international: '國際', australia: '澳大利亞', uk: '英國' },
    field: { country_region: '國家/地區', yes: '是', no: '否' },
    keys: {
      SUBMIT: '提交',
      FROM: '從',
      TO: '到',
      DATE: '日期',
      UPDATE: '更新',
      pleaseSelect: '請選擇',
      amount: '金額',
      actualAmount: '  實際出金 = ',
      rebateAccount: '返傭賬號',
      comingSoon: '敬請期待',
      noData: '沒有數據',
      NAME: '姓名',
      EMAIL: '電子郵件',
      ACCTYPE: '賬戶類型',
      PLATFORM: '交易平台',
      BASECURRENCY: '幣種',
      DATERANGE: '申請時間',
      STATUS: '狀態',
      DEST: '目標帳戶',
      tradingHistory: '交易歷史',
      ACCNUM: '賬號',
      BALANCE: '余額',
      NONE: '無',
      ALL: '全部',
      PROFITLOST: '盈虧',
      MARGINLEVEL: '保證金比例',
      ACCOUNTEQUITY: '賬戶凈值',
      CREDIT: '贈金',
      DOWNLOAD: '下載',
    },
    dateRange: {
      today: '今天',
      yesterday: '昨天',
      lastSevenDays: '最近7天',
      lastThirtyDays: '最近30天',
      weekToDate: '本周',
      monthToDate: '本月',
      quarterToDate: '本季度',
      previousWeek: '上周',
      previousMonth: '上月',
      previousQuarter: '上季度',
    },
    products: {
      forex: '外匯',
      commodities: '大宗商品',
      indices: '指數',
      crypto: '加密貨幣',
      bond: '債券',
      bonds: '債券',
      gold: '黃金',
      minigold: '迷你黃金',
      goldfutures: '黃金期貨',
      bitcoin: '加密貨幣',
      silver: '銀',
      silverfutures: '銀期貨',
      oil: '原油',
      oilfutures: '原油期貨',
      index: '指數',
      indexfutures: '指數期貨',
      etf: 'ETF',
      forexfutures: '外匯',
      stock: '外匯期貨',
    },
  },
  withdrawalFreeze: {
    header: '暫時不支持出金',
    desc: '由於您修改了登錄密碼，基於安全原因，暫時不支持出金 T+{day}天。預計限制將在{hour}小時後解除。',
    desc2: '由於您的登錄電子郵件/手機號碼已修改，暫時不支持出金 T+{day}天。預計在{hour}小時後解除此限制。',
  },
  report: {
    rebate: {
      column: {
        date: '日期',
        name: '名字',
        accountNumber: '賬號',
        accountType: '賬號類型',
        volume: '交易量',
        rebate: '傭金',
        totalRebate: '總傭金',
        instrument: '產品',
        notionalvalue: '名義價值',
      },
      dropdown: {
        allInstruments: '所有產品',
        all: '全部',
        instruments: '產品',
      },
      others: {
        lots: '手數',
        rebate: '傭金',
        totalRebate: '總傭金',
        instrumentsTraded: '已交易產品',
        rebates: '傭金',
      },
    },
    btn: { showAll: '顯示全部', hide: '隱藏' },
    comingSoon: '敬請期待',
    noData: '沒有數據',
    nodataMessage: '當前沒有要下載的數據，請您先篩選條件後再進行下載',
    timeLimitMessage: '僅可下載90天內的數據，請您重新篩選後再進行下載',
  },
  ibReport: { openedAccs: '已開賬戶數', funding: '出入金記錄', account: '賬號' },
  lead: { demoAccounts: '模擬賬戶' },
  pendingAccounts: {
    errorDoc: '以下客戶已經提交注冊申請, 但還未提供滿足開設賬戶的相關證件.',
    acceptedDocType: '可接受的證件類型包括:',
    primaryDoc: '身份證明',
    passport: '有效期內的護照覆印件(簽名頁)',
    id: '政府頒發的身份證覆印件',
    dl: '有效期內的駕照覆印件',
    address: '地址證明',
    addressList: [
      '水/電/煤賬單覆印件, 清晰的顯示客戶的名字和居住地址 (必須是最近3個月內)',
      '信用卡賬單/銀行對賬單覆印件',
    ],
    tip: '請聯系客戶提供一份身份證明和地址證明到 ',
  },
  rebatePaymentHistory: {
    rebateHistory: '傭金申請記錄',
    withdrawHistory: '傭金出金記錄',
    transferHistory: '轉賬歷史',
  },
  referralLinks: {
    copyLink: '複製連結',
    downloadPage: '下載APP鏈接',
    campLang: '推廣語言',
    cpy: '覆制鏈接',
    alreadyCPY: '已覆制!',
    liveAcc: '開戶連結',
    demoAcc: '模擬賬戶',
    homePage: '主頁鏈接',
    promotion: '活動推廣鏈接',
    hintTitle: '什麽是活動推廣？',
    hintMessage:
      '活動推廣鏈接是提供給IB的新的推廣方式，該分享鏈接會引導用戶至贈金活動頁面並完成注冊，通過該分享鏈接可有效提高用戶注冊的成功率',
    hintShortLink: '使用短鏈接分享',
    hintShortLinkTitle: '什麽是短鏈接',
    hintShortLinkMessage: '短鏈接是一種鏈接生成規則，它能更好的保護您的分享鏈接不被他人更改，從而保障您的IB拓展權益',
    inviteCodeHeader: '推廣邀請碼',
    inviteCode: '邀請碼',
    customButton: '自定義',
    copyButton: '複製',
    customInviteCodeHeader: '自定義邀請碼',
    currentCode: '當前邀請碼',
    newCode: '新邀請碼',
    notice: '注意:邀請碼最少6位參數。可輸入字母、數字、非特殊符號',
    inviteCodeFormatError: '邀請碼格式錯誤，請重新編輯',
    inviteCodeAlreadyExist: '該邀請碼已被其他人搶注，請重新輸入',
    pleaseEnter: '請輸入',
  },
  settings: {
    info: '信息',
    reg: '注冊日期',
    CustomerInfo: '客戶資訊',
    name: '姓名',
    email: '電子郵件',
    phone: '電話號碼',
    address: '地址',
  },
  unfundedAcc: {
    neverFundedAccs: '從未入金客戶',
    prevFundedZero: '已入金客戶(余額為0)',
    accBalance: '賬號',
  },
  multiib: {
    level: '{level} 級',
    noib: '此帳戶下沒有子介紹經紀商（IB）。',
  },
  AWCDialog: {
    optIn: '立即加入',
    noThanks: '不用了，謝謝',
  },
  header: { bckToCP: '返回客戶後台', logout: '登出' },
  menu: {
    dashboard: '概覽',
    ibReport: 'IB 報告',
    rebateReport: '傭金報告',
    iblevel: '多級介紹經紀商（IB）',
    ibAccs: '客戶管理',
    leads: '潛在客戶',
    pendingAccs: '待審核客戶',
    unfundedAccs: '未入金客戶',
    payHistory: '交易歷史',
    ibProfile: '個人信息',
    refLinks: '推廣鏈接',
    contactUs: '聯系我們',
    switchCP: '返回客戶後台',
  },
  footer: {
    riskWarn: `風險警告：交易外匯和差價合約涉及重大風險，可能導致您的投資資本損失。
    交易杠桿產品可能並不適合所有投資者。
    交易前，請考慮您的經驗水平、投資目標，並在必要時尋求獨立的財務建議。
    請閱讀我們網站上的法律文件，並確保您在做出任何交易決定之前完全了解風險。`,
    riskWarn_fsc: `風險提示：金融衍生品是複雜的交易工具，並具有因槓桿作用而導致迅速虧損的高風險。 您應該考慮您是否了解金融衍生品的運作方式，以及您是否有能力承擔損失資金的高風險。`,
  },
  responseMsg: {
    //4xx warning
    410: '參數驗證失敗',
    411: '密碼錯誤',
    420: '用戶不存在',
    421: '密碼重置被拒',
    490: '請不要重覆輸入卡信息',
    //5xx error
    500: '發生錯誤',
    501: '申請失敗, 請稍後重試',
    505: '文件上傳失敗',
    510: '驗證碼錯誤',
    511: '發送短消息失敗',
    520: '登錄失敗, 請重試',
    521: '您在此設備上已處於登入狀態!',
    522: '請重新登錄',
    523: '請重新登錄',
    524: '您提供的郵箱尚未注冊',
    525: '用戶名或密碼錯誤',
    527: '用戶不是IB',
    528: '用戶不存在',
    529: '用戶名或密碼錯誤',
    530: '代碼無效',
    531: '代碼無效',
    540: `系統找不到您的交易賬號`,
    541: '無法找到返傭賬號',
    542: '抱歉，您不允許開同名賬號',
    544: '您不允許申請一個額外的賬戶，直到您的身份證明核實為止',
    550: '賬號錯誤',
    551: '您的余額不足',
    554: '請求的數額為0或者空',
    562: '無法處理您的支付請求，請重新嘗試。如問題仍未解決，請通過Live Chat或者電子郵件{email}與我們取得聯系',
    564: '國家未能配合該支付渠道',
    565: '銀行代碼錯誤',
    566: '無法找到支付方式',
    581: '您已成功選擇加入促銷活動。 請注意，您必須先入金才能出金。',
    584: `您沒有可申請的傭金`,
    590: '無法找到轉出賬號',
    591: '無法找到轉入賬號',
    593: '由於您的賬戶包含信貸部分，我們無法對您所選擇的賬戶進行資金轉移處理。',
    594: '不允許從這個賬戶轉移資金',
    session_timeout: '登錄時間超時, 正在回到登錄頁面',
  },
}
