/*
 * @Author: kevin_G kevin.guo@suntontech.net
 * @Date: 2022-12-08 13:51:43
 * @LastEditors: kevin_Gxr kevin.guo@suntontech.net
 * @LastEditTime: 2023-01-11 10:53:04
 * @FilePath: /inx-ib-portal/src/util/loading.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Loading } from 'element-ui'

let loadingInstance,
  isTimeout = true,
  ajaxCount = 0

function startLoading(method) {
  if (method == 'router') {
    isTimeout = false
  }
  if (method == 'ajax') ajaxCount++
  loadingInstance = Loading.service({
    lock: true,
    fullscreen: true,
    spinner: 'client-portal-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  })
}

function endLoading() {
  if (loadingInstance)
    setTimeout(() => {
      isTimeout = true
      if (ajaxCount == 0) loadingInstance.close()
    }, 500)
}

function endAjaxLoading() {
  ajaxCount--
  if (loadingInstance && isTimeout && ajaxCount == 0) loadingInstance.close()
}

export { startLoading, endLoading, endAjaxLoading }
