const fca = require('@/regulator/fca.js')
const fsc = require('@/regulator/fsc.js')
const scb = require('@/regulator/scb.js')
const noReferralLinksUserList = require('@/constants/noReferralLinksUserList.js')
const country = require('@/constants/country.js')
const { customerService } = require('@/constants/customerService')

const regulator = { fsc, scb, fca }

function filterForAsiaAndThaiSupportEmail(reg, countryCode = -1) {
  const asiaEmailGroup = [
    country.countryCodeEnum.AUSTRALIA, // AU
    country.countryCodeEnum.CAMBODIA, // KH
    country.countryCodeEnum.CHINA, // CN
    country.countryCodeEnum.HONG_KONG, // HK
    country.countryCodeEnum.INDONESIA, // ID
    country.countryCodeEnum.SOUTH_KOREA, // KR
    country.countryCodeEnum.LAO_PEOPLE_REPUBLIC, // LA
    country.countryCodeEnum.MALAYSIA, // MY
    country.countryCodeEnum.MONGOLIA, // MN
    country.countryCodeEnum.MYANMAR, // MM
    country.countryCodeEnum.NEW_ZEALAND, // NZ
    country.countryCodeEnum.PHILIPPINES, // PH
    country.countryCodeEnum.SINGAPORE, // SG
    country.countryCodeEnum.TAIWAN, // TW
    country.countryCodeEnum.VIETNAM, // VN
    country.countryCodeEnum.THAILAND, // TH
  ]

  if (reg === 'fsc') {
    if (asiaEmailGroup.includes(parseInt(countryCode))) {
      return regulator[reg].info.asiaEmail
    }
  }
}

module.exports = {
  name: 'infinox',
  isAU: true,
  cpUrl: 'https://www.infinox.com/',
  style: {
    menuBackground: `#1e1e1e`,
    menuTextColor: `#ffffff`,
    menuActiveTextColor: `#ffcd05`,
  },
  info: {
    fullName: 'INFINOX',
    entityName: 'Infinox Global Prime',
    getUrl(reg) {
      let url = ''
      let inc = /ixtrades.vn/.test(location.host)
      if (reg != 'fca' && inc) {
        url = 'myaccount.ixtrades.vn'
      } else {
        url = regulator[reg].info.url
      }
      return reg ? url : ''
    },
    getPhone(reg) {
      return reg ? regulator[reg].info.phone : ''
    },
    getEmail(reg, countryCode = -1) {
      const supportEmail = filterForAsiaAndThaiSupportEmail(reg, countryCode)
      const email = reg ? regulator[reg].info.email : ''
      return supportEmail || email
    },
    getAccountEmail(reg, countryCode = -1) {
      const supportEmail = filterForAsiaAndThaiSupportEmail(reg, countryCode)
      const email = reg ? regulator[reg].info.accountEmail : ''
      return supportEmail || email
    },
    getLiveChatVisible(reg, countryCode = -1) {
      const isLiveChatVisible = reg ? regulator[reg].info.liveChatVisible : false
      return reg ? isLiveChatVisible : false
    },
  },
  getMenus(reg, userID) {
    let menus = reg ? regulator[reg].menus : []
    if (noReferralLinksUserList.noReferralLinksUserList.includes(userID)) {
      menus.forEach((ele, index) => {
        if (ele.name === 'menu.refLinks') {
          menus.splice(index, 1)
        }
      })
    }
    return reg ? menus : []
  },
  getRiskValue(reg) {
    return reg ? regulator[reg].riskValue : []
  },
  accountTypeMaps(reg) {
    return reg ? regulator[reg].accountTypeMaps : {}
  },
  getCheckMethodMinAmount(reg) {
    return reg ? regulator[reg].withdrawSetup.checkMethodMinAmount : false
  },
  getCancelWithdraw(reg) {
    return reg ? regulator[reg].withdrawSetup.cancelWithdraw : false
  },
  chartColor: [
    '#cf5f38',
    '#6bdace',
    '#ffffff',
    '#67cb06',
    '#a340ff',
    '#667fff',
    '#73c5e6',
    '#ffad87',
    '#f6fc55',
    '#d066b1',
    /* '#e42422','#f2046e','#af06ce','#4a00e1','#003cf4','#50c3e3', */
  ],
}
