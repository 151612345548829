'use strict'

exports.__esModule = true
exports.default = {
    el: {
        colorpicker: {
            confirm: 'OK',
            clear: 'Kosongkan',
        },
        datepicker: {
            now: 'Sekarang',
            today: 'Hari ini',
            cancel: 'Batalkan',
            clear: 'Kosongkan',
            confirm: 'OK',
            selectDate: 'Pilih tarikh',
            selectTime: 'Pilih masa',
            startDate: 'Tarikh Mula',
            startTime: 'Masa Mula',
            endDate: 'Tarikh Tamat',
            endTime: 'Masa Tamat',
            prevYear: 'Tahun Sebelumnya',
            nextYear: 'Tahun Hadapan',
            prevMonth: 'Bulan Sebelumnya',
            nextMonth: 'Bulan Depan',
            year: '',
            month1: 'Januari',
            month2: 'Februari',
            month3: 'Mac',
            month4: 'April',
            month5: 'Mei',
            month6: 'Jun',
            month7: 'Julai',
            month8: 'Ogos',
            month9: 'September',
            month10: 'Oktober',
            month11: 'November',
            month12: 'Disember',
            week: 'minggu',
            weeks: {
                sun: 'Ahd',
                mon: 'Isn',
                tue: 'Sel',
                wed: 'Rab',
                thu: 'Kha',
                fri: 'Jum',
                sat: 'Sab'
            },
            months: {
                jan: 'Jan',
                feb: 'Feb',
                mar: 'Mac',
                apr: 'Apr',
                may: 'Mei',
                jun: 'Jun',
                jul: 'Jul',
                aug: 'Ogos',
                sep: 'Sep',
                oct: 'Okt',
                nov: 'Nov',
                dec: 'Dis'
            }
        },
        select: {
            loading: 'Memuatkan',
            noMatch: 'Tiada data yang sepadan',
            noData: 'Tiada data',
            placeholder: 'Pilih'
        },
        cascader: {
            noMatch: 'Tiada data yang sepadan',
            loading: 'Memuatkan',
            placeholder: 'Pilih',
            noData: 'Tiada data'
        },
        pagination: {
            goto: 'Pergi ke',
            pagesize: '/halaman',
            total: 'Jumlah {total}',
            pageClassifier: '',
        },
        messagebox: {
            title: 'Mesej',
            confirm: 'OK',
            cancel: 'Batalkan',
            error: 'Input salah'
        },
        upload: {
            deleteTip: 'tekan padam untuk keluarkan',
            delete: 'Padam',
            preview: 'Pratonton',
            continue: 'Teruskan'
        },
        table: {
            emptyText: 'Tiada data',
            confirmFilter: 'Sahkan',
            resetFilter: 'Tetapkan semula',
            clearFilter: 'Semua',
            sumText: 'Jumlah'
        },
        tree: {
            emptyText: 'Tiada data'
        },
        transfer: {
            noMatch: 'Tiada data yang sepadan',
            noData: 'Tiada data',
            titles: ['Senarai 1', 'Senarai 2'], // untuk diterjemahkan
            filterPlaceholder: 'Masukkan kata kunci', // untuk diterjemahkan
            noCheckedFormat: '{total} barang', // untuk diterjemahkan
            hasCheckedFormat: '{checked}/{total} diperiksa' // untuk diterjemahkan
        },
        image: {
            error: 'GAGAL'
        },
        pageHeader: {
            title: 'Kembali' // untuk diterjemahkan
        },
        popconfirm: {
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
        },
        empty: {
            description: 'Tiada data'
        },
    }
}
