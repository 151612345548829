module.exports = {
  info: {
    url: 'www.infinox.com',
    phone: '', // 0-800-060-8744
    email: 'support@infinox.com',
    asiaEmail: 'support.asia@infinox.com',
    accountEmail: 'accounts@Infinoxmarkets.com',
    liveChatVisible: true,
  },
  riskValue: '83.66%',
  menus: [
    {
      name: 'menu.dashboard',
      path: '/home',
      icon: 'home',
    },
    {
      name: 'menu.ibReport',
      path: '/ibreport',
      icon: 'ibreport',
    },
    {
      name: 'menu.rebateReport',
      path: '/rebatereport',
      icon: 'rebatereport',
    },
    {
      name: 'menu.tradeReport',
      path: '/tradereport',
      icon: 'tradereport',
    },
    {
      name: 'menu.iblevel',
      path: '/iblevel',
      icon: 'iblevel',
    },
    {
      name: 'menu.ibAccs',
      path: '/ibaccounts',
      icon: 'ibaccounts',
    },
    {
      name: 'menu.pendingAccs',
      path: '/pendingaccounts',
      icon: 'pendingaccounts',
    },
    {
      name: 'menu.leads',
      path: '/leads',
      icon: 'leads',
    },
    // {
    //   name: 'menu.unfundedAccs',
    //   path: '/unfundedaccounts',
    //   icon: 'unfundedaccounts',
    // },
    {
      name: 'menu.allRetailAccs',
      path: '/allretailaccounts',
      icon: 'allRetailAccounts',
    },
    {
      name: 'menu.payHistory',
      path: '/rebatePaymentHistory',
      icon: 'rebatePaymentHistory',
    },
    {
      name: 'menu.ibProfile',
      path: '/settings',
      icon: 'settings',
    },
    {
      name: 'menu.refLinks',
      path: '/referralLinks',
      icon: 'referralLinks',
    },
    {
      name: 'menu.contactUs',
      path: '/contactUs',
      icon: 'contactUs',
    },
  ],
  accountTypeMaps: {
    0: 'ALL',
    1: 'Standard STP',
    2: 'Raw ECN',
    3: 'Standard STP & Binary Options',
    4: 'Raw ECN & Binary Options',
    5: 'Binary Options Only',
    6: 'Swap free STP',
    7: 'Swap free ECN',
    8: 'PRO ECN',
    10: 'PAMM',
    101: 'MT5 Standard STP',
    11: 'Net STP',
    12: 'Net ECN',
    13: 'Hedge STP',
    14: 'Hedge ECN',
    15: 'MT5 Swap free STP', // MT5 Swap free STP
    16: 'MT5 Swap free ECN', // MT5 Swap free ECN
    17: 'MT5 PRO ECN', //MT5 PRO ECN
    19: 'Cent STP', //mt4
    20: 'Cent Swap free STP', //mt4
    22: 'Cent ECN', //mt4
    23: 'Cent Swap free ECN', //mt4
    24: 'Cent STP', //mt5
    25: 'Cent Swap free STP', //mt5
    26: 'Cent ECN', //mt5
    27: 'Cent Swap free ECN', //mt5
    28: 'MT5 PAMM', //MT5 PAMM
    99: 'Social Trading',
  },
  withdrawSetup: {
    checkMethodMinAmount: true,
    cancelWithdraw: true,
  },
}
