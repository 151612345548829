export default {
  home: {
    RebateAccountNumber: '返佣账号',
    totalCommission: '总佣金',
    APPLY_FOR_A_REBATE: '申请佣金',
    availableBalance: '余额',
    WITHDRAW_REBATE: '出金',
    TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT: '转账',
    TOTAL_VOLUME: '交易量',
    NET_FUNDING: '净入金',
    DEPOSITS: '总入金',
    WITHDRAW: '总出金',
    OPEND_ACCOUNT: '已开账号数',
    PERFORMANCE: '趋势图',
    MOST_TRADED_INSTRUMENTS: '交易产品分布',
    TOP_PERFORMING_ACCOUNTS_FOR_THIS_MONTH: '本月业绩表现TOP',
    RECENTLY_OPENED_ACCOUNTS: '近期新开户',
    ACCOUNT_MANAGER: '客户经理',
    Account: '账号',
    Name: '姓名',
    Volume: '交易量',
    Rebate: '佣金',
    Balance: '余额',
    ContactClient: '联系客户',
    applyRebateSuccessTip:
      '祝贺你，我们收到了您的申请要求。 | {amount}将在1个工作日内转入返佣账户{account}。 | 一旦您的申请处理完毕，您可以提取或转移资金。',
    HOVER: '前一天的佣金将会在澳大利亚东部时间9至11时之间计算',
    blacklist: '您的账号目前暂时无法提交返佣申请，请联系您的客户经理',
  },
  liveChat: { header: '在线聊天' },
  contactUs: {
    tip: '如果您想要获得更多的信息或者有任何问题, 请通过以下联系方式联系我们. 我们将在24小时内尽快回复您.',
  },
  fileUploader: {
    supportedFormat: '文件支持类型: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: '文件支持类型: {files}',
    maxSize: '最大文件大小: {maxSize}MB',
    maxFileNum: '最多文件数: {maxFileNum}',
    failedFileExtension: '请上传正确的文件类型',
  },
  notification: {
    pendingIdentityProof: {
      header: '帐户信息不完整',
      id: '身份',
      poa: '地址',
      both: '身份/地址',
      content: '您的 {doc} 证明目前正在处理中，将很快完成。',
    },
  },
  identityProof: {
    home: {
      header: '帐户信息不完整',
      content: '您好，{name}，您的帐户申请已接近完成，但是您仍然需要提交其他文件以验证通过您的帐户。',
    },
    popup: {
      header: '您好 {name}, 我们需要更多的账户信息以处理您的账户申请. 请提供以下所需文件:',
      addressProofSpan: `<strong>
      <b>确认您的地址</b>
      </strong>
      <br />
      <I>请提交<b>以下文件之一</b>：
        <br /><br />
      </I>
      - 近期的水电费账单</br>(最近3个月内)
      <br />
      <b class="or">或</b><br />
      - 近期的银行对账单</br>(最近3个月内)
      <br />
      <br />
      <b>请注意:</b> 您的全名及目前的地址必须在您提供的以上文件中清晰可见。<br />`,
    },
    reasonType: {
      4: '不完整的文件',
      5: '不清楚的文件',
      6: '需要提供彩色复印件',
      8: '开户名称不一致',
      9: '地址证明签发日期不合符规定',
    },
    reason: '我们无法审核您的文件，原因: {reason}，请重新上传',
    success: '感谢您上传的文件, 我们将会尽快对文件进行审核。',
  },
  rebate: {
    status: {
      processed: '已完成',
      processing: '处理中',
      rejected: '被拒绝',
    },
  },
  ibAccounts: {
    ibAccount: '返佣账号',
    subIbs: '子代理返佣账号',
    tradingList: '交易清单',
    OpeningList: '持仓列表',
    closeList: '已平仓列表',
    tradingListColumn: {
      ticket: '交易单号',
      openTime: '开仓时间',
      type: '类型',
      volume: '交易量',
      item: '产品名称',
      openPrice: '开盘价',
      closeTime: '关仓时间',
      closePrice: '收盘价',
      commission: '返佣',
      profit: '盈亏',
    },
  },
  withdraw: {
    warning: {
      desc: '请注意以下事项：',
      desc1: '使用者需承担银行转帐手续费，通常在135至250日圆之间。',
      desc2: '下午2点后提出的提款申请可能会延迟或于次日处理。',
    },
    banklist: {
      agriculturalbankofchina: '中国农业银行',
      bankofbeijing: '北京银行',
      bankofchina: '中国银行',
      bankofcommunications: '交通银行',
      bankofdongguan: '东莞银行',
      bankofguangzhou: '广州银行',
      bankofhangzhou: '杭州银行',
      bankoflanzhoucoltd: '兰州银行股份有限公司',
      bankofningbo: '宁波银行',
      bankofshanghai: '上海银行',
      chinabohaibank: '渤海银行',
      chinaciticbank: '中信银行',
      chinaconstructionbank: '中国建设银行',
      chinaeverbrightbank: '光大银行',
      chinaindustrialbank: '兴业银行',
      chinamerchantsbank: '招商银行',
      chinaminshengbank: '中国民生银行',
      chinazheshangbank: '浙商银行',
      guandongdevelopmentbank: '广发银行',
      huaxiabank: '华夏银行',
      industrialandcommercialbankofchina: '中国工商银行',
      jiangsunantongruralcommercialbank: '江苏南通农村商业银行',
      pinganbank: '平安银行',
      postalsavingsbankofchina: '中国邮政储蓄银行',
      shanghaipudongdevelopmentbank: '浦发银行',
      shanxiruralcreditcooperative: '山西农村信用社',
      shenzhendevelopbank: '深圳发展银行',
      wenlingruralcommercialbank: '温岭农商银行',
      zhejiangyilururalcommercialbank: '浙江挏庐农村商业银行',
    },
    default: {
      withdrawlFeeAlert: '银联出金低于100美元，将收取20美元的手续费。',
      selectCC: '选择信用卡',
      selectBA: '选择银行账户',
      anotherCC: '手动输入信用卡',
      anotherBA: '添加银行帐户',
      remember: '记住我的帐号',
    },
    transfer: {
      info: '{platform} 会垫付每个月第一次出金所产生的银行费用. 当月内的其他出金将会产生20单位出金货币的银行费用.',
      label: {
        upload: '请上传一张近3个月银行对账单的截图、照片或复印件',
      },
      formValidation: {
        upload: '请上传银行帐单',
      },
    },
    alipay: {
      field: { accountName: '支付宝账号名字', email: '支付宝账号邮箱' },
    },
    REBATE_WITHDRAW_REQUEST: '出金申请',
    PleaseSelectDesiredWithdrawMethod: '请选择所需的出金方法',
    BankName: '银行名称',
    bankCode: '银行代码',
    bankBranchCode: '分行代码',
    bankAccountCurrency: '银行账户货币',
    BankAccountsName: '银行账户名',
    FasaPayAccountName: 'FasaPay账户姓名',
    AustralianBankName: '澳洲银行名称',
    BankAccountNumber: '银行账号',
    FasaPayAccountNumber: 'FasaPay 账户号码',
    BankAccountBeneficiary: '银行收款人姓名',
    BSB: 'BSB信息',
    SortCode: '银行代码(Sort Code)',
    skrillEmail: 'Skrill邮箱',
    NetellerEmail: 'Neteller邮箱',
    PerfectMoneyEmail: 'Perfect Money邮箱',
    SticpayEmail: 'Sticpay邮箱',
    BitwalletEmail: 'Bitwallet邮箱',
    BitcoinEmail: 'Bitcoin钱包地址',
    USDTEmail: 'Tether钱包地址',
    BankAccountName: '收款人姓名',
    BankBranch: '支行名称（3码分行代号)',
    BankAccountType: '银行帐户类型',
    Province: '省',
    City: '市',
    BankAddress: '银行地址',
    AccountNumberIBAN: '银行账号',
    BankBeneficiaryName: '银行收款人姓名',
    BankAccountBeneficiaryName: '银行账户收款人姓名',
    AccountHoldersAddress: '账户持有人地址',
    SelectWithdrawCard: '请选择出金卡',
    Swift: 'Swift代码',
    SwiftAud: 'Swift代码(非澳元账号)',
    ABA_SortCodeABA: 'Sort代码',
    DocumentType: 'Document Type',
    DocumentDetails: 'Document Details',
    PixKeyType: 'PIX Key Type',
    PixKey: 'PIX Key',
    AccountType: 'Account Type',
    AccountDetails: 'Account Details',
    ImportantNotes: '重要信息',
    ifscCode: 'IFSC 代码',
    uploadDocument: '请上传一张近3个月银行对账单的截图、照片或复印件',
    YourPaymentGreaterThanAvailableBalance: '您的出金金额大于可用余额',
    integer: '仅支持整数输入',
    plnWithdraw: '金额必须小于或等于zł {money} PLN',
    CannotUseMoreThanTwoDecimalValues: '无法使用超过两位小数的数值和负数',
    YourPaymentIsBeingProcessed: '您的出金申请已经提交',
    Itcannotbe0: '出金不能为0',
    noCardWarn: '没有有效的卡, 请先绑卡',
    amtLarger: '最低出金金额为{currencyType}{minLimit}。 如果您的余额小于 30 必须全额提取',
    blackList: `很遗憾，您目前无法提交提款请求。请联系{supportEmail}取得更多信息`,
    NetellerChargeAlert: 'Neteller 出金限额为每笔交易 500,000 美元，并收取 2% 的手续费（上限为 30 美元）。',
    FasaPayChargeAlert: 'FasaPay 对每次出金收取 0.5% 的手续费。',
    SkrillChargeAlert: 'Skrill 对每次出金收取 1% 的手续费。',
    cryptoAlert:
      '请注意，我们无法通过 BUSDT 进行存款或取款，请确保地址和加密货币与我们接受的链和货币匹配，否则您可能会损失资金。',
    usdtNote:
      '受ERC20的矿工费上涨影响，出金处理时间也相应延迟。为了保障您的出金处理速度，您可以选择使用TRC20，或支付较高的矿工费申请出金，以加快您的出金申请进度。',
    name: '姓名',
    phone: '电话',
    address: '详细地址',
    email: '电子邮件',
    status: {
      withdrawalSubmitted: '已提交',
      withdrawalCanceled: '取消',
      withdrawalAuditing: '处理中',
      withdrawalRejected: '拒绝',
      withdrawalAccepted: '处理中',
      withdrawalPayFailed: '失败',
      withdrawalSuccess: '已完成',
      withdrawalFailed: '失败',
      withdrawalPending: '等候中',
      withdrawalPartialFailed: '失败',
      transferSubmitted: '处理中',
      transferSuccess: '已完成',
      transferRejected: '拒绝',
      transferFailed: '失败',
      transferProcessing: '处理中',
    },
    formErrorMsg: {
      bankName: '请填写银行名称',
      bsb: '请填写BSB',
      sortCode: '请填写Sort Code',
      beneName: '请填写帐户名',
      bankCodeReq: '银行代码是必需的',
      bankBranchCodeReq: '分行代码是必需的',
      accNum: '请填写帐户号码',
      swift: '请填写Swift',
      bankAddress: '请填写银行地址',
      holderAddress: '请填写持卡人地址',
      amt: '请填写金额',
      firstSixDigits: '请填写卡号前六位',
      lastFourDigits: '请填写卡号前四位',
      branchName: '请填写分行名称',
      email: '请填写电子邮件',
      accName: '请填写银行账户名',
      province: '请填写银行所在省',
      city: '请填写银行所在市',
      bankCard: '请选择一张卡',
      emailFormat: '请填写正确的电邮地址',
      amt0: '金额不能为0',
      ifscCode: '请填写IFSC 代码',
      bitcoin: '请填写Bitcoin钱包地址',
      bitcoinAddressLength: '比特币钱包地址必须在 26 到 42 个字符之间',
      usdt: '请填写USDT钱包地址',
      dynamicReq: '请填写{fieldname}',
      uploadDocument: '请上传银行帐单',
      onlyChineseCharcters: '请仅输入中文',
    },
    note: `由于最近的变化，我行可能无法为在某些国家注册的客户办理国际银行转账。<br/>
      如果您必须通过国际银行转账取现，请与我们的客户支持联系：+ 44 2080 363 883 或<a href='mailto:{mail}'>{mail}</a>.<br/>
      给您带来的不便我们深表歉意。`,
    minAmountWarn:
      '申请的提款金额小于申请的支付网关的最低金额。在提交提款请求之前，请将您的账户余额充值至/超过最低限额。更多信息可以在<a href="https://{regulatorUrl}/clients/accounts/withdraw/">这里</a>找到。',
  },
  transfer: {
    REBATE_TRANSFER_REQUEST: '转账申请',
    transferAmountIsGreaterThanAvailableBalance: '您的转账金额大于可用余额',
    yourRebateTransferHasBeenProcessed: '您的转账申请已提交',
    PleaseEnterTransferInformation: '请输入准确的转账信息',
    tradingAccount: '交易账号',
    rebateAccount: '返佣账号',
    account: '账号',
    blackList: '此账户已被限制进行账户间转账，请联系 {supportEmail}',
  },
  transferHistory: {
    payStatus: {
      processing: '处理中',
      paid: '已付款',
      unpaid: '未付款',
      rejected: '被拒绝',
    },
  },
  common: {
    liveChat: { desc: '<li><p>需要帮助?</p><span>在线客服</span></li>' },
    lang: {
      english: '英文',
      chinese: '中文',
      french: '法文',
      thai: '泰文',
      german: '德文',
      spanish: '西班牙文',
      malay: '马来西亚文',
      viet: '越南文',
      indo: '印尼文',
      arabic: '阿拉拍文',
      japanese: '日文',
    },
    button: {
      confirm: '确认',
      cancel: '取消',
      identityProof: '完成帐户申请',
      upload: '上传',
      selectFile: '上传文件',
      fundnow: '即刻入金',
      iunderstand: '我明白了',
    },
    withdrawChannel: {
      hongkongbanktransfer: '香港本地银行转账',
      banktransfer: '银行转帐',
      aus: '银行转账 (澳大利亚)',
      int: '银行转账 (国际)',
      chn: '银行转账 (中国)',
      uk: '银行转帐 (英国)',
      unionpay: '银联转帐',
      thailandinstantbankwiretransfer: '泰国银行即时转账',
      malaysiainstantbankwiretransfer: '马来西亚银行即时转账',
      banktransferbpaypolipay: '银行转帐/BPay银行转帐/PoLi支付',
      cryptocurrencybitcoin: '加密货币-Bitcoin',
      cryptocurrencyusdt: '加密货币-USDT',
      nigeriainstantbankwiretransfer: '尼日利亚银行即时转账',
      vietnaminstantbankwiretransfer: '越南银行即时转账',
      indonesiainstantbankwiretransfer: '印度尼西亚银行即时转账',
      philippinesinstantbankwiretransfer: '菲律宾银行即时转账',
      southafricainstantbankwiretransfer: '南非银行即时转账',
      indiainstantbanktransfer: '印度银行即时转账',
      laosinstantbankwiretransfer: '老挝即时银行电汇',
      ugandabanktransfer: '乌干达当地银行转账',
      rwandabanktransfer: '卢旺达当地银行转账',
      zambiabanktransfer: '赞比亚当地银行转账',
      congobanktransfer: '刚果当地银行转账',
      cameroonbanktransfer: '喀麦隆当地银行转账',
      burundibanktransfer: '布隆迪当地银行转账',
      kenyabanktransfer: '肯尼亚当地银行转账',
      ghanabanktransfer: '加纳当地银行转账',
      tanzaniabanktransfer: '坦桑尼亚当地银行转账',
      brazilbanktransfer: '巴西银行即时转账',
      mexicobanktransfer: '墨西哥银行即时转账',
      internetbankingmexico: '线上银行 (墨西哥)',
      internetbankingbrazil: '线上银行 (巴西)',
      astropay: 'Astropay',
      transfer: '转账至账号',
      alipay: '支付宝',
    },
    country: { international: '国际', australia: '澳大利亚', uk: '英国' },
    field: { country_region: '国家/地区', yes: '是', no: '否' },
    keys: {
      SUBMIT: '提交',
      FROM: '从',
      TO: '到',
      DATE: '日期',
      UPDATE: '更新',
      pleaseSelect: '请选择',
      amount: '金额',
      actualAmount: '  实际出金 = ',
      rebateAccount: '返佣账号',
      comingSoon: '敬请期待',
      noData: '没有数据',
      NAME: '姓名',
      EMAIL: '电子邮件',
      ACCTYPE: '账户类型',
      PLATFORM: '交易平台',
      BASECURRENCY: '币种',
      DATERANGE: '申请时间',
      STATUS: '状态',
      DEST: '目标帐户',
      tradingHistory: '交易历史',
      ACCNUM: '账号',
      BALANCE: '余额',
      NONE: '无',
      ALL: '全部',
      PROFITLOST: '盈亏',
      MARGINLEVEL: '保证金比例',
      ACCOUNTEQUITY: '账户净值',
      CREDIT: '赠金',
      DOWNLOAD: '下载',
    },
    dateRange: {
      today: '今天',
      yesterday: '昨天',
      lastSevenDays: '最近7天',
      lastThirtyDays: '最近30天',
      weekToDate: '本周',
      monthToDate: '本月',
      quarterToDate: '本季度',
      previousWeek: '上周',
      previousMonth: '上月',
      previousQuarter: '上季度',
    },
    products: {
      forex: '外汇',
      commodities: '大宗商品',
      indices: '指数',
      crypto: '加密货币',
      bond: '债券',
      bonds: '债券',
      gold: '黄金',
      minigold: '迷你黄金',
      goldfutures: '黄金期货',
      bitcoin: '加密货币',
      silver: '白银',
      silverfutures: '白银期货',
      oil: '原油',
      oilfutures: '原油期货',
      index: '指数',
      indexfutures: '指数期货',
      etf: 'ETF',
      forexfutures: '外汇',
      stock: '外汇期货',
    },
  },
  withdrawalFreeze: {
    header: '暂时不支持提现',
    desc: '由于您修改了登录密码，为了您的账户资金安全，T+{day} 天内暂不支持提现。预计 {hour} 小时后解除限制。',
    desc2: '由于您修改了登录邮箱/手机号，为了您的账户资金安全，T+{day} 天内暂不支持提现。预计 {hour}小时后解除限制。',
  },
  report: {
    rebate: {
      column: {
        date: '日期',
        name: '名字',
        accountNumber: '账号',
        accountType: '账号类型',
        volume: '交易量',
        rebate: '佣金',
        totalRebate: '总佣金',
        instrument: '产品',
        notionalvalue: '名义价值',
      },
      dropdown: {
        allInstruments: '所有产品',
        all: '全部',
        instruments: '产品',
      },
      others: {
        lots: '手数',
        rebate: '佣金',
        totalRebate: '总佣金',
        instrumentsTraded: '已交易产品',
        rebates: '佣金',
      },
    },
    btn: { showAll: '显示全部', hide: '隐藏' },
    comingSoon: '敬请期待',
    noData: '没有数据',
    nodataMessage: '当前没有要下载的数据，请您先筛选条件后再进行下载',
    timeLimitMessage: '仅可下载90天内的数据，请您重新筛选后再进行下载',
  },
  ibReport: { openedAccs: '已开账户数', funding: '出入金记录', account: '账号' },
  lead: { demoAccounts: '模拟账户' },
  pendingAccounts: {
    errorDoc: '以下客户已经提交注册申请, 但还未提供满足开设账户的相关证件.',
    acceptedDocType: '可接受的证件类型包括:',
    primaryDoc: '身份证明',
    passport: '有效期内的护照复印件(签名页)',
    id: '政府颁发的身份证复印件',
    dl: '有效期内的驾照复印件',
    address: '地址证明',
    addressList: [
      '水/电/煤账单复印件, 清晰的显示客户的名字和居住地址 (必须是最近3个月内)',
      '信用卡账单/银行对账单复印件',
    ],
    tip: '请联系客户提供一份身份证明和地址证明到 ',
  },
  rebatePaymentHistory: {
    rebateHistory: '佣金申请记录',
    withdrawHistory: '佣金出金记录',
    transferHistory: '转账历史',
  },
  referralLinks: {
    copyLink: '复制链接',
    downloadPage: '下载 APP 链接',
    campLang: '推广语言',
    cpy: '复制链接',
    alreadyCPY: '已复制!',
    liveAcc: '开户链接',
    demoAcc: '模拟账户',
    homePage: '主页链接',
    promotion: '活动推广链接',
    hintTitle: '什么是活动推广？',
    hintMessage:
      '活动推广链接是提供给IB的新的推广方式，该分享链接会引导用户至赠金活动页面并完成注册，通过该分享链接可有效提高用户注册的成功率',
    hintShortLink: '使用短链接分享',
    hintShortLinkTitle: '什么是短链接',
    hintShortLinkMessage: '短链接是一种链接生成规则，它能更好的保护您的分享链接不被他人更改，从而保障您的IB拓展权益',
    customButton: '自定义',
    copyButton: '复制',
    customInviteCodeHeader: '自定义邀请码',
    currentCode: '当前邀请码',
    newCode: '新邀请码',
    notice: '注意:邀请码最少6位参数。可输入字母、数字、非特殊符号',
    inviteCodeFormatError: '邀请码格式错误，请重新编辑',
    inviteCodeAlreadyExist: '该邀请码已被其他人抢注，请重新输入',
    pleaseEnter: '请输入',
    inviteCodeHeader: '推广邀请码',
    inviteCode: '邀请码',
  },
  settings: {
    info: '信息',
    reg: '注册日期',
    CustomerInfo: '客户信息',
    name: '姓名',
    email: '电子邮件',
    phone: '电话号码',
    address: '地址',
  },
  unfundedAcc: {
    neverFundedAccs: '从未入金客户',
    prevFundedZero: '已入金客户(余额为0)',
    accBalance: '账号',
  },
  multiib: {
    level: '{level} 级',
    noib: '此帐户下没有子介绍经纪商（IB）。',
  },
  AWCDialog: {
    optIn: '立即加入',
    noThanks: '不用了，謝謝',
  },
  header: { bckToCP: '返回客户后台', logout: '登出' },
  menu: {
    dashboard: '概览',
    ibReport: 'IB 报告',
    rebateReport: '佣金报告',
    iblevel: '多级介绍经纪商（IB）',
    ibAccs: '客户管理',
    leads: '潜在客户',
    pendingAccs: '待审核客户',
    unfundedAccs: '未入金客户',
    payHistory: '交易历史',
    ibProfile: '个人信息',
    refLinks: '推广链接',
    contactUs: '联系我们',
    switchCP: '返回客户后台',
  },
  footer: {
    riskWarn: `风险警告：交易外汇和差价合约涉及重大风险，可能导致您的投资资本损失。
    交易杠杆产品可能并不适合所有投资者。
    交易前，请考虑您的经验水平、投资目标，并在必要时寻求独立的财务建议。
    请阅读我们网站上的法律文件，并确保您在做出任何交易决定之前完全了解风险。`,
    riskWarn_fsc:
      '风险提示：金融衍生品是复杂的交易工具，并具有因杠杆作用而导致迅速亏损的高风险。您应该考虑您是否了解金融衍生品的运作方式，以及您是否有能力承担损失资金的高风险。',
  },
  responseMsg: {
    //4xx warning
    410: '参数验证失败',
    411: '密码错误',
    420: '用户不存在',
    421: '密码重置被拒',
    490: '请不要重复输入卡信息',
    //5xx error
    500: '发生错误',
    501: '申请失败, 请稍后重试',
    505: '文件上传失败',
    510: '验证码错误',
    511: '发送短消息失败',
    520: '登录失败, 请重试',
    521: '您在此设备上已处于登入状态!',
    522: '请重新登录',
    523: '请重新登录',
    524: '您提供的邮箱尚未注册',
    525: '用户名或密码错误',
    527: '用户不是IB',
    528: '用户不存在',
    529: '用户名或密码错误',
    530: '代码无效',
    531: '代码无效',
    540: `系统找不到您的交易账号`,
    541: '无法找到返佣账号',
    542: '抱歉，您不允许开同名账号',
    544: '您不允许申请一个额外的账户，直到您的身份证明核实为止',
    550: '账号错误',
    551: '您的余额不足',
    554: '请求的数额为0或者空',
    562: '无法处理您的支付请求，请重新尝试。如问题仍未解决，请通过Live Chat或者电子邮件{email}与我们取得联系',
    564: '国家未能配合该支付渠道',
    565: '银行代码错误',
    566: '无法找到支付方式',
    581: '您已成功选择加入促销活动。 请注意，您必须先入金才能出金。',
    584: `您没有可申请的佣金`,
    590: '无法找到转出账号',
    591: '无法找到转入账号',
    593: '由于您的账户包含信贷部分，我们无法对您所选择的账户进行资金转移处理。',
    594: '不允许从这个账户转移资金',
    session_timeout: '登录时间超时, 正在回到登录页面',
  },
}
