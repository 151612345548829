export const abc = 123
export const noReferralLinksUserList = [
    // test data
    // 10006564,
    // 10006888,
    // 10005192,
    // 10006306,
    1612685,
    1612699,
    1612700,
    1612702,
    1612704,
    1612706,
    1612707,
    1612709,
    1612710,
    1612711,
    1612712,
    1612714,
    1612715,
    1612717,
    1612719,
    1612721,
    1612724,
    1612726,
    1612728,
    1612730,
    1612731,
    1612733,
    1612735,
    1612737,
    1612741,
    1612745,
    1612749,
    1612751,
    1612732,
    1612739,
    1612746,
    1612727,
    1612738,
    1612742,
    1612743,
    1612744,
    1612748,
    1612750,
    1612752,
    458972,
    1463796,
    1065440,
    1256487,
    899026,
    1342028,
    256281,
    181931,
    585141,
    450518,
    422884,
    675436,
    588775,
    440628,
    878548,
    823636,
    836213,
    834078,
    903708,
    881638,
    1588469,
    216875,
    933881,
    1057045,
    168896,
    1039001,
    509587,
    1080018,
    1113389,
    1190013,
    216240,
    813700,
    797766,
    1217401,
    384891,
    998638,
    1134568,
    1191739,
    1236177,
    648468,
    1524347,
    1195499,
    1603027,
    1603012,
    23944,
    175112,
    177593,
    226215,
    1603082,
    181531,
    1603086,
    1603087,
    1603089,
    190784,
    196266,
    218182,
    1603095,
    1603096,
    1524339,
    1603097,
    237036,
    251310,
    253105,
    864412,
    226033,
    263858,
    244379,
    1603102,
    274992,
    285313,
    1603103,
    1603104,
    314397,
    310790,
    418890,
    360483,
    1603113,
    362808,
    400166,
    1603115,
    373094,
    376766,
    917358,
    1603122,
    390348,
    1603123,
    447783,
    278824,
    1524344,
    1524345,
    365234,
    178009,
    453309,
    400387,
    1603129,
    448824,
    1603130,
    181264,
    1603132,
    237776,
    435982,
    223820,
    458397,
    1467018,
    1603133,
    1603135,
    470191,
    198179,
    1603137,
    1172214,
    1603138,
    214279,
    1603139,
    1524338,
    1603140,
    1603141,
    562235,
    1603143,
    640061,
    345242,
    218151,
    692142,
    686569,
    364686,
    1201217,
    700102,
    900145,
    959672,
    714478,
    1603146,
    576998,
    1603147,
    904513,
    1603148,
    1603149,
    1603150,
    714217,
    598747,
    802385,
    1603151,
    784920,
    1603153,
    751804,
    637743,
    1603154,
    726127,
    1603155,
    552374,
    1603159,
    1603160,
    1603161,
    869244,
    871804,
    630374,
    1603165,
    1603166,
    364456,
    1603167,
    1603168,
    901541,
    1603170,
    1603172,
    1603174,
    1603175,
    1603176,
    891692,
    954650,
    1603179,
    1603221,
    868751,
    1603184,
    758926,
    1603185,
    666460,
    1603186,
    956820,
    538795,
    564041,
    694187,
    1603187,
    1603188,
    972852,
    1603190,
    1295884,
    901254,
    975739,
    894748,
    474822,
    477655,
    774377,
    993827,
    1603191,
    1005016,
    974833,
    991435,
    1603194,
    1603195,
    1603196,
    1004139,
    1603197,
    1024825,
    1603198,
    1603199,
    1603200,
    562496,
    1037350,
    1603201,
    985762,
    1524346,
    1603204,
    820712,
    1603206,
    1603207,
    1044533,
    1603209,
    1603210,
    369767,
    1603211,
    1603212,
    1603213,
    1603214,
    1603215,
    1603249,
    1603218,
    759228,
    1114989,
    770133,
    586446,
    1603220,
    1184535,
    1603221,
    1145205,
    1222332,
    1603222,
    1142246,
    1188721,
    1156338,
    1603224,
    1603226,
    1603227,
    1603228,
    582009,
    1154922,
    198812,
    1603229,
    1159328,
    1603230,
    1603231,
    797710,
    1603232,
    1603233,
    1603235,
    975790,
    1603238,
    1198656,
    983450,
    1202494,
    1190951,
    1017051,
    1603240,
    1603241,
    1036628,
    851662,
    1151973,
    1603243,
    1603246,
    1258377,
    1603247,
    1603248,
    976466,
    1603249,
    1603253,
    1524348,
    1217708,
    840390,
    1289018,
    1603257,
    1249593,
    1265417,
    1256292,
    1603260,
    1120893,
    1524341,
    1263409,
    1603263,
    1603264,
    1603265,
    1603267,
    1603268,
    1073677,
    1603269,
    1284902,
    1277747,
    1603271,
    1294243,
    454758,
    1603272,
    1284367,
    1276737,
    868073,
    1293931,
    1308978,
    1237176,
    1603275,
    1298237,
    1282474,
    1603277,
    382027,
    1305883,
    1197262,
    1603279,
    1603280,
    1603281,
    1603282,
    1264135,
    1603283,
    882081,
    1331508,
    1319746,
    1367175,
    1603285,
    1603286,
    1207431,
    1457067,
    1335060,
    265266,
    1335785,
    1328949,
    1603291,
    1276200,
    1070049,
    1353765,
    1603293,
    1603294,
    1603296,
    711963,
    1603297,
    1392244,
    1194206,
    544622,
    1603298,
    1294204,
    1603302,
    735592,
    1086711,
    1603303,
    1603306,
    1603307,
    1524349
]