export default {
  // 超出省略加title属性
  textOverflow: {
    // 指令的定义
    inserted: el => {
      $(el).addClass('text_overflow')
      $(el).attr('title', $(el).text())
    },
    componentUpdated: el => {
      $(el).attr('title', $(el).text())
    },
  },
  // header & menu click show sidebar
  'menu-click-outside': {
    bind: function(el, binding, vnode) {
      el.clickOutsideEvent = function(event) {
        if (!(el == event.target || el.contains(event.target)) && event.target.id != 'menu_open_button') {
          vnode.context[binding.expression](event)
        }
      }
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
  },
}
